<template>
  <div class="popup subuser">
    <div class="container-sm">
      <div class="panel">

        <!-- Title -->
        <div class="title">
          <b>{{ sub_user._id ? $t('users.popup.update_title') : $t('users.popup.add_title') }}</b>
          <aside class="actions">
            <a class="info" @click="close">
              <i class="fas fa-times"></i>
            </a>
          </aside>
        </div>

        <!-- Content -->
        <div class="popup-content">
          <!-- Form -->
          <div class="row space">
            <div class="col-md-6">
              <label>{{ $t('users.popup.first_name_label') }}</label>
              <input type="text" v-model="sub_user.first_name" :placeholder="$t('users.popup.first_name_placeholder')">
            </div>
            <div class="col-md-6">
              <label>{{ $t('users.popup.last_name_label') }}</label>
              <input type="text" v-model="sub_user.last_name" :placeholder="$t('users.popup.last_name_placeholder')">
            </div>
          </div>

          <div class="row space">
            <div class="col-md-6">
              <label>{{ $t('users.popup.email_label') }}</label>
              <input type="email" v-model="sub_user.email" :disabled="!is_email_editable" :placeholder="$t('users.popup.email_placeholder')">
            </div>
            <div class="col-md-6">
              <label>{{ $t('users.popup.company_role_label') }}</label>
              <input type="text" v-model="sub_user.company_role" :placeholder="$t('users.popup.company_role_placeholder')">
            </div>
          </div>

          <div class="row space">
            <div class="col-md-6">
              <label>{{ $t('users.popup.organization_label') }}</label>
              <organizations-select v-model:value="sub_user.organization._id" :placeholder="$t('users.popup.organization_placeholder')"></organizations-select>
            </div>
            <div class="col-md-6" v-if="!sub_user._id">
              <label>{{ $t('users.popup.language_preference_label') }}</label>
              <select v-model="sub_user.language">
                <option value="" :label="$t('users.popup.language_preference_placeholder')" selected disabled></option>
                <option value="fr">Français</option>
                <option value="en">English</option>
              </select>
            </div>
          </div>

          <div class="row space" v-if="!sub_user._id">
            <div class="col-md-6">
              <label>{{ $t('users.popup.password_label') }}</label>
              <input type="password" v-model="sub_user.password" :placeholder="$t('users.popup.password_placeholder')">
            </div>
            <div class="col-md-6">
              {{ $t('users.popup.confirmation_password_label') }}
              <input type="password" v-model="password_confirmation" :placeholder="$t('users.popup.confirmation_password_placeholder')">
            </div>
            <div class="col-12 text-info">
              <p>
                <small>
                  <i class="fas fa-info-circle"></i>
                  {{ $t('users.popup.password_note') }}
                </small>
              </p>
            </div>
          </div>

          <!-- Alert -->
          <aside class="alert danger" v-if="errors.length">
            <p>
              <i class="fas fa-exclamation-triangle"></i>
              <b>{{ $t('users.popup.error_title') }}</b>
            </p>
            <ul>
              <li v-for="error in errors">{{ error }}</li>
            </ul>
          </aside>

        </div>

        <div class="footer">
          <aside class="actions">
            <a class="btn danger" @click="archiveSubUser(user)" v-if="canArchiveSubUser(user)">
              {{ $t('users.popup.footer_archive_btn') }}
            </a>
          </aside>
          <aside class="actions">
            <a class="btn" @click="close">
              {{ $t('users.popup.footer_cancel_btn') }}
            </a>
            <a class="btn success" @click="processSubUser">
              {{ sub_user._id ? $t('users.popup.footer_save_btn') : $t('users.popup.footer_add_btn') }}
            </a>
          </aside>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import organizationsSelect from '../organizationsSelect.vue';
  import {inject, ref, reactive} from 'vue';
  import {useI18n} from 'vue-i18n';
  import {toast} from 'vue3-toastify';
  import {useEmailEdition} from '../../composables/email-edition';

  const {t} = useI18n();

  const event_hub = inject('event-hub');
  const sty = inject('sentry');
  const axios = inject('axios');

  const props = defineProps({
    user: {
      required: true,
      type: Object
    },
    popup: {
      required: true,
      type: Boolean
    }
  });

  const emit = defineEmits(['update-popup']);

  const is_loading = ref(false);
  const errors = ref([]);
  const password_confirmation = ref('');

  const sub_user = reactive(props.user ? JSON.parse(JSON.stringify(props.user)) : {
    language: '',
    first_name: '',
    last_name: '',
    email: '',
    company_role: '',
    organization: {
      _id: ''
    },
    password: ''
  });

  const {is_email_editable} = useEmailEdition(sub_user);

  const close = () => {
    emit('update-popup', false);
  };

  const updateUser = (data) => {
    delete data.language;

    return axios.put(`${process.env.API_URL}/users`, data)
      .then(() => {
        is_loading.value = false;
        toast.success(t('users.popup.msg.save_success'));
      });
  };

  const createUser = (data) => {
    is_loading.value = true;

    return axios.post(`${process.env.API_URL}/users`, data)
      .then((res) => {
        is_loading.value = false;
        toast.success(t('users.popup.msg.create_success'));
      });
  };

  const validateForm = () => {
    let fields_errors = [];

    if (!sub_user.first_name || !sub_user.last_name || !sub_user.company_role || !sub_user.organization || !sub_user.language) {
      fields_errors.push(t('users.popup.msg.errors_fields'));
    }

    if (!sub_user._id) {
      if (!sub_user.password || !password_confirmation.value || (sub_user.password !== password_confirmation.value) || (sub_user.password.length < 8)) {
        fields_errors.push(t('users.popup.msg.errors_password'));
      }
    }

    const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email_regex.test(sub_user.email)) {
      fields_errors.push(t('users.popup.msg.errors_valid_email'));
    }

    errors.value = fields_errors;
  };

  const canArchiveSubUser = (user) => {
    if (!user._id) {
      return false;
    }

    const current_user = JSON.parse(localStorage.user_data);

    return user._id !== current_user._id;
  };

  const archiveSubUser = (user) => {
    const data = {...user};

    delete data.language;

    data.organization = user.organization._id;
    data.deleted = true;

    return axios.put(`${process.env.API_URL}/users`, data)
      .then(() => {
        is_loading.value = false;
        toast.success(t('users.popup.msg.save_success'));
        close();
        event_hub.emit('refresh-subusers');
      }).catch(() => {
        const error_msg = t('users.popup.msg.archive_error');
        toast.error(error_msg);
      });
  };

  const processSubUser = () => {
    is_loading.value = true;
    validateForm();

    if (!errors.value.length) {
      let sub_user_processing = createUser;
      let error_msg = t('users.popup.msg.create_error');
      let data = {
        ...sub_user,
        organization: sub_user.organization._id
      };

      if (data._id) {
        sub_user_processing = updateUser;
        error_msg = t('users.popup.msg.update_error');
        data.organization = sub_user.organization._id;
      }

      sub_user_processing(data)
        .then(() => {
          is_loading.value = false;
          close();
          event_hub.emit('refresh-subusers');
        })
        .catch((error) => {
          if (error.status === 409) {
            toast.error(t('users.popup.msg.error_used_email'));
          } else {
            toast.error(error_msg);
          }
          sty.setContext('data', data);
          sty.captureException(error);
          is_loading.value = false;
        });
    } else {
      is_loading.value = false;
    }
  };

  event_hub.on('organization_update', (org) => sub_user.organization = org);
</script>
