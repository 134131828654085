<template>
  <div class="row justify-content-center pending" data-testid="campaign-pending">
    <div class="panel text-center col-sm-4 no-results message">
      <i class="icon fas fa-hourglass-half"></i>
      <h3>{{ $t('campaign.pending.title') }}</h3>
      <p>{{ $t('campaign.pending.text') }}</p>
    </div>
  </div>
</template>

<script setup>
</script>
