<template>
  <div class="panel heatmap-panel">
    <div class="title">
      <b>{{ $t('geoindicators.report.tourism.details.heatmap_title') }}</b>
      <aside class="actions">
        <dropdown :is_button="false">
          <template #actions>
            <ul class="list">
              <li>
                <label class="checkbox">
                  {{ $t('geoindicators.report.widgets.heat_maps_toggle') }}
                  <input v-model="show_heatmap_layer" type="checkbox">
                  <span></span>
                </label>
              </li>
              <li>
                <label class="checkbox">
                  {{ $t('geoindicators.report.widgets.markers_toggle') }}
                  <input v-model="show_marker_layer" type="checkbox">
                  <span></span>
                </label>
              </li>
              <li>
                <label class="checkbox">
                  {{ $t('geoindicators.report.widgets.polygons_toggle') }}
                  <input v-model="show_polygon_layer" type="checkbox">
                  <span></span>
                </label>
              </li>
            </ul>
          </template>
          <template #title>
            <a class="info">
              <i class="fas fa-layer-group"></i>
              <span class="d-none d-lg-inline">{{ $t('geoindicators.report.widgets.layers_toggle') }}</span>
              <i class="fas fa-caret-down"></i>
            </a>
          </template>
        </dropdown>
      </aside>
    </div>
    <div class="heatmap">
      <div class="heatmap-wrapper">
        <GoogleMap
          :api-promise="apiPromise"
          ref="map_ref"
          mapId="TOURISM_HEATMAP_ID"
          style="height: 600px;"
          :zoom="map.zoom"
          :options="map.options"
          :center="map.center"
          @zoom_changed="mapZoomChanged">
          <!-- Heatmap -->
          <HeatmapLayer
            v-if="show_heatmap_layer"
            :options="{
              ...heatmap_options,
              data: markers,
            }"/>
            <!-- Polygons -->
          <Polygon
            v-if="show_polygon_layer"
            v-for="(polygon, i) in data.polygons"
            :key="i"
            :options="{
              paths: polygon.path,
              editable: false,
              draggable: false,
              fillColor: polygon.color[4],
              strokeColor: polygon.color[4],
              fillOpacity: 0.35,
              strokeOpacity: 1
            }">
          </Polygon>
          <!-- Markers -->
          <template v-for="(polygon, i) of data.polygons">
            <AdvancedMarker
              v-if="show_marker_layer"
              v-for="(path, j) of polygon.path"
              :key="path[0].lng"
              :options="{
                position: path[0],
                gmpClickable: true,
                draggable: false
              }"
              @click="toggleMarkerInfo(polygon, j)">
              <!-- Info window -->
              <InfoWindow
                v-model="info_window_open"
                :options="{
                  position: info_window_position,
                  content: info_options.content
                }"
              />
            </AdvancedMarker>
          </template>
        </GoogleMap>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {GoogleMap, HeatmapLayer, InfoWindow, AdvancedMarker, Polygon} from 'vue3-google-map';
  import {ref, computed, watch, reactive} from 'vue';
  import {useGmap} from '../../../../js/gmap-imports.js';

  const props = defineProps({
    data: {
      type: Object,
      required: true,
      default: () => ({})
    }
  });

  const show_heatmap_layer = ref(true);
  const show_polygon_layer = ref(true);
  const show_marker_layer = ref(true);
  const info_window_position = ref(null);
  const info_window_open = ref(false);

  const heatmap_options = {
    radius: 20,
    maxIntensity: 100,
    opacity: 0.8,
    gradient: [
      'rgba(0, 255, 255, 0)',
      'rgba(0, 0, 223, 0.6)',
      'rgba(0, 0, 223, 1)',
      'rgba(0, 255, 255, 1)',
      'rgba(102, 255, 0, 1)',
      'rgba(147, 255, 0, 1)',
      'rgba(193, 255, 0, 1)',
      'rgba(238, 255, 0, 1)',
      'rgba(244, 227, 0, 1)',
      'rgba(249, 198, 0, 1)',
      'rgba(255, 113, 0, 1)',
      'rgba(255, 57, 0, 1)',
      'rgba(255, 0, 0, 1)'
    ]
  };

  const info_options = reactive({
    content: '',
    pixelOffset: {
      width: 0,
      height: -35
    }
  });

  const map = reactive({
    zoom: 8,
    center: {
      lat: 46.194833,
      lng: -72.844110
    },
    options: {
      minZoom: 2,
      maxZoom: 16
    }
  });

  const {
    apiPromise,
    map_ref,
    mapZoomChanged,
    zoomTo,
    initializeGoogleMapsAPI
  } = useGmap(map);

  const markers = computed(() => {
    let points = [];

    points = props.data.positions.map((pos) => {
      return {
        location: {lat: pos.location.lat, lng: pos.location.lng},
        weight: Math.max(pos.weight, 10)
      };
    });
    return points;
  });

  const toggleMarkerInfo = (polygon, j) => {
    info_window_position.value = polygon.path[j][0];
    info_options.content = polygon.label;
    info_window_open.value = true;
    zoomTo({coordinates: polygon.path[j]}, 10);
  };

  watch(() => props.data, (oldVal, newVal) => {
    if (props.data.polygons.length) {
      map.center = props.data.polygons[0].path[0][0];
      map.zoom = 8;
    }
  }, {
    immediate: true,
    deep: true
  });

  initializeGoogleMapsAPI();
</script>
