<template>
  <div>
    <a class="secondary" @click="convertToCampaign(params.data._id)">{{ $t('samples.table.convert') }} <i class="fas fa-chevron-right"></i></a>
  </div>
</template>

<script setup>
  import {useRouter} from 'vue-router';
  import {ref, inject} from 'vue';
  import {useI18n} from 'vue-i18n';
  import {toast} from 'vue3-toastify';

  const {t} = useI18n();
  const router = useRouter();

  const axios = inject('axios');

  defineProps(['params']);

  const error = ref(false);
  const is_loading = ref(false);

  const convertToCampaign = (sample_id) => {
    error.value = false;
    is_loading.value = true;

    axios.get(`${process.env.API_URL}/samples/convert/${sample_id}`)
      .then((res) => {
        router.push({path: `/geomarketing/campaigns/edit/${res.data._id}`});
        is_loading.value = false;
      })
      .catch((err) => {
        error.value = true;
        is_loading.value = false;
        toast.error(t('samples.convert_error'));
      });
  };
</script>
