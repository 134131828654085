import sectors from './sectors.json';
import subsectors from './subsectors.json';

const all_sectors = sectors.concat(subsectors);

export const getSector = function(i) {
  const index = all_sectors.findIndex((s) => s.en === i);
  const lang = localStorage.lang;

  return index !== -1 ? all_sectors[index][lang] : i;
};
