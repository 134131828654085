<template>
  <div class="panel fences-builder" :class="{'fullscreen': fullscreen, 'disabled': map_disabled}">

    <!-- Title -->
    <div class="row title">
      <div class="col">
        <b>{{ title }}</b>
        <span v-if="show_tooltip">
          <i class="fas fa-question-circle tooltip-icon" v-tooltip="is_conversion ? $t('campaign.edit.fences_panel.tooltips.conversion_move') : $t('campaign.edit.fences_panel.tooltips.conversion_show')"></i>
        </span>
        <span class="badge info" v-if="fence_type === 'report'" v-tooltip="$t('campaign.edit.fences_panel.tooltips.nb_fences', {fences: fences.length, subfences: subfences_length})">{{ fences_length }}</span>
        <span class="badge info" v-else>{{ fences_length }}</span>
        <span class="badge danger" v-if="error_fences.length">{{ error_fences.length }}</span>
      </div>

      <aside class="actions horizontal-row mt-2 ml-3 mt-sm-0 ml-sm-0">
        <a class="info" @click="deleteAllFences">
          <i class="far fa-trash-alt"></i>
          {{ $t('campaign.edit.fences_panel.delete_fence') }}
        </a>
        <a class="info" @click="exportFences">
          <i class="fas fa-file-download"></i>
          {{ $t('campaign.edit.fences_panel.export_fence') }}
        </a>
        <a class="info" @click="geojson_input.click()" data-testid="geojson-input">
          <input
            type="file"
            class="files-input"
            style="display: none;"
            @change="importFile"
            ref="geojson_input"
            accept=".json, .csv, .xlsx, .xls, .geojson"
          />
          <i class="fas fa-file-upload"></i>
          {{ $t('campaign.edit.fences_panel.import_fence') }}
        </a>
        <a class="info" @click="openFullscreen">
          <i class="fas fa-expand"></i>
          {{ $t('campaign.edit.fences_panel.full_screen') }}
        </a>
      </aside>
    </div>

    <div class="row" v-if="categories.length">
      <div class="tabs">
        <a class="tab"
           :class="{'active': selected_category_index === key}"
           v-for="(category, key) in categories"
           @click="updateCategory(key)">
          {{ category.name }}

          <span class="badge info">{{ getCategoryFencesCount(category.name) }}</span>
        </a>
      </div>
    </div>

    <!-- Alert -->
    <aside class="alert danger fence-alert" v-if="product === 'tourism' && fences.length > limits.max_polygon && is_exclusion === false">
      <i class="fas fa-exclamation-circle"></i>
      {{ $t('campaign.edit.fences_panel.alerts.warning_fences_limit_tourism') }}
    </aside>

    <aside class="alert warning fence-alert" v-else-if="outside_zone && !error_fences.length">
      <i class="fas fa-exclamation-circle"></i>
      {{ $t('campaign.edit.fences_panel.alerts.warning_outside_zone') }}
    </aside>

    <aside class="alert warning fence-alert" v-if="product !== 'tourism' && fences.length > get_max_polygon">
      <i class="fas fa-exclamation-circle"></i>
      {{ $t('campaign.edit.fences_panel.alerts.error_fences_limit', {nb: get_max_polygon}) }}
    </aside>

    <aside class="alert danger fence-alert" v-else-if="fences.length < get_min_polygon && !is_new_report">
      <i class="fas fa-exclamation-circle"></i>
      {{ $t('geoindicators.creation.errors.not_enough_fences', get_min_polygon) }}
    </aside>

    <!-- Content -->
    <div class="row">

      <!-- Map -->
      <div class="col-md-8 map-wrapper">
        <div class="map-disabled"></div>
        <GoogleMap
          :api-promise="apiPromise"
          ref="map_ref"
          class="google-map"
          mapId="map.mapId"
          streetViewControlPosition="LEFT_BOTTOM"
          :zoom="map.zoom"
          :scaleControl="true"
          :center="map.center"
          @click="mapClickListener"
          @contextmenu="mapRightClickListener"
          @mousemove="mapMouseMoveListener"
          @bounds_changed="mapBoundsChanged"
          @zoom_changed="mapZoomChanged">
          <Polyline
            v-if="drawing_coordinates.length > 0"
            :options="{
              ...map.polylineOptions,
              path: drawing_coordinates
            }"
            @click="polylineClickListener"/>
          <Polyline
            v-if="drawing_next"
            :options="{
              ...map.polylineTmpOptions,
              path: drawing_next
              }"
            @click="mapClickListener"/>
          <Polygon
            v-if="fence_type === 'report' && coverage_area.length"
            :options="{
              ...map_report_options,
              paths: [world_map, ...coverage_area]
              }">
          </Polygon>
          <div v-if="visible_fences.length <= 500 || map.zoom >= 14">
            <Polygon
              v-for="(fence, id) in visible_fences"
              :options="{
                ...getPolygonOption(fence.status),
                paths: [...fence.coordinates],
                editable: visible_fences.length <= 15 || map.zoom >= 16,
                draggable: visible_fences.length <= 15 || map.zoom >= 16
              }"
              :ref="inst => storePolygonInstance(inst, fence.fence_index, fence.polygon_index)"
              @mouseup="polygonMouseUpListener($event, fence.fence_index, fence.polygon_index)"
              @rightclick="polygonRightClickListener($event, fence.fence_index, fence.polygon_index)">
            </Polygon>
          </div>
          <fencesBuilderAutocomplete
            v-if="map_ref && google"
            :map_ref="map_ref"
            :google_api="google"
            :placeholder="$t('campaign.edit.fences_panel.search_location_address')"
            @place_changed="autocompleteSearchListener">
          </fencesBuilderAutocomplete>
          <AdvancedMarker
            :options="{
              position: map.marker
            }">
          </AdvancedMarker>

          <a class="btn close" @click="closeFullscreen" v-if="fullscreen">
            <i class="fas fa-compress-arrows-alt"></i>
            <span class="d-none d-md-inline">{{ $t('campaign.edit.fences_panel.close_full_screen') }}</span>
          </a>
        </GoogleMap>

        <div class="overlay" v-if="!(visible_fences.length <= 500 || map.zoom >= 14)">{{ $t('campaign.edit.fences_panel.alerts.zoom_results') }}</div>
      </div>

      <!-- List -->
      <div class="col-md-4">
        <div class="fences-list" :class="{'panel': fullscreen, 'closed' : !is_fences_list_visible}">
          <a class="mt-2 d-flex justify-content-center d-inline d-md-none" @click="toggleFencesList"><i :class="!fullscreen ? 'disabled' : is_fences_list_visible ? 'fas fa-chevron-down' : 'fas fa-chevron-up'"></i></a>
          <div class="scroller" v-if="fences.length">
            <div
              v-for="(item, index) in fences_list"
              :key="index"
              class="fence"
              :class="{'error': item.status !== 'valid', 'subfence': !item.is_main_polygon && fence_type === 'report'}"
              @click="zoomTo(item)">

              <div v-if="!item.is_main_polygon && fence_type === 'report'">
                <i class="fas fa-subfence fa-level-up-alt fa-rotate-90"></i>
                <label>{{ item.label }}</label>
              </div>
              <div v-else>
                <input
                  type="text"
                  :placeholder="$t('campaign.edit.fences_panel.zone_name')"
                  v-model="item.label"
                  @input="handleLabelInput($event, item.fence_index)">
              </div>
              <div class="fence-actions">
                <small class="red" v-if="item.status !== 'valid'">
                  <i class="fas fa-exclamation-triangle"></i>
                  <span v-if="item.status === 'error:area'">{{ $t('campaign.edit.fences_panel.alerts.error_area', {min: limits.polygon_min_area, max: limits.polygon_max_area}) }}</span>
                  <span v-if="item.status === 'error:edges'">{{ $t('campaign.edit.fences_panel.alerts.error_edge', {edges: limits.max_edges_length}) }}</span>
                  <span v-if="item.status === 'error:side'">{{ $t('campaign.edit.fences_panel.alerts.error_side', {side: (limits.max_side_length_m / 1000).toFixed(1)}) }}</span>
                  <span v-if="item.status === 'error:intersecting'">{{ $t('campaign.edit.fences_panel.alerts.error_intersecting') }}</span>
                  <span v-if="item.status === 'error:invalid_coords'">{{ $t('campaign.edit.fences_panel.alerts.error_invalid_coords') }}</span>
                  <span v-if="item.status === 'error:product_territory'">{{ $t('campaign.edit.fences_panel.alerts.outside_of_territory') }}</span>
                  <span v-if="item.status === 'error:no_fence_name'">{{ $t('geoindicators.creation.errors.no_fence_name') }}</span>
                </small>
                <a v-if="item.is_main_polygon && is_mutlipolygon_allowed" @click="toggleFenceSwitch(item, index)">
                  <i v-if="is_fence_being_edited(item)" class="fas fa-pen" v-tooltip.bottom="$t('campaign.edit.fences_panel.tooltips.add_subfence_loading')"></i>
                  <i v-else class="fas fa-draw-polygon" v-tooltip.bottom="$t('campaign.edit.fences_panel.tooltips.add_subfence')"></i>
                </a>
                <a @click="divideFence(item)" v-if="fence_type === 'campaign'">
                  <i class="fas fa-cut" v-tooltip.bottom="$t('campaign.edit.fences_panel.tooltips.divide_fence')"></i>
                </a>
                <a @click="deleteFence(item)">
                  <i class="far fa-trash-alt" v-tooltip.bottom="$t('campaign.edit.fences_panel.tooltips.delete_fence')"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="empty" v-if="!fences.length">
            <i class="fas fa-draw-polygon map"></i>
            <i class="fas fa-mouse-pointer cursor"></i>
            <p v-if="map_disabled">{{ $t('campaign.edit.fences_panel.click_to_draw_disabled') }}</p>
            <p v-else>{{ $t('campaign.edit.fences_panel.click_to_draw') }}</p>
            <p><small>{{ $t('campaign.edit.fences_panel.note_click_to_draw') }}</small></p>
          </div>
        </div>
      </div>

    </div>

    <!-- Needed for autocompletion -->
    <div ref="fences_builder_map_for_places"></div>

    <div class="popup import-popup" v-if="show_import_popup">
      <div class="container">

        <loading :loading="is_loading" :is_panel="false" :loading_message="$t('campaign.edit.fences_panel.import_loading_msg')"></loading>

        <div class="panel list" v-if="!is_loading && !import_error">
          <div class="import-success">
            <div class="title">
              <b>{{ $t('campaign.edit.fences_panel.confirmation_import') }}</b>
              <span class="badge success">{{ $t('campaign.edit.fences_panel.badge_success', get_valid_fences) }}</span>
              <span class="badge danger">{{ $t('campaign.edit.fences_panel.badge_error', get_invalid_fences) }}</span>
              <div class="actions">
                <a class="info" @click=closeImportPopup>
                  <i class="fas fa-times"></i>
                </a>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="fence-to-import col-md-12">
                <table class="fence-table">
                  <thead>
                  <tr>
                    <th>{{ $t('campaign.edit.fences_panel.table_address') }}</th>
                    <th class="text-center">{{ $t('campaign.edit.fences_panel.table_status') }}</th>
                    <th>{{ $t('campaign.edit.fences_panel.table_details') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(imported, i) in fences_to_import">
                    <tr>
                      <td class="text-left">{{ imported.label }}</td>
                      <td class="text-center">
                        <span class="badge" :class="{danger: !imported.is_valid, success: imported.is_valid }">{{ imported.is_valid ? $t('campaign.edit.fences_panel.table_badge_success') : $t('campaign.edit.fences_panel.table_badge_error') }}</span>
                      </td>
                      <td class="text-left">{{ imported.status }}</td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="footer">
              <aside class="actions">
              <p class="subtitle">{{ $t('campaign.edit.fences_panel.footer.import_confirmation', get_valid_fences) }}</p>
                <a class="btn" @click=closeImportPopup>
                  {{ $t('campaign.edit.fences_panel.footer.cancel') }}
                </a>
                <a class="btn success" data-testid="confirm-import-btn" @click="confirmImport">
                  {{ $t('campaign.edit.fences_panel.footer.import_btn') }}
                </a>
              </aside>
            </div>
          </div>
        </div>

        <div class="panel" v-if="import_error">
          <div class="import-success">
            <div class="title">
              <b>{{ $t('campaign.edit.fences_panel.importation_error_title') }}</b>
              <div class="actions">
                <a class="info" @click=closeImportPopup>
                  <i class="fas fa-times"></i>
                </a>
              </div>
            </div>
            <aside class="alert danger">
              <p>
                <i class="fas fa-exclamation-triangle"></i>
                {{ error_message }}
              </p>
            </aside>
            <div class="footer">
              <aside class="actions">
                <a class="btn" @click="closeImportPopup(), import_error = false">{{t('campaign.edit.fences_panel.footer.cancel')}}</a>
                <a class="btn success" href="assets/gabarit_adresses.xlsx"  target="_blank">{{t('campaign.edit.fences_panel.footer.download_template')}}</a>
              </aside>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- Map Popup -->
    <map-circle-popup
      v-if="show_import_popup_map"
      @update-map-circle="importAddresses"
      @update-popup-map="togglePopupMap">
    </map-circle-popup>
  </div>
</template>

<script setup>
  import {mapOptions, useGmap} from '../../js/gmap-imports.js';
  import validato from '@propulso/validato';
  import MapCirclePopup from '../components/map-circle-popup.vue';
  import {exportGeoJson} from '../../js/fences';
  import {ref, computed, reactive, inject, toRaw} from 'vue';
  import {useRoute} from 'vue-router';
  import {useI18n} from 'vue-i18n';
  import {toast} from 'vue3-toastify';
  import {GoogleMap, AdvancedMarker, Polygon, Polyline} from 'vue3-google-map';
  import fencesBuilderAutocomplete from './fencesBuilderAutocomplete.vue';

  const {t} = useI18n();
  const route = useRoute();

  const event_hub = inject('event-hub');
  const axios = inject('axios');
  const sty = inject('sentry');

  const props = defineProps({
    title: {
      type: String,
      required: false,
    },
    coverage_area: {
      type: Array,
      required: false,
      default: () => []
    },
    fence_type: {
      type: String,
      required: true
    },
    custom_title: {
      type: String,
      required: false
    },
    show_tooltip: {
      type: Boolean,
      required: false,
      default: true
    },
    value: {
      type: Array,
      required: true
    },
    is_conversion: {
      type: Boolean,
      default: false
    },
    economic_fence_type: {
      type: String,
      default: ''
    },
    categories: {
      type: Array,
      default: () => {
        return [];
      }
    },
    map_disabled: {
      type: Boolean,
      default: false
    },
    is_exclusion: {
      type: Boolean,
      required: false,
      default: false
    },
    export_polygons_data: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    product: {
      type: String,
      required: true,
      default: ''
    },
    fences_limit: {
      type: Number,
      required: false,
      default: 500
    },
    license: {
      type: Object,
      required: false,
      default: () => ({})
    }
  });

  const emit = defineEmits(['input']);

  const world_map = ref([
    {lat: -85.1054596961173, lng: -180},
    {lat: 85.1054596961173, lng: -180},
    {lat: 85.1054596961173, lng: 180},
    {lat: -85.1054596961173, lng: 180},
    {lat: -85.1054596961173, lng: 0}
  ]);

  const geojson_input = ref({});
  const data_file = ref('');
  const extension = ref('');
  const is_loading = ref(false);
  const drawing = ref(false);
  const drawing_preview = ref(false);
  const fullscreen = ref(false);
  const fences_builder_map_for_places = ref(null);
  const fences = ref([]);
  const visible_fences = ref([]);
  const fences_to_import = ref([]);
  const show_import_popup = ref(false);
  const show_import_popup_map = ref(false);
  const import_error = ref(false);
  const is_fences_list_visible = ref(true);
  const error_fences = ref([]);
  const error_message = ref('');
  const selected_category_index = ref(0);
  const polygon_being_edited_id = ref(null);
  const limits = ref(validato.products[props.product]);

  const map = reactive({
    options: {
      ...mapOptions,
      maxZoom: 30,
      streetViewControlOptions: {
        position: 6,
      }
    },
    center: {
      lat: 45.4052927,
      lng: -71.9566867
    },
    zoom: 7,
    bounds: null,
    marker: null,
    mapId: 'DEMO_MAP_ID',
    polylineOptions: {
      strokeColor: '#3498db',
      strokeOpacity: 1.0,
      path: [],
      editable: true
    },
    polylineTmpOptions: {
      strokeColor: '#3498db',
      strokeOpacity: 0.3,
      cursor: 'crosshair',
      path: []
    }
  });

  const map_report_options = reactive({
    clickable: false,
    fillColor: '#d82c0d',
    fillOpacity: 0.2,
    strokeOpacity: 0
  });

  const is_new_report = computed(() => route.query?.new === 'true');

  const drawing_coordinates = computed(() => {
    return drawing.value ? [...drawing.value.coordinates] : [];
  });

  const drawing_next = computed(() => {
    return drawing_preview.value ? [...drawing_preview.value] : [];
  });

  const get_min_polygon = computed(() => {
    if (props.product === 'tourism' && props.is_exclusion) {
      return limits.value.min_exclusion_fences;
    } else if (props.product === 'economic' && props.economic_fence_type === 'destination') {
      return limits.value.min_destination_fences;
    } else {
      return limits.value.min_polygon;
    }
  });

  const get_max_polygon = computed(() => {
    return props.fences_limit ? props.fences_limit : props.license.limits.max_report_polygon;
  });

  const get_valid_fences = computed(() => {
    return fences_to_import.value ? fences_to_import.value.filter((f) => f.is_valid).length : 0;
  });

  const get_invalid_fences = computed(() => {
    return fences_to_import.value ? fences_to_import.value.filter((f) => !f.is_valid).length : 0;
  });

  const is_mutlipolygon_allowed = computed(() => {
    return props.license.limits.multipolygon_allowed;
  });

  const selected_category = computed(() => {
    return props.categories[selected_category_index.value];
  });

  const fences_list = computed(() => {
    let computed_fences_list = [];
    for (const fence_index in fences.value) {
      const fence = {...fences.value[fence_index]};

      if (props.fence_type === 'report') {
        for (const polygon_index in fence.coordinates) {
          const polygon = fence.coordinates[polygon_index];
          const is_main_polygon = parseInt(polygon_index) === 0;

          if (!is_main_polygon || !fence.status) {
            const errors = validato.validateFence(polygon, props.product, fence.label, props.license);

            fence.status = errors.length ? errors[0].status : 'valid';
          }

          const item_data = {
            label: is_main_polygon ? fence.label : `${fence.label}-${polygon_index}`,
            coordinates: polygon,
            status: fence.status,
            is_main_polygon
          };

          if (is_main_polygon) {
            item_data.polygon_index = polygon_index;
            item_data.fence_index = fence_index;
          } else if (!is_main_polygon) {
            item_data.main_polygon_label = fence.label;
            item_data.polygon_index = polygon_index;
            item_data.fence_index = fence_index;
          }

          if (fence.category) {
            item_data.category = fence.category;
          }

          computed_fences_list.push(item_data);
        }
      } else {
        computed_fences_list.push({
          ...fence,
          fence_index: parseInt(fence_index)
        });
      }
    }

    return props.economic_fence_type === 'destination' ? computed_fences_list.filter((fence) => fence.category === selected_category.value.name) : computed_fences_list;
  });

  const fences_length = computed(() => {
    if (props.fence_type === 'report') {
      return fences.value.reduce((acc, fence) => acc + fence.coordinates.length, 0);
    } else {
      return fences.value.length;
    }
  });

  const subfences_length = computed(() => {
    if (props.fence_type === 'report') {
      return fences.value.reduce((acc, fence) => {
        if (fence.coordinates.length > 1) {
          acc += fence.coordinates.length - 1;
        }
        return acc;
      }, 0);
    }
  });

  const outside_zone = computed(() => {
    let is_outside = false;

    for (let fence of fences.value) {
      if (props.fence_type === 'report') {
        for (const coords of fence.coordinates) {
          if (props.coverage_area.length && !validato.doesZoneContainsPolygons(coords, props.coverage_area)) {
            is_outside = true;
          }
        }
      } else if (props.coverage_area.length && !validato.doesZoneContainsPolygons(fence.coordinates, props.coverage_area)) {
        is_outside = true;
      }
    }

    return is_outside;
  });

  const completedFence = () => {
    let service = new google.value.places.PlacesService(fences_builder_map_for_places.value);
    let coordinates = drawing_coordinates.value;
    let start_point = new google.value.LatLng(coordinates[0].lat, coordinates[0].lng);
    let bounds = new google.value.LatLngBounds(start_point, start_point);
    let fence_label = undefined;

    for (let c of coordinates) {
      bounds.extend(c);
    }

    service.nearbySearch({
      fields: ['adr_address', 'name', 'geometry', 'business_status', 'vicinity'],
      bounds: bounds
    }, (results) => {
      let origin = bounds.getCenter();
      let distance = 1000000;

      for (let r of results) {
        let current_distance = google.value.geometry.spherical.computeDistanceBetween(origin, r.geometry.location);

        if (current_distance < distance) {
          distance = current_distance;
          fence_label = `${r.name}, ${r.vicinity}`;
        }
      }

      validateStatusFences({
        coordinates: props.fence_type === 'report' ? [drawing_coordinates.value] : drawing_coordinates.value,
        label: fence_label
      });
      drawing.value = false;
    });
  };

  const validateStatusFences = (fences_to_validate) => {
    fences_to_validate = !Array.isArray(fences_to_validate) ? [fences_to_validate] : fences_to_validate;
    for (const fence of fences_to_validate) {
      const fence_data = {
        ...fence,
        status: 'valid'
      };

      if (props.fence_type === 'report') {
        for (let i = 0; i < fence.coordinates.length; i++) {
          const coords = fence.coordinates[i];
          const errors = validato.validateFence(coords, props.product, fence.label, props.license);
          const product_errors = errors.filter((e) => e.scope === 'product');
          const status = product_errors.length ? product_errors[0].status : 'valid';

          if (status !== 'valid') {
            fence_data.status = i === 0 ? status : 'error:invalid_coords';
          }
        }
      } else {
        const errors = validato.validateFence(fence.coordinates, props.product, fence.label, props.license);

        fence_data.status = errors.length ? errors[0].status : 'valid';
      }

      let fence_index = fences.value.findIndex((f) => {
        return props.fence_type === 'report' ? f.coordinates[0][0].lat === fence.coordinates[0][0].lat : f.coordinates[0].lat === fence.coordinates[0].lat && f.coordinates[0].lng === fence.coordinates[0].lng;
      });

      fence_index = fence_index === -1 ? fences.value.length : fence_index;

      if (props.fence_type === 'report') {
        if (props.economic_fence_type !== '') {
          fence_data.type = props.economic_fence_type;
          if (fence_data.type === 'destination') {
            if (selected_category.value) {
              const does_category_exists = props.categories.filter((category) => {
                return category.name === fence_data.category && category.value === parseInt(fence_data.avg_purchase);
              }).length;

              if (!fence_data.category || !does_category_exists) {
                fence_data.category = selected_category.value.name;
                fence_data.avg_purchase = parseInt(selected_category.value.value);
              }
            }
          }
        }
      }
      fences.value[fence_index] = fence_data;
    }

    updateFences();
  };

  const {
    apiPromise,
    google,
    map_ref,
    polygonMouseUpListener,
    storePolygonInstance,
    mapClickListener,
    mapZoomChanged,
    mapBoundsChanged,
    mapRightClickListener,
    mapMouseMoveListener,
    polylineClickListener,
    autocompleteSearchListener,
    autoFinishPolygon,
    getPolygonOption,
    zoomTo,
    openFullscreen,
    closeFullscreen,
    polygonRightClickListener,
    initializeGoogleMapsAPI,
    getMapInitialCenter
  } = useGmap(
    map,
    fences,
    visible_fences,
    props.fence_type,
    fences_list,
    drawing,
    drawing_preview,
    fullscreen,
    polygon_being_edited_id,
    completedFence,
    validateStatusFences
  );

  const is_fence_being_edited = (fence) => {
    return String(fence.fence_index) === String(polygon_being_edited_id.value);
  };

  const closeImportPopup = () => {
    show_import_popup.value = false;
  };

  const updateFences = () => {
    if (props.fence_type === 'report') {
      event_hub.emit('outside_zone', outside_zone);
      event_hub.emit(props.is_exclusion ? 'report_exclusion_fences_update' : 'report_target_fences_update', {fences: fences.value, economic_fence_type: props.economic_fence_type});
    } else if (props.fence_type === 'campaign') {
      event_hub.emit(props.is_conversion ? 'conversion_fences_update' : 'target_fences_update', fences.value);
    }

    mapBoundsChanged(map.bounds);
    error_fences.value = fences.value.filter((fence) => fence.status.includes('error'));
    emit('input', fences.value);
  };


  const toggleFencesList = () => {
    is_fences_list_visible.value = !is_fences_list_visible.value;
  };

  const deleteFence = (fence) => {
    if (props.fence_type === 'report') {
      if (fence.is_main_polygon) {
        fences.value.splice(fence.fence_index, 1);
      } else {
        fences.value[fence.fence_index].coordinates.splice(fence.polygon_index, 1);
      }
    } else {
      fences.value.splice(fence.fence_index, 1);
    }

    validateStatusFences(fences.value);
  };

  const deleteAllFences = () => {
    fences.value = [];
    updateFences();
  };

  const divideFence = (fence) => {
    if (props.fence_type === 'campaign') {
      is_loading.value = true;

      const sub_fences = validato.divide(JSON.parse(JSON.stringify(fences.value[fence.fence_index])), props.fence_type);
      deleteFence(fence);
      validateStatusFences(sub_fences.map((e, i) => {
        return {
          coordinates: e.coordinates,
          label: `${fence.label}-${(i + 1)}`
        };
      }));

      toast.success(t('campaign.edit.addresses_importation.division_zone'));
      is_loading.value = false;
    }
  };

  const exportFences = () => {
    const file_name = props.export_polygons_data.id && props.export_polygons_data.name ? `${props.export_polygons_data.id}_${props.export_polygons_data.name}` : 'propulso-audience';

    return exportGeoJson(fences.value, file_name, props.fence_type);
  };

  const confirmImport = () => {
    validateStatusFences(fences_to_import.value.filter((f) => f.is_valid).map((fence) => {
      return {
        coordinates: fence.coordinates,
        label: fence.label
      };
    }));

    show_import_popup.value = false;
    toast.success(t('campaign.edit.addresses_importation.import_validation'));
  };

  const importFile = () => {
    const reader = new FileReader();
    const file = geojson_input.value.files[0];
    fences_to_import.value = [];
    const file_type_regex = /^.*\.(xlsx|xls|csv|json)$/;
    import_error.value = false;

    reader.onload = (event) => {
      let regex_match = file.name.match(file_type_regex);

      if (regex_match && regex_match.length) {
        const file_extension = regex_match[1];
        if (file_extension === 'json') {
          importGeoJson(event.target.result);
          show_import_popup.value = true;
        } else if (file_extension === 'csv' || file_extension === 'xlsx' || file_extension === 'xls') {
          data_file.value = event.target.result;
          extension.value = file_extension;
          show_import_popup_map.value = true;
        }
      } else {
        toast.error(`${file.name}:` + t('campaign.edit.fences_panel.alerts.error_valid_file_type'));
      }
    };
    reader.readAsDataURL(file);
    geojson_input.value.value = '';
  };

  const importGeoJson = (data) => {
    const geojson = JSON.parse(atob(data.replace('data:application/json;base64,', '')));

    for (let feature of geojson.features) {
      const is_multipolygon = feature.geometry.type === 'MultiPolygon';

      if (is_multipolygon) {
        if (props.fence_type === 'campaign') {
          const sub_polygons = [];
          let index = 0;

          for (const polygon of feature.geometry.coordinates) {
            for (const sub_polygon of polygon) {
              const last_index = sub_polygon.length - 1;
              const [first_lng, first_lat] = sub_polygon[0];
              const [last_lng, last_lat] = sub_polygon[last_index];

              if (first_lng === last_lng && first_lat === last_lat) {
                sub_polygon.pop();
              }

              const name_suffix = index > 0 ? `-${index}` : '';

              sub_polygons.push({
                name: `${feature.properties.name}${name_suffix}`,
                coordinates: sub_polygon
              });
              index++;
            }
          }

          feature.geometry.coordinates = sub_polygons.map((p) => p.coordinates);
          feature.geometry.type = 'Polygon';
          feature.properties.names = sub_polygons.map((p) => p.name);
        } else {
          for (const polygon of feature.geometry.coordinates) {
            const last_index = polygon[0].length - 1;
            const [first_lng, first_lat] = polygon[0][0];
            const [last_lng, last_lat] = polygon[0][last_index];

            if (first_lng === last_lng && first_lat === last_lat) {
              polygon[0].pop();
            }
          }
        }
      } else {
        const polygon = feature.geometry.coordinates[0];
        const last_index = polygon.length - 1;
        const [first_lng, first_lat] = polygon[0];
        const [last_lng, last_lat] = polygon[last_index];

        if (first_lng === last_lng && first_lat === last_lat) {
          polygon.pop();
        }
      }

      if (props.fence_type === 'report') {
        const fence_index = fences_to_import.value.findIndex((fence) => {
          return feature.properties.name === fence.label;
        });

        const coords = feature.geometry.coordinates.map((poly) => {
          const result = [];

          if (is_multipolygon) {
            for (const coord of poly) {
              for (const c of coord) {
                result.push({lat: c[1], lng: c[0]});
              }
            }
          } else {
            for (const coord of poly) {
              result.push({lat: coord[1], lng: coord[0]});
            }
          }
          return result;
        });

        if (fence_index === -1) {
          fences_to_import.value.push({
            label: feature.properties.name,
            is_valid: true,
            coordinates: coords
          });
        } else {
          fences_to_import.value[fence_index].coordinates.push(coords[0]);
        }
      } else {
        for (let i = 0; i < feature.geometry.coordinates.length; i++) {
          const polygon = feature.geometry.coordinates[i];
          const label = feature.properties.names ? feature.properties.names[i] : feature.properties.name;

          fences_to_import.value.push({
            label,
            is_valid: true,
            coordinates: polygon.map((c) => ({lat: c[1], lng: c[0]}))
          });
        }
      }
    }
  };

  const importAddresses = (circle, fences_size) => {
    show_import_popup_map.value = false;
    is_loading.value = true;
    show_import_popup.value = true;
    let addresses = {
      data:{
        data: data_file.value,
        extension: extension.value
      },
      circle: toRaw(circle),
      fences_size: fences_size.value
    };

    axios.post(`${process.env.API_URL}/addresses`, addresses)
      .then((res) => {
        for (let fence of res.data) {
          if (fence.isFieldMissing) {
            fence.is_valid = false;
            fence.status = t('campaign.edit.addresses_importation.missing_informations');
          } else if (fence.not_found) {
            fence.is_valid = false;
            fence.status = t('campaign.edit.addresses_importation.no_results');
          } else if (!fence.isWithinCircle) {
            fence.is_valid = false;
            fence.status = t('campaign.edit.addresses_importation.not_in_radius');
          } else if (fence.isOutOfRange) {
            fence.is_valid = false;
            fence.status = t('campaign.edit.addresses_importation.fence_to_big');
          } else {
            fence.is_valid = true;
          }

          fences_to_import.value.push({
            label: `${fence.address ? fence.address + ',' : ''} ${fence.city ? fence.city + ',' : ''} ${fence.postal_code ? fence.postal_code + ',' : ''} ${fence.province ? fence.province : ''}`,
            coordinates: props.fence_type === 'report' ? [fence.coordinates] : fence.coordinates,
            is_valid: fence.is_valid,
            status: fence.status
          });
        }

        is_loading.value = false;
      })
      .catch((err) => {
        error_message.value = err.status === 413 ? t('campaign.edit.addresses_importation.file_size_exceed') : err.status === 400 ? t('campaign.edit.addresses_importation.template_message') : t('campaign.edit.addresses_importation.fetch_error') ;
        import_error.value = true;
        is_loading.value = false;
        sty.setContext('data', addresses);
        sty.captureException(err);
      });
  };

  const getCategoryFencesCount = (category) => {
    return fences.value.filter((fence) => fence.status === 'valid' && fence.category === category).length;
  };

  const updateCategory = (key) => {
    selected_category_index.value = key;
    updateFences();
  };

  const updateFenceCategory = (args) => {
    const {old: oldCategoryName, newVal: newCategory, i: index} = args;

    fences.value = fences.value.map((f) => {
      if (f.category === oldCategoryName) {
        f.category = newCategory.name;
        f.avg_purchase = newCategory.value;
      }

      return f;
    });

    props.categories[index] = newCategory;
    updateFences();
  };

  const deleteFenceCategory = () => {
    fences.value = fences.value.filter((fence) => {
      const does_category_exists = props.categories.filter((category) => category.name === fence.category).length;
      return !!does_category_exists;
    });

    updateFences();
  };

  const toggleFenceSwitch = (item, index) => {
    if (is_fence_being_edited(item)) {
      polygon_being_edited_id.value = null;
    } else {
      let fence_index = fences.value.findIndex((f) => f.label === item.label);

      if (props.economic_fence_type === 'destination') {
        fence_index = fences.value.findIndex((fence) => props.categories[selected_category_index.value].name === fence.category && item.label === fence.label);
      }

      if (fence_index > -1) {
        polygon_being_edited_id.value = fence_index;
      }
    }
  };

  const togglePopupMap = (popupMap) => {
    show_import_popup_map.value = popupMap;
  };

  const handleLabelInput = (event, fence_index) => {
    const updated_fence = fences.value[fence_index];
    validateStatusFences({
      ...updated_fence,
      label: event.target.value
    });
  };

  // @TODO: temp fix for https://app.clickup.com/t/v7844v
  const initialFences = JSON.parse(JSON.stringify(props.value.map((fence) => {
    const coordinates = fence.coordinates;

    if (coordinates) {
      if (props.fence_type === 'report') {
        for (const polygon_index in coordinates) {
          const polygon = coordinates[polygon_index];
          const first_point = polygon[0];
          const last_point = polygon[polygon.length - 1];

          if (first_point && first_point.lat === last_point.lat && first_point.lng === last_point.lng) {
            fence.coordinates[polygon_index] = polygon.slice(0, -1);
          }
        }
      } else {
        const first_point = coordinates[0];
        const last_point = coordinates[coordinates.length - 1];

        if (first_point && first_point.lat === last_point.lat && first_point.lng === last_point.lng) {
          fence.coordinates = coordinates.slice(0, -1);
        }
      }
    }

    return fence;
  })));

  validateStatusFences(initialFences);

  if (props.economic_fence_type === 'destination') {
    event_hub.on('update_fence_category', updateFenceCategory);
    event_hub.on('delete_fence_category', deleteFenceCategory);
  }

  map.center = getMapInitialCenter() || map.center;

  window.addEventListener('keydown', (event) => {
    switch (event.key) {
      case 'Escape':
      case 'Esc':
        drawing.value = false;
        closeFullscreen();
        break;
      case 'Enter':
        autoFinishPolygon();
        break;
    }
  });

  initializeGoogleMapsAPI();
</script>
