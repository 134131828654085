const parseDate = function(date, format = '', locale = 'fr-CA') {
  const utc_date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  switch (format) {
    case 'labels': return `${year}-${month > 9 ? month : '0' + month}-${day > 9 ? day : '0' + day}`;
    case 'slashed': return `${year}/${month}/${day}`;
    case 'month_year': return utc_date.toLocaleString(getLocaleLanguage(locale), {month: 'short'}) + " " + utc_date.getUTCFullYear();
    default: return `${year}-${month}-${day}`;
  }
};

const setPacingColor = function(progress) {
  if (progress <= 60) {
    return 'red';
  } else if (progress > 60 && progress <= 80) {
    return 'primary';
  } else if (progress > 80) {
    return 'green';
  }
};

const getCampaignStatus = function(status, t) {
  switch (status.toLowerCase()) {
    case 'active':
      return t('campaign.list.table.status_active');
    case 'pending':
      return t('campaign.list.table.status_pending');
    case 'paused':
      return t('campaign.list.table.status_paused');
    case 'draft':
      return t('campaign.list.table.status_draft');
    case 'ended':
      return t('campaign.list.table.status_ended');
  }
};

const setCampaignColor = function(status) {
  switch (status.toLowerCase()) {
    case 'active':
      return 'green';
    case 'pending':
      return 'orange';
    case 'paused':
      return 'red';
    case 'draft':
      return 'blue';
    case 'ended':
      return 'grey';
  }
};

const getReportStatus = function(status, t) {
  switch (status.toLowerCase()) {
    case 'ended':
      return t('geoindicators.report.status.ended');
    case 'pending':
      return t('geoindicators.report.status.pending');
    case 'unavailable':
      return t('geoindicators.report.status.unavailable');
    case 'draft':
      return t('geoindicators.report.status.draft');
    case 'too_large':
      return t('geoindicators.report.status.too_large');
  }
};

const setReportColor = function(status) {
  switch (status.toLowerCase()) {
    case 'ended':
      return 'green';
    case 'pending':
      return 'orange';
    case 'draft':
      return 'blue';
    case 'unavailable':
      return 'red';
    case 'too_large':
      return 'black';
  }
};

const getLocaleLanguage = function(locale) {
  switch (locale) {
    case 'fr':
      return 'fr-CA';
    case 'en':
      return 'en-CA';
  }
};

const getDateRange = function(range_type = '', date = new Date(), difference = 0) {
  const today = new Date();
  let firstDay = null;
  let range = [];

  switch (range_type) {
    case 'week':
      firstDay = today.getDate() - today.getDay();
      range = [new Date(today.getFullYear(), today.getMonth(), firstDay), today];
      break;
    case 'month':
      range = [new Date(today.getFullYear(), today.getMonth(), 1), today];
      break;
    case 'all_time':
      range = [new Date(2018, 0, 1), new Date(2030, 0, 1)];
      break;
    case 'yesterday':
      range = [new Date(date.getFullYear(), date.getMonth(), date.getDate() - 2), new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)];
      break;
    case 'year':
      if (date === today.getFullYear() - 3) {
        range = [new Date(today.getFullYear() - 3, today.getMonth(), today.getDate() + 1), new Date(today.getFullYear() - 3, 11, 31)];
      } else {
        range = [new Date(date, 0, 1), new Date(date, 11, 31)];
      }
      break;
    case 'last_12_months':
      range = [new Date(today.getFullYear() - 1, today.getMonth(), today.getDate() - 6), new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)];
      break;
    case 'custom':
      range = [date, new Date(date.getFullYear(), date.getMonth(), date.getDate() + difference)];
      break;
    default:
      range = [today, today];
      break;
  }

  return range.sort((a, b) => {
    return a > b ? 1 : -1;
  });
};

const computeCaptationRate = function(value, rate) {
  return Math.floor(value * 100 / rate);
};

const handleTimezoneOffset = function(start, end, reverse_offset) {
  const formatted_dates = {
    start: new Date(start),
    end: new Date(end)
  };
  let start_timezone_offset = formatted_dates.start.getTimezoneOffset();
  let end_timezone_offset = formatted_dates.end.getTimezoneOffset();

  if (reverse_offset) {
    start_timezone_offset *= -1;
    end_timezone_offset *= -1;
  }

  if (start_timezone_offset !== 0 || end_timezone_offset !== 0) {
    formatted_dates.start = new Date(formatted_dates.start.getTime() + start_timezone_offset * 60 * 1000);
    formatted_dates.end = new Date(formatted_dates.end.getTime() + end_timezone_offset * 60 * 1000);
  }

  return formatted_dates;
};

const sortEntries = function(data) {
  const filtered_data = Object.entries(data)
    .reduce((acc, [key, value]) => {
      acc[key] = value;

      return acc;
    }, {});

  return Object.entries(filtered_data).sort(([date1], [date2]) => new Date(date1) - new Date(date2));
};

const formatNumber = function(number) {
  return (parseFloat(number)).toLocaleString('FR').replace(',', '.');
};

const canDataChange = function(report) {
  const report_types = ['visits', 'trails', 'tourism'];

  if (report.end_date && report_types.includes(report.type)) {
    const now = new Date();
    const report_end = new Date(report.end_date);
    const data_change_window = 90;

    if ((now - report_end) / (1000 * 60 * 60 * 24) <= data_change_window) {
      return true;
    }
  }

  return false;
};

const keyPressed = function(event, esc_action = () => {}, enter_action = () => {}) {
  switch (event.key) {
    case 'Escape':
      esc_action();
      break;
    case 'Enter':
      enter_action();
      break;
    default: break;
  }
};

const filterReportDataToExport = function(report) {
  let computed_report = report.series;

  if (report.type === 'visits' || report.type === 'trails') {
    computed_report = {
      ...computed_report,
      ...computed_report.normalized
    };

    delete computed_report.raw;
    delete computed_report.normalized;

    if (computed_report.direction) {
      delete computed_report.direction.show_directions;
    }

    if (computed_report.speed) {
      delete computed_report.speed.show_speed_series;
    }

    if (computed_report.visits_source) {
      delete computed_report.visits_source.show_visits_source;
    }

    if (computed_report.visits_count) {
      delete computed_report.visits_count.by_day;
    }

    delete computed_report.visits_source.territories;
  }

  return computed_report;
};

const emailValidation = (email) => {
  const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return email_regex.test(email);
};

const phoneValidation = (phone) => {
  const phone_regex = /^\+?[1-9]\d{1,14}$/;

  return phone_regex.test(phone);
};

export {
  parseDate,
  setPacingColor,
  getCampaignStatus,
  setCampaignColor,
  getReportStatus,
  setReportColor,
  getLocaleLanguage,
  getDateRange,
  computeCaptationRate,
  handleTimezoneOffset,
  sortEntries,
  formatNumber,
  keyPressed,
  filterReportDataToExport,
  canDataChange,
  emailValidation,
  phoneValidation
};
