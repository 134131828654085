import {computed, ref, inject, reactive} from 'vue';
import {parseDate, handleTimezoneOffset} from '../../js/helpers';
import {useRouter, useRoute} from 'vue-router';
import {toast} from 'vue3-toastify';
import {useI18n} from 'vue-i18n';

export function useReport(event_hub) {
  const route = useRoute();
  const router = useRouter();
  const {t} = useI18n;

  const axios = inject('axios');
  const sty = inject('sentry');

  const is_loading = ref(false);
  const error = ref(false);
  const outside_zone = ref(false);
  const coverage_area = ref([]);
  const categories = ref([]);
  const origin_fences = ref([]);
  const destination_fences = ref([]);

  const report = reactive({});
  const license = reactive({});

  const is_fence_remaining = computed(() => {
    if (!is_loading.value && !error.value && report && report.target_fences) {
      const valid_fences_count = report.target_fences.filter((fence) => fence.status === 'valid').length;
      const fences_remaining = license?.limits.report_max_polygon - valid_fences_count;

      return fences_remaining >= 0;
    }
    return true;
  });

  const is_license_valid = computed(() => {

    return (
      is_fence_remaining.value &&
      !!license.active &&
      (license.limits.monthly_max_reports - license.reports_count - 1) >= 0 &&
      !outside_zone.value
    );
  });

  const copyReport = async() => {
    try {
      is_loading.value = true;
      const response = await axios.put(`${process.env.API_URL}/geoindicators/reports/${report._id}/copy`);

      router.push({path: `/geoindicators/reports/edit/${response.data._id}`, query: {duplicated: true, new: true}});
    } catch (err) {
      error.value = true;
      toast.error(t('geoindicators.report.copy_error'));
      sty.captureException(err);
    } finally {
      is_loading.value = false;
    }
  };

  const deleteReport = async() => {
    try {
      is_loading.value = true;
      await axios.put(`${process.env.API_URL}/geoindicators/reports/${report._id}/delete`);
      router.push({path: '/geoindicators/reports'});
    } catch (err) {
      error.value = true;
      toast.error(t('geoindicators.report.delete_error'));
      sty.captureException(err);
    } finally {
      is_loading.value = false;
    }
  };

  const getSharedLink = (isCustom = false, preview = false, t) => {
    const domain = license?.is_wl ? `geo.${license.domain[0]}.${license.domain[1]}` : 'app.geo.propulso.io';
    const url_prefix = preview ? 'preview' : 'public';
    let url = `https://${domain}/#/${url_prefix}/reports`;

    url += isCustom
      ? `/custom/${route.params.report_id}`
      : `/${route.params.report_id}`;

    navigator.clipboard.writeText(url);
    toast.success(t('geoindicators.report.toasted.success'));
  };

  const getLicense = async() => {
    try {
      is_loading.value = true;
      const response = await axios.get(`${process.env.API_URL}/license`);
      const license_data = response.data;

      if (license_data.active) {
        const formatted_dates = handleTimezoneOffset(license_data.start_date, license_data.end_date, false);

        Object.assign(license, {
          ...license_data,
          start_date: parseDate(formatted_dates.start, 'labels'),
          end_date: parseDate(formatted_dates.end, 'labels'),
        });

        coverage_area.value = license_data.license_zones
          ? license_data.license_zones.flatMap((zone) => zone.coordinates)
          : [];
      } else {
        Object.assign(license, license_data);
      }
    } catch (err) {
      error.value = true;
      toast.error(t('geoindicators.report.license_error'));
      sty.captureException(err);
    } finally {
      is_loading.value = false;
    }
  };

  const getReport = async() => {
    try {
      is_loading.value = true;
      const response = await axios.get(`${process.env.API_URL}/geoindicators/reports/${route.params._id}`);
      const report_data = response.data;

      Object.assign(report, {
        ...report_data,
        dates: handleTimezoneOffset(report_data.start_date, report_data.end_date, false),
      });
      if (report_data.type === 'economic') {
        categories.value = report_data.categories;
        origin_fences.value = report_data.target_fences.filter((fence) => fence.type === 'origin');
        destination_fences.value = report_data.target_fences.filter((fence) => fence.type === 'destination');
      }
    } catch (err) {
      error.value = true;
      toast.error(t('geoindicators.report.fetch_error'));
      sty.captureException(err);
    } finally {
      is_loading.value = false;
    }
  };

  const updateOutsideZone = (outsideZone) => {
    outside_zone.value = outsideZone.value;
  };

  const computeTime = (reportType, queueTime, targetFencesLength, reportDurationDays) => {
    let report_type_time = 5;
    let report_duration_time = 5;
    let target_fences_time = 5;

    switch (reportType) {
      case 'market':
        report_type_time = 0.5;

        if (reportDurationDays < 180) {
          report_duration_time = 3.5;
        } else if (179 < reportDurationDays && reportDurationDays < 365) {
          report_duration_time = 4;
        } else if (364 < reportDurationDays) {
          report_duration_time = 5.5;
        }

        if (targetFencesLength < 3) {
          target_fences_time = 13;
        } else if (2 < targetFencesLength && targetFencesLength < 5) {
          target_fences_time = 23;
        } else if (4 < targetFencesLength && targetFencesLength < 10) {
          target_fences_time = 30;
        } else if (9 < targetFencesLength && targetFencesLength < 20) {
          target_fences_time = 35;
        } else if (19 < targetFencesLength) {
          target_fences_time = 45;
        }

        break;
      case 'visits':
        report_type_time = 1;

        if (reportDurationDays < 90) {
          report_duration_time = 2;
        } else if (89 < reportDurationDays && reportDurationDays < 180) {
          report_duration_time = 2.5;
        } else if (179 < reportDurationDays && reportDurationDays < 365) {
          report_duration_time = 3;
        } else if (364 < reportDurationDays) {
          report_duration_time = 5;
        }

        if (targetFencesLength < 3) {
          target_fences_time = 10;
        } else if (2 < targetFencesLength && targetFencesLength < 5) {
          target_fences_time = 15;
        } else if (4 < targetFencesLength && targetFencesLength < 10) {
          target_fences_time = 20;
        } else if (9 < targetFencesLength && targetFencesLength < 20) {
          target_fences_time = 30;
        } else if (19 < targetFencesLength) {
          target_fences_time = 40;
        }

        break;
      case 'economic':
        report_type_time = 2;

        if (reportDurationDays < 180) {
          report_duration_time = 1.5;
        } else if (179 < reportDurationDays && reportDurationDays < 365) {
          report_duration_time = 2;
        } else if (364 < reportDurationDays) {
          report_duration_time = 2.75;
        }

        if (targetFencesLength < 5) {
          target_fences_time = 6;
        } else if (4 < targetFencesLength && targetFencesLength < 10) {
          target_fences_time = 8;
        } else if (9 < targetFencesLength && targetFencesLength < 20) {
          target_fences_time = 12;
        } else if (19 < targetFencesLength) {
          target_fences_time = 22;
        }

        break;
      case 'tourism':
        report_type_time = 10;

        if (reportDurationDays < 180) {
          report_duration_time = 2;
        } else if (179 < reportDurationDays && reportDurationDays < 365) {
          report_duration_time = 2.5;
        } else if (364 < reportDurationDays) {
          report_duration_time = 3;
        }

        if (targetFencesLength === 1) {
          target_fences_time = 15;
        } else if (targetFencesLength === 2) {
          target_fences_time = 20;
        } else if (targetFencesLength === 3) {
          target_fences_time = 25;
        } else if (3 < targetFencesLength) {
          target_fences_time = 27;
        }
        break;
      case 'trails':
        report_type_time = 2;

        if (reportDurationDays < 30) {
          report_duration_time = 4;
        } else if (29 < reportDurationDays && reportDurationDays < 90) {
          report_duration_time = 4;
        } else if (89 < reportDurationDays && reportDurationDays < 180) {
          report_duration_time = 4.5;
        } else if (179 < reportDurationDays && reportDurationDays < 365) {
          report_duration_time = 5;
        } else if (364 < reportDurationDays) {
          report_duration_time = 7;
        }

        if (targetFencesLength === 1) {
          target_fences_time = 5;
        } else if (targetFencesLength === 2) {
          target_fences_time = 6;
        } else if (2 < targetFencesLength && targetFencesLength < 6) {
          target_fences_time = 9;
        } else if (5 < targetFencesLength) {
          target_fences_time = 20;
        }
        break;
    }

    return Math.ceil((queueTime + report_type_time * target_fences_time * report_duration_time) / 5) * 5;
  };

  const openChat = () => {
    window._chatlio.open();
  };

  if (event_hub) {
    event_hub.on('outside_zone', updateOutsideZone);
  }

  return {
    is_loading,
    error,
    report,
    license,
    outside_zone,
    coverage_area,
    categories,
    origin_fences,
    destination_fences,
    is_fence_remaining,
    is_license_valid,
    copyReport,
    deleteReport,
    getSharedLink,
    getLicense,
    getReport,
    updateOutsideZone,
    computeTime,
    openChat,
  };
}
