export const REPORTS = {
  unitary: {
    before_after: {
      url_demo: {
        en: '67b3577a4369100cdbbaf0f2',
        fr: '67b350a04369100cdbbab98a'
      },
      url_documentation: ''
    },
    cross_visits: {
      url_demo: {
        en: '67b351d94369100cdbbac0db',
        fr: '67b34c4c4369100cdbbaa60a'
      },
      url_documentation: ''
    }
  },
  industry: {
    visits: {
      url_demo: {
        en: '67b3566d4369100cdbbae35b',
        fr: '67b34b7b4369100cdbbaa4d3'
      },
      url_documentation: ''
    },
    market: {
      url_demo: {
        en: '67b3563e4369100cdbbae2e2',
        fr: '67b350584369100cdbbab90c'
      },
      url_documentation: ''
    },
    economic: {
      url_demo: {
        en: '645ad3718bac380002fbaeca',
        fr: '645ad3718bac380002fbaeca'
      },
      url_documentation: ''
    },
    tourism: {
      url_demo: {
        en: '659856cd98e11300021c390b',
        fr: '659856cd98e11300021c390b'
      },
      url_documentation: ''
    },
    trails: {
      url_demo: {
        en: '67b357d54369100cdbbaf818',
        fr: '67b351314369100cdbbab9e4'
      },
      url_documentation: ''
    },
  },
  beta: {
    beta_habits_vs_population: {
      url_demo: '',
      url_documentation: ''
    }
    // TEMP
    // beta_visitors_profiles: {
    //   url_demo: '',
    //   url_documentation: ''
    // },
  },
};
