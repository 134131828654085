<template>
  <div class="panel">
    <div class="title">
      <b>
        {{ $t('geoindicators.report.widgets.visits_entry_exits.title') }}
        <tooltip
          :text="$t('geoindicators.report.widgets.visits_entry_exits.tooltip')" :show_tooltip="show_tooltip">
        </tooltip>
      </b>
      <aside class="actions">
        <dropdown :is_button="false">
          <template #actions>
            <ul class="list">
              <li>
                <label class="checkbox">
                  <i class="color" :style="{ 'background': entry_options.gradient[4] }"></i> {{ $t('geoindicators.report.widgets.visits_entry_exits.entry_layer') }}
                  <input v-model="show_entry_layer" type="checkbox">
                  <span></span>
                </label>
              </li>
              <li>
                <label class="checkbox">
                  <i class="color" :style="{ 'background': exit_options.gradient[4] }"></i> {{ $t('geoindicators.report.widgets.visits_entry_exits.exit_layer') }}
                  <input v-model="show_exit_layer" type="checkbox">
                  <span></span>
                </label>
              </li>
              <li>
                <label class="checkbox">
                  {{ $t('geoindicators.report.widgets.markers_toggle') }}
                  <input v-model="show_marker_layer" type="checkbox">
                  <span></span>
                </label>
              </li>
              <li>
                <label class="checkbox">
                  {{ $t('geoindicators.report.widgets.polygons_toggle') }}
                  <input v-model="show_polygon_layer" type="checkbox">
                  <span></span>
                </label>
              </li>
            </ul>
          </template>
          <template #title>
            <a class="info">
              <i class="fas fa-layer-group"></i>
              <span class="d-none d-lg-inline">{{ $t('geoindicators.report.widgets.layers_toggle') }}</span>
              <i class="fas fa-caret-down"></i>
            </a>
          </template>
        </dropdown>
      </aside>
    </div>

    <div class="custom-heatmap-container">
      <div class="heatmap-container">
        <GoogleMap
          :api-promise="apiPromise"
          ref="report_map"
          mapId="VISITS_ENTRY_EXIT_ID"
          style="height: 650px;"
          :maxZoom="map.options.maxZoom"
          :zoom="map.zoom"
          :scaleControl="true"
          :center="map.center">
          <div v-for="(fence, i) of data">
            <!-- Entry points cluster -->
            <MarkerCluster
              v-if="show_entry_layer"
              clusterClass="entry-exit-cluster"
              :options="{
                algorithm: cluster_algorithm,
                renderer: clusterRenderer(total_entries, 'assets/entry1.png')
              }">
              <AdvancedMarker
                v-for="(point, j) of fence.entry"
                :key="`m-${j}`"
                :options="markerOptions(point)">
              </AdvancedMarker>
            </MarkerCluster>
            <!-- Exit points cluster -->
            <MarkerCluster
              v-if="show_exit_layer"
              clusterClass="entry-exit-cluster"
              :options="{
                algorithm: cluster_algorithm,
                renderer: clusterRenderer(total_exits, 'assets/exit1.png')
              }">
              <AdvancedMarker
                v-for="(point, j) of fence.exit"
                :key="`m-${j}`"
                :options="markerOptions(point)">
              </AdvancedMarker>
            </MarkerCluster>
            <!-- Target fences polygon -->
            <Polygon
              v-for="(path, j) of fence.polygon.path"
              v-if="show_polygon_layer"
              :key="`p-${i}`"
              :options="{
                paths: path,
                editable: false,
                draggable: false,
                fillColor: fence.polygon.color[4],
                strokeColor: fence.polygon.color[4],
                fillOpacity: 0.35,
                strokeOpacity: 1
              }">
            </Polygon>
            <!-- Polygon Marker -->
            <AdvancedMarker
              v-for="(path, j) of fence.polygon.path"
              v-if="show_marker_layer"
              :key="path[0].lng"
              :options="{
                position: path[0],
                gmpClickable: true
                }"
              @click="toggleMarkerInfo(fence.polygon, i, j)">
              <!-- Info window -->
              <InfoWindow
                v-if="show_marker_layer && active_marker.polygon_index === i && active_marker.marker_index === j"
                v-model="info_window_open"
                :options="{
                  position: info_window_position,
                  content: info_options.content
                }"
              />
            </AdvancedMarker>
          </div>
        </GoogleMap>
      </div>
    </div>
  </div>
</template>

<script setup>
  import tooltip from '../../tooltip.vue';
  import {mapOptions, useGmap} from '../../../../js/gmap-imports.js';
  import {computed, reactive, ref, watch} from 'vue';
  import {GoogleMap, InfoWindow, AdvancedMarker, MarkerCluster, Polygon} from 'vue3-google-map';
  import {SuperClusterAlgorithm} from '@googlemaps/markerclusterer';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    },
    show_tooltip: {
      type: Boolean,
      required: false
    }
  });

  const show_polygon_layer = ref(true);
  const show_entry_layer = ref(true);
  const show_exit_layer = ref(true);
  const show_marker_layer = ref(true);
  const info_window_position = ref(null);
  const info_window_open = ref(false);

  const active_marker = reactive({});

  const info_options = reactive({
    content: '',
    pixelOffset: {
      width: 0,
      height: -35
    }
  });

  const map = reactive({
    options: {
      ...mapOptions,
      maxZoom: 16
    },
    center: {
      lat: 45.539448,
      lng: -73.63254
    },
    zoom: 6
  });

  const entry_options = reactive({
    gradient: ['rgba(52, 152, 219, 0)', 'rgba(52, 152, 219, 0.4)', 'rgba(52, 152, 219, 0.6)', 'rgba(52, 152, 219, 0.8)', 'rgba(52, 152, 219, 1)']
  });

  const exit_options = reactive({
    gradient: ['rgba(255, 160, 0, 0)', 'rgba(255, 160, 0, 0.4)', 'rgba(255, 160, 0, 0.6)', 'rgba(255, 160, 0, 0.8)', 'rgba(255, 160, 0, 1)']
  });

  const {
    apiPromise,
    map_ref: report_map,
    zoomTo
  } = useGmap(map);

  const total_entries = computed(() => {
    return props.data.reduce((acc, serie) => acc + serie.entry.length, 0);
  });

  const total_exits = computed(() => {
    return props.data.reduce((acc, serie) => acc + serie.exit.length, 0);
  });

  const cluster_algorithm = new SuperClusterAlgorithm({
    minPoints: 10,
    radius: 400
  });

  const markerOptions = (point) => {
    const marker = document.createElement('div');

    marker.style.pointerEvents = 'none';
    marker.style.display = 'none';

    return {
      position: point,
      content: marker,
      gmpClickable: false
    };
  };

  const toggleMarkerInfo = (polygon, polygon_index, marker_index) => {
    active_marker.polygon_index = polygon_index;
    active_marker.marker_index = marker_index;
    info_window_position.value = polygon.path[marker_index][0];
    info_options.content = polygon.label;
    info_window_open.value = true;
    zoomTo({coordinates: polygon.path[marker_index]}, 15);
  };

  const clusterCalculator = (markers, total_markers) => {
    const percent = markers.length / total_markers * 100;
    let index = 3;

    if (percent <= 20) {
      index = 2;
    }

    if (percent <= 5) {
      index = 1;
    }

    return {
      text: `${percent.toFixed(2)}%`,
      index: index
    };
  };

  const clusterRenderer = (total_markers, icon_url) => {
    return {
      render({markers, position}) {
        const result = clusterCalculator(markers, total_markers);

        return new google.maps.Marker({
          position,
          label: {
            text: result.text,
            color: '#fff',
            fontSize: '14px',
            fontFamily: '$font-stack',
            fontWeight: 'bold'
          },
          icon: {
            url: icon_url,
            scaledSize: new google.maps.Size(60, 60)
          }
        });
      }
    };
  };

  watch(() => props.data, (oldVal, newVal) => {
    if (props.data.length) {
      map.center = props.data[0].polygon.path[0][0];
      map.zoom = 11;
    }
  }, {
    immediate: true,
    deep: true
  });
</script>
