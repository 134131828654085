<template>
  <div class="report-type-selection container">
    <header-bar
      :title="$t('geoindicators.creation.header.create_title')"
      :hide_actions="true"
      :unsaved_changes="report_type_changed">
    </header-bar>

    <loading :loading="is_loading" :error="error"/>

    <div v-if="!is_loading && !error">
      <report-creation-stepper
      :step="step">
      </report-creation-stepper>

      <report-type-selection
        v-if="step === 'choice'"
        :current_type="report.type"
        :license="license"
        @selected="selectReportType">
      </report-type-selection>

      <footer-bar
        :unsaved_changes="selected_report_type === ''"
        :report_data="{type: selected_report_type}"
        :license="license"
        :price_text="price_note"
        :note_next_btn="warning_note"
        :step="step"
        @next_step="nextStep">
      </footer-bar>
    </div>
  </div>
</template>

<script setup>
  import headerBar from '../components/reports/creation/headerBar.vue';
  import reportCreationStepper from '../components/reports/creation/reportCreationStepper.vue';
  import reportTypeSelection from '../components/reports/creation/reportTypeSelection.vue';
  import footerBar from '../components/reports/creation/footerBar.vue';
  import {useReport} from '../composables/report.js';
  import {inject, ref, computed, onMounted} from 'vue';
  import {useRoute, useRouter} from 'vue-router';
  import {useI18n} from 'vue-i18n';

  const {t} = useI18n();
  const router = useRouter();
  const route = useRoute();

  const sty = inject('sentry');
  const axios = inject('axios');
  const event_hub = inject('event-hub');

  const {
    is_loading,
    license,
    error,
    report,
    is_license_valid,
    getLicense,
    getReport,
  } = useReport(event_hub);

  Object.assign(license, {
    ...license,
    included_report_types: [],
    additional_report_types: []
  });

  const selected_report_type = ref('');
  const step = ref('choice');

  const is_included_in_license = computed(() => {
    if (selected_report_type.value && !selected_report_type.value.startsWith('beta')) {
      const included_types = license.additional_report_types ?
        license.included_report_types.concat(license.additional_report_types) :
        license.included_report_types;

      return included_types.includes(selected_report_type.value);
    }
    return false;
  });

  const report_type_changed = computed(() => {
    return report && report.type && selected_report_type.value !== report.type;
  });

  const price_note = computed(() => {
    const price = {
      note: '',
      html: '-'
    };

    if (selected_report_type.value !== '') {
      if (!license.license_type) {
        price.html = t('geoindicators.creation.price.price_not_included_license');
        price.note = t('geoindicators.creation.price.no_license');
      } else if (is_license_valid.value && is_included_in_license.value) {
        price.html = t('geoindicators.creation.price.price_included_license');
        price.note = t('geoindicators.creation.price.price_license_alert');
      } else if (!is_included_in_license.value || !is_license_valid.value) {
        price.html = t('geoindicators.creation.price.price_not_included_license');
        price.note = t('geoindicators.creation.price.license_warning_alert');
      }
    }

    return price;
  });

  const warning_note = computed(() => {
    return {
      type: 'warning',
      note: report_type_changed.value ? t('geoindicators.creation.unsaved_changes.report_type_change') : ''
    };
  });

  const nextStep = async() => {
    if (selected_report_type.value.startsWith('beta')) {
      step.value = 'beta';
      router.push({path: '/geoindicators/reports/edit', query: {type: selected_report_type.value}});
    } else {
      return createOrUpdateReport();
    }
  };

  const createOrUpdateReport = async() => {
    is_loading.value = true;
    error.value = false;

    if (route.params._id) {
      if (report_type_changed.value) {
        await axios.patch(`${process.env.API_URL}/geoindicators/reports/${route.params._id}`, {type: selected_report_type.value})
          .then((res) => {
            router.push({path: `/geoindicators/reports/edit/${res.data._id}`, query: {new: true}});
          })
          .catch((err) => {
            sty.captureException(err);
            error.value = true;
          }).finally(() => {
            is_loading.value = false;
          });
      } else {
        router.push({path: `/geoindicators/reports/edit/${route.params._id}`});
      }
    } else {
      await axios.post(`${process.env.API_URL}/geoindicators/reports`, {type: selected_report_type.value})
        .then((res) => {
          router.push({path: `/geoindicators/reports/edit/${res.data._id}`, query: {new: true}});
        })
        .catch((err) => {
          sty.captureException(err);
          error.value = true;
        }).finally(() => {
          is_loading.value = false;
        });
    }
  };

  const selectReportType = (report_type) => {
    selected_report_type.value = report_type;
  };

  is_loading.value = true;

  onMounted(async() => {
    await getLicense()
      .then(async() => {
        if (route.params._id) {
          await getReport()
            .then(() => {
              selectReportType(report.type);
            });
        }
      })
      .catch((err) => {
        error.value = true;
        sty.captureException(err);
      }).finally(() => {
        is_loading.value = false;
      });

    event_hub.on('next_step', createOrUpdateReport);
  });

</script>
