export const BLOCKED_DOMAINS = [
  // Facebook
  'facebook.com',
  'fb.com',
  'fb.me',
  'l.facebook.com',
  'm.facebook.com',
  'web.facebook.com',
  'business.facebook.com',
  'events.facebook.com',
  'pages.facebook.com',

  // WhatsApp
  'wa.me',
  'whatsapp.com',
  'api.whatsapp.com',

  // Google domains
  'goo.gl',
  'forms.gle',
  'docs.google.com/forms',
  'docs.google.com',
  'drive.google.com',
  'sites.google.com',
  'calendar.google.com',
  'google.com/maps',
  'maps.google.com',

  // YouTube
  'youtube.com',
  'youtu.be',

  // App Stores
  'apps.apple.com',
  'play.google.com',

  // PDFs
  '.pdf'
];
