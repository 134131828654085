 <template>
  <div>
    <div v-if="show_summary">
      <div class="title-separator">
        <h1>{{ $t('geoindicators.report.visits.summary.title') }}</h1>
        <p>{{ $t('geoindicators.report.visits.summary.text') }}</p>
        <hr>
      </div>

      <div class="row">
        <div class="col-md-4">
          <report-summary :data="data.summary.visits.sum" :type="'visits'" :title="$t('geoindicators.report.widgets.report_summary.total_visits')"></report-summary>
        </div>
        <div class="col-md-4">
          <report-summary :data="data.summary.visitors.sum" :type="'visitors'" :title="$t('geoindicators.report.widgets.report_summary.unique_visitors')"></report-summary>
        </div>
        <div class="col-md-4">
          <report-summary :data="data.summary.duration.sum" :type="'duration'" :title="$t('geoindicators.report.widgets.report_summary.visits_duration.text')"></report-summary>
        </div>
      </div>
    </div>

    <div class="title-separator">
      <h1>{{ $t('geoindicators.report.visits.visits.title') }}</h1>
      <p>{{ $t('geoindicators.report.visits.visits.text') }}</p>
      <hr>
    </div>

    <template v-if="display_volumes">
      <div class="row">
        <div class="col-md-6">
          <div class="panel">
            <div class="metric-title highlight">
              <div class="icon-wrapper">
                <i class="fas fa-users"></i>
              </div>
              <h2 v-if="data.summary.visits.sum">≈ {{ formatNumber(data.summary.visits.sum | splitNumber) }}</h2>
              <h2 v-else>-</h2>
              <p>{{ $t('geoindicators.report.widgets.report_summary.total_visits') }}</p>
            </div>
          </div>
        </div>
      </div>

      <visits-count
        :data="data.visits_count"
        :show_summary="true"
        :show_visits_count_per_day="limits.visits_per_day">
      </visits-count>
    </template>

    <template v-if="is_preview">
      <preview-form :classes="'visits'"></preview-form>
    </template>

    <visits-repartition :class="{'preview': is_preview && display_volumes}" :data="data.visits_repartition"></visits-repartition>

    <div :class="{'preview': is_preview}">
      <template v-if="display_volumes">
        <template v-if="data.summary.visitors">
          <div class="title-separator">
            <h1>{{ $t('geoindicators.report.visits.visitors.title') }}</h1>
            <p>{{ $t('geoindicators.report.visits.visitors.text') }}</p>
            <hr>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="panel">
                <div class="metric-title highlight">
                  <div class="icon-wrapper">
                    <i class="fas fa-user"></i>
                  </div>
                  <h2 v-if="data.summary.visits.sum">≈ {{ formatNumber(data.summary.visitors.sum | splitNumber) }}</h2>
                  <h2 v-else>-</h2>
                  <p>{{ $t('geoindicators.report.widgets.report_summary.unique_visitors') }}</p>
                </div>
              </div>
            </div>
          </div>
        </template>

        <visits-unique
          :data="data.visitors_count.by_month.avg ? data.visitors_count : data.visitors_count"
          :show_summary="true"
          :show_tooltip="true">
        </visits-unique>
      </template>

      <div class="title-separator">
        <h1>{{ $t('geoindicators.report.visits.origins.title') }}</h1>
        <p>{{ $t('geoindicators.report.visits.origins.text') }}</p>
        <hr>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="panel">
            <div class="metric-title">
              <h2 v-if="data.geography_summary.avg_distance_count">{{ $filters.splitNumber(data.geography_summary.avg_distance_count) }} <span>{{ $t('geoindicators.report.widgets.geography_summary.suffix') }}</span></h2>
              <h2 v-else>-</h2>
              <p>{{ $t('geoindicators.report.widgets.geography_summary.avg_title') }}</p>
            </div>
          </div>

          <tourists-repartition-donut :data="data.geography_summary.tourists_repartition" :show_tooltip="true"></tourists-repartition-donut>
        </div>

        <div class="col-md-6">
          <geography-summary :data="data.geography_summary"></geography-summary>
        </div>
      </div>

      <template v-if="data.origins.countries.length">
        <tourism-origins
          :data="data.origins"
          :show_title="true">
        </tourism-origins>
      </template>

      <visits-source :data="data.visits_source"></visits-source>

      <visits-pathing :data="data.visits_pathing"></visits-pathing>

      <div class="title-separator">
        <h1>{{ $t('geoindicators.report.visits.duration.title') }}</h1>
        <p>{{ $t('geoindicators.report.visits.duration.text') }}</p>
        <hr>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="panel">
            <div class="metric-title">
              <h2 v-if="data.summary.duration.avg">{{ $filters.splitNumber(data.summary.duration.avg) }} <span>{{ $t('geoindicators.report.widgets.report_summary.visits_duration.suffix') }}</span></h2>
              <h2 v-else>-</h2>
              <p>{{ $t('geoindicators.report.widgets.report_summary.visits_duration.text') }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <duration-distribution :data="data.duration"></duration-distribution>
        </div>

        <div class="col-md-6">
          <visits-duration-daytime-distribution :data="data.duration_daytime_distribution"></visits-duration-daytime-distribution>
        </div>
      </div>

      <visits-duration :data="data.duration"></visits-duration>

      <div class="title-separator">
        <h1>{{ $t('geoindicators.report.visits.frequency.title') }}</h1>
        <p>{{ $t('geoindicators.report.visits.frequency.text') }}</p>
        <hr>
      </div>

      <div class="row">
        <div class="col-md-6">
          <customer-visits-distribution :data="data.customer_visits_distribution"></customer-visits-distribution>
        </div>

        <div class="col-md-6">
          <customer-visits-duration-distribution :data="data.customer_visits_duration_distribution"></customer-visits-duration-distribution>
        </div>
      </div>

      <template v-if="data.demographics.income.avg">
        <div class="title-separator">
          <h1>{{ $t('geoindicators.report.visits.demography.title') }}</h1>
          <p>{{ $t('geoindicators.report.visits.demography.text') }}</p>
          <hr>
        </div>

        <demographic-infos :data="data.demographics"></demographic-infos>
      </template>
    </div>

  </div>
</template>

<script setup>
  import reportSummary from '../widgets/reportSummary.vue';
  import visitsCount from '../widgets/visitsCount.vue';
  import visitsUnique from '../widgets/visitsUnique.vue';
  import visitsDuration from '../widgets/visitsDuration.vue';
  import visitsDurationDaytimeDistribution from '../widgets/visitsDurationDaytimeDistribution.vue';
  import visitsRepartition from '../widgets/visitsRepartition.vue';
  import durationDistribution from '../widgets/durationDistribution.vue';
  import customerVisitsDistribution from '../widgets/customerVisitsDistribution.vue';
  import customerVisitsDurationDistribution from '../widgets/customerVisitsDurationDistribution.vue';
  import geographySummary from '../widgets/geographySummary.vue';
  import visitsSource from '../widgets/visitsSource.vue';
  import visitsPathing from '../widgets/visitsPathing.vue';
  import touristsRepartitionDonut from '../widgets/touristsRepartitionDonut.vue';
  import tourismOrigins from '../widgets/tourismOrigins.vue';
  import demographicInfos from '../widgets/demographicInfos.vue';
  import previewForm from '../widgets/previewForm.vue';
  import {ref, computed} from 'vue';
  import {formatNumber} from '../../../../js/helpers';

  const props = defineProps({
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    limits: {
      type: Object,
      required: false,
      default: () => ({})
    },
    is_preview: {
      type: Boolean,
      required: false,
      default: false
    }
  });

  const show_summary = ref(process.env.SHOW_SUMMARY === 'true');
  const display_volumes = computed(() => {
    return !(!props.data.summary.visits && !props.data.summary.visitors && !props.data.visits_count && !props.data.visitors_count);
  });
</script>
