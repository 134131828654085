<template>
  <a class="dropdown right" :class="{'active': open, 'btn' : is_button }" @click="toggle">
    <slot name="actions"></slot>
    <slot name="title"></slot>
  </a>
</template>

<script setup>
  import {ref, watch} from 'vue';

  const props = defineProps({
    is_button: {
      type: Boolean,
      default: true
    },
    id: {
      type: Number,
      default: 0,
      required: false
    },
    active_dropdown_id: {
      type: Number,
      default: 0,
      required: false
    }
  });

  const emit = defineEmits(['toggle']);
  const open = ref(false);

  const toggle = () => {
    props.id ? emit('toggle', props.id) : open.value = !open.value;
  };

  watch(() => props.active_dropdown_id, (id) => {
    open.value = id === props.id;
  });

</script>
