<template>
  <div class="panel">
    <div class="title">
      <b>{{ $t('geoindicators.creation.information_title') }}</b>
    </div>

    <aside class="alert warning" v-if="data_might_change"><i class="fa fa-exclamation-circle"></i> {{ $t('geoindicators.report.warnings.normalization') }}</aside>

    <label>{{ $t('geoindicators.creation.report_name') }}</label>
    <input
      v-tooltip.bottom="!is_name_valid ? {content: $t('geoindicators.creation.errors.no_report_name'), classes: 'error'} : ''"
      :class="{'invalid-field': !is_name_valid}"
      type="text"
      v-model="report.name"
      :placeholder="$t('geoindicators.creation.report_name_placeholder')"
      data-testid="report-name">

    <label>{{ $t('geoindicators.creation.start_end_dates') }}</label>
    <date-range-picker
      v-tooltip.bottom="date_picker_tooltip"
      v-model:value="report.dates"
      :classes="{'dp__invalid-field': !is_date_valid, 'dp__warning-field': is_date_valid && !is_lookback_valid}"
      :duration="{min: product.min_report_duration_days, max: product.max_report_duration_days}"
      :disabled_dates="disabledDates"
      :product="'geoindicators'"
      :enable_shortcuts="true">
    </date-range-picker>

    <label>{{ $t('geoindicators.creation.organization') }}</label>
    <organizations-select v-model:value="report.organization" :placeholder="$t('geoindicators.creation.organization_placeholder')"></organizations-select>
  </div>
</template>

<script setup>
  import dateRangePicker from '../../dateRangePicker.vue';
  import organizationsSelect from '../../organizationsSelect.vue';
  import validato from '@propulso/validato';
  import {reactive, computed, inject, watch} from 'vue';
  import {useI18n} from 'vue-i18n';
  import {canDataChange} from '../../../../js/helpers';

  const {t} = useI18n();

  const event_hub = inject('event-hub');

  const props = defineProps({
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    license: {
      type: Object,
      required: true,
      default: () => ({})
    }
  });

  const report = reactive(JSON.parse(JSON.stringify(props.value)));
  const product = reactive(validato.products[report.type]);

  const data_might_change = computed(() => {
    return canDataChange(report.end_date, report.type);
  });

  const date_picker_tooltip = computed(() => {
    if (!is_date_valid.value) {
      return {
        content: t('geoindicators.creation.errors.period_out_of_range', {
          min: min_report_duration_days.value,
          max: max_report_duration_days.value
        }),
        classes: 'error'
      };
    } else if (!is_lookback_valid.value) {
      return {
        content: t('geoindicators.creation.errors.lookback_out_of_range', {n: props.license.limits.lookback_days}),
        classes: 'warning'
      };
    }
    return '';
  });

  const is_lookback_valid = computed(() => {
    const lookback_limit = new Date();

    lookback_limit.setDate(lookback_limit.getDate() - props.license.limits.lookback_days);

    return new Date(report.dates.start) >= lookback_limit;
  });

  const min_report_duration_days = computed(() => {
    return props.license.limits.min_report_duration_days ? props.license.limits.min_report_duration_days : product.min_report_duration_days;
  });

  const max_report_duration_days = computed(() => {
    return props.license.limits.max_report_duration_days ? props.license.limits.max_report_duration_days : product.max_report_duration_days;
  });

  const is_date_valid = computed(() => {
    return validato.isFieldValid('report_dates', props.license, new Date(report.dates.start), report.dates.end, report.type, min_report_duration_days.value, max_report_duration_days.value);
  });

  const is_name_valid = computed(() => {
    return validato.isFieldValid('report_name', props.license, report.name);
  });

  const disabledDates = (date) => {
    const today = new Date();
    const min = new Date(validato.LOOKBACK);
    const max = new Date(today);

    min.setDate(min.getDate());
    max.setDate(today.getDate() - 7);

    return date < min || date > max;
  };

  watch(() => report, (new_value)  => {
    event_hub.emit('report_update', new_value);
    event_hub.emit('organization_update', report.organization);
  }, {
    deep: true
  });
</script>
