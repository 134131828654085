<template>
  <div class="panel">
    <div class="title">
      <b>{{ $t('geoindicators.creation.nights_split') }}</b>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="row align-items-center">
          <div class="col-4">
            <input
              v-tooltip.bottom="!is_split_valid
                ? {
                    content: t('geoindicators.creation.errors.nights_split_out_of_range',
                    {
                      min: product.min_day_split,
                      max: product.max_day_split
                    }), classes: 'error'
                  }
                : ''"
              :class="{'invalid-field': !is_split_valid}"
              type="number"
              v-model="nights_split">
          </div>
          <div class="col-6">
            <p>{{ $t('geoindicators.creation.nights').toLowerCase() }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import validato from '@propulso/validato';
  import {ref, computed, watch, inject} from 'vue';
  import {useI18n} from 'vue-i18n';

  const event_hub = inject('event-hub');
  const {t} = useI18n();

  const props = defineProps({
    value: {
      type: Object,
      required: true
    },
    license: {
      type: Object,
      required: true
    }
  });

  const nights_split = ref(props.value.day_split);
  const product = ref(validato.products[props.value.type]);

  const is_split_valid = computed(() => {
    return validato.isFieldValid('day_split', props.license, nights_split.value);
  });

  watch(nights_split, (update) => {
    event_hub.emit('nights_split_update', update);
  }, {
    deep: true
  });
</script>
