<template>
  <div class="panel">
    <div class="title">
      <b>{{ $t('geoindicators.creation.frequency') }}</b>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <label>{{ $t('geoindicators.creation.minimum') }}</label>
        <div class="row align-items-center">
          <div class="col-4">
            <input
            v-tooltip.bottom="!is_min_valid || !is_min_less_than_max ? {content: t('geoindicators.creation.errors.min_freq_out_of_range', {min: product.min_frequency, max: product.max_frequency}), classes: 'error'}: ''"
            :class="{'invalid-field': !is_min_valid || !is_min_less_than_max}"
            type="number"
            v-model="frequency.min">
          </div>
          <div class="col-6">
            <p>{{ $t('geoindicators.creation.visits') }}</p>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <label>{{ $t('geoindicators.creation.maximum') }}</label>
        <div class="row align-items-center">
          <div class="col-4">
            <input
            v-tooltip.bottom="!is_max_valid || !is_min_less_than_max ? {content: t('geoindicators.creation.errors.max_freq_out_of_range', {min: product.min_frequency, max: product.max_frequency}), classes: 'error'}: ''"
            :class="{'invalid-field': !is_max_valid || !is_min_less_than_max}"
            type="number"
            v-model="frequency.max">
          </div>
          <div class="col-6">
            <p>{{ $t('geoindicators.creation.visits') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import validato from '@propulso/validato';
  import {ref, inject, reactive, computed, watch} from 'vue';
  import {useI18n} from 'vue-i18n';

  const event_hub = inject('event-hub');
  const {t} = useI18n();

  const props = defineProps({
    value: {
      type: Object,
      required: true
    },
    license: {
      type: Object,
      required: true
    }
  });

  const product = ref(validato.products[props.value.type]);

  const frequency = reactive({
    min: props.value.min_frequency,
    max: props.value.max_frequency
  });

  const is_min_valid = computed(() => {
    return validato.isFieldValid('min_frequency', props.license, frequency.min);
  });

  const is_max_valid = computed(() => {
    return validato.isFieldValid('max_frequency', props.license, frequency.max);
  });

  const is_min_less_than_max = computed(() => {
    return validato.isFieldValid('compare_frequency', props.license, parseInt(frequency.min), parseInt(frequency.max));
  });

  watch(frequency, (update) => {
    event_hub.emit('frequency_update', update);
  }, {
    deep: true
  });
</script>
