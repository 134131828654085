<template>
  <div class="stats-by-domains-wrapper detailed-stats-wrapper col-lg-6">
    <div class="panel stats-by-domains">
      <div class="title">
        <b>{{ $t('campaign.stats.by_fence.title') }}</b>
        <aside class="actions">
          <a v-if="!is_loading" @click="csvExport" class="info">
            <i class="fas fa-file-excel"></i>
            <span class="d-none d-sm-inline">{{ $t('campaign.stats.export_csv') }}</span>
          </a>
        </aside>
      </div>

      <loading :loading="is_loading" :error="error" :is_panel="false"></loading>

      <div id="fence-grid-wrapper" class="grid-wrapper" v-if="!is_loading && !error">
        <ag-grid-vue
          style="height: 300px;"
          ref="fences_grid"
          class="ag-theme-balham ag-grid"
          :rowData="stats"
          :gridOptions="grid_options"
          @gridReady="onGridReady">
        </ag-grid-vue>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {AgGridVue} from 'ag-grid-vue3';
  import {useCampaignStatsTable} from '../composables/campaignStatsTable';
  import {useI18n} from 'vue-i18n';

  const file_name = 'metriques_zones_ciblees.csv';

  const {t} = useI18n();

  const getCustomColDefs = () => {
    return [{
      headerName: t('campaign.stats.by_fence.table_header'),
      field: 'fence',
      minWidth: 120
    }];
  };

  const props = defineProps({
    filters: {
      type: Object,
      required: true
    },
    campaign_id: {
      type: String,
      required: true
    },
    org_id: {
      type: String,
      required: true
    }
  });

  const {
    grid_html_id,
    grid_ref,
    stats_url,
    grid_options,
    stats,
    is_loading,
    error,
    csvExport,
    onGridReady,
    getStats
  } = useCampaignStatsTable(props, getCustomColDefs, file_name);

  grid_html_id.value = 'fence-grid-wrapper';
  grid_ref.value = 'fences_grid';
  stats_url.value = `${process.env.API_URL}/campaigns/${props.campaign_id}/reports/target_fences`;

  getStats();
</script>
