<template>
  <div class="panel no-padding">
    <div class="top-title metric-title">
      <h2>{{ global_filters.splitNumber(data.sum) }}
        <span v-if="type === 'duration'">{{ $t('geoindicators.report.widgets.report_summary.visits_duration.suffix') }}</span>
        <span v-if="type === 'speed'">km/h</span>
      </h2>
      <p>{{ title }}</p>
    </div>

    <apexchart
      height="150"
      type="area"
      :options="options"
      :series="series">
    </apexchart>
  </div>
</template>

<script setup>

  import {useChartOptions} from '../chart-options';
  import {sortEntries, parseDate} from '../../../../js/helpers';
  import {reactive, computed, getCurrentInstance} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {t, locale} = useI18n();
  const {DEFAULT_OPTIONS, MINUTES_TOOLTIP, SIMPLE, VISITORS_TOOLTIP, VISITS_TOOLTIP, SUMMARY_SPEED_TOOLTIP} = useChartOptions(t);

  const {appContext} = getCurrentInstance();
  const global_filters = appContext.config.globalProperties.$filters;

  const props = defineProps({
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    type: {
      type: String,
      required: true,
      default: ''
    },
    title: {
      type: String,
      required: true,
      default: ''
    }
  });

  const annotations = reactive({
    yaxis: [
      {
        y: 0,
        borderColor: '#969696',
        strokeDashArray: 2,
        label: {
          borderColor: '#ffa000',
          style: {
            color: '#fff',
            background: '#ffa000'
          },
          text: ' '
        }
      }
    ]
  });

  const ordered_data = computed(() => {
    return sortEntries(props.data.series);
  });

  const options = computed(() => {
    let tooltip = MINUTES_TOOLTIP;

    switch (props.type) {
      case 'visits':
        tooltip = VISITS_TOOLTIP;
        break;
      case 'visitors':
        tooltip = VISITORS_TOOLTIP;
        break;
      case 'duration':
        tooltip = MINUTES_TOOLTIP;
        break;
      case 'speed':
        tooltip = SUMMARY_SPEED_TOOLTIP;
        break;
    }

    return {
      ...DEFAULT_OPTIONS,
      ...tooltip,
      ...SIMPLE,
      annotations: {
        ...annotations,
        yaxis: [{
          ...annotations.yaxis[0],
          y: props.data.avg
        }]
      },
      xaxis: {
        categories: ordered_data.value.map(([date, nb_visits]) => parseDate(new Date(date), 'month_year', locale.value))
      }
    };
  });

  const series = computed(() => {
    return [{
      name: '',
      data: ordered_data.value.map(([date, nb_visits]) => nb_visits)
    }];
  });
</script>
