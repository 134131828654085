<template>
  <div class="popup import-popup">
    <div class="container">
      <div class="panel">
        <!-- Title -->
        <div class="title">
          <b>{{$t('components.map_circle_popup.title')}}</b>
          <div class="actions">
            <a class="info" @click="close">
              <i class="fas fa-times"></i>
            </a>
          </div>
        </div>
        <!-- Circle map -->
        <GoogleMap
          :api-promise="apiPromise"
          ref="popup_map"
          class="responsive-map"
          :options="options"
          :zoom="zoom"
          :center="center">
          <Circle
            ref="popup_circle"
            :options="{
              center: circle.centerPoint,
              radius: circle.radius,
              visible: true,
              draggable: true,
              editable: true,
              fillColor: 'grey',
              fillOpacity: 0.2
            }"
            @radius_changed="updateRadius"
            @center_changed="updatePosition">
          </Circle>
          <InfoWindow
            v-model="info_window_open"
            :options="{
              position: circle.centerPoint,
              content: info_options.content
            }"
          />
        </GoogleMap>
        <div class="divider"></div>
        <!-- Fence size -->
        <div>
          <label>{{$t('components.map_circle_popup.zone_size')}}</label>
          <select v-model="fences_size">
            <option :key="zoneSize.label" v-for="zoneSize in zones_size" :value="zoneSize.value">{{ zoneSize.label }}</option>
          </select>
        </div>
        <!-- Footer -->
        <div class="footer">
          <aside class="actions">
            <a @click="close" class="btn">{{$t('campaign.edit.fences_panel.footer.cancel')}}</a>
            <a class="btn success" @click="updateMap">{{$t('components.map_circle_popup.btn_next')}}</a>
          </aside>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {ref, reactive} from 'vue';
  import {mapOptions} from '../../js/map';
  import {useI18n} from 'vue-i18n';
  import {Circle, GoogleMap, InfoWindow} from 'vue3-google-map';
  import {useGmap} from '../composables/gmap';

  const {t} = useI18n();

  const emit = defineEmits(['update-popup-map', 'update-map-circle']);

  const options = ref(mapOptions);
  const zoom = ref(6);
  const popup_circle = ref(null);
  const fences_size = ref('small');

  const {apiPromise, map_ref: popup_map} = useGmap();

  const zones_size = ref([
    {label: t('components.map_circle_popup.label_zone_small'), value: 'small'},
    {label: t('components.map_circle_popup.label_zone_medium'), value: 'medium'},
    {label: t('components.map_circle_popup.label_zone_large'), value: 'large'}
  ]);

  const info_options = reactive({
    content: t('components.map_circle_popup.label_options'),
    pixelOffset: {
      width: 0,
      height: -35
    }
  });

  const center = reactive({
    lat: 46.901471,
    lng: -72.693870
  });

  const circle = reactive({
    radius: 220000,
    centerPoint: {
      lat: 46.901471,
      lng: -72.693870
    }
  });

  const close = () => {
    emit('update-popup-map', false);
  };

  const updatePosition = () => {
    const pos = popup_circle.value.circle.getCenter();
    circle.centerPoint.lat = pos.lat();
    circle.centerPoint.lng = pos.lng();
  };

  const updateRadius = () => {
    const radius = popup_circle.value.circle.getRadius();
    circle.radius = radius;
  };

  const updateMap = () => {
    emit('update-map-circle', circle, fences_size);
  };
</script>
