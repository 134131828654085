<template>
  <i
    class="fas fa-circle"
    :class="setReportColor(params.data.status)"
    v-tooltip.right="getReportStatus(params.data.status, t)">
  </i>
</template>

<script setup>
  import {getReportStatus, setReportColor} from '../../../js/helpers';
  import {useI18n} from 'vue-i18n';

  const {t} = useI18n();

  defineProps({
    params: {
      type: Object,
      required: false
    }
  });
</script>
