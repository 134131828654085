<template>
  <div class="sample">

    <header class="title-bar">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-lg-6 align-self-center">
            <h1 class="title">{{ $t('samples.create_page.header_title') }}</h1>
          </div>
        </div>
      </div>
    </header>

    <loading :loading="is_loading"></loading>

    <div class="container" v-if="!is_loading">

      <div class="row breadcrumbs">
        <div class="col-6 d-none d-lg-inline">
          <a href="/#/geomarketing/samples">‹ {{ $t('samples.create_page.breadcrumbs_title') }}</a>
        </div>
      </div>

      <div class="panel">
        <div class="title">
          <b>{{ $t('samples.create_page.information_panel.title') }}</b>
        </div>

        <label for="sample-name">{{ $t('samples.create_page.information_panel.sample_name') }}</label>
        <input id="sample-name" v-model="sample.name" :placeholder="$t('samples.create_page.information_panel.sample_name')">
        <label>{{ $t('samples.create_page.information_panel.sample_recency_title') }} <i class="fas fa-question-circle tooltip-icon" v-tooltip.bottom="$t('samples.create_page.information_panel.sample_recency_tooltip')"></i></label>
        <select v-model="sample.recency" >
          <option v-for="recency in $tm('samples.create_page.information_panel.recencies')" :key="recency.label" :value="recency.value">{{ recency.label }}</option>
        </select>
      </div>

      <div class="panel">
        <div class="title">
          <b>{{ $t('samples.create_page.goal_panel.title') }}</b>
        </div>
        <div class="goals">
          <label class="radio">
            <i class="fas fa-store"></i>
            {{ $t('samples.create_page.goal_panel.goal_foot_traffic') }}
            <input type="radio" name="sample_goal" value="foot_traffic" v-model="sample.goal">
            <span></span>
          </label>
          <label class="radio">
            <i class="fas fa-mouse-pointer"></i>
            {{ $t('samples.create_page.goal_panel.goal_clicks') }}
            <input type="radio" name="sample_goal" value="clicks" v-model="sample.goal">
            <span></span>
          </label>
          <label class="radio">
            <i class="fas fa-users"></i>
            {{ $t('samples.create_page.goal_panel.goal_notoriety') }}
            <input type="radio" name="sample_goal" value="notoriety" v-model="sample.goal">
            <span></span>
          </label>
          <label class="radio">
            <i class="fas fa-bullhorn"></i>
            {{ $t('samples.create_page.goal_panel.goal_announcement') }}
            <input type="radio" name="sample_goal" value="announcement" v-model="sample.goal">
            <span></span>
          </label>
        </div>
      </div>

      <fences-builder
        v-model:value="sample.target_fences"
        :title="$t('campaign.edit.strategy.title')"
        :export_polygons_data="{ id: sample._id, name: sample.name }"
        :product="'campaign_target'"
        :fence_type="'campaign'">
      </fences-builder>

      <div class="panel">
        <div class="title">
          <b>{{ `${$t('campaign.edit.creatives_panel.title')}` }}</b>
          <i class="fas fa-question-circle tooltip-icon" v-tooltip="$t('campaign.edit.creatives_panel.title_tooltip')"></i>
        </div>
        <div class="upload-choice">
          <div class="row">
            <div class="col-6">
              <div class="choice" @click="mediaTypeUpdate('banner')" :class="{'active': sample.media_type === 'banner'}">
                <i class="fas fa-image icon"></i>
                <p>{{ $t('campaign.edit.creatives_panel.banner_type') }}</p>
              </div>
            </div>
            <div class="col-6">
              <div class="choice" @click="mediaTypeUpdate('video')" :class="{'active': sample.media_type === 'video'}">
                <i class="fas fa-play-circle video icon"></i>
                <p>{{ $t('campaign.edit.creatives_panel.video_type') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <aside class="alert danger" v-if="errors.length">
        <p>
          <i class="fas fa-exclamation-triangle"></i>
          <b>{{ $t('samples.create_page.errors.title') }}</b>
        </p>
        <ul>
          <li v-for="error in errors">{{ error }}</li>
        </ul>
      </aside>

      <hr>
      <div class="actions-bar text-right">
        <a class="btn success" @click="createSample">
          {{ $t('samples.create_page.create_sample_button') }}
        </a>
      </div>
    </div>

  </div>
</template>

<script setup>
  import {useRouter, useRoute} from 'vue-router';
  import {useI18n} from 'vue-i18n';
  import {toast} from 'vue3-toastify';
  import {ref, reactive, inject} from 'vue';
  import fencesBuilder from '../components/fencesBuilder.vue';

  const router = useRouter();
  const route = useRoute();
  const {t} = useI18n();

  const event_hub = inject('event-hub');
  const sty = inject('sentry');
  const axios = inject('axios');

  const is_loading = ref(false);
  const errors = ref([]);

  const sample = reactive({
    name: '',
    recency: '1_month',
    goal: '',
    media_type: 'banner',
    target_fences: []
  });

  const validateSample = () => {
    const error_array = [];

    if (!sample.name) {
      error_array.push(t('samples.create_page.errors.name_error'));
    }
    if (!sample.recency) {
      error_array.push(t('samples.create_page.errors.recency_error'));
    }
    if (!sample.goal) {
      error_array.push(t('samples.create_page.errors.goal_error'));
    }
    if (!sample.target_fences.length) {
      error_array.push(t('samples.create_page.errors.target_fences_error'));
    } else if (sample.target_fences.length > 50) {
      error_array.push(t('samples.create_page.errors.target_max_fences_error'));
    } else if (error_array.target_fences) {
      error_array.target_fences.forEach((fence) => {
        if (!fence.label) {
          error_array.push(t('samples.create_page.errors.fence_name_error'));
        }
        if (fence.status !== 'valid') {
          error_array.push(t('samples.create_page.errors.valid_fences_error'));
        }
      });
    }
    if (!sample.media_type) {
      error_array.push(t('samples.create_page.errors.media_type_error'));
    }

    if (error_array.length && route.query.new === 'true') {
      router.replace({'query.new': null});
    }
    errors.value = error_array;
  };

  const mediaTypeUpdate = (type) => {
    sample.media_type = type;
  };

  const createSample = () => {
    is_loading.value = true;
    validateSample();

    if (!errors.value.length) {
      axios.post(`${process.env.API_URL}/samples`, sample)
        .then((res) => {
          sample.value = res.data;
          is_loading.value = false;
          toast.success(t('samples.create_page.sucess_msg_created'));
          router.push({path: `/geomarketing/samples`});
        })
        .catch((err) => {
          is_loading.value = false;
          toast.error(t('samples.create_page.error_msg_created'));
          sty.setContext('data', sample);
          sty.captureException(err);
        });

    } else {
      is_loading.value = false;
    }
  };

  event_hub.on('target_fences_update', (fences) => sample.target_fences = fences);

</script>
