<template>
  <div>
    <ul class="report-creation steps-bar">
      <li
        v-for="(step, index) in steps"
        :class="{'done': index <= step_number, 'current': index === step_number}">
        <span class="item-bubble"></span>
        <p class="label">{{ $t(`geoindicators.creation.stepper.${step}`)}}</p>
      </li>
    </ul>
  </div>
</template>

<script setup>
  import {ref, computed} from 'vue';

  const props = defineProps({
    step: {
      required: false,
      default: '',
      type: String
    }
  });

  const steps = ref(['choice', 'edit', 'summary']);

  const step_number = computed(() => {
    return steps.value.indexOf(props.step);
  });
</script>
