<template>
  <div class="panel no-padding grid visits-origin" id="grid-wrapper" v-if="row_data.length">
    <div class="title" v-if="show_title">
      <b>{{ t('geoindicators.report.before_after.details.title') }}</b>
    </div>

    <div class="tabs">
      <a class="tab"
        :class="{'active': selected === key }"
        v-for="[key, value] of Object.entries(categories)"
        @click="updateSelected(key)">
        {{ value }}
      </a>
    </div>

    <loading :loading="is_loading" :is_panel="false"></loading>

    <ag-grid-vue
      v-if="!is_loading"
      ref="detailed_grid"
      class="ag-theme-balham ag-grid"
      :rowData="row_data"
      :gridOptions="grid_options"
      :columnDefs="grid_options.columnDefs"
      @gridReady="onGridReady"
      @gridSizeChanged="onGridSizeChanged">
    </ag-grid-vue>
  </div>
</template>

<script setup>
  import {getSector} from '../../../../js/sectors.js';
  import {AgGridVue} from 'ag-grid-vue3';
  import {useI18n} from 'vue-i18n';
  import {ref, reactive} from 'vue';

  const {t} = useI18n();

  const props = defineProps({
    data: {
      type: Object,
      required: true,
      default: () => {}
    },
    show_title: {
      type: Boolean,
      required: false,
      default: false
    }
  });

  const categories = {
    subsectors: t('geoindicators.report.before_after.details.sectors_tab'),
    brands: t('geoindicators.report.before_after.details.brands_tab'),
    places: t('geoindicators.report.before_after.details.places_tab')
  };

  const detailed_grid = ref(null);
  const is_loading = ref(false);
  const selected = ref('subsectors');
  const grid_api = ref(null);
  const row_data = ref([]);
  const row_headers = ref([]);

  const grid_options = reactive({
    columnDefs: [],
    defaultColDef: {
      resizable: true,
      sortable: true
    },
    domLayout: 'autoHeight',
    rowHeight: 35,
    headerHeight: 50,
    multiSortKey: 'ctrl',
    localeText: {
      contains: t('geoindicators.report.ag_grid_table.contains'),
      clearFilter: t('geoindicators.report.ag_grid_table.clearFilter'),
      filterOoo: t('geoindicators.report.ag_grid_table.filterOoo'),
      noRowsToShow: t('geoindicators.report.ag_grid_table.noRowsToShow')
    }
  });

  const blanks_filter = [
    {
      displayKey: 'blanks',
      displayName: t('geoindicators.report.ag_grid_table.all'),
      test: function(filterValue, cellValue) {
        return cellValue === '' || cellValue !== '';
      },
      hideFilterInput: true
    },
    {
      displayKey: 'no_blanks',
      displayName: t('geoindicators.report.ag_grid_table.no_blanks'),
      test: function(filterValue, cellValue) {
        return cellValue !== '';
      },
      hideFilterInput: true
    }
  ];

  const contains_filter = {
    displayKey: 'contains',
    test: function([filterValue], cellValue) {
      return cellValue == null || cellValue > filterValue;
    },
    hideFilterInput: true
  };

  const updateSelected = (new_selected) => {
    is_loading.value = true;
    selected.value = new_selected;
    row_data.value = props.data[new_selected];
    grid_options.columnDefs = row_headers.value[new_selected];
    setTimeout(() => {is_loading.value = false}, 3000);
  };

  const onGridReady = (params) => {
    grid_api.value = params.api;
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const parsePercentage = (percentage) => {
    if (percentage.includes('<')) {
      return 0.5;
    } else if (percentage.includes(' - ')) {
      return parseFloat(percentage.split(' - ')[0]);
    } else if (percentage.includes('~0')) {
      return 0;
    }
    return parseFloat(percentage.replace('%', ''));
  };

  const getHeadersDefs = () => {
    const headers = [];

    for (let index in props.data) {
      headers[index] = [];

      for (const [key, val] of Object.entries(props.data[index][0])) {
        const filter_options = [...blanks_filter];

        if (typeof val === 'string') {
          filter_options.push(contains_filter);
        }

        const header_col = key === 'name' ? `${index}.${key}` : key;

        const comparator = (first_value, second_value) => {
          if (key === 'percentage') {
            const first = parsePercentage(first_value.percentage || first_value);
            const second = parsePercentage(second_value.percentage || second_value);

            return first === second ? 0 : (first > second ? 1 : -1);
          }

          if (key === 'sector' || key === 'name') {
            const first = (getSector(first_value) || '').toString().toLowerCase();
            const second = (getSector(second_value) || '').toString().toLowerCase();

            return first.localeCompare(second);
          }
        };

        headers[index].push({
          headerName: t('geoindicators.report.before_after.details' + '.' + header_col),
          field: `${key}`,
          sortable: true,
          suppressSizeToFit: false,
          filter: false,
          filterParams: {
            filterOptions: filter_options,
            clearButton: true,
            suppressSorting: false,
            suppressAndOrCondition: true
          },
          comparator: comparator,
          cellRenderer: (params) => {
            return params.value.includes('%') ? params.value : getSector(params.value);
          },
          ...((key === 'percentage') && {sort: 'desc'})
        });
      }

      const header_order = ['sector', 'name', 'percentage'];

      headers[index].sort((a, b) => header_order.indexOf(a.field) - header_order.indexOf(b.field));
    }

    return headers;
  };

  row_data.value = props.data[selected.value];
  row_headers.value = getHeadersDefs();
  grid_options.columnDefs = row_headers.value[selected.value];
</script>
