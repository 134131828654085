<template>
  <div class="geoindicators-reports">

    <!-- Header -->
    <header class="title-bar" ref="stickyHeader">
      <div class="row justify-content-between">
        <div class="col-4 align-self-center">
          <h1 class="title">{{ $t('geoindicators.reports.title') }}</h1>
        </div>
        <!-- Search Box -->
        <div v-if="show_gi_creation_btn" class="col-8 align-self-center text-right">
          <div class="search-box">
            <i class="fas fa-search search-icon"></i>
            <input
              id="filter-text-box"
              type="text"
              v-model="search_query"
              @input="onFilterTextBoxChanged"
              :placeholder="$t('geoindicators.reports.table.filters_placeholder')"
            />
            <button
              v-if="search_query"
              @click="clearSearch"
              class="clear-filter-btn"
              aria-label="Clear search"
            >
              <i class="fas fa-times"></i>
          </button>
          </div>

          <a @click="createReport" class="btn primary" data-testid="create-report">
            <i class="fas fa-plus"></i>
            <span class="d-none d-lg-inline pl-lg-1">{{ `${$t('geoindicators.reports.create_btn')}` }}</span>
          </a>
        </div>
      </div>
    </header>

    <loading :loading="is_loading && !show_grid" :error="error"/>

    <div v-if="!is_loading && !error">
      <!-- Reports list -->
      <div v-if="reports.length" v-show="show_grid" class="panel no-padding grid-wrapper" ref="gridWrapper">
        <ag-grid-vue
          ref="geoindicateurs-grid"
          class="ag-theme-balham ag-grid ag-list"
          :context="context"
          :rowData="reports"
          :gridOptions="grid_options"
          @grid-ready="onGridReady"
          @grid-size-changed="onGridSizeChanged">
        </ag-grid-vue>
      </div>

      <!-- No report -->
      <div class="container" v-else>
        <div class="panel dark no-report">
          <div class="row space">
            <div class="col-lg-6 offset-lg-1 align-self-center order-lg-last">
              <img src="assets/geoindicateurs-dark.svg" alt="GéoIndicateurs">
            </div>
            <div class="col-lg-5 align-self-center text">
              <h1>
                <b>{{ $t('geoindicators.reports.no_report.title') }}</b>
                <sup>{{ $t('geoindicators.reports.no_report.sup') }}</sup>
              </h1>
              <p>{{ $t('geoindicators.reports.no_report.text') }}</p>
              <div class="keypoint">
                <ul>
                  <li v-for="keypoint in $tm('geoindicators.reports.no_report.keypoints')">{{ keypoint }}</li>
                </ul>
              </div>
              <p class="cta">{{ $t('geoindicators.reports.no_report.text_cta') }}</p>
              <a v-if="show_gi_creation_btn" @click="createReport" class="btn primary">
                <i class="fas fa-plus"></i>
                {{ $t('geoindicators.reports.no_report.btn_cta') }}
              </a>
              <a href="/#/geoindicators/reports/6171a5a418bcaa0003755003" class="cta-link" target="_blank">
                <i class="fas fa-angle-right"></i>
                {{ $t('geoindicators.reports.no_report.btn_demo') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {reactive, ref, inject} from 'vue';
  import {useI18n} from 'vue-i18n';
  import {useRouter} from 'vue-router';
  import {AgGridVue} from 'ag-grid-vue3';
  import {parseDate, handleTimezoneOffset} from '../../js/helpers';
  import {useWhiteLabel} from '../composables/whitelabel';
  import reportRowStatus from '../components/ag-grid/reportRowStatus.vue';
  import customStatusFilter from '../components/ag-grid/customStatusFilter.vue';
  import customTextFilter from '../components/ag-grid/customTextFilter.vue';
  import customReportType from '../components/ag-grid/customReportType.vue';
  import customClientFilter from '../components/ag-grid/customClientFilter.vue';

  const {show_gi_creation_btn} = useWhiteLabel();
  const {t} = useI18n();
  const router = useRouter();

  const sty = inject('sentry');
  const axios = inject('axios');

  const context = ref({});
  const is_loading = ref(false);
  const error = ref(false);
  const grid_api = ref(null);
  const search_query =  ref('');
  const reports =  ref([]);
  const show_grid = ref(false);

  const saveGridState = () => {
    const column_state = grid_api.value.getColumnState();
    localStorage.setItem('gridColumnState', JSON.stringify(column_state));
    show_grid.value = true;
  };

  const grid_options = reactive({
    columnDefs: [
      {
        headerName: t('geoindicators.reports.table.status_header'),
        field: 'status',
        cellRenderer: reportRowStatus,
        minWidth: 75,
        width: 30,
        filter: customStatusFilter
      }, {
        headerName: t('geoindicators.reports.table.report_header'),
        field: 'name',
        width: 65,
        cellRenderer: (params) => {
          return `<a href="${getURL(params.data)}${params.data._id}">${params.data.name}</a>`;
        },
        filter: customTextFilter
      }, {
        headerName: t('geoindicators.reports.table.org_header'),
        field: 'organization',
        width: 60,
        filter: customClientFilter,
        cellRenderer: (params) => {
          return `<a href="${getURL(params.data)}${params.data._id}">${params.data.organization.name}</a>`;
        }
      }, {
        headerName: t('geoindicators.reports.table.type_header'),
        field: 'type',
        width: 65,
        filter: customReportType,
        cellRenderer: (params) => {
          const beta_badge = params.value.startsWith('beta_') ? `<span class="badge info beta">beta</span> ` : '';

          return `${beta_badge}<a href="${getURL(params.data)}${params.data._id}">${t(`geoindicators.report.type.${params.value}`)}</a>`;
        }
      }, {
        headerName: t('geoindicators.reports.table.date_header'),
        field: 'date',
        width: 65,
        sortable: false,
        filter: false,
        cellRenderer: (params) => {
          const formatted_dates = handleTimezoneOffset(params.data.start_date, params.data.end_date, false);
          return `<a href="${getURL(params.data)}${params.data._id}">${parseDate(formatted_dates.start, 'labels')} ${t('geoindicators.report.header.period_to')} ${parseDate(formatted_dates.end, 'labels')}</a>`;
        }
      }, {
        headerName: t('geoindicators.reports.table.creation_header'),
        field: 'activation_date',
        width: 65,
        filter: false,
        cellRenderer: (params) => {
          return params.data.activation_date
            ? `<a href="${getURL(params.data)}${params.data._id}">${parseDate(new Date(params.data.activation_date), 'labels')}</a>`
            : '';
        }
      },
      {
        headerName: 'id',
        field: '_id',
        hide: true,
        width: 0
      }
    ],
    defaultColDef : {
      minWidth: 150,
      resizable: true,
      sortable: true,
      filter: true,
      lockVisible: true
    },
    animateRows: false,
    domLayout: 'autoHeight',
    rowHeight: 40,
    headerHeight: 50,
    suppressHorizontalScroll: true,
    onSortChanged: saveGridState,
    onColumnMoved: saveGridState
  });

  const getURL = (params) => {
    if (params.status === 'draft') {
      return '/#/geoindicators/reports/edit/';
    } else {
      return '/#/geoindicators/reports/';
    }
  };

  const onGridReady = (params) => {
    grid_api.value = params.api;
    localStorage.getItem('gridColumnState') ? loadGridState() : saveGridState();
  };

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onFilterTextBoxChanged = () => {
    grid_api.value.setGridOption('quickFilterText', document.getElementById("filter-text-box").value);
  };

  const clearSearch = () => {
    search_query.value = '';
    grid_api.value.setGridOption('quickFilterText', search_query.value);
  };

  const loadGridState = () => {
    let column_state = localStorage.getItem('gridColumnState') || [];

    if (!column_state || column_state.length === 0) {
      column_state = grid_api.value.getColumnState();
      localStorage.setItem('gridColumnState', JSON.stringify(column_state));
    }

    if (column_state.length > 0) {
      grid_api.value.applyColumnState({
        state: JSON.parse(column_state),
        applyOrder: true
      });
    }

    show_grid.value = true;
  };

  const getData = () => {
    is_loading.value = true;
    error.value = false;

    axios.get(`${process.env.API_URL}/geoindicators/reports`)
      .then((res) => {
        reports.value = res.data;
        is_loading.value = false;
      })
      .catch((err) => {
        sty.captureException(err);
        is_loading.value = false;
        error.value = true;
      });
  };

  const createReport = () => {
    return router.push({path:'/geoindicators/reports/create'});
  };

  defineExpose({reportRowStatus});
  getData();
</script>
