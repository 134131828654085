<template>
  <div class="panel time-parting" v-if="show_widget">
    <div class="title">
      <b>
        {{ $t('geoindicators.creation.time_parting_title') }}
        <small class="optional">({{ $t('geoindicators.creation.optional') }})</small>
      </b>

      <aside class="actions">
        <a
          class="info"
          v-if="allow"
          const @click ="addParting"
          :class="{'disabled': !max_time_parting_valid}"
          v-tooltip="!max_time_parting_valid ? {content: $t('geoindicators.creation.errors.nb_time_parting_out_of_range')}: ''">
          <i class="fas fa-plus"></i> {{ $t('geoindicators.creation.add_time_parting') }}
        </a>
      </aside>
    </div>

    <p v-if="!allow">{{ $t('geoindicators.creation.time_parting_filter_license_only')}}</p>
    <p v-else-if="!time_parting.length">{{ $t('geoindicators.creation.time_parting_filter_default_msg')}}</p>

    <div v-if="allow" v-for="(time_parting_item, time_parting_index) in time_parting" :key="time_parting_index">
      <aside
        class="alert danger"
        v-if="time_parting.length && time_parting[time_parting_index].days.length < 1 && !is_new_report">
        <i class="fas fa-exclamation-circle"></i>
        {{ $t('geoindicators.creation.errors.no_day_part')}}
      </aside>

      <div class="row">
        <div class="col-12">
          <label
            class="checkbox filter-choice"
            :class="{'primary': time_parting[time_parting_index].days.includes(day.value)}"
            v-for="(day, index) in days_list">
            <input
            type="checkbox"
            name="days_parting"
            :value="day.value"
            v-model="time_parting[time_parting_index].days">
            {{ day.text }}
         </label>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-6 time-select">
              {{ $t('geoindicators.creation.filter_from') }}:
              <select
              v-model="time_parting[time_parting_index].start_time"
              v-tooltip.bottom="!isTimeValid(time_parting[time_parting_index].start_time, time_parting[time_parting_index].end_time) ? {
              content: $t('geoindicators.creation.errors.hour_part_from_to'),
              classes: 'error'
              }: ''"
              :class="{'invalid-field': !isTimeValid(time_parting[time_parting_index].start_time, time_parting[time_parting_index].end_time)}">
                <option
                  v-for="hour of time_slots"
                  :key="hour"
                  :value="hour" >
                  {{ hour }}
                </option>
              </select>
            </div>

            <div class="col-sm-6 time-select">
              {{ $t('geoindicators.creation.filter_until') }}:
              <select
                v-model="time_parting[time_parting_index].end_time"
                v-tooltip.bottom="!isTimeValid(time_parting[time_parting_index].start_time, time_parting[time_parting_index].end_time) ? {
                content: $t('geoindicators.creation.errors.hour_part_from_to'),
                classes: 'error'
                }: ''"
                :class="{'invalid-field': !isTimeValid(time_parting[time_parting_index].start_time, time_parting[time_parting_index].end_time)}">
                <option
                  v-for="hour of filteredEndHours(time_parting[time_parting_index].start_time)"
                  :key="hour"
                  :value="hour">
                  {{ hour }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-sm-6 align-self-end">
          <div class="row text-right">
            <div class="col-12">
              <a @click="deleteParting(time_parting_index)" class="info link">
                <i class="far fa-trash-alt"></i> {{ $t('geoindicators.creation.delete') }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="divider info" v-if="time_parting_index < time_parting.length - 1"></div>
    </div>
  </div>
</template>

<script setup>
  import {isFieldValid} from '@propulso/validato';
  import {reactive, computed, inject, ref, watch} from 'vue';
  import {useRoute} from 'vue-router';
  import {useI18n} from 'vue-i18n';

  const event_hub = inject('event-hub');
  const {t} = useI18n();
  const route = useRoute();

  const props = defineProps({
    value: {
      type: Object,
      required: false
    },
    license: {
      type: Object,
      required: true
    },
    show_widget: {
      type: Boolean,
      required: false,
      default: true
    },
    allow: {
      type: Boolean,
      required: false,
      default: false
    }
  });

  const time_slots = ref(null);
  const time_parting = reactive(props.value.time_parting ? props.value.time_parting : []);
  const days_list = [
    {text: t('geoindicators.creation.days_of_week.monday'), value: 1},
    {text: t('geoindicators.creation.days_of_week.tuesday'), value: 2},
    {text: t('geoindicators.creation.days_of_week.wednesday'), value: 3},
    {text: t('geoindicators.creation.days_of_week.thursday'), value: 4},
    {text: t('geoindicators.creation.days_of_week.friday'), value: 5},
    {text: t('geoindicators.creation.days_of_week.saturday'), value: 6},
    {text: t('geoindicators.creation.days_of_week.sunday'), value: 0}
  ];

  const is_new_report = computed(() => route.query?.new === 'true');

  const max_time_parting_valid = computed(() => {
    return isFieldValid('time_parting_count', props.license, time_parting.length);
  });

  const isTimeValid = (start, end) => {
    return isFieldValid('is_time_valid', props.license, start, end);
  };

  const filteredEndHours = (time_parting_start = '23:00') => {
    return time_parting_start ? timeSlots().filter((time) => time_parting_start < time) : timeSlots();
  };

  const timeSlots = () => {
    const slots = [];

    for (let hour = 0; hour < 24; hour++) {
      for (let minutes = 0; minutes < 60; minutes += 30) {
        const formattedTime = `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        slots.push(formattedTime);
      }
    }

    return slots;
  };

  const addParting = () => {
    if (max_time_parting_valid) {
      time_parting.push({
        days: [],
        start_time: '00:00',
        end_time: '23:30'
      });
    }
  };

  const deleteParting = (index) => {
    time_parting.splice(index, 1);
  };

  watch(time_parting, (update) => {
    event_hub.emit('time_part_update', update);
  }, {
    deep: true
  }
  );

  time_slots.value = timeSlots();
</script>
