const chartOptions = {
  stroke: {
    width: 2,
    curve: 'smooth'
  },
  plotOptions: {
    bar: {
      columnWidth: '20%'
    }
  },
  legend: {
    show: false
  },
  dataLabels: {
    enabled: false
  },
  chart: {
    toolbar: {
      show: false
    },
    animations: {
      enabled: false
    }
  },
  colors: ['#008060', '#333', '#9b59b6', '#3498db', '#ffc453', '#d82c0d', '#ffa000', '#dcddde'],
  fill: {
    opacity: [1, 1, 1, 1, 1, 1, 1, 0.3],
    gradient: {
      inverseColors: false,
      shade: 'light',
      type: 'vertical',
      opacityFrom: 0.85,
      opacityTo: 0.55
    }
  },
  labels: [],
  xaxis: {
    labels: {
      show: false
    }
  },
  yaxis: [{
    show: false,
    seriesName: 'Impressions',
    fieldKey: 'impressions'
  }, {
    show: false,
    seriesName: 'CPM',
    fieldKey: 'cpm'
  }, {
    show: false,
    seriesName: 'CPC',
    fieldKey: 'cpc'
  }, {
    show: false,
    seriesName: 'Clics',
    fieldKey: 'clicks'
  }, {
    show: false,
    seriesName: 'CPA',
    fieldKey: 'cpa'
  }, {
    show: false,
    seriesName: 'CTR',
    fieldKey: 'ctr'
  }, {
    show: false,
    seriesName: 'Actions',
    fieldKey: 'actions'
  }, {
    show: false,
    seriesName: 'Total',
    fieldKey: 'total_spend'
  }],
  markers: {
    size: 0,
    strokeColors: ['#008060', '#333', '#9b59b6', '#3498db', '#ffc453', '#d82c0d', '#ffa000', '#dcddde']
  }
};

const chartSeries = [{
  name: 'Impressions',
  fieldKey: 'impressions',
  type: 'line',
  data: []
}, {
  name: 'CPM',
  fieldKey: 'cpm',
  type: 'line',
  data: []
}, {
  name: 'CPC',
  fieldKey: 'cpc',
  type: 'line',
  data: []
}, {
  name: 'Clicks',
  fieldKey: 'clicks',
  type: 'line',
  data: []
}, {
  name: 'CPA',
  fieldKey: 'cpa',
  type: 'line',
  data: []
}, {
  name: 'CTR',
  fieldKey: 'ctr',
  type: 'line',
  data: []
}, {
  name: 'Actions',
  fieldKey: 'actions',
  type: 'line',
  data: []
}, {
  name: 'Total',
  fieldKey: 'total_spend',
  type: 'column',
  data: []
}];

const gaugeOptions = {
  chart: {
    height: 300,
    offsetY: 0,
    fontFamily: 'Poppins, sans-serif',
    foreColor: '#969696',
    sparkline: {
      enabled: true
    }
  },
  grid: {
    padding: {
      bottom: 30
    }
  },
  plotOptions: {
    radialBar: {
      startAngle: -115,
      endAngle: 115,
      dataLabels: {
        name: {
          fontSize: '14px',
          color: '#000',
          offsetY: 5
        },
        value: {
          offsetY: -30,
          fontSize: '1.5rem',
          fontWeight: 'bold',
          color: '#ffa000'
        }
      },
      hollow: {
        margin: 0,
        size: '70%',
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: 'front',
      },
      track: {
        background: '#dcddde',
        strokeWidth: '70%',
        margin: 0,
      },
    },
  },
  colors: ['#ffa000'],
  stroke: {
    lineCap: 'round'
  }
};

export {
  chartOptions,
  chartSeries,
  gaugeOptions
};
