<template>
  <div>
    <div class="panel">
      <div class="metric-title">
        <h2 v-if="data.avg">{{ data.avg }} <span>{{ $t('geoindicators.report.widgets.customer_visits_duration_distribution.avg_time_between.suffix') }}</span></h2>
        <h2 v-else>-</h2>
        <p>{{ $t('geoindicators.report.widgets.customer_visits_duration_distribution.avg_time_between.text') }}</p>
      </div>
    </div>

    <div class="panel">
      <div class="title">
        <b>
          {{ $t('geoindicators.report.widgets.customer_visits_duration_distribution.distribution_title') }}
          <tooltip
            :text="$t('geoindicators.report.widgets.customer_visits_duration_distribution.distribution_tooltip')"
            :show_tooltip="show_tooltip">
          </tooltip>
        </b>
      </div>

      <apexchart
      height="350"
      type="bar"
      :options="options"
      :series="series">
      </apexchart>
    </div>
  </div>
</template>

<script setup>
  import {useChartOptions} from '../chart-options';
  import tooltip from '../../tooltip.vue';
  import {useApexChart} from '../../../composables/apexchart';

  import {computed} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {t, tm} = useI18n();
  const {get_max_yaxis_value} = useApexChart();
  const {DEFAULT_OPTIONS, HORIZONTAL_GRADIENT, DEMOGRAPHIC_TOOLTIP} = useChartOptions(t);

  const props = defineProps({
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    show_tooltip: {
      type: Boolean,
      required: false
    }});

  const series = computed(() => [{
    name: '',
    data: Object.values(props.data.series)
  }]);

  const options = computed(() => {
    return {
      ...DEFAULT_OPTIONS,
      ...HORIZONTAL_GRADIENT,
      ...DEMOGRAPHIC_TOOLTIP,
      xaxis: {
        categories: tm('geoindicators.report.widgets.customer_visits_duration_distribution.distribution_labels'),
        max: get_max_yaxis_value(props.data.series),
        tickAmount: 5
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      }
    };
  });

</script>
