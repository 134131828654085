<template>
  <div>
    <span class="ag-filter-title">{{ params.colDef.headerName }}</span>
    <input
      class="ag-filter-filter mt-2"
      type="text"
      :placeholder="params.colDef.headerName"
      v-model="filter"
      @input="onFilterChanged"
    />
  </div>
</template>

<script>
  import {ref} from 'vue';

  export default {
    props: {
      params: {
        type: Object,
        required: true
      }
    },

    setup(props) {
      const filter = ref("");

      const afterGuiAttached = () => {
        filter.value = "";
      };

      const onFilterChanged = () => {
        props.params.api.onFilterChanged();
      };

      const isFilterActive = () => {
        return filter.value.trim() !== "";
      };

      const doesFilterPass = (node) => {
        const value = props.params.getValue(node);
        return (
          !filter.value.trim() ||
          (value && value.toString().toLowerCase().includes(filter.value.toLowerCase()))
        );
      };

      return {
        filter,
        afterGuiAttached,
        onFilterChanged,
        isFilterActive,
        doesFilterPass
      };
    }
  };
</script>
