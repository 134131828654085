<template>
  <div>
    <div class="panel">
      <div class="metric-title">
        <h2 v-if="data.avg_speed_count">
          {{ $filters.splitNumber( data.avg_speed_count) }} <span>
          {{ $t('geoindicators.report.widgets.geography_summary.speed.suffix') }}</span>
        </h2>
        <h2 v-else>-</h2>
        <p>{{ $t('geoindicators.report.widgets.geography_summary.speed.avg_title') }}</p>
      </div>
    </div>

    <div class="panel">
      <div class="title">
        <b>
          {{ $t('geoindicators.report.widgets.geography_summary.speed.distribution_title') }}
          <tooltip
            :text="$t('geoindicators.report.widgets.geography_summary.speed.distribution_tooltip')"
            :show_tooltip="show_tooltip">
          </tooltip>
        </b>
      </div>

      <apexchart
        height="350"
        type="bar"
        :options="options"
        :series="series"
        :class="{'hide-section': !data.show_speed_series}">
      </apexchart>
    </div>
  </div>
</template>

<script setup>
  import {useChartOptions} from '../chart-options';
  import tooltip from '../../tooltip.vue';
  import {useGisWidget} from '../../../composables/gis_widget';
  import {useApexChart} from '../../../composables/apexchart';
  import {computed} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {t, tm} = useI18n();
  const {DEFAULT_OPTIONS, HORIZONTAL_GRADIENT, SPEED_TOOLTIP, NO_DATA_MESSAGE} = useChartOptions(t, tm);
  const {get_max_yaxis_value} = useApexChart();

  const props = defineProps({
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    show_tooltip: {
      type: Boolean,
      required: false
    }
  });

  const {computeCategories} = useGisWidget(props.data);

  const series = computed(() => {
    let data = [];

    if (props.data.show_speed_series) {
      data = [{
        name: '',
        data: Object.values(props.data.series)
      }];
    }

    return data;
  });

  const options = computed(() => {
    const categories = computeCategories(props.data.series, 'speed');

    return {
      ...DEFAULT_OPTIONS,
      ...SPEED_TOOLTIP,
      ...HORIZONTAL_GRADIENT,
      ...NO_DATA_MESSAGE,
      xaxis: {
        categories: categories,
        max: get_max_yaxis_value(props.data.series),
        tickAmount: 5
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      }
    };
  });
</script>
