import {ref, watch} from "vue";

const useEmailEdition = (sub_user) => {
  const is_email_editable = ref(false);
  const local_org = JSON.parse(localStorage.user_data).organization;

  const updateEmailEditability = () => {
    if (!sub_user.organization._id) {
      is_email_editable.value = true;

      return;
    }

    const connected_org_level = local_org.org_level;
    const email_org_level = sub_user.organization.org_level;

    is_email_editable.value = connected_org_level < email_org_level;
  };

  updateEmailEditability();

  watch(() => sub_user.organization, updateEmailEditability, {
    deep: true
  });

  return {
    is_email_editable
  };
};

export {useEmailEdition};
