<template>
  <div class="login">
    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-md-3 col-lg-4 offset-lg-4">

          <!-- Logo -->
          <div class="logo text-center">
            <whitelabel></whitelabel>
          </div>

          <!-- Content -->
          <div class="panel">
            <!-- loading -->
            <div class="text-center" v-if="is_loading">
              <img src="assets/loading.value.gif">
              <p>{{ $t('login.connexion_loading') }}</p>
            </div>
            <!-- Form -->

            <div v-if="!is_loading && !reset_mode">
              <h3 class="text-center">{{ $t('login.title') }}</h3>
              <input
                type="text"
                :placeholder="$t('login.placeholder_username')"
                data-testid="input-username"
                v-model="form.email"
                @keyup.enter="login">
              <input
                v-model="form.password"
                :placeholder="$t('login.placeholder_password')"
                type="password"
                @keyup.enter="login">
              <button id="login" @click="login" class="primary">
                <i class="fa fa-lock"></i>
                {{ $t('login.connexion_btn') }}
              </button>
              <!-- Errors -->
              <div class="alert danger" v-if="error">
                <i class="fa fa-exclamation-circle"></i>
                <span>{{error}}</span>
              </div>
              <!-- Forgot password -->
              <div class="footer text-center" v-if="show_login_options">
                <a href="https://propulso.io/fr/inscription">{{ $t('login.footer_open_account') }}</a>
                &nbsp; • &nbsp;
                <a @click="forgotPassword">{{ $t('login.footer_forget_password') }}</a>
              </div>
            </div>
            <!-- Password reset steps -->
            <email-validation v-if="reset_mode === 'step-1'"></email-validation>
            <reset-password v-if="reset_mode === 'step-2'"></reset-password>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import whitelabel from '../components/whitelabel.vue';
  import emailValidation from '../components/login/email-validation.vue';
  import resetPassword from '../components/login/reset-password.vue';
  import {useWhiteLabel} from '../composables/whitelabel';
  import {ref, reactive, watch, inject} from 'vue';
  import {useRoute, useRouter} from 'vue-router';
  import {useI18n} from 'vue-i18n';

  const {t} = useI18n();
  const route = useRoute();
  const router = useRouter();
  const {show_login_options} = useWhiteLabel();

  const axios = inject('axios');
  const sty = inject('sentry');
  const i18n = inject('i18n');

  const is_loading = ref(false);
  const error = ref('');
  const reset_mode = ref(false);

  const form = reactive({
    email: '',
    password: ''
  });

  const login = () => {
    is_loading.value = true;
    error.value = '';

    axios.post(`${process.env.API_URL}/account/login`, form)
      .then((res) => {
        // Set cookies
        localStorage.setItem('jwt', res.data.token);
        localStorage.setItem('email', res.data.user.email);
        localStorage.setItem('user_data', JSON.stringify(res.data.user));
        localStorage.setItem('lang', res.data.user.language ? res.data.user.language : 'fr');

        if (res.data.user.language) {
          i18n.locale = res.data.user.language;
        }

        localStorage.setItem('currency', res.data.user.organization.markup.currency);
        sty.setUser(res.data.user);
        // Auth chat
        _chatlio.identify(res.data.user._id, {
          name: res.data.user.first_name + ' ' + res.data.user.last_name,
          email: res.data.user.email,
          organization: res.data.user.organization.name,
          organization_id: res.data.user.organization._id
        });

        // Auth Hubspot
        if (process.env.NODE_ENV === 'production' && _hsq) {
          _hsq.push(['identify', {
            email: res.data.user.email
          }]);
          _hsq.push(['trackPageView']);
        }

        is_loading.value = false;
        router.push({path: '/geomarketing/campaigns'});
      })
      .catch((err) => {
        if (err.status === 404) {
          error.value = t('login.error_password');
        } else {
          error.value = t('login.error_connection');
        }
        is_loading.value = false;
      });
  };

  const forgotPassword = () => {
    router.push('/?resetPassword=true');
  };

  watch(route, (to) => {
          reset_mode.value = false;

          if (to.query.resetPassword) {
            reset_mode.value = 'step-1';
          } else if (to.query.seed) {
            reset_mode.value = 'step-2';
          }
        },
        {
          deep: true,
          immediate: true
        });

  if (localStorage.email) {
    form.email = localStorage.email;
  }
</script>
