<template>
  <div ref="container" class="autocomplete-container">
    <input
      ref="autocomplete_field"
      class="autocomplete-input"
      v-bind="$attrs"
      :placeholder="placeholder"
    />
  </div>
</template>

<script setup>
  import {ref, watch} from 'vue';
  const props = defineProps({
    map_ref: {
      type: Object,
      required: true
    },
    google_api: {
      type: Object,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  });

  const emit = defineEmits(['place_changed']);

  const autocomplete_field = ref(null);
  const autocomplete = ref(null);
  const container = ref(null);

  watch(container, () => {
    props.map_ref.map.controls[props.google_api.ControlPosition.TOP_RIGHT].push(container.value);

    autocomplete.value = new props.google_api.places.Autocomplete(autocomplete_field.value);
    autocomplete.value.addListener('place_changed', () => {
      emit('place_changed', autocomplete.value.getPlace());
    });
  });
</script>

<style scoped>
.autocomplete-container {
  padding-right: 8px;
  width: 60%;
}

.autocomplete-input {
  height: 36px;
  font-size: 15px;
  padding: 5px;
  padding-left: 12px;
}
</style>
