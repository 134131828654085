import querystring from 'querystring';
import {parseDate} from '../../js/helpers';
import {inject, ref, reactive, watch, getCurrentInstance} from 'vue';
import {useI18n} from 'vue-i18n';

export function useCampaignStatsTable(props, getCustomColDefs, file_name, col_def_override = null) {
  const {appContext} = getCurrentInstance();
  const global_filters = appContext.config.globalProperties.$filters;

  const {t} = useI18n();

  const sty = inject('sentry');
  const axios = inject('axios');

  const stats = ref([]);
  const grid_html_id = ref('');
  const grid_ref = ref('');
  const stats_url = ref('');
  const grid_api = ref(null);
  const is_loading = ref(false);
  const error = ref(false);

  const getColDefs = col_def_override !== null ? col_def_override : () => {
    return [
      ...getCustomColDefs(),
      {
        headerName: t('campaign.list.summary_pannels.impressions'),
        field: 'impressions',
        minWidth: 80,
        width: 80,
        sort: 'desc',
        cellRenderer: (params) => `${global_filters.splitNumber(params.value)}`
      }, {
        headerName: t('campaign.list.summary_pannels.clicks'),
        field: 'clicks',
        minWidth: 40,
        width: 80,
        cellRenderer: (params) => `${global_filters.splitNumber(params.value)}`
      }, {
        headerName: t('campaign.list.summary_pannels.ctr'),
        field: 'ctr',
        minWidth: 40,
        width: 100,
        cellRenderer: (params) => `${params.value}%`
      }, {
        headerName: t('campaign.list.summary_pannels.total_spend'),
        field: 'total_spend',
        minWidth: 40,
        width: 100,
        cellRenderer: (params) => `${global_filters.splitNumber(params.value)}$ ${localStorage.currency}`
      }, {
        headerName: 'Actions',
        field: 'actions',
        minWidth: 40,
        width: 80,
        cellRenderer: (params) => `${global_filters.splitNumber(params.value)}`
      }, {
        headerName: t('campaign.list.summary_pannels.cpa'),
        field: 'cpa',
        minWidth: 40,
        width: 100,
        cellRenderer: (params) => `${global_filters.splitNumber(params.value)}$ ${localStorage.currency}`
      }, {
        headerName: t('campaign.list.summary_pannels.cpc'),
        field: 'cpc',
        minWidth: 40,
        width: 80,
        cellRenderer: (params) => `${global_filters.splitNumber(params.value)}$ ${localStorage.currency}`
      }, {
        headerName: t('campaign.list.summary_pannels.cpm'),
        field: 'cpm',
        minWidth: 40,
        width: 80,
        cellRenderer: (params) => `${global_filters.splitNumber(params.value)}$ ${localStorage.currency}`
      }];
  };

  const grid_options = reactive({
    columnDefs: getColDefs(),
    defaultColDef : {
      resizable: true,
      sortable: true
    },
    rowHeight: 35,
    headerHeight: 50,
    localeText: {
      noRowsToShow: t('account.invoices.invoices_panel.local_text.noRowsToShow'),
      clearFilter: t('account.invoices.invoices_panel.local_text.clearFilter'),
      contains: t('account.invoices.invoices_panel.local_text.contains'),
      notContains: t('account.invoices.invoices_panel.local_text.notContains'),
      toolPanelButton: t('account.invoices.invoices_panel.local_text.toolPanelButton'),
      filterOoo: t('account.invoices.invoices_panel.local_text.filterOoo')
    },
    enableCellTextSelection: true
  });

  const csvExport = () => {
    grid_api.value.exportDataAsCsv({fileName: file_name});
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
    grid_api.value = params.api;
  };

  const getStats = (bind_stats_override = null) => {
    is_loading.value = true;
    error.value = false;

    const start = parseDate(props.filters.dates.start);
    const end = parseDate(props.filters.dates.end);
    const query = {
      start_date: start,
      end_date: end
    };

    if (props.org_id) {
      query.client_id = props.org_id;
    }

    const url = `${stats_url.value}?${querystring.stringify(query)}`;

    return axios.get(url)
      .then((res) => {
        bind_stats_override !== null ? bind_stats_override(res.data) : bindStatsToGrid(res.data);
        is_loading.value = false;
      })
      .catch((err) => {
        error.value = true;
        is_loading.value = false;
        sty.captureException(err);
      });
  };

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const bindStatsToGrid = (data) => {
    stats.value = data;
  };

  watch(() => props.filters, () => {
    getStats()
      .then(() => {
        grid_api.value.setRowData(stats);
      });
  }, {
    deep: true
  });

  return {
    axios,
    grid_options,
    stats,
    grid_html_id,
    grid_ref,
    stats_url,
    grid_api,
    is_loading,
    error,
    csvExport,
    onGridReady,
    getStats,
    onGridSizeChanged,
    getColDefs,
    bindStatsToGrid
  };
}
