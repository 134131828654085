//Modules
import {createApp} from 'vue';
import * as Sentry from '@sentry/vue';
import {ExtraErrorData} from '@sentry/integrations';

//Internal Libs
import './js/img';
import {router, setupRouterGuards} from './router.js';
import event_hub from './js/event-hub.js';

//VueJS
import Vue3Toastify, {toast} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import * as VueCarousel from 'vue3-carousel';
import VueAxios from 'vue-axios';
import axios from 'axios';
import FloatingVue from 'floating-vue';
import {createI18n} from 'vue-i18n';
import VueYoutube from 'vue3-youtube';
import VueApexCharts from 'vue3-apexcharts';
import vSelect from 'vue-select';
import VueDatePicker from '@vuepic/vue-datepicker';
import {GChart} from 'vue-google-charts';

//Components
import loading from './vue/components/loading.vue';
import menunav from './vue/components/menunav.vue';
import collapseItem from './vue/components/collapse-item.vue';
import adDetector from './vue/components/ad-detector.vue';
import translation from './vue/translation.js';
import dropdown from './vue/components/dropdown.vue';

const app = createApp({});

app.component('menunav', menunav);
app.component('collapseItem', collapseItem);
app.component('adDetector', adDetector);
app.component('loading', loading);
app.component('dropdown', dropdown);

app.use(VueAxios, axios);
app.use(VueApexCharts);
app.provide('axios', app.config.globalProperties.axios);
app.component('VueCarousel', VueCarousel);
app.component('VueYoutube', VueYoutube);
app.component('VueDatePicker', VueDatePicker);
app.component('v-select', vSelect);
app.component('GChart', GChart);

app.use(FloatingVue, {
  placement: window.innerWidth < 576 ? 'bottom' : 'right',
  triggers: ['hover', 'click']
});
app.use(Vue3Toastify, {
  autoClose: 5000,
  closeButton: false,
  hideProgressBar: true,
  icon: false,
  multiple: false,
  position: toast.POSITION.BOTTOM_CENTER,
  theme: 'colored',
  width: '400px'
});

const nav_lang = navigator.language === 'fr-FR' || navigator.language === 'fr' || navigator.language === 'fr-CA' ? 'fr' : 'en';
const i18n = createI18n({
  locale: localStorage.getItem('lang') ? localStorage.getItem('lang') : nav_lang,
  allowComposition: true,
  messages: translation,
  globalInjection: true,
  legacy: false
});

app.use(i18n);

app.config.globalProperties.$filters = {
  splitNumber: (value) => {
    return value && Number(value) ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : value;
  },

  formatCurrency: (value, symbol = '$', zero = 2, options = {}) => {
    const {symbolOnLeft: symbol_on_left = true, thousandSeparator: thousand_separator = ',', decimalSeparator: decimal_separator = '.'} = options;

    const number = parseFloat(value);

    if (isNaN(number)) {
      return value;
    }

    const fixed_value = number.toFixed(zero);

    const parts = fixed_value.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousand_separator);

    const formatted_number = parts.join(decimal_separator);

    return symbol_on_left
      ? `${symbol}${formatted_number}`
      : `${formatted_number}${symbol}`;
  },

  ifZero: (value) => {
    return Number(value) === 0 ? "-" : value;
  },

  money: (value) => {
    if (typeof value === 'number') {
      if (i18n.locale === 'fr') {
        return value.toFixed().toString() + '$';
      }
      return '$' + value.toFixed().toString();
    }
  },

  capitalizeFirstLetter: (str) => {
    return str.substring(0, 1).toLocaleUpperCase() + str.substring(1);
  }
};

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue: app,
    dsn: process.env.SENTRY_DSN,
    integrations: [new ExtraErrorData({depth: 9})],
    normalizeDepth: 10
  });
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('jwt');
      router.push('/');
    }
    return Promise.reject(error);
  }
);

app.provide('event-hub', event_hub);
app.provide('sentry', Sentry);
app.provide('i18n', i18n);
app.config.compilerOptions.whitespace = 'preserve';

app.use(router);

router.beforeEach(setupRouterGuards(i18n, Sentry, axios));

app.mount('#app');
