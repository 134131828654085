<template>
  <div>
    <div class="title-separator">
      <h3>{{ $t('geoindicators.creation.basic_informations_section_title')}}</h3>
      <hr>
    </div>

    <basic-informations
      v-model:value="local_data"
      :license="license">
    </basic-informations>

    <time-parting
      v-model:value="local_data"
      :allow="local_data.limits.allow_day_parting"
      :license="license">
    </time-parting>

    <fences-builder
      v-model:value="local_data.target_fences"
      :title="$t('campaign.edit.strategy.title')"
      :fence_type="'report'"
      :show_tooltip="false"
      :export_polygons_data="{ id: local_data._id, name: local_data.name }"
      :product="'before_after'"
      :coverage_area="coverage_area"
      :license="license">
    </fences-builder>

    <div class="title-separator">
      <h3>{{ $t('geoindicators.creation.parameters_section_title')}}</h3>
      <hr>
    </div>

    <time-before-after
      v-model:value="local_data"
      :license="license">
    </time-before-after>
  </div>
</template>

<script setup>
  import basicInformations from '../basicInformations.vue';
  import fencesBuilder from '../../../fencesBuilder.vue';
  import timeBeforeAfter from '../filters/timeBeforeAfter.vue';
  import timeParting from '../filters/timeParting.vue';
  import {ref} from 'vue';

  const props = defineProps({
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    coverage_area: {
      type: Array,
      required: false,
      default: () => []
    },
    license: {
      type: Object,
      required: true,
      default: () => ({})
    }
  });

  const local_data = ref(props.data);
</script>
