<template>
  <div>
    <div class="panel">
      <div class="title">
        <b>{{ $t('geoindicators.creation.time_filter_title') }}</b>
      </div>

      <div class="row justify-content-between">
        <div class="col-sm-6">
          <label>{{ $t('geoindicators.creation.time_seconds_before_title') }}</label>
          <div class="row">
            <div class="col-sm-3">
              <input
                :class="{'invalid-field': !is_before_valid}"
                type="number"
                v-model="seconds_before.value"
                v-tooltip.bottom="getTooltip(!is_before_valid)">
            </div>

            <div class="col-sm-4">
              <select
                v-model="seconds_before.type"
                class="capitalized">
                <option value="hours">{{ $filters.capitalizeFirstLetter($t('geoindicators.creation.hours')) }}</option>
                <option value="minutes">{{ $filters.capitalizeFirstLetter($t('geoindicators.creation.minutes')) }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <label>{{ $t('geoindicators.creation.time_seconds_after_title') }}</label>
          <div class="row">
            <div class="col-sm-3">
              <input
                :class="{'invalid-field': !is_after_valid}"
                type="number"
                v-model="seconds_after.value"
                v-tooltip.bottom="getTooltip(!is_after_valid)">
            </div>

            <div class="col-sm-4">
              <select
                v-model="seconds_after.type"
                class="capitalized">
                <option value="hours">{{ $filters.capitalizeFirstLetter($t('geoindicators.creation.hours')) }}</option>
                <option value="minutes">{{ $filters.capitalizeFirstLetter($t('geoindicators.creation.minutes')) }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import validato from '@propulso/validato';
  import {reactive, ref, computed, watch, inject} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {t} = useI18n();

  const event_hub = inject('event-hub');

  const props = defineProps({
    value: {
      type: Object,
      required: true
    },
    license: {
      type: Object,
      required: true
    }
  });

  const product = ref(validato.products[props.value.type]);

  const hour_in_seconds = 3600;

  const seconds_before = reactive(
    {
      value: props.value.seconds_before / hour_in_seconds,
      type: 'hours'
    }
  );

  const seconds_after = reactive(
    {
      value: props.value.seconds_after / hour_in_seconds,
      type: 'hours'
    }
  );

  const is_at_least_one_non_zero = computed(() => {
    return validato.isFieldValid('is_at_least_one_non_zero', props.license, seconds_before.value, seconds_after.value);
  });

  const is_before_valid = computed(() => {
    return validato.isFieldValid('seconds_before_after', props.license, seconds_before.value);
  });

  const is_after_valid = computed(() => {
    return validato.isFieldValid('seconds_before_after', props.license, seconds_after.value);
  });

  const getTooltip = (value) => {
    if (value) {
      return {
        content: t('geoindicators.creation.errors.hours_out_of_range', {
          min: product.value.min_time / hour_in_seconds,
          max: product.value.max_time / hour_in_seconds
        }),
        classes: 'error'
      };
    }
    if (!is_at_least_one_non_zero.value) {
      return {
        content: t('geoindicators.creation.errors.seconds_not_zeros'),
        classes: 'error'
      };
    }
    return '';
  };

  const timeInUnit = (type, value) => {
    switch (type) {
      case 'hours':
        return value / hour_in_seconds;
      case 'minutes':
        return value / 60;
    }
  };

  const timeInSeconds = (type, value) => {
    switch (type) {
      case 'hours':
        return value * hour_in_seconds;
      case 'minutes':
        return value * 60;
    }
  };

  watch(() => seconds_before.value, () => {
    event_hub.emit('seconds_before_update', timeInSeconds(seconds_before.type, seconds_before.value));
  }, {
    deep: true
  });

  watch(() => seconds_before.type, () => {
    seconds_before.value = timeInUnit(seconds_before.type, props.value.seconds_before);
  });

  watch(() => seconds_after.value, (update) => {
    event_hub.emit('seconds_after_update', timeInSeconds(seconds_after.type, seconds_after.value));
  }, {
    deep: true
  });

  watch(() => seconds_after.type, (update) => {
    seconds_after.value = timeInUnit(seconds_after.type, props.value.seconds_after);
  });
</script>
