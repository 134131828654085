<template>
  <div class="panel">
    <div class="title">
      <b>{{ $t('campaign.edit.flight_configurator_panel.title') }}</b>
      <aside class="actions" v-if="budget_type === 'period'">
        <a class="info" @click="addFlight">
          <i class="fas fa-plus"></i>
          {{ $t('campaign.edit.flight_configurator_panel.add_period') }}
        </a>
      </aside>
    </div>
    <!-- Periods -->
    <div class="flight" v-for="(flight, i) in flights" v-if="budget_type === 'period'">
      <div class="row">
        <div class="col-md-4 align-self-center">
          <label>{{ $t('campaign.edit.flight_configurator_panel.start_date') }}</label>
          <vue-date-picker class="date-picker"
                       v-model="flight.dates.start"
                       value-type="format"
                       format="yyyy-MM-dd"
                       range-separator="-"
                       :auto-apply="true"
                       :locale="locale"
                       :clearable="false"
                       :enable-time-picker="false"
                       :disabled-dates="(date) => disabledPeriodsDates(date, i, 'start')"
                       :placeholder="$t('campaign.edit.flight_configurator_panel.placeholder_choose_date')"
                       :disabled="isDatePickerDisabled(flight.dates.start)"
                       @update:modelValue="emitFlightsUpdate">
          </vue-date-picker>
        </div>
        <div class="col-md-4 align-self-center">
          <label>{{ $t('campaign.edit.flight_configurator_panel.end_date') }}</label>
          <vue-date-picker class="date-picker"
                       v-model="flight.dates.end"
                       value-type="format"
                       format="yyyy-MM-dd"
                       range-separator="-"
                       :auto-apply="true"
                       :locale="locale"
                       :clearable="false"
                       :enable-time-picker="false"
                       :disabled-dates="(date) => disabledPeriodsDates(date, i, 'end')"
                       :placeholder="$t('campaign.edit.flight_configurator_panel.placeholder_choose_date')"
                       :disabled="isDatePickerDisabled(flight.dates.end)"
                       @update:modelValue="emitFlightsUpdate">
          </vue-date-picker>
        </div>
        <div class="col-3 align-self-center" :class="{'col-4': flights.length === 1}">
          <label for="flight-value" v-if="budget_metric === 'money'">{{ $t('campaign.edit.flight_configurator_panel.period_budget_money') }}</label>
          <label for="flight-value" v-if="budget_metric === 'impression'">{{ $t('campaign.edit.flight_configurator_panel.period_budget_impressions') }}</label>
          <input
            type="number"
            :min="minimum"
            step="25"
            id="flight-value"
            v-model="flight.value"
            @input="emitFlightsUpdate"
            :disabled="parseFlightDate(flight.dates.end) < today"
          >
        </div>
        <div class="col-1 align-self-center actions" v-if="flights.length > 1 && parseFlightDate(flight.dates.start) >= today">
          <a class="info" @click="removeFlight(i)">
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
      <div class="divider"></div>
    </div>
    <!-- Monthly -->
    <div class="flight" v-if="budget_type === 'monthly'">
      <div class="row">
        <div class="col-md-4 align-self-center">
          <label>{{ $t('campaign.edit.flight_configurator_panel.start_date_campaign') }}</label>
          <vue-date-picker class="date-picker"
                       v-model="flights[0].dates.start"
                       value-type="format"
                       format="yyyy-MM-dd"
                       range-separator="-"
                       :auto-apply="true"
                       :locale="locale"
                       :clearable="false"
                       :enable-time-picker="false"
                       :disabled-dates="(date) => disabledMonthlyDates(date, true)"
                       :placeholder="$t('campaign.edit.flight_configurator_panel.placeholder_choose_date')"
                       :disabled="campaign_status !== 'draft'"
                       @update:modelValue="emitFlightsUpdate">
          </vue-date-picker>
        </div>
        <div class="col-md-4 align-self-center">
          <label>{{ $t('campaign.edit.flight_configurator_panel.end_date_campaign') }}</label>
          <vue-date-picker class="date-picker"
                       v-model="flights[0].dates.end"
                       value-type="format"
                       format="yyyy-MM-dd"
                       range-separator="-"
                       :auto-apply="true"
                       :locale="locale"
                       :clearable="false"
                       :enable-time-picker="false"
                       :disabled-dates="(date) => disabledMonthlyDates(date, false)"
                       :placeholder="$t('campaign.edit.flight_configurator_panel.placeholder_choose_date')"
                       @update:modelValue="emitFlightsUpdate">
          </vue-date-picker>
        </div>
        <div class="col-md-4 align-self-center">
          <label for="flight-value" v-if="budget_metric === 'money'">
            {{ $t('campaign.edit.flight_configurator_panel.monthly_budget_money') }}
            <i class="fas fa-question-circle tooltip-icon" v-tooltip.bottom="$t('campaign.edit.flight_configurator_panel.monthly_budget_money_tooltip')"></i>
          </label>
          <label for="flight-value" v-if="budget_metric === 'impression'">
            {{ $t('campaign.edit.flight_configurator_panel.monthly_budget_impressions') }}
            <i class="fas fa-question-circle tooltip-icon" v-tooltip.bottom="$t('campaign.edit.flight_configurator_panel.monthly_budget_impressions_tooltip')"></i>
          </label>
          <input
            type="number"
            :min="minimum"
            step="25"
            id="flight-value"
            @input="emitFlightsUpdate"
            v-model="flights[0].value"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {ref, computed, watch} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {locale} = useI18n();

  const props = defineProps({
    modelValue: {
      type: Array,
      required: true
    },
    budget_type: {
      type: String,
      default: 'period'
    },
    budget_metric: {
      type: String,
      default: 'money'
    },
    campaign_status: {
      type: String
    },
    current_cpm: {
      type: Number
    }
  });

  const emit = defineEmits(['input', 'update:modelValue']);

  const flights = ref(JSON.parse(JSON.stringify(props.modelValue)));
  const today = ref(new Date(new Date().setHours(0, 0, 0, 0)));

  const minimum = computed(() => {
    switch (props.budget_metric) {
      case 'money':
        return 75;
      case 'impression':
        return (Math.ceil((75 / props.current_cpm * 1000) / 100)) * 100;
    }
  });


  const emitFlightsUpdate = () => {
    emit('update:modelValue', flights.value.map((e) => ({
      dates: {start: e.dates.start, end: e.dates.end},
      value: parseFloat(e.value),
      ...(e.external_id ? {external_id: e.external_id} : {})
    })));
  };

  const addFlight = () => {
    flights.value.push({
      dates: {
        start: '',
        end: ''
      },
      value: 500
    });
  };

  const removeFlight = (i) => {
    if (flights.value.length > 1) {
      flights.value.splice(i, 1);
    }
  };

  const disabledMonthlyDates = (date, isStart) => {
    if (date < today.value) {
      return true;
    }

    if (!flights.value[0].dates.start && !flights.value[0].dates.end) {
      return false;
    }

    return isStart ? date > parseFlightDate(flights.value[0].dates.end) : date < parseFlightDate(flights.value[0].dates.start);
  };

  const disabledPeriodsDates = (date, index, date_type) => {
    if (today.value > date) {
      return true;
    }

    if (date_type === 'start' && date > parseFlightDate(flights.value[index].dates.end)) {
      return true;
    } else if (date_type === 'end' && date < parseFlightDate(flights.value[index].dates.start)) {
      return true;
    }

    for (let i = 0; i < flights.value.length; i++) {
      if (i !== index) {
        let f = flights.value[i];
        if (f.dates && parseFlightDate(f.dates.start) <= date && parseFlightDate(f.dates.end) >= date) {
          return true;
        }
      }
    }

    return false;
  };

  const parseFlightDate = (flight_date) => {
    const date = new Date(flight_date);

    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  };

  const isDatePickerDisabled = (date) => {
    return props.campaign_status !== 'draft' && parseFlightDate(date) <= today.value;
  };

  watch(flights, () => {
    emit('input', flights.value.map((e) => {
      let flight = {
        dates: {
          start: e.dates.start,
          end: e.dates.end
        },
        value: parseFloat(e.value)
      };

      if (e.external_id) {
        flight.external_id = e.external_id;
      }

      return flight;
    }));
  }, {
    deep: true
  });

  const budget_type = computed(() => props.budget_type);

  watch(budget_type, (val) => {
    if (val === 'monthly') {
      flights.value = flights.value.slice(0, 1);
    }
  });
</script>
