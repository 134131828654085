<template>
  <div class="invoices">
    <div class="panel">
      <div class="title">
        <b>{{ $t('account.invoices.invoices_panel.title') }}</b>
      </div>

      <loading :loading="is_loading" :error="error"/>

      <div id="invoices-grid-wrapper" class="table" v-if="!is_loading && !error">
        <ag-grid-vue
          ref="invoices_grid"
          class="ag-theme-balham ag-grid"
          :rowData="invoices"
          :gridOptions="grid_options"
          @gridReady="onGridReady"
          @grid-size-changed="onGridSizeChanged">
        </ag-grid-vue>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {AgGridVue} from 'ag-grid-vue3';
  import cellDownloadPDF from '../ag-grid/cellDownloadPDF.vue';
  import {useI18n} from 'vue-i18n';
  import {reactive, ref, inject, getCurrentInstance} from 'vue';

  const {appContext} = getCurrentInstance();
  const global_filters = appContext.config.globalProperties.$filters;

  const {t} = useI18n();

  const sty = inject('sentry');
  const axios = inject('axios');

  const is_loading = ref(false);
  const error = ref(false);
  const invoices = ref([]);

  const getColDefs = () => {
    return [{
      headerName: t('account.invoices.invoices_panel.col_defs.status_header_name'),
      field: 'status',
      minWidth: 80,
      width: 100,
      cellRenderer: (params) => {
        switch (params.value) {
          case 'Payée':
            return `<span class="badge success">` + t('account.invoices.invoices_panel.col_defs.badge_success') + `</span>`;
          case 'Partiellement payée':
            return `<span class="badge warning">` + t('account.invoices.invoices_panel.col_defs.badge_warning') + `</span>`;
          case 'Non payée':
            return `<span class="badge danger">` + t('account.invoices.invoices_panel.col_defs.badge_danger') + `</span>`;
        }
      }
    }, {
      headerName: t('account.invoices.invoices_panel.col_defs.date_header_name'),
      field: 'date',
      sort: 'desc',
      minWidth: 80,
      width: 100
    }, {
      headerName: t('account.invoices.invoices_panel.col_defs.invoice_header_name'),
      field: 'invoice_number',
      minWidth: 80,
      width: 100
    }, {
      headerName: t('account.invoices.invoices_panel.col_defs.amount_header_name'),
      field: 'amount',
      minWidth: 80,
      width: 100,
      cellRenderer: (params) => `${global_filters.splitNumber(params.value)}$`
    }, {
      headerName: t('account.invoices.invoices_panel.col_defs.organiation_header_name'),
      field: 'org_name',
      minWidth: 80,
      width: 100
    }, {
      headerName: '',
      minWidth: 80,
      width: 100,
      cellRenderer: cellDownloadPDF,
      cellRendererParams: (params) => {
        return {
          id: params.data.id
        };
      }
    }];
  };

  const grid_options = reactive({
    columnDefs: getColDefs(),
    defaultColDef : {
      resizable: true,
      sortable: true
    },
    domLayout: 'autoHeight',
    rowHeight: 40,
    headerHeight: 50,
    localeText: {
      noRowsToShow: t('account.invoices.invoices_panel.local_text.noRowsToShow'),
      clearFilter: t('account.invoices.invoices_panel.local_text.clearFilter'),
      contains: t('account.invoices.invoices_panel.local_text.contains'),
      notContains: t('account.invoices.invoices_panel.local_text.notContains'),
      toolPanelButton: t('account.invoices.invoices_panel.local_text.toolPanelButton'),
      filterOoo: t('account.invoices.invoices_panel.local_text.filterOoo')
    },
    enableCellTextSelection: true
  });

  const getInvoices = () => {
    is_loading.value = true;

    axios.get(`${process.env.API_URL}/accounting/invoices`)
      .then((res) => {
        invoices.value = res.data;
        is_loading.value = false;
      }).catch((err) => {
        error.value = true;
        sty.captureException(err);
      });
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    if (!window.matchMedia('(max-width: 1100px)').matches) {
      const grid_width = document.getElementById('invoices-grid-wrapper').offsetWidth;
      let columns_to_show = [];
      let columns_to_hide = ['client_id'];

      const all_columns = params.api.getColumns();
      let total_cols_width = 0;

      for (let i = 0; i < all_columns.length; i++) {
        let column = all_columns[i];
        total_cols_width += column.getMinWidth();
        if (total_cols_width > grid_width) {
          columns_to_hide.push(column.colId);
        } else {
          columns_to_show.push(column.colId);
        }
      }

      params.api.setColumnsVisible(columns_to_show, true);
      params.api.setColumnsVisible(columns_to_hide, false);
      params.api.sizeColumnsToFit();
    }
  };

  getInvoices();
</script>
