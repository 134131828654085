<template>
  <div class="knowledge">
    <iframe :src="hubspot_url" class="hubspot-iframe"></iframe>
  </div>
</template>

<script setup>
  import {ref, computed} from 'vue';
  import {useRoute} from 'vue-router';

  const route = useRoute();

  const props = defineProps({
    page: {
      type: String,
      required: false,
      default: ''
    }
  });

  const current_lang = ref('en');

  const hubspot_url = computed(() => {
    if (route.params.lang && route.params.category && route.params.article) {
      return `https://hs.propulso.io/${route.params.lang}/knowledge/${route.params.category}/${route.params.article}`;
    }

    if (route.params.lang && route.params.article) {
      return `https://hs.propulso.io/${route.params.lang}/knowledge/${route.params.article}`;
    }

    if (current_lang.value === 'fr') {
      const page = route.query.page ? route.query.page : props.page;

      return `https://hs.propulso.io/fr/knowledge/${page}`;
    }

    return 'https://hs.propulso.io/en/knowledge';
  });

  if (localStorage.lang) {
    current_lang.value = localStorage.lang;
  }
</script>
