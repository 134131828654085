<template>
  <div class="geoindicators-new container">
    <div v-if="step === 'edit'">
      <header-bar
        :title="$t('geoindicators.creation.header.edit_title')"
        :unsaved_changes="unsaved_changes"
        :hide_actions="is_loading || error"
        @copy_report="copyReport"
        @delete_report="deleteReport">
      </header-bar>

      <loading :loading="is_loading" :error="error"/>

      <div v-if="!is_loading && !error">
        <report-creation-stepper
          :step="step">
        </report-creation-stepper>

        <component
          class="panels"
          :is="report_loader"
          :data="report"
          :coverage_area="coverage_area"
          :destination_fences="destination_fences"
          :origin_fences="origin_fences"
          :license="license">
        </component>

        <footer-bar
          :unsaved_changes="unsaved_changes"
          :report_data="report"
          :license="license"
          :price_text="price_note"
          :note_next_btn="error_note"
          :step="step"
          @next_step="changeStep"
          @save_report="saveReport">
        </footer-bar>
      </div>
    </div>
    <div v-else-if="step === 'summary'">
      <header-bar
        :title="$t('geoindicators.creation.header.summary_title')"
        :unsaved_changes="unsaved_changes"
        :hide_actions="true">
      </header-bar>

      <loading :loading="is_loading" :error="error"/>

      <div v-if="!is_loading && !error">
        <report-creation-stepper
        :step="step">
        </report-creation-stepper>

        <creation-summary
          :report="report"
          :license="license"
          :price_text="price_note"
          :is_included_in_license="is_included_in_license"
          :is_license_valid="is_license_valid"
          :is_fence_remaining="is_fence_remaining"
          :organization_name="organization_name">
        </creation-summary>

        <footer-bar
          :unsaved_changes="unsaved_changes"
          :report_data="report"
          :license="license"
          :price_text="price_note"
          :step="step"
          :is_payment_valid="is_payment_valid"
          :is_report_valid="is_report_valid"
          @next_step="changeStep"
          @activate_report="activateReport">
        </footer-bar>
      </div>
    </div>
    <div v-else-if="step.includes('beta')">
      <header-bar
        :title="step === 'beta' ? $t('geoindicators.creation.header.edit_title') : $t('geoindicators.creation.header.summary_title')"
        :unsaved_changes="unsaved_changes"
        :hide_actions="true">
      </header-bar>

      <loading :loading="is_loading" :error="error"/>

      <div v-if="!is_loading && !error">
        <report-creation-stepper
        :step="step.includes('summary') ? 'summary' : 'edit'">
        </report-creation-stepper>

        <creation-summary v-if="step === 'beta-summary'"
          :license="license"
          :report="report"
          :price_text="price_note"
          :is_included_in_license="is_included_in_license"
          :is_license_valid="is_license_valid"
          :is_fence_remaining="is_fence_remaining"
          :step="step"
          :organization_name="organization_name">
        </creation-summary>

        <aside v-else class="alert warning">
          <p>
            <i class="fas fa-exclamation-circle"></i>
            <b>{{ $t('geoindicators.creation.report_types.beta.alert.title') }}</b>
          </p>
          <p>{{ $t('geoindicators.creation.report_types.beta.alert.text1') }} <a v-on:click="openChat">{{ $t('geoindicators.creation.report_types.beta.chat') }}</a> {{ $t('geoindicators.creation.report_types.beta.alert.text2') }}</p>
        </aside>

        <footer-bar
          :unsaved_changes="unsaved_changes"
          :report_data="{...report, type: route.query.type}"
          :license="license"
          :price_text="price_note"
          :step="step"
          :is_payment_valid="false"
          :is_report_valid="true"
          @next_step="changeStep"
          @activate_report="activateReport">
        </footer-bar>
      </div>
    </div>
  </div>
</template>

<script setup>
  import headerBar from '../components/reports/creation/headerBar.vue';
  import footerBar from '../components/reports/creation/footerBar.vue';
  import reportCreationStepper from '../components/reports/creation/reportCreationStepper.vue';
  import creationSummary from './geoindicateurs-creation-summary.vue';
  import {handleTimezoneOffset} from '../../js/helpers.js';
  import {useReport} from '../composables/report.js';
  import validato from '@propulso/validato';
  import {inject, onBeforeUnmount, ref, getCurrentInstance, computed, watch, onBeforeMount, defineAsyncComponent} from 'vue';
  import {onBeforeRouteLeave, useRoute, useRouter} from 'vue-router';
  import {toast} from 'vue3-toastify';
  import {useI18n} from 'vue-i18n';

  const event_hub = inject('event-hub');
  const {appContext} = getCurrentInstance();
  const global_filters = appContext.config.globalProperties.$filters;

  const {
    is_loading,
    error,
    license,
    report,
    categories,
    destination_fences,
    origin_fences,
    coverage_area,
    outside_zone,
    is_license_valid,
    is_fence_remaining,
    copyReport,
    deleteReport,
    getLicense,
    getReport,
    openChat
  } = useReport(event_hub);

  const {t} = useI18n();

  const route = useRoute();
  const router = useRouter();

  const sty = inject('sentry');
  const axios = inject('axios');

  const unsaved_changes = ref(false);
  const step = ref(route.query.type ? 'beta' : 'edit');
  const organization_name = ref('');
  const is_payment_valid = ref(false);
  const has_license_errors = ref(false);

  const is_included_in_license = computed(() => {
    if (report && report.type && !outside_zone.value && !has_license_errors.value) {
      const included_types = license.additional_report_types ?
        license.included_report_types.concat(license.additional_report_types) :
        license.included_report_types;

      return included_types.includes(report.type);
    }
    return false;

  });

  const computed_report = computed(() => {
    return Object.assign({}, JSON.parse(JSON.stringify(report)));
  });

  const report_loader = computed(() => {
    if (report.type) {
      return defineAsyncComponent(() => import(/* webpackMode: "eager" */`../components/reports/creation/types/${report.type}.vue`));
    }
  });

  const price = computed(() => {
    const dates = report.dates;
    let price = 0;

    if (dates && report && report.target_fences && report.target_fences.length) {
      const zones_count = report.target_fences.length;
      const months_count = ((start, end) => {
        end = new Date(end);
        start = new Date(start);
        let months = (end.getFullYear() - start.getFullYear()) * 12;
        months -= start.getMonth();
        months += end.getMonth();
        return months + 1;
      })(dates.start, dates.end);

      switch (report.type) {
        case 'visits':
          price = Math.floor(zones_count * 750 * Math.max(1 - ((zones_count - 1) / 10 * 0.2), 0.8) * (months_count * 0.005 + 1));
          break;
        case 'market':
          price = Math.floor(zones_count * 350 * Math.max(1 - ((zones_count - 1) / 10 * 0.2), 0.8) * (months_count * 0.005 + 1));
          break;
        case 'economic':
          price = Math.floor((7000 + (zones_count * 15)) * (months_count * 0.005 + 1));
          break;
        case 'trails':
          price = Math.floor(zones_count * 750 * Math.max(1 - ((zones_count - 1) / 10 * 0.2), 0.8) * (months_count * 0.005 + 1));
          break;
        case 'tourism':
          price = months_count * 25000;
          break;
      }
    }

    return price;
  });

  const reseller_price = computed(() => {
    let reseller_price = 0;

    if (license && license.license_type === 'reseller' && license.discount_report) {
      reseller_price = price.value - price.value * license.discount_report / 100;
    }

    return reseller_price;
  });

  const is_report_valid = computed(() => {
    has_license_errors.value = false;

    const report_errors = validato.validateReport({...report, fences: report.target_fences}, license);

    for (const error of report_errors) {
      if (error && error.scope === 'license') {
        has_license_errors.value = true;
      } else {
        return false;
      }
    }

    if (report.target_fences.length) {
      const target_fences_errors = validato.validateFences(report.target_fences, report.type, license);

      if (target_fences_errors.length) {
        for (const error of target_fences_errors) {
          if (error.scope === 'license') {
            has_license_errors.value = true;
          } else {
            return false;
          }
        }
      }
    }

    if (report.type === 'tourism') {
      if (report.exclusion_fences.length) {
        const exclusion_fences_errors = validato.validateFences(report.exclusion_fences, report.type, license);

        if (exclusion_fences_errors.length) {
          for (const error of exclusion_fences_errors) {
            if (error.scope === 'license') {
              has_license_errors.value = true;
            } else {
              return false;
            }
          }
        }
      }
    }

    if (report.type === 'economic') {
      if (origin_fences.value.length) {
        const origin_fences_errors = validato.validateFences(origin_fences.value, report.type, license);

        if (origin_fences_errors.length) {
          for (const error of origin_fences_errors) {
            if (error.scope === 'license') {
              has_license_errors.value = true;
            } else {
              return false;
            }
          }
        }
      }

      if (destination_fences.value.length) {
        const destination_fences_errors = validato.validateFences(destination_fences.value, report.type, license);

        if (destination_fences_errors.length) {
          for (const error of destination_fences_errors) {
            if (error.scope === 'license') {
              has_license_errors.value = true;
            } else {
              return false;
            }
          }
        }
      }
    }

    return true;
  });

  const price_note = computed(() => {
    const currency = JSON.parse(localStorage.user_data).organization.markup.currency;
    const price_info = {
      note: '',
      html: `-$ ${currency}`
    };

    if (!license.license_type) {
      price_info.html = price.value === 0 ? t('geoindicators.creation.price.price_not_included_license') : `${global_filters.splitNumber(price.value)}$ ${currency}`;
      price_info.note = t('geoindicators.creation.price.no_license');
    } else if (license.license_type === 'reseller') {
      price_info.note = `${t('geoindicators.creation.price.reseller_discount')} ${license.discount_report}%`;
      price_info.html = `<div class="before-discount"><span>${global_filters.ifZero(global_filters.splitNumber(price.value))}$ ${currency}</span></div> ${global_filters.ifZero(global_filters.splitNumber(reseller_price.value))}$ ${currency}`;
    } else if (is_license_valid.value) {
      if (price.value === 0) {
        if (is_included_in_license.value) {
          price_info.html = t('geoindicators.creation.price.price_included_license');
          price_info.note = t('geoindicators.creation.price.price_license_alert');
        } else {
          price_info.html = t('geoindicators.creation.price.price_not_included_license');
          price_info.note = t('geoindicators.creation.price.license_warning_alert');
        }
      } else if (is_included_in_license.value) {
        price_info.html = `<div class="before-discount"><span>${global_filters.ifZero(global_filters.splitNumber(price.value))}$ ${currency}</span></div> 0$ ${currency}`;
        price_info.note = t('geoindicators.creation.price.price_license_alert');
      } else {
        price_info.html = `${global_filters.ifZero(global_filters.splitNumber(price.value))}$ ${currency}`;
        price_info.note = t('geoindicators.creation.price.license_warning_alert');
      }
    } else if (price.value === 0) {
      price_info.html = t('geoindicators.creation.price.price_not_included_license');
      price_info.note = t('geoindicators.creation.price.license_warning_alert');
    } else {
      price_info.html = `${global_filters.ifZero(global_filters.splitNumber(price.value))}$ ${currency}`;
      price_info.note = t('geoindicators.creation.price.license_warning_alert');
    }

    return price_info;
  });

  const error_note = computed(() => {
    if (!is_report_valid.value && route.query.new !== 'true') {
      return {
        type: 'error',
        note: t('geoindicators.creation.errors.default_error')
      };
    }
  });

  const changeStep = () => {
    if (step.value.includes('beta')) {
      step.value = step.value === 'beta' ? 'beta-summary' : 'beta';
    } else {
      step.value = step.value === 'edit' ? 'summary' : 'edit';
    }
  };

  const updateReport = (r) => {
    Object.assign(report, r);
  };

  const updateCategories = (new_cat) => {
    categories.value = new_cat;
  };

  const updateDuration = (durations) => {
    report.min_duration = parseInt(durations.min);
    report.max_duration = parseInt(durations.max);
    unsaved_changes.value = true;
  };

  const updateFrequency = (frequencies) => {
    report.min_frequency = parseInt(frequencies.min);
    report.max_frequency = parseInt(frequencies.max);
    unsaved_changes.value = true;
  };

  const updateTimeParting = (time_parting) => {
    report.time_parting = time_parting;
    unsaved_changes.value = true;
  };

  const updateDelay = (delay) => {
    report.before_delay = parseInt(delay.before);
    report.after_delay = parseInt(delay.after);
    unsaved_changes.value = true;
  };

  const updateNightsSplit = (nights_split) => {
    report.day_split = parseInt(nights_split);
    unsaved_changes.value = true;
  };

  const updateOrganization = (org) => {
    if (org && org.name) {
      organization_name.value = org.name;
      report.organization = org._id;
    } else {
      organization_name.value = '';
    }
  };

  const updateSecondsBefore = (time) => {
    report.seconds_before = parseInt(time);
    unsaved_changes.value = true;
  };

  const updateSecondsAfter = (time) => {
    report.seconds_after = parseInt(time);
    unsaved_changes.value = true;
  };

  const updateMinSecondsBetween = (time) => {
    report.min_seconds_between = parseInt(time);
    unsaved_changes.value = true;
  };

  const updateMaxSecondsBetween = (time) => {
    report.max_seconds_between = parseInt(time);
    unsaved_changes.value = true;
  };

  const updateSummaryStates = (summary_states) => {
    is_loading.value = summary_states.loading;
    error.value = summary_states.error;
    is_payment_valid.value = summary_states.payment_valid;
  };

  const saveReport = () => {
    is_loading.value = true;
    error.value = false;

    const formatted_dates = handleTimezoneOffset(report.dates.start, report.dates.end, true);
    const data = {
      organization: report.organization,
      name: report.name,
      type: report.type,
      start_date: formatted_dates.start,
      end_date: formatted_dates.end,
      price: price.value,
      use_license: is_license_valid.value && is_included_in_license.value,
      target_fences: report.target_fences
    };

    if (report.time_parting) {
      data.time_parting = report.time_parting;
    }

    if (data.type === 'economic') {
      data.before_delay = parseInt(report.before_delay);
      data.after_delay = parseInt(report.after_delay);
    } else if (data.type === 'tourism') {
      data.exclusion_fences = report.exclusion_fences;
      data.min_duration = parseInt(report.min_duration);
      data.max_duration = parseInt(report.max_duration);
      data.min_frequency = parseInt(report.min_frequency);
      data.max_frequency = parseInt(report.max_frequency);
      data.day_split = parseInt(report.day_split);
    } else if (data.type === 'before_after') {
      data.seconds_before = parseInt(report.seconds_before);
      data.seconds_after = parseInt(report.seconds_after);
    } else if (data.type === 'cross_visits') {
      data.min_seconds_between = parseInt(report.min_seconds_between);
      data.max_seconds_between = parseInt(report.max_seconds_between);
    }

    return axios.put(`${process.env.API_URL}/geoindicators/reports/${report._id}`, data)
      .then((res) => {
        unsaved_changes.value = 'skip';
        Object.assign(report, {
          ...res.data,
          dates: handleTimezoneOffset(res.data.start_date, res.data.end_date, false)
        });

        if (report.type === 'economic') {
          categories.value = res.data.categories;
          origin_fences.value = report.target_fences.filter((fence) => fence.type === 'origin');
          destination_fences.value = report.target_fences.filter((fence) => fence.type === 'destination');
        }

        is_loading.value = false;
        toast.success(t('geoindicators.creation.save_report_success'));
        router.replace(router.currentRoute.value.path);
      })
      .catch((err) => {
        error.value = true;
        is_loading.value = false;
        toast.error(t('geoindicators.creation.errors.creation_error'));
        sty.setContext('data', report);
        sty.captureException(err);
      });
  };

  const activateReport = () => {
    is_loading.value = true;

    if (!is_report_valid.value) {
      is_loading.value = false;
    } else {
      return axios.put(`${process.env.API_URL}/geoindicators/${report._id}/activate`)
        .then(() => {
          unsaved_changes.value = 'skip';
          router.push({path: `/geoindicators/reports/${report._id}`});
        })
        .catch((err) => {
          error.value = true;
          toast.error(t('geoindicators.creation.errors.creation_error'));
          sty.setContext('data', report);
          sty.captureException(err);
        }).finally(() => {
          is_loading.value = false;
        });
    }
  };

  watch(computed_report, (newVal, oldVal) => {
    if (JSON.stringify(oldVal) !== '{}') {
      if (unsaved_changes.value === 'skip') {
        unsaved_changes.value = false;
      } else if (!unsaved_changes.value) {
        unsaved_changes.value = JSON.stringify(newVal) !== JSON.stringify(oldVal);
      }
    }
  });

  const beforeBrowserUnload = (event) => {
    if (unsaved_changes.value === true && process.env.ENV !== 'DEV') {
      event.preventDefault();
      event.returnValue = t('geoindicators.creation.unsaved_changes.app_navigation');

      return t('geoindicators.creation.unsaved_changes.app_navigation');
    }
  };

  onBeforeMount(async() => {
    is_loading.value = true;
    unsaved_changes.value = 'skip';

    try {
      await getLicense();

      if (route.params._id) {
        await getReport();
      }

      is_loading.value = false;
    } catch (err) {
      error.value = true;
      is_loading.value = false;
      sty.captureException(err);
    }

    event_hub.on('time_part_update', updateTimeParting);
    event_hub.on('seconds_before_update', updateSecondsBefore);
    event_hub.on('seconds_after_update', updateSecondsAfter);
    event_hub.on('min_seconds_between_update', updateMinSecondsBetween);
    event_hub.on('max_seconds_between_update', updateMaxSecondsBetween);
    event_hub.on('report_categories', updateCategories);
    event_hub.on('duration_update', updateDuration);
    event_hub.on('frequency_update', updateFrequency);
    event_hub.on('nights_split_update', updateNightsSplit);
    event_hub.on('delay_update', updateDelay);
    event_hub.on('organization_update', updateOrganization);
    event_hub.on('report_update', updateReport);
    event_hub.on('summary_states', updateSummaryStates);

    event_hub.on('report_target_fences_update', (data) => {
      if (report.type === 'economic') {
        const fence_type_to_keep = data.economic_fence_type === 'destination' ? 'origin_fences' : 'destination_fences';
        const fences_to_keep = fence_type_to_keep === 'origin_fences' ? origin_fences.value : destination_fences.value;
        const fences = fences_to_keep.concat(data.fences);

        report.target_fences = fences;

        if (data.economic_fence_type === 'destination') {
          destination_fences.value = data.fences;
        } else {
          origin_fences.value = data.fences;
        }
      } else {
        report.target_fences = data.fences;
      }
    });

    event_hub.on('report_exclusion_fences_update', (data) => {
      if (report.type === 'tourism') {
        report.exclusion_fences = data.fences;
      }
    });

    window.addEventListener('beforeunload', beforeBrowserUnload);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', beforeBrowserUnload);
  });

  onBeforeRouteLeave((to, from, next) => {
    if (unsaved_changes.value === true && process.env.ENV !== 'DEV') {
      if (window.confirm(t('geoindicators.creation.unsaved_changes.app_navigation'))) {
        window.removeEventListener('beforeunload', beforeBrowserUnload);
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  });
</script>
