<template>
  <div class="panel">
      <div class="title">
        <div class="row">
          <div class="col-6">
            <b>{{ $t('geoindicators.report.widgets.market_shares_donut_visitors.title') }}</b>
          </div>
        </div>
      </div>

      <apexchart
      height="350"
      width="100%"
      type="donut"
      :options="options"
      :series="series">
      </apexchart>
    </div>
</template>

<script setup>
  import {useChartOptions} from '../chart-options';

  import {computed} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {t} = useI18n();
  const {DEFAULT_OPTIONS, DONUT, VISITORS_TOOLTIP_DONUT} = useChartOptions(t);

  const props = defineProps({
    data: {
      type: Object,
      required: true,
      default: () => ({})
    }
  });

  const series = computed(() => {
    return props.data.series;
  });

  const options = computed(() => {
    return {
      ...DEFAULT_OPTIONS,
      ...DONUT,
      ...VISITORS_TOOLTIP_DONUT,
      colors: props.data.colors.map((color) => color[3]),
      labels: props.data.labels
    };
  });
</script>
