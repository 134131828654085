<template>
  <div class="panel radar">
    <div class="title">
      <b>
        {{ $t('geoindicators.report.widgets.directions.title') }}
        <tooltip
          :text="$t('geoindicators.report.widgets.directions.tooltip')"
          :show_tooltip="show_tooltip">
        </tooltip>
      </b>
    </div>

    <div class="row no-gutters">
      <div class="col-xl-8">
        <apexchart
        height="475"
        type="radar"
        :options="options"
        :series="series"
        class="radar-direction"
        :class="{'hide-section': !data.show_directions}">
        </apexchart>
      </div>

      <div class="col-xl-4">
        <table>
          <tbody>
            <tr v-for="([name, value], index) of Object.entries(ordered_serie)">
              <td>{{ categories[value[0]] }}</td>
              <td class="value">{{ value[1] }}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script setup>
  import {useChartOptions} from '../chart-options';
  import tooltip from '../../tooltip.vue';
  import {reactive, computed} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {t} = useI18n();
  const {DEFAULT_OPTIONS, RADAR, RADAR_TOOLTIP, NO_DATA_MESSAGE} = useChartOptions(t);

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    show_tooltip: {
      type: Boolean,
      required: false
    }
  });

  const categories = reactive({
    north: t('geoindicators.report.widgets.directions.north'),
    north_east: t('geoindicators.report.widgets.directions.north_east'),
    east: t('geoindicators.report.widgets.directions.east'),
    south_east: t('geoindicators.report.widgets.directions.south_east'),
    south: t('geoindicators.report.widgets.directions.south'),
    south_west: t('geoindicators.report.widgets.directions.south_west'),
    west: t('geoindicators.report.widgets.directions.west'),
    north_west: t('geoindicators.report.widgets.directions.north_west')
  });

  const series = computed(() => {
    let data = [];

    if (props.data.show_directions) {
      data = [{
        name: '',
        data: Object.values(props.data.series)
      }];
    }

    return data;
  });

  const ordered_serie = computed(() => {
    let serie = [];
    if (props.data.show_directions) {
      serie = Object.entries(props.data.series).sort((first, second) => {
        return second[1] - first[1];
      }, []);
    }

    return serie;
  });

  const options = computed(() => {
    return {
      ...DEFAULT_OPTIONS,
      ...RADAR,
      ...RADAR_TOOLTIP,
      ...NO_DATA_MESSAGE,
      xaxis: {
        categories: Object.values(categories),
        labels: {
          style: {
            fontSize: '15px'
          }
        }
      }
    };
  });

</script>
