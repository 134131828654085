<template>
  <div class="sample">
    <header class="title-bar">
      <div class="row justify-content-between">
        <div class="col-8 align-self-center">
          <h1 class="title">{{ $t('samples.header_title') }}</h1>
        </div>
        <div class="col-4 align-self-center text-right">
          <a href="#/geomarketing/samples/create?new=true" class="btn primary">
            <i class="fas fa-plus"></i>
            <span class="d-none d-lg-inline pl-lg-1">{{ `${$t('samples.create_sample_button')}` }}</span>
          </a>
        </div>
      </div>
    </header>

    <loading :loading="is_loading" :error="error"/>

    <div class="panel no-padding" v-if="!is_loading && !error && samples.length">
      <div id="grid-wrapper">
        <ag-grid-vue
          ref="samples_grid"
          class="ag-theme-balham ag-grid"
          :rowData="samples"
          :gridOptions="grid_options"
          @grid-size-changed="onGridSizeChanged">
        </ag-grid-vue>
      </div>
    </div>

    <div class="row justify-content-center" v-if="!is_loading && !error && !samples.length">
      <div class="panel text-center col-10 col-sm-4 no-results">
        <i class="icon fas fa-search-minus"></i>
        <p>{{ $t('samples.no_sample_pannel') }}</p>
      </div>
    </div>

  </div>
</template>

<script setup>
  import {ref, reactive, onMounted, inject} from 'vue';
  import {useI18n} from 'vue-i18n';
  import {AgGridVue} from 'ag-grid-vue3';
  import rowStatus from '../components/ag-grid/rowStatus.vue';
  import sampleCellDetails from '../components/ag-grid/sampleCellDetails.vue';
  import progressBar from '../components/ag-grid/progressBar.vue';
  import sampleCellConvert from '../components/ag-grid/sampleCellConvert.vue';
  import sampleCellBudget from '../components/ag-grid/sampleCellBudget.vue';

  const {t} = useI18n();
  const sty = inject('sentry');
  const axios = inject('axios');

  const is_loading = ref(false);
  const error = ref(false);
  const samples = ref([]);

  const grid_options = reactive({
    columnDefs: [
      {
        cellRenderer: rowStatus,
        headerName: '',
        field: 'status',
        minWidth: 30,
        width: 30
      },
      {
        cellRenderer: progressBar,
        headerName: t('samples.table.header_progress'),
        field: 'progress',
        minWidth: 100,
        width: 100
      },
      {
        headerName: t('samples.table.header_name'),
        field: 'name',
        width: 120
      },
      {
        cellRenderer: sampleCellDetails,
        headerName: t('samples.table.header_details'),
        field: 'details',
        width: 120,
        sortable: false
      },
      {
        cellRenderer: sampleCellBudget,
        headerName: t('samples.table.header_budget'),
        field: 'price',
        width: 120,
        sortable: false
      },
      {
        cellRenderer: sampleCellConvert,
        headerName: '',
        minWidth: 200,
        field: 'organization',
        sortable: false
      },
    ],
    defaultColDef: {
      minWidth: 180,
      resizable: true,
      sortable: true
    },
    domLayout: 'autoHeight',
    rowHeight: 90,
    headerHeight: 50,
    suppressHorizontalScroll: true
  });

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const getSamples = () => {
    is_loading.value = true;
    error.value = false;

    axios
      .get(`${process.env.API_URL}/samples`)
      .then((res) => {
        samples.value = res.data;
        is_loading.value = false;
      })
      .catch((err) => {
        is_loading.value = false;
        error.value = true;
        sty.captureException(err);
      });
  };

  onMounted(() => {
    getSamples();
  });
</script>
