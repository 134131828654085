import {createRouter, createWebHashHistory} from 'vue-router';

import campaignsList from './vue/pages/campaigns-list.vue';
import campaignsStats from './vue/pages/campaigns-stats.vue';
import campaignsEdit from './vue/pages/campaigns-edit.vue';
import invoices from './vue/pages/invoices-list.vue';
import subAccounts from './vue/pages/sub-accounts.vue';
import login from './vue/pages/login.vue';
import geoacademyKnowledge from './vue/pages/geoacademy-knowledge-base.vue';
import geoindicateursList from './vue/pages/geoindicateurs-list.vue';
import geoindicateursReport from './vue/pages/geoindicateurs-report.vue';
import geoindicateursCreationReportChoice from './vue/pages/geoindicateurs-creation-report-choice.vue';
import geoindicateursEdit from './vue/pages/geoindicateurs-creation-edit.vue';
import welcome from './vue/pages/welcome.vue';
import internal from './vue/pages/internal.vue';
import subUsers from './vue/pages/sub-users.vue';
import creditCards from './vue/pages/credit-cards.vue';
import sample from './vue/pages/sample.vue';
import createSample from './vue/pages/create-sample.vue';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {path: '/', component: login},
    {path: '/geomarketing/campaigns/', component: campaignsList, meta: {title_key: 'campaigns_list'}},
    {path: '/geomarketing/campaigns/edit/:_id', component: campaignsEdit, meta: {title_key: 'campaigns_edit'}},
    {path: '/geomarketing/campaigns/stats/:org_id/:campaign_id', component: campaignsStats, meta: {title_key: 'campaigns_stats'}},
    {path: '/geomarketing/samples/', component: sample, meta: {title_key: 'samples_list'}},
    {path: '/geomarketing/samples/create/', component: createSample, meta: {title_key: 'create_sample'}},
    {path: '/account/invoices', component: invoices, meta: {title_key: 'invoices'}},
    {path: '/account/credit-cards', component: creditCards, meta: {title_key: 'credit_cards'}},
    {path: '/account/sub-users', component: subUsers, meta: {title_key: 'sub_users'}},
    {path: '/account/sub-accounts', component: subAccounts, meta: {title_key: 'sub_accounts'}},
    {path: '/geoacademy/knowledge', component: geoacademyKnowledge, meta: {title_key: 'geoacademy_knowledge'}},
    {path: '/geoacademy/knowledge/:lang/:article', component: geoacademyKnowledge, meta: {title_key: 'geoacademy_article'}},
    {path: '/geoacademy/knowledge/:lang/:category/:article', component: geoacademyKnowledge, meta: {title_key: 'geoacademy_category_article'}},
    {path: '/geoindicators/reports/', component: geoindicateursList, meta: {title_key: 'geoindicators_list'}},
    {path: '/geoindicators/reports/create', component: geoindicateursCreationReportChoice, meta: {title_key: 'geoindicators_create_report'}},
    {path: '/geoindicators/reports/create/:_id', component: geoindicateursCreationReportChoice, meta: {title_key: 'geoindicators_create_report'}},
    {path: '/geoindicators/reports/edit', component: geoindicateursEdit, meta: {title_key: 'geoindicators_edit_report'}},
    {path: '/geoindicators/reports/edit/:_id', component: geoindicateursEdit, meta: {title_key: 'geoindicators_edit_report'}},
    {path: '/geoindicators/reports/:report_id', component: geoindicateursReport, meta: {title_key: 'geoindicators_report'}},
    {path: '/welcome', component: welcome, meta: {title_key: 'welcome'}},
    {path: '/internal', component: internal, meta: {title_key: 'internal'}},
    {path: '/public/reports/:report_id', component: geoindicateursReport, meta: {title_key: 'public_geoindicators_report'}},
    {path: '/preview/reports/:report_id', component: geoindicateursReport, meta: {title_key: 'public_geoindicators_report'}},
    {path: '/:pathMatch(.*)*', redirect: '/', meta: {title_key: 'redirect'}}
  ]
});

function isJWTExpired(jwt) {
  const base64 = jwt.split('.')[1].replace('-', '+').replace('_', '/');
  const token = JSON.parse(window.atob(base64));

  return (token.exp * 1000) <= Date.now();
}

const setupRouterGuards = function(i18n, sty, http) {
  return (to, from, next) => {
    document.title = to.meta.title_key ? i18n.global.t(`titles.${to.meta.title_key}`) : 'Propulso';

    const token = localStorage.jwt;
    const logged_out = !token || isJWTExpired(token);

    if (to.path.includes('/proposition/') || to.path.includes('welcome') || to.path.includes('public') || to.path.includes('preview')) {
      next();
    } else {
      if (to.path !== '/') {
        if (logged_out) {
          localStorage.removeItem('jwt');
          localStorage.removeItem('user_data');
          next({path: '/'});
        } else {
          sty.setUser(JSON.parse(localStorage.user_data));
          http.defaults.headers.common.Authorization = 'Bearer ' + token;
          next();
        }
      } else {
        if (logged_out) {
          next();
        } else {
          sty.setUser(JSON.parse(localStorage.user_data));
          next({path: '/geomarketing/campaigns'});
        }
      }
    }
  };
};


export {router, setupRouterGuards};
