<template>
  <div>
    <div class="row">
      <div class="col-xl-6">
        <div class="panel">
          <div class="title">
            <b>
              {{ $t('geoindicators.report.widgets.demographic_infos.income') }}
              <tooltip
                :text="$t('geoindicators.report.widgets.demographic_infos.income_tooltip')"
                :show_tooltip="show_tooltip">
              </tooltip>
            </b>
          </div>
          <apexchart
          height="250"
          type="bar"
          :options="revenu_options"
          :series="income">
          </apexchart>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="panel">
          <div class="title">
            <b>
              {{ $t('geoindicators.report.widgets.demographic_infos.age') }}
              <tooltip
                :text="$t('geoindicators.report.widgets.demographic_infos.age_tooltip')"
                :show_tooltip="show_tooltip">
              </tooltip>
            </b>
          </div>
          <apexchart
          height="250"
          type="bar"
          :options="age_options"
          :series="age">
          </apexchart>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="panel">
          <div class="title">
            <b>
              {{ $t('geoindicators.report.widgets.demographic_infos.education') }}
              <tooltip
                :text="$t('geoindicators.report.widgets.demographic_infos.education_tooltip')"
                :show_tooltip="show_tooltip">
              </tooltip>
            </b>
          </div>
          <apexchart
          height="250"
          type="bar"
          :options="education_options"
          :series="education">
          </apexchart>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="row">
          <div class="col-lg-6">
            <div class="panel">
              <div class="metric-title">
                <h2 v-if="data.income.avg">{{ $filters.splitNumber(data.income.avg) }}<span>{{ $t('geoindicators.report.widgets.demographic_infos.avg_income.suffix') }}</span></h2>
                <h2 v-else>-</h2>
                <p>{{ $t('geoindicators.report.widgets.demographic_infos.avg_income.text') }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="panel">
              <div class="metric-title">
                <h2 v-if="data.age.avg">{{ data.age.avg }} <span> {{ $t('geoindicators.report.widgets.demographic_infos.avg_age.suffix') }}</span></h2>
                <h2 v-else>-</h2>
                <p>
                  {{ $t('geoindicators.report.widgets.demographic_infos.avg_age.text') }}
                  <tooltip
                    :text="$t('geoindicators.report.widgets.demographic_infos.avg_age.tooltip')"
                    :show_tooltip="show_tooltip">
                  </tooltip>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {useChartOptions} from '../chart-options';
  import tooltip from '../../tooltip.vue';
  import {useApexChart} from '../../../composables/apexchart';

  import {reactive, computed} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {t, tm} = useI18n();
  const {get_max_yaxis_value} = useApexChart();
  const {DEFAULT_OPTIONS, HORIZONTAL_GRADIENT, DEMOGRAPHIC_TOOLTIP} = useChartOptions(t);

  const props = defineProps({
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    show_tooltip: {
      type: Boolean,
      required: false
    }});

  const revenu_options = reactive({
    ...DEFAULT_OPTIONS,
    ...HORIZONTAL_GRADIENT,
    ...DEMOGRAPHIC_TOOLTIP,
    xaxis: {
      max: get_max_yaxis_value(props.data.income.series),
      tickAmount: 5,
      categories: ['0-29k$', '30-74k$', '75-99k$', '100-149k$', '150k+$']
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    }
  });

  const age_options = reactive({
    ...DEFAULT_OPTIONS,
    ...HORIZONTAL_GRADIENT,
    ...DEMOGRAPHIC_TOOLTIP,
    xaxis: {
      max: get_max_yaxis_value(props.data.age.series),
      tickAmount: 5
    },
    labels: tm('geoindicators.report.widgets.demographic_infos.age_labels'),
    plotOptions: {
      bar: {
        horizontal: true
      }
    }
  });

  const education_options = reactive({
    ...DEFAULT_OPTIONS,
    ...HORIZONTAL_GRADIENT,
    ...DEMOGRAPHIC_TOOLTIP,
    xaxis: {
      max: get_max_yaxis_value(props.data.education.series),
      tickAmount: 5
    },
    labels: tm('geoindicators.report.widgets.demographic_infos.education_labels'),
    plotOptions: {
      bar: {
        horizontal: true
      }
    }
  });

  const income = computed(() => [{
    name: '',
    data: Object.values(props.data.income.series)
  }]);

  const education = computed(() => [{
    name: '',
    data: Object.values(props.data.education.series)
  }]);

  const age = computed(() => [{
    name: '',
    data: Object.values(props.data.age.series)
  }]);
</script>
