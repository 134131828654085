<template>
  <div class="collapse-item">

    <div class="question" @click="toggleExpand">
      <a class="plus" :class="{'expanded': expanded }"></a>
      <a class="minus" :class="{'expanded': expanded }"></a>
      <slot name="title"></slot>
    </div>
    <slot name="subtitle"></slot>

    <transition name="slide-down">
      <div class="content" v-if="expanded">
        <slot name="content"></slot>
      </div>
    </transition>

  </div>
</template>

<script setup>
  import {ref} from 'vue';

  const expanded = ref(false);

  const toggleExpand = () => {
    expanded.value = !expanded.value;
  };
</script>
