<template>
  <div>
    <div class="panel no-padding grid" id="grid-wrapper">
      <div class="tabs">
        <a class="tab"
          :class="{'active': selected === key }"
          v-for="(category, key) in data"
          @click="selected = key">
          {{ category.name }}
        </a>
      </div>

      <ag-grid-vue
        ref="detailed-grid"
        class="ag-theme-balham ag-grid"
        :rowData="data[selected].fences"
        :gridOptions="grid_options"
        :columnDefs="grid_options.columnDefs"
        :pinnedBottomRowData="lastRow(data[selected].total)"
        @gridReady="onGridReady">
      </ag-grid-vue>
    </div>
  </div>
</template>

<script setup>
  import {AgGridVue} from 'ag-grid-vue3';
  import {ref, reactive} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {t} = useI18n();

  const props = defineProps({
    data: {
      type: Array,
      required: true,
      default: () => []
    }
  });

  const selected = ref(0);

  const getCustomColDefs = () => {
    const headers = [];

    for (const year of props.data[0].years) {
      headers.push({
        headerName: year,
        field: `years.${year}`,
        sortable: true,
        minWidth: 100,
        cellRenderer: (params) => {
          return params.value ? `${(params.value * 100).toFixed(2)}%` : '0%';
        }
      });
    }

    return headers;
  };

  const getColDefs = () => {
    return [
      {
        headerName: t('geoindicators.report.economic.details.fence'),
        field: 'name',
        sortable: true,
        minWidth: 400
      },
      ...getCustomColDefs(),
      {
        headerName: t('geoindicators.report.economic.details.sum'),
        field: 'years.total',
        sortable: true,
        cellRenderer: (params) => {
          return params.value ? `${(params.value * 100).toFixed(2)}%` : '0%';
        }
      },
      {
        headerName: t('geoindicators.report.economic.details.note'),
        field: 'impact',
        sortable: true,
        cellRenderer: (params) => {
          return params.value ? `${t('geoindicators.report.economic.details.note_cell_text', {number: params.value})}` : '';
        }
      }];
  };

  const grid_options = reactive({
    columnDefs: getColDefs(),
    defaultColDef: {
      resizable: true,
      sortable: true
    },
    domLayout: 'autoHeight',
    rowHeight: 35,
    headerHeight: 50
  });

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };

  const lastRow = (data) => {
    return [{
      name: 'Total',
      years: data
    }];
  };
</script>
