<template>
  <div class="tourism-report">
    <div class="title-separator">
      <h1>{{ $t('geoindicators.report.tourism.summary.title') }}</h1>
      <p>{{ $t('geoindicators.report.tourism.summary.text') }}</p>
      <hr>
    </div>

    <tourism-summary
      :data="data.summary">
    </tourism-summary>

    <tourism-visits
      :data="data.visits">
    </tourism-visits>

      <template v-if="is_preview">
        <preview-form :classes="'tourism'"></preview-form>
      </template>

      <div :class="{'preview': is_preview}">

      <div class="title-separator">
        <h1>{{ $t('geoindicators.report.tourism.duration.title') }}</h1>
        <p>{{ $t('geoindicators.report.tourism.duration.subtitle') }}</p>
        <hr>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h2>{{ $t('geoindicators.report.tourism.excursionists') }}</h2>

            <div class="panel">
              <div class="metric-title">
                <h2>{{ $filters.splitNumber( data.durations.excursionists.avg) }} <span>{{ $t('geoindicators.report.tourism.hours').toLowerCase() }}</span></h2>
                <p>{{ $t('geoindicators.report.tourism.duration.avg_duration') }}</p>
              </div>
          </div>

          <tourism-duration-distribution
            :data="data.durations.excursionists.series"
            :title="t('geoindicators.report.tourism.duration.excursionists_duration_repartition_title')"
            :categories="$tm('geoindicators.report.tourism.duration.hours_categories')">
          </tourism-duration-distribution>
        </div>

        <div class="col-md-6">
          <h2>{{ $t('geoindicators.report.tourism.tourists') }}</h2>

          <div class="panel">
            <div class="metric-title">
              <h2>{{ $filters.splitNumber( data.durations.tourists.avg) }} <span>{{ $t('geoindicators.report.tourism.nights').toLowerCase() }}</span></h2>
              <p>{{ $t('geoindicators.report.tourism.duration.avg_duration') }}</p>
            </div>
          </div>

          <tourism-duration-distribution
            :data="data.durations.tourists.series"
            :title="t('geoindicators.report.tourism.duration.tourists_duration_repartition_title')"
            :categories="$tm('geoindicators.report.tourism.duration.nights_categories')">
          </tourism-duration-distribution>
        </div>
      </div>

      <div class="title-separator">
        <h1>{{ $t('geoindicators.report.tourism.frequency.title') }}</h1>
        <p>{{ $t('geoindicators.report.tourism.frequency.subtitle') }}</p>
        <hr>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h2>{{ $t('geoindicators.report.tourism.excursionists') }}</h2>

          <div class="panel">
            <div class="metric-title">
              <h2>
                {{ $filters.splitNumber(data.frequencies.excursionists.avg) }}
                <span>{{ $t('geoindicators.report.tourism.visits').toLowerCase() }}</span>
              </h2>
              <p>{{ $t('geoindicators.report.tourism.frequency.avg_frequency_excursionists') }}</p>
            </div>
          </div>

          <tourism-frequency-distribution
            :data="data.frequencies.excursionists.series"
            :title="t('geoindicators.report.tourism.frequency.excursionists_frequency_repartition_title')">
          </tourism-frequency-distribution>
        </div>

        <div class="col-md-6">
          <h2>{{ $t('geoindicators.report.tourism.tourists') }}</h2>

          <div class="panel">
            <div class="metric-title">
              <h2>
                {{ $filters.splitNumber(data.frequencies.tourists.avg) }}
                <span>{{ $t('geoindicators.report.tourism.visits').toLowerCase() }}</span>
              </h2>
              <p>{{ $t('geoindicators.report.tourism.frequency.avg_frequency_tourists') }}</p>
            </div>
          </div>

          <tourism-frequency-distribution
            :data="data.frequencies.tourists.series"
            :title="$t('geoindicators.report.tourism.frequency.tourists_frequency_repartition_title')">
          </tourism-frequency-distribution>
        </div>
      </div>

      <div class="title-separator">
        <h1>{{ $t('geoindicators.report.tourism.details.title') }}</h1>
        <p>{{ $t('geoindicators.report.tourism.details.text') }}</p>
        <hr>
      </div>

      <tourism-heatmap
      :data="data.heatmap">
      </tourism-heatmap>

      <tourism-origins
        :data="data.origins">
      </tourism-origins>
    </div>
  </div>
</template>

<script setup>
  import tourismSummary from '../widgets/tourismSummary.vue';
  import tourismVisits from '../widgets/tourismVisits.vue';
  import tourismDurationDistribution from '../widgets/tourismDurationDistribution.vue';
  import tourismFrequencyDistribution from '../widgets/tourismFrequencyDistribution.vue';
  import tourismHeatmap from '../widgets/tourismHeatmap.vue';
  import tourismOrigins from '../widgets/tourismOrigins.vue';
  import previewForm from '../widgets/previewForm.vue';
  import {useI18n} from 'vue-i18n';

  const {t} = useI18n();

  defineProps({
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    is_preview: {
      type: Boolean,
      required: false,
      default: false
    }
  });
</script>
