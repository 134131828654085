<template>
  <vue-date-picker
    :auto-apply="true"
    :clearable="false"
    :enable-time-picker="false"
    :disabled-dates="disabled_dates"
    :month-change-on-scroll="'inverse'"
    :locale="locale"
    :class="classes"
    :no-today="true"
    :placeholder="$t('components.datepicker.placeholder')"
    :multi-calendars="{solo: true}"
    :range="{noDisabledRange: true}"
    class="date-picker"
    v-model="date"
    format="yyyy-MM-dd"
    ref="datepicker">
    <template #menu-header v-if="enable_shortcuts">
      <div class="mx-shortcuts-wrapper">
        <a
          class="mx-shortcuts"
          v-for="preset in presets"
          :key="preset.text"
          @click="() => applyPresets(preset.onClick)">
          {{ preset.text }}
        </a>
      </div>
    </template>
  </vue-date-picker>
</template>

<script setup>
  import {ref, computed, watch, onMounted} from 'vue';
  import {useI18n} from 'vue-i18n';
  import VueDatePicker from '@vuepic/vue-datepicker';
  import {getDateRange} from '../../js/helpers';

  const props = defineProps({
    value: {
      required: true,
      type: Object
    },
    disabled_dates: {
      required: false,
      type: Function
    },
    enable_shortcuts: {
      required: false,
      type: Boolean,
      default: true
    },
    product: {
      required: false,
      type: String,
      default: 'campaign'
    },
    classes: {
      required: false,
      type: Object
    }
  });

  const emit = defineEmits(['update:value']);

  const {t, locale} = useI18n();

  const date = ref(
    props.value.start ? [new Date(props.value.start), new Date(props.value.end)] : null
  );

  const datepicker = ref(null);

  const presets = computed(() => {
    if (props.product === 'campaign') {
      return [
        {
          text: t('components.datepicker.dp_shortcuts.yesterday'),
          onClick: () => getDateRange('yesterday')
        },
        {
          text: t('components.datepicker.dp_shortcuts.last_7_days'),
          onClick: () => getDateRange('custom', new Date(), -6)
        },
        {
          text: t('components.datepicker.dp_shortcuts.last_30_days'),
          onClick: () => getDateRange('custom', new Date(), -29)
        },
        {
          text: t('components.datepicker.dp_shortcuts.this_month'),
          onClick: () => getDateRange('month')
        },
        {
          text: t('components.datepicker.dp_shortcuts.all_time'),
          onClick: () => getDateRange('all_time')
        }
      ];
    } else {
      const current_year = new Date().getFullYear();
      const last_three_years = Array.from({length: 3}, (_, i) => current_year - 3 + i)
        .map((year) => ({
          text: `${year}`,
          onClick: () => getDateRange('year', year)
        }));

      return [
        ...last_three_years,
        {
          text: t('components.datepicker.dp_shortcuts.last_12_months'),
          onClick: () => getDateRange('last_12_months')
        }
      ];
    }
  });

  const applyPresets = (presetFunction) => {
    const new_dates = presetFunction();

    if (new_dates) {
      date.value = [new Date(new_dates[0]), new Date(new_dates[1])];

      if (datepicker.value) {
        datepicker.value.updateInternalModelValue(date.value);
      }
    }
  };

  watch(date, (newDate) => {
    if (newDate) {
      emit('update:value', {
        start: newDate[0],
        end: newDate[1]
      });
    }
  });

  onMounted(() => {
    if (!date.value) {
      const start_date = new Date();
      const end_date = new Date(new Date().setDate(start_date.getDate() + 7));
      date.value = [start_date, end_date];
    }
  });
</script>
