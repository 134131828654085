<template>
  <div>
    <div class="panel">
      <div class="title">
        <b>{{ $t('geoindicators.creation.time_between_title') }}</b>
      </div>

      <div class="row justify-content-between">
        <div class="col-sm-6">
          <label>{{ $t('geoindicators.creation.min_seconds_between_title') }}</label>
          <div class="row">
            <div class="col-sm-3">
              <input
                :class="{'invalid-field': !is_min_valid}"
                type="number"
                v-model="min_seconds_between.value"
                v-tooltip.bottom="getMinTooltip(!is_min_valid)">
            </div>
            <div class="col-sm-4">
              <select
                v-model="min_seconds_between.type"
                class="capitalized">
                <option value="days">{{ $filters.capitalizeFirstLetter($t('geoindicators.creation.days')) }}</option>
                <option value="hours">{{ $filters.capitalizeFirstLetter($t('geoindicators.creation.hours')) }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <label>{{ $t('geoindicators.creation.max_seconds_between_title') }}</label>
          <div class="row">
            <div class="col-sm-3">
              <input
                :class="{'invalid-field': !is_max_valid}"
                type="number"
                v-model="max_seconds_between.value"
                v-tooltip.bottom="getMaxTooltip(!is_max_valid)">
            </div>
            <div class="col-sm-4">
              <select
                v-model="max_seconds_between.type"
                class="capitalized">
                <option value="days">{{ $filters.capitalizeFirstLetter($t('geoindicators.creation.days')) }}</option>
                <option value="hours">{{ $filters.capitalizeFirstLetter($t('geoindicators.creation.hours')) }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import validato from '@propulso/validato';
  import {ref, reactive, computed, watch, inject} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {t} = useI18n();

  const event_hub = inject('event-hub');

  const props = defineProps({
    value: {
      type: Object,
      required: true
    },
    license: {
      type: Object,
      required: true
    }
  });

  const product = ref(validato.products[props.value.type]);

  const day_in_seconds = 86400;
  const hour_in_seconds = 3600;

  const min_seconds_between = reactive({
    value: timeInUnit('days', props.value.min_seconds_between),
    type: 'days'
  });

  const max_seconds_between = reactive({
    value: timeInUnit('days', props.value.max_seconds_between),
    type: 'days'
  });

  const is_min_less_than_max = computed(() => {
    const min_seconds = timeInSeconds(min_seconds_between.type, min_seconds_between.value);
    const max_seconds = timeInSeconds(max_seconds_between.type, max_seconds_between.value);

    return validato.isFieldValid('compare_seconds_between', props.license, min_seconds, max_seconds);
  });

  const is_min_valid = computed(() => {
    return is_min_between_valid.value && is_min_less_than_max.value && min_seconds_between.value >= 0;
  });

  const is_max_valid = computed(() => {
    return is_max_between_valid.value && is_min_less_than_max.value && max_seconds_between.value > 0;
  });

  const is_min_between_valid = computed(() => {
    const min_seconds = timeInSeconds(min_seconds_between.type, min_seconds_between.value);
    return validato.isFieldValid('min_seconds_between', props.license, min_seconds);
  });

  const is_max_between_valid = computed(() => {
    const min_seconds = timeInSeconds(min_seconds_between.type, min_seconds_between.value);
    const max_seconds = timeInSeconds(max_seconds_between.type, max_seconds_between.value);

    return validato.isFieldValid('max_seconds_between', props.license, max_seconds, min_seconds);
  });

  const getMinTooltip = (value) => {
    if (value) {
      if (is_min_less_than_max.value) {
        return {
          content: t('geoindicators.creation.errors.days_out_of_range', {
            min: product.value.minimum_min_seconds_between / day_in_seconds,
            max: product.value.maximum_min_seconds_between / day_in_seconds
          }),
          classes: 'error'
        };
      } else {
        return {
          content: t('geoindicators.creation.errors.seconds_min_higher_than_max'),
          classes: 'error'
        };
      }
    }
    return '';
  };

  const getMaxTooltip = (value) => {
    if (value) {
      if (is_min_less_than_max.value) {
        return {
          content: t('geoindicators.creation.errors.days_out_of_range', {
            min: product.value.minimum_min_seconds_between / day_in_seconds,
            max: product.value.maximum_max_seconds_between / day_in_seconds
          }),
          classes: 'error'
        };
      } else {
        return {
          content: t('geoindicators.creation.errors.seconds_min_higher_than_max'),
          classes: 'error'
        };
      }
    }
    return '';
  };

  function timeInUnit(type, value) {
    switch (type) {
      case 'days':
        return value / day_in_seconds;
      case 'hours':
        return value / hour_in_seconds;
      default:
        return value;
    }
  }

  function timeInSeconds(type, value) {
    switch (type) {
      case 'days':
        return value * day_in_seconds;
      case 'hours':
        return value * hour_in_seconds;
      default:
        return value;
    }
  }

  watch(() => min_seconds_between.value, () => {
    event_hub.emit(
      'min_seconds_between_update',
      timeInSeconds(min_seconds_between.type, min_seconds_between.value)
    );
  }, {
    deep: true
  });

  watch(() => min_seconds_between.type, () => {
    min_seconds_between.value = timeInUnit(min_seconds_between.type, props.value.min_seconds_between);
  });

  watch(() => max_seconds_between.value, () => {
    event_hub.emit(
      'max_seconds_between_update',
      timeInSeconds(max_seconds_between.type, max_seconds_between.value)
    );
  }, {
    deep: true
  });

  watch(() => max_seconds_between.type, () => {
    max_seconds_between.value = timeInUnit(max_seconds_between.type, props.value.max_seconds_between);
  });
</script>
