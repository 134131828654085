<template>
  <div class="row">
    <div class="col-12">
      <div class="panel">
        <div class="title row">
          <div class="col-6">
            <b>
              {{ $t('geoindicators.report.widgets.visits_unique.title') }}
              <tooltip
                :text="$t('geoindicators.report.widgets.visits_unique.tooltip')"
                :show_tooltip="show_tooltip">
              </tooltip>
            </b>
          </div>
          <div class="col-6 text-right align-self-center">
            <dropdown :is_button="false">
              <template #actions>
                <ul class="list">
                  <li @click="updatePeriod('by_month')">
                    <label class="radio">
                      {{ $t('geoindicators.report.widgets.visits_unique.per_month') }}
                      <input v-model="selected_period" value="by_month" type="radio">
                      <span></span>
                    </label>
                  </li>
                  <li @click="updatePeriod('by_quarter')">
                    <label class="radio">
                      {{ $t('geoindicators.report.widgets.visits_unique.per_quarter') }}
                      <input v-model="selected_period" value="by_quarter" type="radio">
                      <span></span>
                    </label>
                  </li>
                  <li @click="updatePeriod('by_year')">
                    <label class="radio">
                      {{ $t('geoindicators.report.widgets.visits_unique.per_year') }}
                      <input v-model="selected_period" value="by_year" type="radio">
                      <span></span>
                    </label>
                  </li>
                </ul>
              </template>
              <template #title>
                <a class="info">
                  {{ $t('geoindicators.report.widgets.visits_unique.per_period') }}
                  <i class="fas fa-caret-down"></i>
                </a>
              </template>
            </dropdown>
          </div>
        </div>

        <template v-if="show_summary">
          <div class="title row">
            <div class="col-md-4">
              <div class="chart-title no-indicator">
                <h2>{{ min }}</h2>
                <p>{{ $tm('geoindicators.report.widgets.visits_unique.min_visitors')[selected_period] }}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="chart-title no-indicator">
                <h2>{{ max }}</h2>
                <p>{{ $tm('geoindicators.report.widgets.visits_unique.max_visitors')[selected_period] }}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="chart-title no-indicator">
                <h2>{{ avg }}</h2>
                <p>{{ $tm('geoindicators.report.widgets.visits_unique.avg_visitors')[selected_period] }}</p>
              </div>
            </div>
          </div>
        </template>

        <apexchart
        height="350"
        type="area"
        :options="options"
        :series="series">
        </apexchart>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {useChartOptions} from '../chart-options';
  import {useGisWidget} from '../../../composables/gis_widget';
  import tooltip from '../../tooltip.vue';
  import {parseDate, sortEntries, formatNumber} from '../../../../js/helpers';
  import {ref, computed} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {t, locale} = useI18n();
  const {DEFAULT_OPTIONS, VISITORS_TOOLTIP, AREA} = useChartOptions(t);

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    show_summary: {
      type: Boolean,
      required: false,
      default: false
    },
    show_tooltip: {
      type: Boolean,
      required: false
    }
  });

  const {updatePeriod} = useGisWidget(props.data);

  const selected_period = ref('by_month');

  const ordered_values = computed(() => {
    return sortEntries(props.data[selected_period.value].series);
  });

  const series = computed(() => {
    return [{
      name: '',
      data: ordered_values.value.map(([date, nb_visitors]) => nb_visitors)
    }];
  });

  const options = computed(() => {
    let avg_annotation = {};
    let min_marker_index;
    let max_marker_index;

    if (props.show_summary) {
      avg_annotation = {
        yaxis: [
          {
            y: props.data[selected_period.value].avg,
            borderColor: '#969696',
            strokeDashArray: 2,
            label: {
              borderColor: '#ffa000',
              style: {
                color: '#fff',
                background: '#ffa000'
              },
              text: ' '
            }
          }
        ]
      };

      if (ordered_values.value.length > 1) {
        min_marker_index = ordered_values.value.map((e) => {
          return e[1];
        }).indexOf(props.data[selected_period.value].min);

        max_marker_index = ordered_values.value.map((e) => {
          return e[1];
        }).indexOf(props.data[selected_period.value].max);
      }
    }

    return {
      ...DEFAULT_OPTIONS,
      ...VISITORS_TOOLTIP,
      ...AREA,
      annotations: avg_annotation,
      xaxis: {
        floating: false,
        categories: ordered_values.value.map(([date, nb_visitors]) => {
          return selected_period.value === 'by_month' ? parseDate(new Date(date), 'month_year', locale.value) : date;
        }),
        tickAmount: 12,
        tooltip: {
          enabled: false
        }
      },
      markers: {
        size: 0.000001,
        hover: {
          size: 5
        },
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: min_marker_index,
            fillColor: '#ffffff',
            strokeColor: '#ffa000',
            strokeWidth: 2,
            size: 7,
            shape: 'square'
          },
          {
            seriesIndex: 0,
            dataPointIndex: max_marker_index,
            fillColor: '#ffffff',
            strokeColor: '#ffa000',
            strokeWidth: 2,
            size: 7,
            shape: 'square'
          }
        ]
      }
    };
  });

  const min = computed(() => {
    return props.data[selected_period.value].min >= 0 ? formatNumber(props.data[selected_period.value].min) : '-';
  });

  const max = computed(() => {
    return props.data[selected_period.value].max >= 0 ? formatNumber(props.data[selected_period.value].max) : '-';
  });

  const avg = computed(() => {
    return props.data[selected_period.value].avg >= 0 ? formatNumber((props.data[selected_period.value].avg)) : '-';
  });
</script>
