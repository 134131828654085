<template>
  <header class="title-bar">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 align-self-center">
          <h1>
            {{ title }}
              <span class="badge danger" v-if="unsaved_changes === true">{{ $t('geoindicators.creation.header.unsaved_changes') }}</span>
          </h1>
        </div>

        <div class="col-lg-3 text-right align-self-center" v-if="!hide_actions">
            <dropdown>
              <template #actions>
                <ul class="list">
                  <li @click="copyReport" :class="{'disabled': unsaved_changes === true}">
                    <i class="far fa-clone"></i>
                    {{ $t('geoindicators.report.header.copy') }}
                  </li>
                  <li @click="deleteReport">
                    <i class="fas fa-trash-alt"></i>
                    {{ $t('geoindicators.report.header.delete_draft') }}
                  </li>
                </ul>
              </template>
              <template #title>
                {{ $t('geoindicators.report.header.actions') }}
                <i class="d-none d-lg-inline fas fa-caret-down"></i>
              </template>
            </dropdown>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
  defineProps({
    hide_actions: {
      required: false,
      default: false,
      type: Boolean
    },
    unsaved_changes: {
      required: false,
      default: false,
      type: [Boolean, String]
    },
    title: {
      required: false,
      default() {
        return '';
      },
      type: String
    }
  });

  const emit = defineEmits(['copy_report', 'delete_report']);

  const copyReport = () => {
    emit('copy_report');
  };

  const deleteReport = () => {
    emit('delete_report');
  };
</script>
