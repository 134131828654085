<template>
  <div>
    <div class="panel no-padding">
      <ag-grid-vue
        ref="summary_grid"
        class="ag-theme-balham ag-grid"
        :rowData="data_categories"
        :gridOptions="summary_grid_options"
        :pinnedBottomRowData="lastRow(summary_total)"
        @gridReady="onGridReady">
      </ag-grid-vue>
    </div>
  </div>
</template>

<script setup>
  import {AgGridVue} from 'ag-grid-vue3';
  import {reactive, getCurrentInstance} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {appContext} = getCurrentInstance();
  const global_filters = appContext.config.globalProperties.$filters;

  const {t} = useI18n();

  defineProps({
    data_categories: {
      type: Array,
      required: true,
      default: () => []
    },
    summary_total: {
      type: Object,
      required: true,
      default: () =>  ({})
    }
  });

  const summary_grid_options = reactive({
    columnDefs: [
      {
        headerName: t('geoindicators.report.economic.summary_grid.categories'),
        field: 'name',
        minWidth: 400,
      },
      {
        headerName: t('geoindicators.report.economic.summary_grid.avg_purchase'),
        field: 'avg_purchase',
        cellRenderer: (params) => {
          return params.value ? `${params.value}$` : '';
        }
      },
      {
        headerName: t('geoindicators.report.economic.summary_grid.visits_share'),
        field: 'visits_share',
        cellRenderer: (params) => {
          return params.value ? `${params.value}%` : '';
        }
      },
      {
        headerName: t('geoindicators.report.economic.summary_grid.avg_benefits_by_visitor'),
        field: 'avg_benefits_by_visitor',
        cellRenderer: (params) => {
          return params.value ? `${params.value}$` : '0%';
        }
      },
      {
        headerName: t('geoindicators.report.economic.summary_grid.sum_benefits'),
        field: 'sum_benefits',
        cellRenderer: (params) => {
          return params.value ? `${global_filters.splitNumber(params.value)}$` : '';
        }
      }
    ],
    defaultColDef: {
      resizable: true,
      sortable: true
    },
    domLayout: 'autoHeight',
    rowHeight: 35,
    headerHeight: 50
  });

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };

  const lastRow = (data) => {
    return [{
      name: 'Total',
      avg_purchase: '',
      visits_share: '',
      ...data
    }];
  };
</script>
