<template>
  <div class="row">
    <div class="col-12">
      <div class="panel">
        <div class="title">
          <div class="row">
            <div class="col-6">
              <b>
                {{ $t('geoindicators.report.widgets.visits_repartition.title') }}
                <tooltip
                  :text="$t('geoindicators.report.widgets.visits_repartition.tooltip')"
                  :show_tooltip="show_tooltip">
                </tooltip>
              </b>
            </div>
          </div>
        </div>

        <apexchart
        height="350"
        type="bar"
        :options="options"
        :series="series">
        </apexchart>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {useChartOptions} from '../chart-options';
  import tooltip from '../../tooltip.vue';
  import {sortEntries} from '../../../../js/helpers';
  import {ref, computed} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {t, tm} = useI18n();
  const {DEFAULT_OPTIONS, PERCENT_TOOLTIP, GRADIENT} = useChartOptions(t);

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    show_tooltip: {
      type: Boolean,
      required: false
    }
  });

  const selected_period = ref('by_day');

  const ordered_values = computed(() => {
    return sortEntries(props.data[selected_period.value]);
  });

  const series = computed(() => {
    return [{
      name: '',
      data: ordered_values.value.map(([date, percentage_visits]) => percentage_visits)
    }];
  });

  const options = computed(() => {
    let locale_categories = [];
    let categories = '';

    locale_categories = tm('geoindicators.report.widgets.visits_distribution.days_categories');
    categories = ordered_values.value.map(([date, nb_visits]) => locale_categories[date]);

    return {
      ...DEFAULT_OPTIONS,
      ...PERCENT_TOOLTIP,
      ...GRADIENT,
      xaxis: {
        categories: categories,
        floating: false,
        tickPlacement: 'on',
        tickAmount: 12
      }
    };
  });
</script>
