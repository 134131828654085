import {reactive, computed} from 'vue';

const FEATURES = {
  geoAcademy: 'geoAcademy',
  chat: 'chat',
  campaignCreation: 'campaignCreation',
  geoIndicators: 'geoIndicators',
  loginOptions: 'loginOptions',
  reportCreation: 'reportCreation',
  campaignActions: 'campaignActions'
};

const WHITELABEL_HIDDEN_FEATURES = {
  'socialdrink': ['geoAcademy', 'chat', 'geoIndicators', 'loginOptions', 'reportCreation'],
  'tctrail': ['geoAcademy', 'chat'],
  'strategicdatasource': ['geoAcademy', 'chat', 'campaignCreation', 'loginOptions', 'reportCreation'],
  'virtualtour360': ['geoAcademy', 'chat', 'loginOptions', 'reportCreation'],
  'pinkmarket': ['geoAcademy', 'chat', 'loginOptions', 'reportCreation'],
  'evowebmarketing': ['geoAcademy', 'chat', 'campaignCreation', 'loginOptions', 'reportCreation'],
  'civilia': ['geoAcademy', 'chat', 'campaignCreation', 'loginOptions', 'reportCreation', 'campaignActions'],
  'etincelle': ['geoAcademy', 'chat', 'loginOptions', 'reportCreation'],
  'clicketmortar': ['geoAcademy', 'chat', 'loginOptions', 'reportCreation'],
  'medialo': ['geoAcademy', 'chat'],
  'flashspotmedia': ['geoAcademy', 'chat', 'campaignCreation', 'loginOptions', 'reportCreation', 'campaignActions'],
  'geotargets': ['geoAcademy', 'chat'],
  'mgmarketing': ['geoAcademy', 'chat', 'campaignCreation', 'loginOptions', 'reportCreation', 'campaignActions'],
  'ilyamarketing': ['loginOptions'],
  'propulsodemo': ['geoAcademy', 'chat', 'loginOptions'],
  'pulsedxm': ['geoAcademy', 'chat', 'loginOptions', 'geoIndicators', 'reportCreation', 'campaignActions'],
  'symphonytourism': ['geoAcademy', 'chat', 'loginOptions'],
  'propulso': []
};

export function useWhiteLabel() {
  const data = reactive({
    domain: location.hostname.split('.').slice(-2, -1)[0]
    //domain: ('https://geo.symphonytourismservices.com').split('.').slice(-2, -1)[0] //for testing
  });

  const isFeatureDisabled = (feature) => {
    const hidden = WHITELABEL_HIDDEN_FEATURES[data.domain] ? WHITELABEL_HIDDEN_FEATURES[data.domain] : [];

    return data.domain && hidden.includes(feature);
  };

  const show_geo_academy = computed(() => {
    return !isFeatureDisabled(FEATURES.geoAcademy);
  });

  const show_geo_indicators = computed(() => {
    return !isFeatureDisabled(FEATURES.geoIndicators);
  });

  const show_campaign_creation_btn = computed(() => {
    return !isFeatureDisabled(FEATURES.campaignCreation);
  });

  const show_login_options = computed(() => {
    return !isFeatureDisabled(FEATURES.loginOptions);
  });

  const show_actions = computed(() => {
    return !isFeatureDisabled(FEATURES.campaignActions);
  });

  const show_gi_creation_btn = computed(() => {
    return !isFeatureDisabled(FEATURES.reportCreation);
  });

  return {
    data,
    isFeatureDisabled,
    show_geo_academy,
    show_geo_indicators,
    show_campaign_creation_btn,
    show_login_options,
    show_actions,
    show_gi_creation_btn
  };
}
