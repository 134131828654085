<template>
  <div class="panel">
    <div class="title">
      <b>{{ $t('geoindicators.creation.duration') }}</b>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <label>{{ $t('geoindicators.creation.minimum') }}</label>
        <div class="row align-items-center">
          <div class="col-4">
            <input
            v-tooltip.bottom="!is_min_valid ? {
              content: t('geoindicators.creation.errors.min_duration_out_of_range',
              {
                min: product.min_excursionist_hours,
                max: product.max_excursionist_hours
              }),
              classes: 'error'
              }: ''"
            :class="{'invalid-field': !is_min_valid}"
            type="number"
            v-model="duration.min">
          </div>
          <div class="col-6">
            <p>{{ $t('geoindicators.creation.hours') }}</p>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <label>{{ $t('geoindicators.creation.maximum') }}</label>
        <div class="row align-items-center">
          <div class="col-4">
            <input
            v-tooltip.bottom="!is_max_valid ? {
              content: t('geoindicators.creation.errors.max_duration_out_of_range',
              {
                min: product.min_tourist_days,
                max: product.max_tourist_days
              }),
              classes: 'error'
              }: ''"
            :class="{'invalid-field': !is_max_valid}"
            type="number"
            v-model="duration.max">
          </div>
          <div class="col-6">
            <p>{{ $t('geoindicators.creation.nights').toLowerCase() }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import validato from '@propulso/validato';
  import {ref, inject, reactive, computed, watch} from 'vue';
  import {useI18n} from 'vue-i18n';

  const event_hub = inject('event-hub');
  const {t} = useI18n();

  const props = defineProps({
    value: {
      type: Object,
      required: true
    },
    license: {
      type: Object,
      required: true
    }
  });

  const product = ref(validato.products[props.value.type]);

  const duration = reactive({
    min: props.value.min_duration,
    max: props.value.max_duration
  });

  const is_min_valid = computed(() => {
    return duration.min !== '' && validato.isFieldValid('min_duration', props.license, duration.min);
  });

  const is_max_valid = computed(() => {
    return validato.isFieldValid('max_duration', props.license, duration.max);
  });

  watch(duration, (update) => {
    event_hub.emit('duration_update', update);
  }, {
    deep: true
  });
</script>
