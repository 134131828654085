<template>
  <footer class="footer-bar">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-4 col-lg-2">
          <a
            @click="previousPage"
            class="btn info breadcrumb">
            <i class="fas fa-arrow-left"></i>
            <span class="btn-text pl-lg-1">{{ `${$t('geoindicators.report.header.return')}` }}</span>
          </a>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6">
          <p class="title">{{ $t('geoindicators.creation.price.time_ready') }}</p>
          <p class="text" :class="{'danger': show_warning_report_generation}">
            <i
              v-if="show_warning_report_generation"
              class="fa fa-exclamation-circle"
              v-tooltip="$t('geoindicators.creation.price.warning_time_ready')">
            </i>
            {{ time }}
          </p>
        </div>
        <div class="price col-xl-2 col-lg-3 col-md-9 col-sm-8 col-6">
          <p class="title" v-if="license.license_type === 'reseller'">{{ $t('geoindicators.creation.price.reseller_title') }}</p>
          <p class="title" v-else>{{ $t('geoindicators.creation.price.title') }}</p>

          <p class="text" v-if="price_text" v-tooltip.top="price_text.note">
            <span v-html="price_text.html" class="footer-price"></span>
            <i class="fa fa-exclamation-circle" v-if="price_text.note"></i>
          </p>
        </div>
        <div class="col-lg-4 col-12 text-right right-actions">
          <a
            @click="previousPage"
            class="btn info mobile">
            <i class="fas fa-arrow-left"></i>
            <span class="btn-text pl-lg-1">{{ `${$t('geoindicators.report.header.return')}` }}</span>
          </a>
          <div class="btn-container">
            <i v-if="note_next_btn && note_next_btn.note"
              v-tooltip.top="{content: note_next_btn.note, classes: note_next_btn.type}"
              class="i-alert fa"
              :class="{'fa-exclamation-triangle error': note_next_btn.type === 'error', 'fa-exclamation-circle warning': note_next_btn.type === 'warning'}">
            </i>
            <a v-if="step === 'edit'"
              @click="saveReport"
              class="btn success"
              :class="{'disabled': unsaved_changes !== true}"
              data-testid="save-report">
              <i class="fas fa-save"></i>
              <span class="btn-text pl-lg-1">{{ `${$t('geoindicators.creation.save')}` }}</span>
            </a>
            <a v-if="step !== 'summary' && step !== 'beta-summary'"
              @click="next"
              class="btn"
              :class="{'disabled': disable_next_button}"
              data-testid="report-next">
              <i class="fas fa-arrow-right"></i>
              <span class="btn-text pl-lg-1">{{ `${$t('geoindicators.creation.next')}` }}</span>
            </a>
            <a v-else
              @click="activateReport"
              class="btn"
              :class="{'disabled': disable_next_button}"
              data-testid="activate-report">
              <i class="fas fa-play"></i>
              <span class="btn-text pl-lg-1">{{ `${$t('geoindicators.creation.create_report')}` }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
  import {useReport} from '../../../composables/report.js';
  import {onMounted, watch, inject, ref, computed, reactive} from 'vue';
  import {useRouter} from 'vue-router';
  import {useI18n} from 'vue-i18n';

  const event_hub = inject('event-hub');
  const router = useRouter();
  const {t} = useI18n();

  const {
    error,
    computeTime,
  } = useReport(event_hub);

  const sty = inject('sentry');
  const axios = inject('axios');

  const props = defineProps({
    unsaved_changes: {
      required: false,
      default: false,
      type: [Boolean, String]
    },
    report_data: {
      required: false,
      default: () => ({}),
      type: Object
    },
    license: {
      required: false,
      default: {},
      type: Object
    },
    price_text: {
      required: false,
      type: Object,
      default: () => {
        return {
          note: '',
          html: '-'
        };
      }
    },
    step: {
      required: false,
      default: '',
      type: String
    },
    note_next_btn: {
      required: false,
      type: Object,
      default: () => ({})
    },
    is_payment_valid: {
      required: false,
      default: false,
      type: Boolean
    }
  });

  const emit = defineEmits(['next_step', 'save_report', 'activate_report']);

  const show_warning_report_generation = ref(false);
  const time = ref('-');

  const report = reactive(props.report_data);

  const disable_next_button = computed(() => {
    if (props.step.includes('beta')) {
      return true;
    } else if (props.step === 'edit') {
      if (props.unsaved_changes || props.note_next_btn.type === 'error' || !report.target_fences || report.target_fences.length === 0) {
        return true;
      }
    } else if (props.step === 'summary') {
      return !props.is_payment_valid;
    }

    return !props.report_data.type;
  });

  const previousPage = () => {
    if (props.step === 'summary' || props.step === 'beta-summary') {
      router.go(0);
    } else if (props.step === 'edit') {
      router.push(`/geoindicators/reports/create/${report._id}`);
    } else if (props.step === 'beta') {
      router.push(`/geoindicators/reports/create`);
    } else {
      router.push(`/geoindicators/reports`);
    }
  };

  const next = () => {
    emit('next_step');
  };

  const saveReport = () => {
    emit('save_report');
  };

  const activateReport = () => {
    emit('activate_report');
  };

  const getBasicTime = async(report) => {
    return axios.get(`${process.env.API_URL}/geoindicators/computingTime`)
      .then((res) => {
        return parseInt(res.data);
      })
      .catch((err) => {
        error.value = true;
        sty.setContext('data', report);
        sty.captureException(err);
      });
  };

  const computingTime = async() => {
    const report = props.report_data;

    if (!report.type) {
      return '-';
    }

    const basic_time = await getBasicTime(report);
    let computing_time_interval = '';

    if (report.type && report.type.startsWith('beta')) {
      time.value = t('geoindicators.creation.price.time_ready_days');
      return;
    }

    if (basic_time) {
      const target_fences_length = report.target_fences ? report.target_fences.reduce((acc, fence) => acc + fence.coordinates.length, 0) : 0;
      const report_duration_days = report.dates ? (report.dates.end - report.dates.start) / 1000 / 60 / 60 / 24 : 0;
      const computing_time = computeTime(report.type, basic_time, target_fences_length, report_duration_days);

      if (computing_time < 60) {
        computing_time_interval = `${computing_time} ${t('geoindicators.creation.price.minutes')}`;
      } else if (59 < computing_time && computing_time < 120) {
        computing_time_interval = t('geoindicators.creation.price.time_between_hours', {min: 1, max: 2});
      } else if (119 < computing_time && computing_time < 240) {
        computing_time_interval = t('geoindicators.creation.price.time_between_hours', {min: 2, max: 4});
      } else if (239 < computing_time && computing_time < 360) {
        computing_time_interval = t('geoindicators.creation.price.time_between_hours', {min: 4, max: 6});
      } else if (239 < computing_time) {
        computing_time_interval = t('geoindicators.creation.price.time_between_hours', {min: 6, max: 8});
      }
    }

    time.value = computing_time_interval;
  };

  watch(() => props.report_data.type, async(newVal, oldVal) => {
    await computingTime();
  });

  onMounted(async() => {
    await computingTime();
  });
</script>
