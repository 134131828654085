<template>
  <div>
    <div v-for="report_category in Object.keys(reports)" :key="report_category">
      <div class="title-separator">
        <h3>{{ $t(`geoindicators.creation.report_categories.${report_category}.title`) }}</h3>
        <p>{{ $t(`geoindicators.creation.report_categories.${report_category}.description`) }}</p>
        <hr>
      </div>
      <ul class="report-list">
        <li v-for="(report_type, report_id) in reports[report_category]" :key="report_id" :id="report_id">
          <div class="panel choice" :class="{'active': report_id === selected_report_type}" @click="selectReportType(report_id)">
            <div class="header">
              <img
                class="icon left"
                :src="`assets/icon-${report_id}-report.svg`"
                :alt="`${$t('geoindicators.creation.alt')}` + $t(`geoindicators.creation.report_types.${report_id}.title`)">
              <div class="right">
                <p class="report-type">
                  <b>{{ $t(`geoindicators.creation.report_types.${report_id}.title`) }}</b>
                  <span class="badge-container">
                    <span v-if="isBeta(report_id)" class="badge primary">{{ $t('geoindicators.creation.report_types.beta.title') }}</span>
                    <span v-if="!isIncludedInLicense(report_id)" class="badge secondary">{{ $t('geoindicators.creation.report_types.pro_license') }}</span>
                  </span>
                </p>
                <p>{{ $t(`geoindicators.creation.report_types.${report_id}.description`) }}</p>
                <div class="actions">
                  <span>
                    <a
                      v-if="report_type.url_documentation"
                      class="info"
                      :href="`/#/geoacademy/knowledge?page=${report_type.url_documentation}`"
                      target="_blank">
                      {{ $t('geoindicators.creation.view_documentation') }}
                      <i class="fas fa-angle-right"></i>
                    </a>
                  </span>
                  <span>
                    <a
                      v-if="report_type.url_demo"
                      class="btn default"
                      :href="`/#/geoindicators/reports/${report_type.url_demo[lang]}`"
                      target="_blank">
                      {{ $t('geoindicators.creation.view_demo') }}
                      <i class="fas fa-external-link-alt"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
  import {REPORTS} from '../report-types';
  import {ref} from 'vue';

  const props = defineProps({
    current_type: {
      type: String,
      default: ''
    },
    license: {
      type: Object,
      required: false,
      default: {}
    }
  });

  const emit = defineEmits(['selected']);

  const reports = ref(REPORTS);
  const selected_report_type = ref('');
  const lang = ref(localStorage.getItem('lang'));

  const selectReportType = (report_id) => {
    selected_report_type.value = report_id;
    emit('selected', selected_report_type.value);
  };

  const isBeta = (report_id) => {
    return report_id.startsWith('beta');
  };

  const isIncludedInLicense = (selected_type) => {
    const included_in_license = props.license.included_report_types.concat(props.license.additional_report_types);

    return included_in_license.includes(selected_type);
  };

  if (props.current_type) {
    selectReportType(props.current_type);
  }
</script>
