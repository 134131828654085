<template>
  <div class="sticky-form-parent" :class="classes">
    <form v-if="!request_sent" class="sticky-form" :class="is_sticky ? 'sticky':'fixed'" @submit.prevent="sendEmail">
      <h3>{{ $t('users.popup.preview.title') }}</h3>
      <hr>
      <div class="space">
        <label for="name">{{ $t('users.popup.preview.name') }}</label>
        <input type="text" id="name" v-model="user.name" :placeholder="$t('users.popup.preview.name')">
        <span v-if="errors.name" class="field-error">{{ t('users.popup.preview.errors.name') }}</span>
      </div>
      <div class="space">
        <label for="email">{{ $t('users.popup.email_label') }}</label>
        <input type="email" id="email" v-model="user.email" :placeholder="$t('users.popup.email_placeholder')">
        <span v-if="errors.email" class="field-error">{{ t('users.popup.preview.errors.email') }}</span>
      </div>
      <div class="space">
        <label for="phone">{{ $t('users.popup.preview.phone') }}</label>
        <input type="text" id="phone" v-model="user.phone" :placeholder="$t('users.popup.preview.phone')">
        <span v-if="errors.phone" class="field-error">{{ t('users.popup.preview.errors.phone') }}</span>      </div>
      <div class="space">
        <label for="message">{{ $t('users.popup.preview.message') }}</label>
        <textarea id="message" v-model="user.message" :placeholder="$t('users.popup.preview.message')"></textarea>
      </div>
      <hr>
      <div class="button-wrapper">
        <button class="btn success" type="submit">{{ $t('users.popup.preview.send_request') }}</button>
      </div>
    </form>

    <form v-if="request_sent" class="sticky-form" :class="is_sticky ? 'sticky':'fixed'">
      {{ $t('users.popup.preview.confirmation') }}
    </form>
  </div>
</template>

<script setup>
  import {ref, reactive, inject, onMounted, onUnmounted} from 'vue';
  import axios from 'axios';
  import {useRoute} from 'vue-router';
  import {useI18n} from 'vue-i18n';
  import {toast} from 'vue3-toastify';
  import {emailValidation, phoneValidation} from '../../../../js/helpers';
  import {computed} from 'vue';

  const {t} = useI18n();
  const route = useRoute();

  const sty = inject('sentry');

  defineProps({
    classes: {
      type: String,
      required: true
    }
  });

  const request_sent = ref(false);
  const scroll_y = ref(window.scrollY);

  const errors = reactive({
    name: false,
    email: false,
    phone: false
  });

  const user = reactive({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const is_sticky = computed(() => scroll_y.value < 1000);

  const validateForm = () => {
    errors.name = !user.name.trim();
    errors.email = !emailValidation(user.email.trim());
    errors.phone = !phoneValidation(user.phone.trim());

    return !errors.name && !errors.email && !errors.phone;
  };

  const sendEmail = async() => {
    if (!validateForm()) return;

    const report_id = route.params.report_id;

    try {
      await axios.post(`${process.env.API_URL}/geoindicators/reports/${report_id}/requestAccess`, {
        name: user.name.trim(),
        email: user.email.trim(),
        phone: user.phone.trim(),
        message: user.message
      });

      request_sent.value = true;
      toast.success(t('users.popup.preview.send_request_success_toast'));
    } catch (err) {
      toast.error(t('users.popup.preview.send_request_error_toast'));
      sty.captureException(err);
    }
  };

  const stickyHandler = () => {
    scroll_y.value = window.scrollY;
  };

  onMounted(() => {
    window.addEventListener('scroll', stickyHandler);
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', stickyHandler);
  });
</script>
