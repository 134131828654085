<template>
  <div>
    <div class="popup">
      <div class="container-sm">
        <div class="panel">
          <!-- Title -->
          <div class="title">
            <b>{{ $t('credit_cards.popup.add_title') }}</b>
            <aside class="actions">
              <a class="info" @click="close">
                <i class="fas fa-times"></i>
              </a>
            </aside>
          </div>

          <loading :loading="is_loading" :is_panel="false"></loading>

          <div v-if="!is_loading">
            <!-- Form -->
            <div class="row justify-content-center">
              <div class="col-md-6">
                <organizations-select
                  v-model:value="new_credit_card.organization_id"
                  :condition="'is_billable'"
                  :placeholder="$t('credit_cards.popup.select_organization')">
                </organizations-select>
                <input type="text" :placeholder="$t('credit_cards.popup.card_holder_name')" v-model="new_credit_card.credit_card.holder_name">
                <input type="number" :placeholder="$t('credit_cards.popup.card_number')" maxlength="16" v-model="new_credit_card.credit_card.number">
                <div class="row">
                  <div class="col-md-4">
                    <select v-model="new_credit_card.credit_card.expiration.month">
                      <option :value="null" :label="$t('credit_cards.popup.card_expiration_month')" selected disabled></option>
                      <option :value="1">{{ $t('credit_cards.popup.expiration_months.january') }}</option>
                      <option :value="2">{{ $t('credit_cards.popup.expiration_months.february') }}</option>
                      <option :value="3">{{ $t('credit_cards.popup.expiration_months.march') }}</option>
                      <option :value="4">{{ $t('credit_cards.popup.expiration_months.april') }}</option>
                      <option :value="5">{{ $t('credit_cards.popup.expiration_months.may') }}</option>
                      <option :value="6">{{ $t('credit_cards.popup.expiration_months.june') }}</option>
                      <option :value="7">{{ $t('credit_cards.popup.expiration_months.july') }}</option>
                      <option :value="8">{{ $t('credit_cards.popup.expiration_months.august') }}</option>
                      <option :value="9">{{ $t('credit_cards.popup.expiration_months.september') }}</option>
                      <option :value="10">{{ $t('credit_cards.popup.expiration_months.october') }}</option>
                      <option :value="11">{{ $t('credit_cards.popup.expiration_months.november') }}</option>
                      <option :value="12">{{ $t('credit_cards.popup.expiration_months.december') }}</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <select id="exp-years" v-model="new_credit_card.credit_card.expiration.year">
                      <option :value="null" :label="$t('credit_cards.popup.card_expiration_year')" selected disabled></option>
                      <option v-for="year in expiration_years" :value="year">{{ year }}</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <input type="text" placeholder="CVV" v-model="new_credit_card.credit_card.cvv">
                  </div>
                  <div class="col-md-8">
                    <input type="text" :placeholder="$t('credit_cards.popup.card_address_street')" maxlength="30" v-model="new_credit_card.address.street">
                  </div>
                  <div class="col-md-4">
                    <input type="text" :placeholder="$t('credit_cards.popup.card_postal_code')" maxlength="10" v-model="new_credit_card.address.postal_code">
                  </div>
                </div>
              </div>
            </div>
            <!-- Errors -->
            <aside class="alert danger" v-if="errors.length">
              <p>
                <i class="fas fa-exclamation-triangle"></i>
                <b>{{ $t('credit_cards.popup.errors.title') }}</b>
              </p>
              <ul>
                <li v-for="error in errors">{{ error }}</li>
              </ul>
            </aside>
          </div>
          <!-- Actions footer -->
          <div v-if="!is_loading" class="footer">
            <aside class="actions">
              <a class="btn" @click="close">
                {{ $t('credit_cards.popup.footer_btn_cancel') }}
              </a>
              <a class="btn success" @click="addCreditCard">
                {{ $t('credit_cards.popup.footer_btn_add') }}
              </a>
            </aside>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
  import organizationsSelect from '../organizationsSelect.vue';
  import {inject, reactive, ref, computed} from 'vue';
  import {useI18n} from 'vue-i18n';
  import {toast} from 'vue3-toastify';

  const {t} = useI18n();

  const axios = inject('axios');
  const sty = inject('sentry');
  const event_hub = inject('event-hub');

  const emit = defineEmits(['update-popup']);

  const is_loading = ref(false);
  const errors = ref(false);

  const new_credit_card = reactive({
    organization_id: '',
    type: 'credit-card',
    credit_card: {
      provider: '',
      number: null,
      expiration: {
        month: null,
        year: null
      },
      cvv: null,
      holder_name: ''
    },
    address: {
      street: '',
      postal_code: ''
    }
  });

  const expiration_years = computed(() => {
    const current_year = new Date().getFullYear();

    return Array.from({length: 26}, (_, i) => current_year + i);
  });

  const close = () => {
    emit('update-popup', false);
  };

  const validateCard = (cc) => {
    const visa_regex = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    const mastercard_regex = /^(?:5[1-5][0-9]{14})$/;

    if (visa_regex.test(cc)) {
      return 'visa';
    }

    if (mastercard_regex.test(cc)) {
      return 'mastercard';
    }

    return false;
  };

  const validateForm = () => {
    let errors = [];
    const form = new_credit_card;

    if (!form.organization_id) {
      errors.push(t('credit_cards.popup.errors.organization'));
    }

    const card_type = validateCard(form.credit_card.number);

    if (card_type) {
      form.credit_card.provider = card_type;
    } else {
      errors.push(t('credit_cards.popup.errors.credit_card_provider'));
    }

    if (!form.credit_card.holder_name || form.credit_card.holder_name.length < 5) {
      errors.push(t('credit_cards.popup.errors.credit_card_holder'));
    }

    if (!form.credit_card.expiration.month || !form.credit_card.expiration.year) {
      errors.push(t('credit_cards.popup.errors.credit_card_expiration_month'));
    }

    if (!form.credit_card.cvv || form.credit_card.cvv.length < 2 || form.credit_card.cvv.length > 3) {
      errors.push(t('credit_cards.popup.errors.credit_card_cvv'));
    }

    if (!form.address.street ||
      form.address.street.length < 5 ||
      form.address.street.length > 30 || !form.address.postal_code  ||
      form.address.postal_code.length < 5 ||
      form.address.postal_code.length > 10
    ) {
      errors.push(t('credit_cards.popup.errors.credit_card_address'));
    }

    return errors;
  };

  const addCreditCard = () => {
    errors.value = validateForm();

    new_credit_card.credit_card.number = parseInt(new_credit_card.credit_card.number);
    new_credit_card.credit_card.expiration.year = parseInt(new_credit_card.credit_card.expiration.year);

    if (!errors.value.length) {
      is_loading.value = true;

      axios.post(`${process.env.API_URL}/payments/profile`, new_credit_card)
        .then(() => {
          is_loading.value = false;
          close();
          event_hub.emit('refresh-credit-cards');
          toast.success(t('credit_cards.popup.add_success_msg_card'));
        })
        .catch((err) => {
          is_loading.value = false;

          const toast_error_message = err.status === 400 ? t('credit_cards.popup.errors.invalid_informations') : t('credit_cards.popup.errors.creating_error');

          sty.setContext('data', new_credit_card);
          sty.captureException(err);
          toast.error(toast_error_message);
        });
    }
  };

  const updateOrganization = (org) => {
    new_credit_card.organization_id = org._id;
  };

  event_hub.on('organization_update', updateOrganization);
</script>
