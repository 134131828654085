<template>
  <div class="panel">
    <div class="title">
      <b>{{ `${$t('campaign.edit.creatives_panel.title')} ` }}</b>
      <i class="fas fa-question-circle tooltip-icon" v-tooltip=" $t('campaign.edit.creatives_panel.title_tooltip')"></i>
      <span class="badge info" v-if="files.length">{{ files.length }}</span>
    </div>
    <div class="file-upload">

      <!-- Alert -->
      <aside class="alert danger" v-if="files.length > files_limit">
        <i class="fas fa-exclamation-circle"></i>
        {{ $t('campaign.edit.creatives_panel.error_max_files') }}
      </aside>

      <!-- Creative type -->
      <div class="upload-choice">
        <div class="row">
          <div @click="popupAnimation" class="col-6">
            <div :class="{'disabled-cursor': selected_media !== 'banner' && !is_draft}">
              <div class="choice" @click="mediaTypeUpdate('banner')" :class="{'active': selected_media === 'banner', 'disabled': !is_draft}">
                <i class="fas fa-image icon"></i>
                <p>{{ $t('campaign.edit.creatives_panel.banner_type') }}</p>
              </div>
            </div>
          </div>
          <div @click="popupAnimation" class="col-6">
            <div :class="{'disabled-cursor': selected_media !== 'video' && !is_draft}">
              <div class="choice" @click="mediaTypeUpdate('video')" :class="{'active': selected_media === 'video', 'disabled': !is_draft}">
                <i class="fas fa-play-circle video icon"></i>
                <p>{{ $t('campaign.edit.creatives_panel.video_type') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="divider"></div>

      <!-- Upload zone -->
      <div class="upload-zone">
        <div class="upload-input">
          <input
            type="file"
            class="files-input"
            :disabled="files.length > files_limit"
            ref="file_input"
            multiple
            @change="handleFilesUpload()"
            :accept="type_accepted"
            data-testid="creatives-input"
          />
          <i class="icon far fa-file-archive"></i>
          <p>
            {{ $t('campaign.edit.creatives_panel.add_files') }}
          </p>
          <p>
            <small>
              <a class="info" target="_blank" href="#/geoacademy/knowledge">
                <i class="fas fa-question-circle"></i>
                {{ $t('campaign.edit.creatives_panel.supported_files') }}
              </a>
            </small>
          </p>
        </div>
      </div>

      <!-- Creatives list -->
      <table class="files-tab" v-if="files[0]">
        <thead>
        <tr>
          <th>{{ $t('campaign.edit.creatives_panel.creative_name') }}</th>
          <th v-if="selected_media === 'banner'">{{ $t('campaign.edit.creatives_panel.dimensions') }}</th>
          <th>{{ $t('campaign.edit.creatives_panel.destination_url') }}</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(file, file_key) in files">
          <tr class="file-listing">
            <td><input v-model="file.name" :placeholder="$t('campaign.edit.creatives_panel.creative_name')"/></td>
            <td v-if="selected_media === 'banner'">
              <select v-model="file.dimensions">
                <option value="" default disabled>{{ $t('campaign.edit.creatives_panel.dimensions') }}</option>
                <option v-for="dimension in banner_dimensions" :value="dimension">{{ dimension.width}} x {{ dimension.height}}</option>
              </select>
            </td>
            <td><input v-model="file.target_url" placeholder="URL (https://....)"/></td>
            <td class="text-left">
              <a class="remove-file" @click="displayPreview(file, $event)" v-if="file._id">
                <i class="far fa-eye"></i>
              </a>
              <a class="remove-file" v-else>
                <i class="far fa-eye tooltip-icon"
                   v-tooltip="$t('campaign.edit.creatives_panel.preview_tooltip')"></i>
              </a>
            </td>
            <td class="text-right">
              <a class="remove-file" @click="removeFile(file_key)">
                <i class="far fa-trash-alt"></i>
              </a>
            </td>
          </tr>
          <creative-preview
            :key="file_key"
            v-if="file.isPreviewDisplayed"
            :source_url="file.source_url"
            :media_height="file.dimensions.height"
            :media_width="file.dimensions.width"
            :media_type="selected_media">
          </creative-preview>
        </template>
        </tbody>
      </table>

      <!-- POPUP -->
      <div v-if="media_alert" class="popup">
        <transition name="popup-transition">
          <div v-if="show" class="container">
            <div class="panel">

              <!-- Title -->
              <div class="title">
                <i class="fas fa-exclamation-triangle"></i>
                <b class="pl-1">{{ $t('campaign.edit.creatives_panel.popup.header_title') }}</b>
                <aside class="actions">
                  <a class="info" @click="close">
                    <i class="fas fa-times"></i>
                  </a>
                </aside>
              </div>

              <!-- Content -->
              <p class="justify-content-center">
                {{ $t('campaign.edit.creatives_panel.popup.text') }}
              </p>

              <!-- Footer -->
                <div class="footer">
                  <aside class="actions row">
                      <div class="col">
                        <p>{{ $t('campaign.edit.creatives_panel.popup.footer_text') }}</p>
                      </div>
                      <div class="col-12">
                        <a class="btn" @click="close">
                          {{ $t('campaign.edit.creatives_panel.popup.footer_cancel_btn') }}
                        </a>
                        <a @click="popupConfirmation" class="btn success ml-2">
                          {{ $t('campaign.edit.creatives_panel.popup.footer_continue_btn') }}
                        </a>
                      </div>
                  </aside>
                </div>
            </div>
          </div>
        </transition>
      </div>

    </div>
  </div>
</template>

<script setup>
  import creativePreview from '../components/creativePreview.vue';
  import {ref, watch, inject, computed} from 'vue';
  import {useI18n} from 'vue-i18n';
  import {toast} from 'vue3-toastify';

  const {t} = useI18n();

  const event_hub = inject('event-hub');

  const props = defineProps({
    files_upload: {
      type: Array,
      required: true
    },
    media_type: {
      type: String
    },
    is_draft: {
      type: Boolean
    }
  });

  const emit = defineEmits(['updateMediaType']);

  const files_limit = ref(50);
  const temp_media = ref(null);
  const show = ref(false);
  const media_alert = ref(false);
  const files = ref(JSON.parse(JSON.stringify(props.files_upload)));
  const file_input = ref(null);

  const banner_dimensions = ref([
    {width: 160, height: 600},
    {width: 300, height: 50},
    {width: 300, height: 100},
    {width: 300, height: 250},
    {width: 300, height: 600},
    {width: 320, height: 50},
    {width: 320, height: 480},
    {width: 336, height: 280},
    {width: 728, height: 90},
    {width: 970, height: 250}
  ]);

  const video_dimensions = ref([
    {width: 1920, height: 1080},
    {width: 1280, height: 720}
  ]);

  const selected_media = computed(() => {
    return props.media_type;
  });

  const type_accepted = computed(() => {
    return props.media_type === 'video' ? '.mp4' : '.jpg, .jpeg, .png, .gif, .zip';
  });

  const popupConfirmation = () => {
    files.value = [];
    emit('updateMediaType', temp_media);
    close();
  };

  const popupAnimation = () => {
    show.value = !!files.value.length;
  };

  const close = () => {
    show.value = false;
    media_alert.value = false;
  };

  const mediaTypeUpdate = (type) => {
    if (selected_media !== type) {
      temp_media.value = type;
      if (files.value.length) {
        media_alert.value = true;
      } else {
        emit('updateMediaType', temp_media);
      }
    }
  };

  const validateBanner = (file) => {
    const allowed_file_types = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'application/zip', 'application/x-zip', 'application/x-zip-compressed'];

    if (!allowed_file_types.includes(file.type)) {
      toast.error(`${file.name}: ` + t('campaign.edit.creatives_panel.error_banner_format_type'));

      return false;
    } else if (file.size > 300000) {
      toast.error(`${file.name}: ` + t('campaign.edit.creatives_panel.error_banner_file_size'));

      return false;
    }

    return true;
  };

  const validateVideo = (file) => {
    const allowed_videos_types = ['video/mp4'];

    if (!allowed_videos_types.includes(file.type)) {
      toast.error(`${file.name}: ` + t('campaign.edit.creatives_panel.error_video_format_size'));

      return false;
    } else if (file.size > 20000000) {
      toast.error(`${file.name}: ` + t('campaign.edit.creatives_panel.error_video_file_size'));

      return false;
    }

    return true;
  };

  const handleFilesUpload = () => {
    const uploaded_files = file_input.value.files;
    const read_file_promises = [];

    for (let i = 0; i < uploaded_files.length; i++) {
      const file = uploaded_files[i];

      if (selected_media === 'banner') {
        if (!validateBanner(file)) {
          break;
        }
      } else if (selected_media === 'video') {
        if (!validateVideo(file)) {
          break;
        }
      }

      const reader = new FileReader();

      read_file_promises.push(new Promise((resolve, reject) => {
        reader.readAsDataURL(file);
        reader.onerror = (error) => reject(error);
        reader.onload = () => {
          let f = {
            name: file.name.replace(/\.[^.]+$/, ''),
            filename: file.name,
            content: reader.result.replace(/data:[a-zA-Z]*\/[a-zA-Z0-9-]*;base64,/, ''),
            target_url: file.target_url ? file.target_url : '',
            type: file.type,
            dimensions: {
              width: 0,
              height: 0
            }
          };

          if (file.type.includes('image/')) {
            const img = new Image();

            img.src = reader.result;
            img.onerror = (error) => reject(error);
            img.onload = () => {
              f.dimensions = {
                width: img.width,
                height: img.height
              };

              if (banner_dimensions.value.filter((dim) => JSON.stringify(dim) === JSON.stringify(f.dimensions)).length) {
                files.value.push(f);
              } else {
                toast.error(t('campaign.edit.creatives_panel.error_creative_format') + `(${f.dimensions.width}x${f.dimensions.height})`);
              }
            };

            img.onerror = (error) => reject(error);
          } else if (file.type.includes('video/')) {
            const video = document.createElement('video');

            video.src = reader.result;
            video.onloadedmetadata = (event) => {
              f.duration = video.duration;
              f.dimensions = {
                width: video.videoWidth,
                height: video.videoHeight
              };

              if (video_dimensions.value.filter((dim) => JSON.stringify(dim) === JSON.stringify(f.dimensions)).length) {
                if (video.duration <= 30.5) {
                  files.value.push(f);
                } else {
                  toast.error(t('campaign.edit.creatives_panel.error_video_length'));
                }
              } else {
                toast.error(t('campaign.edit.creatives_panel.error_creative_format') + ` (${f.dimensions.width}x${f.dimensions.height})`);
              }
            };

          } else {
            files.value.push(f);
          }

          resolve();
        };
      }));
    }

    return Promise.all(read_file_promises);
  };

  const removeFile = (key) => {
    files.value.splice(key, 1);
  };

  const displayPreview = (file, e) => {
    file.isPreviewDisplayed = !file.isPreviewDisplayed;
  };

  watch(files, (update) => {
    event_hub.emit('creatives_update', JSON.parse(JSON.stringify(update)));
  }, {
    deep: true
  });
</script>
