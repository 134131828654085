<template>
  <img :src="logo_url">
</template>

<script setup>
  import {useWhiteLabel} from '../composables/whitelabel';
  import {ref, onMounted} from 'vue';

  const {data} = useWhiteLabel();

  const logo_url = ref('assets/propulso-logo-white.svg');

  const createFavIcon = (url) => {
    const link = document.createElement('link');

    link.rel = 'shortcut icon';
    link.type = 'image/x-icon';
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
  };

  const removeChat = () => {
    _chatlio.hide();
  };

  const detectLogo = async() => {
    const css_root = document.querySelector(':root');

    switch (data.domain) {
      case 'ilyamarketing':
        logo_url.value = 'assets/ilyamarketing-logo.svg';
        css_root.style.setProperty('--primary', '#bab156');
        css_root.style.setProperty('--primary-rgb', '186, 177, 86');
        css_root.style.setProperty('--primary-darker', '#8c8641');
        css_root.style.setProperty('--primary-darkest', '#66612f');
        document.title = 'il y a marketing';
        createFavIcon('assets/generic-favicon.ico');
        break;
      case 'propulsodemo':
        logo_url.value = 'assets/propulsodemo-logo.svg';
        css_root.style.setProperty('--primary', '#88a603');
        css_root.style.setProperty('--primary-rgb', '136, 166, 3');
        css_root.style.setProperty('--primary-darker', '#6d8501');
        css_root.style.setProperty('--primary-darkest', '#506101');
        document.title = 'Your Brand Here';
        removeChat();
        createFavIcon('assets/generic-favicon.ico');
        break;
      case 'mgmarketing':
        logo_url.value = 'assets/mgmarketing-logo.svg';
        css_root.style.setProperty('--primary', '#00a6ce');
        css_root.style.setProperty('--primary-rgb', '0, 65, 81');
        css_root.style.setProperty('--primary-darker', '#0287a8');
        css_root.style.setProperty('--primary-darkest', '#016680');
        document.title = 'MG Marketing';
        removeChat();
        createFavIcon('assets/generic-favicon.ico');
        break;
      case 'geotargets':
        logo_url.value = 'assets/unionppc-logo.svg';
        css_root.style.setProperty('--primary', '#ff3366');
        css_root.style.setProperty('--primary-rgb', '255, 51, 102');
        css_root.style.setProperty('--primary-darker', '#e32957');
        css_root.style.setProperty('--primary-darkest', '#bd2047');
        document.title = 'GeoTargets by Union PPC';
        removeChat();
        createFavIcon('assets/generic-favicon.ico');
        break;
      case 'flashspotmedia':
        logo_url.value = 'assets/flashspot-logo.png';
        css_root.style.setProperty('--primary', '#2276F1');
        css_root.style.setProperty('--primary-rgb', '34, 118, 241');
        css_root.style.setProperty('--primary-darker', '#1c63c9');
        css_root.style.setProperty('--primary-darkest', '#154c9e');
        document.title = 'Flashspot media';
        removeChat();
        createFavIcon('assets/generic-favicon.ico');
        break;
      case 'medialo':
        logo_url.value = 'assets/medialo-logo.svg';
        css_root.style.setProperty('--primary', '#45b7a2');
        css_root.style.setProperty('--primary-rgb', '69, 183, 162');
        css_root.style.setProperty('--primary-darker', '#338a7a');
        css_root.style.setProperty('--primary-darkest', '#27695c');
        document.title = 'Medialo';
        removeChat();
        createFavIcon('assets/generic-favicon.ico');
        break;
      case 'civilia':
        logo_url.value = 'assets/civilia-logo.svg';
        css_root.style.setProperty('--primary', '#db3737');
        css_root.style.setProperty('--primary-rgb', '219, 55, 55');
        css_root.style.setProperty('--primary-darker', '#F48786');
        css_root.style.setProperty('--primary-darkest', '#af2f2f');
        document.title = 'Civilia mobi.sim';
        removeChat();
        createFavIcon('assets/generic-favicon.ico');
        break;
      case 'clicketmortar':
        logo_url.value = 'assets/clickmortar-logo.svg';
        css_root.style.setProperty('--primary', '#09308e');
        css_root.style.setProperty('--primary-rgb', '39, 59, 135');
        css_root.style.setProperty('--primary-darker', '#223273');
        css_root.style.setProperty('--primary-darkest', '#1c2a61');
        document.title = 'Click & Mortar';
        removeChat();
        createFavIcon('assets/generic-favicon.ico');
        break;
      case 'etincelle':
        logo_url.value = 'assets/etincelle-logo.svg';
        css_root.style.setProperty('--primary', '#6f2055');
        css_root.style.setProperty('--primary-rgb', '111, 32, 85');
        css_root.style.setProperty('--primary-darker', '#571842');
        css_root.style.setProperty('--primary-darkest', '#38102b');
        document.title = 'Étincelle';
        removeChat();
        createFavIcon('assets/generic-favicon.ico');
        break;
      case 'evowebmarketing':
        logo_url.value = 'assets/evowebmarketing-logo.svg';
        css_root.style.setProperty('--primary', '#a71c20');
        css_root.style.setProperty('--primary-rgb', '167, 28, 33');
        css_root.style.setProperty('--primary-darker', '#871619');
        css_root.style.setProperty('--primary-darkest', '#6b1114');
        document.title = 'Evo Web Marketing';
        removeChat();
        createFavIcon('assets/generic-favicon.ico');
        break;
      case 'pinkmarket':
        logo_url.value = 'assets/pinkmarket-logo.svg';
        css_root.style.setProperty('--primary', '#ee1588');
        css_root.style.setProperty('--primary-rgb', '238, 21, 137');
        css_root.style.setProperty('--primary-darker', '#cf1377');
        css_root.style.setProperty('--primary-darkest', '#ad1064');
        document.title = 'Pinkmarket';
        removeChat();
        createFavIcon('assets/generic-favicon.ico');
        break;
      case 'virtualtour360':
        logo_url.value = 'assets/virtualtour360-logo.svg';
        css_root.style.setProperty('--primary', '#f39200');
        css_root.style.setProperty('--primary-rgb', '243, 146, 0');
        css_root.style.setProperty('--primary-darker', '#c76b02');
        css_root.style.setProperty('--primary-darkest', '#e94e1b');
        document.title = 'VirtualTour360';
        removeChat();
        createFavIcon('assets/generic-favicon.ico');
        break;
      case 'strategicdatasource':
        logo_url.value = 'assets/strategicdatasource-logo.png';
        css_root.style.setProperty('--primary', '#fcb338');
        css_root.style.setProperty('--primary-rgb', '252, 180, 56');
        css_root.style.setProperty('--primary-darker', '#d99a30');
        css_root.style.setProperty('--primary-darkest', '#bd862a');
        document.title = 'Strategic Data Source';
        removeChat();
        createFavIcon('assets/generic-favicon.ico');
        break;
      case 'tctrail':
        logo_url.value = 'assets/tctrail-propulso.png';
        document.title = 'Propulso x Trans Canada Trail';
        removeChat();
        createFavIcon('assets/generic-favicon.ico');
        break;
      case 'socialdrink':
        logo_url.value = 'assets/socialdrink-logo.svg';
        css_root.style.setProperty('--primary', '#59ace3');
        css_root.style.setProperty('--primary-rgb', '89, 172, 227');
        css_root.style.setProperty('--primary-darker', '#2c6c96');
        css_root.style.setProperty('--primary-darkest', '#1c384b');
        document.title = 'Social Drink';
        removeChat();
        createFavIcon('assets/generic-favicon.ico');
        break;
      case 'pulsedxm':
        logo_url.value = 'assets/pulse_logo.svg';
        css_root.style.setProperty('--primary', '#07172f');
        css_root.style.setProperty('--primary-rgb', '7, 23, 47');
        css_root.style.setProperty('--primary-darker', '#e14f9c');
        css_root.style.setProperty('--primary-darkest', '#d1d3d4');
        document.title = 'Pulse';
        removeChat();
        createFavIcon('assets/generic-favicon.ico');
        break;
      case 'symphonytourism':
        logo_url.value = 'assets/symphony-logo.svg';
        css_root.style.setProperty('--primary', '#899f89');
        css_root.style.setProperty('--primary-rgb', '137, 159, 137');
        css_root.style.setProperty('--primary-darker', '#878787');
        css_root.style.setProperty('--primary-darkest', '#ffffff');
        document.title = 'Symphony Tourism Services';
        removeChat();
        createFavIcon('assets/generic-favicon.ico');
        break;
      case 'activexchange':
        logo_url.value = 'assets/activexchange-logo.png';
        css_root.style.setProperty('--primary', '#573190');
        css_root.style.setProperty('--primary-rgb', '87, 49, 144');
        css_root.style.setProperty('--primary-darker', '#4e2191');
        css_root.style.setProperty('--primary-darkest', '#440f94');
        document.title = 'ActiveXchange';
        removeChat();
        createFavIcon('assets/generic-favicon.ico');
        break;
      default:
        createFavIcon('assets/propulso-favicon.ico');
        break;
    }
  };

  onMounted(() => {
    detectLogo();
  });

</script>
