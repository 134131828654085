<template>
  <div class="row">
    <div class="col-12">
      <div class="panel">
        <div class="title">
          <b>
            {{ $t('geoindicators.report.widgets.visits_source_title') }}
            <tooltip
              :text="$t('geoindicators.report.widgets.visits_source_tooltip')"
              :show_tooltip="show_tooltip">
            </tooltip>
          </b>
          <aside class="actions">
            <dropdown
              :id="1"
              :active_dropdown_id="active_dropdown_id"
              @toggle="toggleDropdown"
              :is_button="false"
            >
              <template #actions>
                <ul class="list legend-list">
                  <h4 class="legend-title">{{ $t('geoindicators.report.widgets.legend.description') }}</h4>
                  <li class="separator"></li>
                  <li v-for="(color, index) in h3_colors" :key="index" class="legend-item">
                    <span :style="{ backgroundColor: color }" class="legend-circle"></span>
                    <span>{{ $tm('geoindicators.report.widgets.legend.labels')[index] }}</span>
                  </li>
                </ul>
              </template>
              <template #title>
                <a class="info">
                  <i class="fas fa-map"></i>
                  <span class="d-none d-lg-inline">{{ $t('geoindicators.report.widgets.legend.title') }}</span>
                  <i class="fas fa-caret-down"></i>
                </a>
              </template>
            </dropdown>
            <dropdown
              :id="2"
              :active_dropdown_id="active_dropdown_id"
              @toggle="toggleDropdown"
              :is_button="false"
            >
              <template #actions>
                <ul class="list layers">
                  <li v-if="data.territories && data.territories[0] && data.territories[0].path">
                    <label class="checkbox">
                      {{ $t('geoindicators.report.widgets.territories_toggle') }}
                      <input v-model="show_territory_layer" type="checkbox">
                      <span></span>
                    </label>
                  </li>
                  <li>
                    <label class="checkbox">
                      {{ $t('geoindicators.report.widgets.heat_maps_toggle') }}
                      <input v-model="show_heatmap_layer" type="checkbox">
                      <span></span>
                    </label>
                  </li>
                  <li>
                    <label class="checkbox">
                      {{ $t('geoindicators.report.widgets.markers_toggle') }}
                      <input v-model="show_marker_layer" type="checkbox">
                      <span></span>
                    </label>
                  </li>
                  <li>
                    <label class="checkbox">
                      {{ $t('geoindicators.report.widgets.polygons_toggle') }}
                      <input v-model="show_polygon_layer" type="checkbox">
                      <span></span>
                    </label>
                  </li>
                </ul>
              </template>
              <template #title>
                <a class="info">
                  <i class="fas fa-layer-group"></i>
                  <span class="d-none d-lg-inline">{{ $t('geoindicators.report.widgets.layers_toggle') }}</span>
                  <i class="fas fa-caret-down"></i>
                </a>
              </template>
            </dropdown>
          </aside>
        </div>

        <div class="custom-heatmap-container">
          <div class="heatmap-container">
            <GoogleMap
              :api-promise="apiPromise"
              ref="map_ref"
              mapId="VISITS_SOURCE_ID"
              style="height: 650px;"
              :zoom="map.zoom"
              :center="map.center"
              :options="map.options"
              @zoom_changed=mapZoomChanged>
              <!-- Territories -->
              <Polygon
                v-for="(territory, id) in displayed_territories"
                :key="territory.fence_id"
                :options="{
                  paths: territory.path,
                  editable: false,
                  draggable: false,
                  fillColor: territory.color[4],
                  strokeColor: territory.color[4],
                  fillOpacity: 0.35,
                  strokeOpacity: 1
                }">
              </Polygon>
              <!-- H3 Grid -->
              <Polygon
                v-if="show_heatmap_layer"
                v-for="(hex, i) in h3_hexagons"
                :key="i"
                :options="{
                  paths: hex.boundary,
                  editable: false,
                  draggable: false,
                  fillColor: hex.color,
                  strokeColor: hex.color,
                  fillOpacity: 0.7,
                  strokeOpacity: 0.1
                }">
              </Polygon>
              <!-- Target fences polygons -->
              <div>
                <Polygon
                  v-for="(polygon, i) in data.polygons"
                  v-if="show_polygon_layer"
                  :key="polygon.path[0][0].lat"
                  :options="{
                    paths: polygon.path,
                    editable: false,
                    draggable: false,
                    fillColor: polygon.color[4],
                    strokeColor: polygon.color[4],
                    fillOpacity: 0.35,
                    strokeOpacity: 1
                  }">
                </Polygon>
              </div>
              <!-- Markers -->
              <template v-for="(polygon, i) of data.polygons">
                <AdvancedMarker
                  v-for="(path, j) of polygon.path"
                  v-if="show_marker_layer"
                  :key="path[0].lng"
                  :options="{
                    position: path[0],
                    gmpClickable: true,
                    draggable: false
                  }"
                  @click="toggleMarkerInfo(polygon, i, j)">
                  <!-- Info window -->
                  <InfoWindow
                    v-if="active_marker.polygon_index === i && active_marker.marker_index === j"
                    v-model="info_window_open"
                    :options="{
                      position: info_window_position,
                      content: info_options.content
                    }"
                  />
                </AdvancedMarker>
              </template>
            </GoogleMap>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import tooltip from '../../tooltip.vue';
  import {mapOptions, useGmap, latLngToCell, cellToBoundary} from '../../../../js/gmap-imports.js';
  import {reactive, ref, computed, watch} from 'vue';
  import {GoogleMap, InfoWindow, AdvancedMarker, Polygon} from 'vue3-google-map';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    show_tooltip: {
      type: Boolean,
      required: false
    }
  });

  const show_heatmap_layer = ref(true);
  const show_territory_layer = ref(true);
  const show_marker_layer = ref(true);
  const show_polygon_layer = ref(true);
  const info_window_position = ref(null);
  const info_window_open = ref(false);
  const h3_hexagons = ref([]);
  const active_dropdown_id = ref(null);

  const h3_colors = ref([
    'rgba(255, 230, 0, 1)',
    'rgba(255, 190, 0, 1)',
    'rgba(255, 160, 0, 1)',
    'rgba(255, 90, 0, 1)',
    'rgba(255, 10, 0, 1)'
  ]);


  const active_marker = reactive({});

  const info_options = reactive({
    content: '',
    pixelOffset: {
      width: 0,
      height: -35
    }
  });

  const map = reactive({
    options: {
      ...mapOptions
    },
    center: {
      lat: 45.539448,
      lng: -73.63254
    },
    zoom: 6
  });

  const {
    apiPromise,
    map_ref,
    mapZoomChanged,
    zoomTo,
    initializeGoogleMapsAPI
  } = useGmap(map);

  const displayed_territories = computed(() => {
    if (show_territory_layer.value && props.data.territories) {
      return props.data.territories.filter((territory) => territory.path.length);
    }

    return [];
  });

  const toggleMarkerInfo = (polygon, polygon_index, marker_index) => {
    active_marker.polygon_index = polygon_index;
    active_marker.marker_index = marker_index;
    info_window_position.value = polygon.path[marker_index][0];
    info_options.content = polygon.label;
    info_window_open.value = true;
    zoomTo({coordinates: polygon.path[marker_index]}, 15);
  };

  const generateH3Hexagons = () => {
    const hexagon_map = new Map();
    const heat_points = props.data.evening_heat_points;

    for (let i = 0; i < heat_points.length; i++) {
      const positions = heat_points[i].positions;

      for (let j = 0; j < positions.length; j++) {
        const pos = positions[j];
        const hex = latLngToCell(pos.location.lat, pos.location.lng, getH3ResolutionForZoom(map.zoom));

        if (!hexagon_map.has(hex)) {
          const boundary = cellToBoundary(hex).map((coord) => ({lat: coord[0], lng: coord[1]}));

          hexagon_map.set(hex, {boundary, weight: pos.weight});
        } else {
          const existing_hex = hexagon_map.get(hex);

          existing_hex.weight += pos.weight;
        }
      }
    }

    h3_hexagons.value = [];

    for (const hex of hexagon_map.values()) {
      const color_index = Math.max(0, Math.min(Math.floor(hex.weight) - 1, h3_colors.value.length - 1));

      hex.color = h3_colors.value[color_index] || h3_colors.value[h3_colors.value.length - 1];
      h3_hexagons.value.push(hex);
    }
  };

  const getH3ResolutionForZoom = (zoom) => {
    const zoom_levels =  [15, 13, 11, 10, 9, 7, 6, 4, 2];
    const resolutions = [10, 9, 8, 7, 6, 5, 4, 3, 2];

    for (let i = 0; i < zoom_levels.length; i++) {
      if (zoom >= zoom_levels[i]) {
        return resolutions[i];
      }
    }

    return 4;
  };

  const toggleDropdown = (id) => {
    active_dropdown_id.value = active_dropdown_id.value === id ? null : id;
  };

  watch(() => props.data, () => {
    if (props.data.polygons.length) {
      map.center = props.data.polygons[0].path[0][0];
      map.zoom = 11;
    }
  }, {
    immediate: true,
    deep: true
  });

  watch(
    () => map.zoom, () => {
      generateH3Hexagons();
    }
  );

  generateH3Hexagons();
  initializeGoogleMapsAPI();
</script>
