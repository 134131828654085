import {ref, watch, reactive} from 'vue';
import {Loader} from '@googlemaps/js-api-loader';

function completePolygon(polygon_being_edited_id, fences, drawing, validateStatusFences, completedFence, drawing_preview) {
  if (polygon_being_edited_id.value !== null) {
    const fence_being_edited = fences.value[polygon_being_edited_id.value];

    fence_being_edited.coordinates.push(drawing.value.coordinates);
    validateStatusFences(fence_being_edited);
    drawing.value = false;
    polygon_being_edited_id.value = null;
  } else {
    completedFence();
  }
  drawing_preview.value = false;
}

export function useGmap(
  map,
  fences,
  visible_fences,
  fence_type,
  fences_list,
  drawing,
  drawing_preview,
  fullscreen,
  polygon_being_edited_id,
  completedFence,
  validateStatusFences) {

  const loader = new Loader({
    apiKey: process.env.GOOGLE_MAPS_API_KEY,
    version: 'weekly',
    libraries: ['core', 'maps', 'marker', 'drawing', 'places', 'visualization'] // take as arg eventually ?
  });

  const api_promise = loader.load();

  const last_path_change =  ref(null);
  const google = ref(null);
  const map_ref = ref(null);

  const polygon_instances = reactive({});

  const mapClickListener = (event) => {
    const coords = event.latLng.toJSON();
    if (drawing.value) {
      addPoint(coords);
    } else {
      newPolygon(coords);
    }
  };

  const mapZoomChanged = () => {
    map.zoom = map_ref.value.map.getZoom();
  };

  const initializeGoogleMapsAPI = () => {
    watch(() => map_ref.value?.ready, (ready) => {
      if (!ready) return;

      if (map != null) {
        map_ref.value.map.setOptions(map.options);
      }
      google.value = map_ref.value.api;
    });
  };

  const mapBoundsChanged = () => {
    const bounds = map_ref.value?.map.getBounds();

    if (bounds) {
      map.bounds = bounds;
      if (fence_type === 'report') {
        visible_fences.value = fences_list.value;
      } else {
        visible_fences.value = fences_list.value.filter((fence) => {
          return fence.coordinates && fence.coordinates.find((coord) => bounds.contains(coord));
        });
      }
    }
  };

  const mapRightClickListener = () => {
    autoFinishPolygon();
  };

  const mapMouseMoveListener = (event) => {
    if (drawing.value && drawing.value.coordinates && drawing.value.coordinates.length >= 1) {
      const new_value = [{...drawing.value.coordinates[drawing.value.coordinates.length - 1]}, event.latLng.toJSON()];

      drawing_preview.value = new_value;
    }
  };

  const polylineClickListener = (event) => {
    if (drawing.value && event.vertex === 0) {
      completePolygon(polygon_being_edited_id, fences, drawing, validateStatusFences, completedFence, drawing_preview);
    }
  };

  const autocompleteSearchListener = (result) => {
    if (result.geometry && result.geometry.location) {
      map.center = result.geometry.location;
      map.marker = result.geometry.location;
      map.zoom = 18;
    }
  };

  const addPoint = (latLng) => {
    drawing_preview.value = false;
    drawing.value.coordinates.push(latLng);
  };

  const newPolygon = (latLng, label = '') => {
    drawing_preview.value = false;
    drawing.value = {
      label,
      status: 'drawing',
      coordinates: [latLng]
    };
  };

  const autoFinishPolygon = () => {
    if (drawing.value && drawing.value.coordinates.length >= 3) {
      completePolygon(polygon_being_edited_id, fences, drawing, validateStatusFences, completedFence, drawing_preview);
    }
  };

  const getPolygonOption = (status) => {
    const color = (status && status.includes('error')) ? '#d82c0d' : '#3498db';

    return {
      fillColor: color,
      fillOpacity: 0.5,
      strokeColor: color,
      strokeOpacity: 1
    };
  };

  const zoomTo = (fence, zoom = 17) => {
    if (fence.coordinates && fence.coordinates.length) {
      map.zoom = zoom;
      map.center = fence.coordinates[0];
    }
  };

  const openFullscreen = () => {
    fullscreen.value = true;
  };

  const closeFullscreen = () => {
    fullscreen.value = false;
  };

  /*
  TODO LATER, this is not the prod behaviour
  const polylineMouseUpListener = (event) => {

    // CATCH NEW POINTS
    console.log('polyline mouse up', event);
    if (event.vertex != undefined) {
      console.log('vertex moved', event.vertex, event.latLng.toJSON());
      drawing.value.coordinates[event.vertex] = event.latLng.toJSON();
    } else if (event.edge != undefined) {
      console.log('edge added', event.edge, event.latLng.toJSON());
      const new_coords = [...drawing.value.coordinates];
      new_coords.splice(event.edge + 1, 0, {...event.latLng.toJSON()});
      drawing.value.coordinates = new_coords;
    }

    //last_path_change.value = [...drawing.value.coordinates];
    // find la fence ?

    //console.log('Polyline drag end', polygon_being_edited_id, fence);
    //const polygon = polygon_instances[`${fence.fence_index}-${fence.polygon_index}`];
    //last_path_change.value = [...polygon.getPath().getArray()];

    //registerPolygonPathChange(fence.fence_index, fence.polygon_index);
  };*/

  const polygonMouseUpListener = (event, fence_index, polygon_index) => {
    registerPolygonPathChange(fence_index, polygon_index);
    updatePolygon(fence_index, polygon_index);
  };

  const registerPolygonPathChange = (fence_index, polygon_index) => {
    const polygon = polygon_instances[`${fence_index}-${polygon_index}`];

    last_path_change.value = [...polygon.getPath().getArray()];
  };

  const updatePolygon = (fence_index, polygon_index) => {
    const updated_fence = fences.value[fence_index];

    if (fence_type === 'report') {
      updated_fence.coordinates[polygon_index] = last_path_change.value.map((coord) => {
        return {
          lat: coord.lat(),
          lng: coord.lng()
        };
      });
    } else {
      updated_fence.coordinates = last_path_change.value.map((coord) => {
        return {
          lat: coord.lat(),
          lng: coord.lng()
        };
      });
    }

    validateStatusFences(updated_fence);
  };

  const polygonRightClickListener = (event, fence_index, polygon_index) => {
    const fence = fences.value[fence_index];
    const coords = fence_type === 'report' ? fence.coordinates[polygon_index] : fence.coordinates;

    if (coords.length > 3) {
      removePoint(event.vertex, coords);

      if (fence_type === 'report') {
        fence.coordinates[polygon_index] = coords;
      } else {
        fence.coordinates = coords;
      }

      validateStatusFences(fence);
    }
  };

  const removePoint = (point_index, points) => {
    points.splice(point_index, 1);
  };

  const storePolygonInstance = (el, fenceIndex, polygonIndex) => {
    const id = `${fenceIndex}-${polygonIndex}`;

    if (el && el.polygon) {
      polygon_instances[id] = el.polygon;
    } else if (!el) {
      delete polygon_instances[id];
    }
  };

  const getMapInitialCenter = () => {
    const org = JSON.parse(localStorage.user_data).organization;

    return org.preferences ? org.preferences.map_initial_center : null;
  };

  return {
    apiPromise: api_promise,
    google,
    map_ref,
    polygonMouseUpListener,
    storePolygonInstance,
    mapClickListener,
    mapZoomChanged,
    mapBoundsChanged,
    mapRightClickListener,
    mapMouseMoveListener,
    polylineClickListener,
    autocompleteSearchListener,
    addPoint,
    newPolygon,
    autoFinishPolygon,
    getPolygonOption,
    zoomTo,
    openFullscreen,
    closeFullscreen,
    polygonRightClickListener,
    removePoint,
    initializeGoogleMapsAPI,
    getMapInitialCenter,
  };
}
