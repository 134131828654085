<template>
  <div class="stats-by-domains-wrapper detailed-stats-wrapper col-lg-6">
    <div class="panel stats-by-domains">
      <div class="title">
        <b>{{ $t('campaign.stats.by_postal_code.title') }}</b>
        <aside class="actions">
          <a v-if="!is_loading" @click="csvExport" class="info">
            <i class="fas fa-file-excel"></i>
            <span class="d-none d-sm-inline">{{ $t('campaign.stats.export_csv') }}</span>
          </a>
        </aside>
      </div>

      <loading :loading="is_loading" :error="error" :is_panel="false"></loading>

      <div id="pc-grid-wrapper" class="grid-wrapper" v-if="!is_loading && !error">
        <ag-grid-vue
          style="height: 300px;"
          ref="pc_grid"
          class="ag-theme-balham ag-grid"
          :rowData="stats"
          :gridOptions="grid_options"
          @gridReady="onGridReady"
          @grid-size-changed="onGridSizeChanged">
        </ag-grid-vue>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {AgGridVue} from 'ag-grid-vue3';
  import {inject, getCurrentInstance} from 'vue';
  import {useCampaignStatsTable} from '../composables/campaignStatsTable';
  import {useI18n} from 'vue-i18n';

  const {appContext} = getCurrentInstance();
  const global_filters = appContext.config.globalProperties.$filters;

  const {t} = useI18n();

  const event_hub = inject('event-hub');

  const file_name = 'metriques_codes_postaux.csv';

  const getCustomColDefs = () => {
    return [{
      headerName: t('campaign.stats.by_postal_code.table_header_zip'),
      field: 'postal_code',
      minWidth: 120
    }, {
      headerName: t('campaign.stats.by_postal_code.table_header_city'),
      field: 'city',
      minWidth: 65
    }];
  };

  const getColDefs = () => {
    return [
      ...getCustomColDefs(),
      {
        headerName: t('campaign.list.summary_pannels.impressions'),
        field: 'impressions',
        minWidth: 80,
        width: 80,
        sort: 'desc',
        cellRenderer: (params) => `${global_filters.splitNumber(params.value)}`
      }, {
        headerName: t('campaign.list.summary_pannels.clicks'),
        field: 'clicks',
        minWidth: 40,
        width: 80,
        cellRenderer: (params) => `${global_filters.splitNumber(params.value)}`
      }, {
        headerName: t('campaign.list.summary_pannels.ctr'),
        field: 'ctr',
        minWidth: 40,
        width: 100,
        cellRenderer: (params) => `${params.value}%`
      }, {
        headerName: t('campaign.list.summary_pannels.total_spend'),
        field: 'total_spend',
        minWidth: 40,
        width: 100,
        cellRenderer: (params) => `${global_filters.splitNumber(params.value)}$ ${localStorage.currency}`
      }, {
        headerName: t('campaign.list.summary_pannels.cpc'),
        field: 'cpc',
        minWidth: 40,
        width: 80,
        cellRenderer: (params) => `${global_filters.splitNumber(params.value)}$ ${localStorage.currency}`
      }, {
        headerName: t('campaign.list.summary_pannels.cpm'),
        field: 'cpm',
        minWidth: 40,
        width: 80,
        cellRenderer: (params) => `${global_filters.splitNumber(params.value)}$ ${localStorage.currency}`
      }];
  };

  const props = defineProps({
    filters: {
      type: Object,
      required: true
    },
    campaign_id: {
      type: String,
      required: true
    },
    org_id: {
      type: String,
      required: true
    }
  });

  const {
    grid_html_id,
    grid_ref,
    stats_url,
    grid_options,
    stats,
    is_loading,
    error,
    csvExport,
    onGridReady,
    onGridSizeChanged,
    getStats
  } = useCampaignStatsTable(props, getCustomColDefs, file_name, getColDefs);


  const bindStatsToGrid = (data) => {
    stats.value = data.metrics ? data.metrics : [];
    event_hub.emit('fetched_pc', data.positions);
  };

  grid_html_id.value = 'pc-grid-wrapper';
  grid_ref.value = 'pc_grid';
  stats_url.value = `${process.env.API_URL}/campaigns/${props.campaign_id}/reports/postal_codes`;

  getStats(bindStatsToGrid);
</script>
