<template>
  <div>
    <div class="panel">
      <div class="title">
        <div class="row">
          <div class="col-6">
            <b>{{ $t('geoindicators.report.widgets.market_shares_visits.title') }}</b>
          </div>
          <div class="col-6 text-right align-self-center">
            <dropdown :is_button="false">
              <template #actions>
                <ul class="list">
                  <li @click="updatePeriod('by_month')">
                    <label class="radio">
                      {{ $t('geoindicators.report.widgets.market_shares_visits.per_month') }}
                      <input v-model="selected_period" value="by_month" type="radio">
                      <span></span>
                    </label>
                  </li>
                  <li @click="updatePeriod('by_quarter')">
                    <label class="radio">
                      {{ $t('geoindicators.report.widgets.market_shares_visits.per_quarter') }}
                      <input v-model="selected_period" value="by_quarter" type="radio">
                      <span></span>
                    </label>
                  </li>
                  <li @click="updatePeriod('by_year')">
                    <label class="radio">
                      {{ $t('geoindicators.report.widgets.market_shares_visits.per_year') }}
                      <input v-model="selected_period" value="by_year"type="radio">
                      <span></span>
                    </label>
                  </li>
                </ul>
              </template>
              <template #title>
                <a class="info">
                  {{ $t('geoindicators.report.widgets.market_shares_visits.per_period') }}
                  <i class="fas fa-caret-down"></i>
                </a>
              </template>
            </dropdown>
          </div>
        </div>
      </div>

      <apexchart
      height="350"
      type="line"
      :options="options"
      :series="series">
      </apexchart>
    </div>
  </div>
</template>


<script setup>
  import {useChartOptions} from '../chart-options';

  import {useGisWidget} from '../../../composables/gis_widget';
  import {parseDate, sortEntries} from '../../../../js/helpers';
  import {ref, computed} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {t, locale} = useI18n();
  const {DEFAULT_OPTIONS, MULTIPLE_PERCENT_TOOLTIP, LINE} = useChartOptions(t);

  const props = defineProps({
    data: {
      type: Object,
      required: true,
      default: () => ({})
    }
  });

  const {updatePeriod} = useGisWidget(props.data);

  const selected_period = ref('by_month');

  const series = computed(() => {
    const series = [];

    for (let i = 0; i < props.data.series.length; i++) {
      const ordered_values = sortEntries(props.data.series[i][selected_period.value]);

      series.push({
        name: props.data.labels[i],
        data: ordered_values.map(([date, visits_share]) => visits_share)
      });
    }
    return series;
  });

  const options = computed(() => {
    const categories = props.data.series.reduce((acc, fence_series) => {
      const ordered_values = sortEntries(fence_series[selected_period.value]);
      return ordered_values.map(([date, visits_share]) => {
        return selected_period.value === 'by_month' ? parseDate(new Date(date), 'month_year', locale.value) : date;
      });
    }, []);

    return {
      ...DEFAULT_OPTIONS,
      ...MULTIPLE_PERCENT_TOOLTIP,
      ...LINE,
      colors: props.data.colors.map((color) => color[3]),
      labels: props.data.labels,
      xaxis: {
        floating: false,
        categories: categories,
        tickAmount: 12,
        tooltip: {
          enabled: false
        }
      },
      annotations: {
        yaxis: [{
          y: props.data.avg,
          borderColor: '#969696',
          strokeDashArray: 2,
          label: {
            borderColor: '#969696',
            style: {
              color: '#fff',
              background: '#969696'
            },
            text: ' '
          }
        }]
      }
    };
  });

</script>
