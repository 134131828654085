<template>
  <div class="row justify-content-center">
    <div class="col-8 col-lg-4">
      <div class="text-center loading" v-if="loading && !error" :class="{ 'panel': is_panel }">
        <img src="assets/loading.gif"/>
        <p v-if="loading_message">{{ loading_message }}</p>
        <p v-else>{{ t('components.loading.msg') }}</p>
      </div>
      <div class="text-center" v-if="error" :class="{ 'panel danger': is_panel }">
        <div>{{ error_message ?? t('components.loading.title') }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {useI18n} from 'vue-i18n';

  const {t} = useI18n();

  defineProps({
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    is_panel: {
      type: Boolean,
      default: true
    },
    loading_message: {
      type: String,
      default: ''
    },
    error_message: {
      type: String,
      default: null
    }
  });
</script>
