<template>
  <i class="fas fa-circle" :class="setCampaignColor(params.data.status)" v-tooltip.right="getCampaignStatus(params.data.status, t)"></i>
</template>

<script setup>
  import {getCampaignStatus, setCampaignColor} from '../../../js/helpers';
  import {useI18n} from 'vue-i18n';

  const {t} = useI18n();

  defineProps({
    params: {
      type: Object,
      required: false
    }
  });
</script>
