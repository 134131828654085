<template>
  <div class="popup subaccount">
    <div class="container-sm" tabindex="0" @keydown="keyPressed($event, close)" ref="sub_account_popup">
      <div class="panel">

        <!-- Title -->
        <div class="title">
          <b>{{ sub_account._id ? $t('account.sub_accounts.popup.update_title') : $t('account.sub_accounts.popup.add_title') }}</b>
          <aside class="actions">
            <a class="info" @click="close">
              <i class="fas fa-times"></i>
            </a>
          </aside>
        </div>

        <!-- Content -->
        <div class="popup-content">
          <!-- Sub account details -->
          <div class="row space">
            <div class="col-md-6">
              <label for="org_name">{{ $t('account.sub_accounts.popup.name_label') }}</label>
              <input type="text" id="org_name" v-model="sub_account.name" :placeholder="$t('account.sub_accounts.popup.name_placeholder')">
            </div>
            <div class="col-md-6">
              <label for="org_website">{{ $t('account.sub_accounts.popup.website_label') }}</label>
              <input type="url" id="org_website" v-model="sub_account.website" :placeholder="$t('account.sub_accounts.popup.website_placeholder')">
            </div>
          </div>

          <div class="row space">
            <div class="col-md-6">
              <label for="org_category">{{ $t('account.sub_accounts.popup.business_category_label') }}</label>
              <select id="org_category" v-model="sub_account.business_category" required="required">
                <option value="" selected="selected" disabled="disabled">{{ $t('account.sub_accounts.popup.business_category_placeholder') }}</option>
                <optgroup :label="option.name" v-for="option in $tm('account.sub_accounts.popup.business_category_list')">
                  <option :value="o.value" v-for="o in option.options">{{ o.label }}</option>
                </optgroup>
                <option value="other">{{ $t('account.sub_accounts.popup.business_category_other') }}</option>
              </select>
            </div>
            <div class="col-md-6">
              <label class="radio">
                {{ $t('account.sub_accounts.popup.account_billable') }}
                <input type="radio" id="org_is_billable" :value="false" v-model="sub_account.is_billable">
                <span></span>
              </label>
              <label class="radio">
                {{ $t('account.sub_accounts.popup.subaccount_billable') }}
                <input type="radio" id="org_is_not_billable" :value="true" v-model="sub_account.is_billable">
                <span></span>
              </label>
            </div>
          </div>

          <!-- Billing details -->
          <div v-if="sub_account.is_billable">
            <div class="row space">
              <div class="col-md-12">
                <label for="org_email">{{ $t('account.sub_accounts.popup.email_label') }}</label>
                <input type="email" id="org_email" v-model="sub_account.accounting_email" :placeholder="$t('account.sub_accounts.popup.email_placeholder')">
              </div>
            </div>
            <div class="row space">
              <div class="col-md-12">
                <label for="org_address">{{ $t('account.sub_accounts.popup.address_label') }}</label>
                <input type="text" id="org_address" v-model="sub_account.address.street" :placeholder="$t('account.sub_accounts.popup.address_placeholder')">
              </div>
            </div>
            <div class="row space">
              <div class="col-md-6">
                <label for="org_city">{{ $t('account.sub_accounts.popup.city_label') }}</label>
                <input type="text" id="org_city" v-model="sub_account.address.city" :placeholder="$t('account.sub_accounts.popup.city_placeholder')">
              </div>
              <div class="col-md-6">
                <label for="org_postal_code">{{ $t('account.sub_accounts.popup.postal_code_label') }}</label>
                <input type="text" id="org_postal_code" v-model="sub_account.address.postal_code" :placeholder="$t('account.sub_accounts.popup.postal_code_placeholder')">
              </div>
            </div>
            <div class="row space">
              <div class="col-md-6">
                <label for="org_state">{{ $t('account.sub_accounts.popup.state_label') }}</label>
                <select id="org_state" required="required" v-model="sub_account.address.state">
                  <option value="" selected="selected" disabled="disabled">{{ $t('account.sub_accounts.popup.state_placeholder') }}</option>
                  <option v-for="key in filtered_options" :key="key.value" :value="key.value">{{ key.text }}</option>
                </select>
              </div>
              <div class="col-md-6">
                <label for="org_country">{{ $t('account.sub_accounts.popup.country_label') }}</label>
                <select id="org_country" required="required" v-model="sub_account.address.country">
                  <option value="" selected="selected" disabled="disabled">{{ $t('account.sub_accounts.popup.country_placeholder') }}</option>
                  <option value="Canada">{{ $t('zones_codes.can') }}</option>
                  <option value="United States of America">{{ $t('zones_codes.usa') }}</option>
                </select>
              </div>
            </div>
          </div>

          <!-- CPM details -->
          <div class="row space" v-else>
            <div class="col-md-6">
              <label
              for="org_cpm_banner">
                {{ $t('account.sub_accounts.popup.cpm_banner_label') }} ($ {{ org_markup.currency }})
                <i class="fas fa-question-circle tooltip-icon" v-tooltip.bottom="$t('account.sub_accounts.popup.cpm_banner_tooltip')"></i>
              </label>
              <input
                type="number"
                id="org_cpm_banner"
                :min="min_cpm_banner"
                v-model="sub_account.markup.self.banner.value"
                :placeholder="$t('account.sub_accounts.popup.cpm_banner_placeholder')">
            </div>
            <div class="col-md-6">
              <label
              for="org_cpm_video">
                {{ $t('account.sub_accounts.popup.cpm_video_label') }} ($ {{ org_markup.currency }})
                <i class="fas fa-question-circle tooltip-icon" v-tooltip.bottom="$t('account.sub_accounts.popup.cpm_video_tooltip')"></i>
              </label>
              <input
                type="number"
                id="org_cpm_video"
                :min="min_cpm_banner"
                v-model="sub_account.markup.self.video.value"
                :placeholder="$t('account.sub_accounts.popup.cpm_video_placeholder')">
            </div>
          </div>

          <!-- Alert -->
          <aside class="alert danger" v-if="errors.length">
            <p>
              <i class="fas fa-exclamation-triangle"></i>
              <b>{{ $t('account.sub_accounts.popup.error_title') }}</b>
            </p>
            <ul>
              <li v-for="error in errors">{{ error }}</li>
            </ul>
          </aside>

        </div>

        <!-- Footer -->
        <div class="footer">
          <aside class="actions">
            <a class="btn" @click="close">
              {{ $t('account.sub_accounts.popup.footer_cancel_btn') }}
            </a>
            <a class="btn success" @click="processSubAccount">
              {{ sub_account._id ? $t('account.sub_accounts.popup.footer_save_btn') : $t('account.sub_accounts.popup.footer_add_btn') }}
            </a>
          </aside>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
  import {inject, ref, reactive, computed, useTemplateRef, onMounted} from 'vue';
  import {useI18n} from 'vue-i18n';
  import {toast} from 'vue3-toastify';
  import {keyPressed} from '../../../js/helpers';

  const {t, tm} = useI18n();

  const event_hub = inject('event-hub');
  const sty = inject('sentry');
  const axios = inject('axios');

  const props = defineProps({
    company: {
      required: false,
      type: Object
    }
  });

  const emit = defineEmits(['update-popup']);
  const popup = useTemplateRef('sub_account_popup');

  const local_org = JSON.parse(localStorage.user_data).organization;
  const org_markup = ref(local_org.markup);
  const min_cpm_video = ref(local_org.markup.self.video.value);
  const min_cpm_banner = ref(local_org.markup.self.banner.value);
  const errors = ref([]);
  const is_loading = ref(false);

  const sub_account_data = Object.keys(props.company).length ? JSON.parse(JSON.stringify(props.company)) : {
    name: '',
    website: '',
    is_billable: false,
    business_category: '',
    accounting_email: '',
    address: {
      street: '',
      state: '',
      postal_code: '',
      country: '',
      city: ''
    },
    markup: local_org.markup
  };

  const sub_account = reactive(sub_account_data);

  const filtered_options = computed(() => {
    const country = sub_account.address.country;

    if (country === 'Canada' || country === 'United States of America') {
      const provinces_or_states = country === 'Canada' ? tm('provinces') : tm('states');

      return Object.entries(provinces_or_states).map(([key, value]) => ({
        value: key.toUpperCase(),
        text: value
      }));
    }

    return [];
  });

  const close = () => {
    emit('update-popup', false);
  };

  const updateSubAccount = (data) => {
    return axios.put(`${process.env.API_URL}/organizations`, data)
      .then((res) => toast.success(t('account.sub_accounts.popup.msg.save_success')));
  };

  const createSubAccount = (data) => {
    return axios.post(`${process.env.API_URL}/organizations`, data)
      .then((res) => {
        is_loading.value = false;
        toast.success(t('account.sub_accounts.popup.msg.create_success'), {type: 'success'});
      });
  };

  const processSubAccount = () => {
    is_loading.value = true;
    validateForm();

    if (!errors.value.length) {
      let sub_account_processing = createSubAccount;
      let error_msg = t('account.sub_accounts.popup.msg.create_error');
      const sb = JSON.parse(JSON.stringify(sub_account));

      let data = {
        name: sb.name,
        website: sb.website,
        is_billable: sb.is_billable,
        business_category: sb.business_category,
        accounting_email: sb.accounting_email,
        address: sb.address,
        markup: {
          ...sb.markup,
          self: {
            banner: {
              ...sb.markup.self.banner,
              value: sb.is_billable ? min_cpm_banner.value : Number(sb.markup.self.banner.value)
            },
            video: {
              ...sb.markup.self.video,
              value: sb.is_billable ?  min_cpm_video.value :  Number(sb.markup.self.video.value)
            }
          }
        }
      };

      if (sub_account._id) {
        sub_account_processing = updateSubAccount;
        error_msg = t('account.sub_accounts.popup.msg.update_error');
        data._id = sb._id;
        data.qbo_id = sb.qbo_id;
        data.external_id = sb.external_id;
      }

      sub_account_processing(data)
        .then(() => {
          is_loading.value = false;
          close();
          event_hub.emit('refresh-organizations');
        })
        .catch((error) => {
          toast.error(error_msg);
          is_loading.value = false;
          sty.setContext('data', data);
          sty.captureException(error);
        });
    } else {
      is_loading.value = false;
    }
  };

  const validateForm = () => {
    let field_errors = [];
    const form = sub_account;

    if (!form.name || !form.business_category) {
      field_errors.push(t('account.sub_accounts.popup.msg.errors_fields'));
    }

    if (!form.website || form.website === '' || !form.website.match(/(((https|http):\/\/)?(www\.)?)(?<website>[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z()]{2,10})\b/i)) {
      field_errors.push(t('account.sub_accounts.popup.msg.errors_website'));
    }

    const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (form.is_billable) {
      if (!email_regex.test(form.accounting_email)) {
        field_errors.push(t('account.sub_accounts.popup.msg.errors_email'));
      }

      if (!form.address.street || !form.address.state || !form.address.postal_code || !form.address.country) {
        field_errors.push(t('account.sub_accounts.popup.msg.errors_address'));
      }
    }

    if (!form.is_billable) {
      if (!form.markup.self.banner.value || !form.markup.self.video.value) {
        field_errors.push(t('account.sub_accounts.popup.msg.errors_cpm'));
      }

      if (form.markup.self.banner.value < min_cpm_banner || form.markup.self.video.value < min_cpm_video) {
        field_errors.push(t('account.sub_accounts.popup.msg.errors_invalid_cpm'));
      }
    }

    errors.value = field_errors;
  };

  onMounted(() => {
    popup.value.focus();
  });
</script>
