<template>
  <div class="stats">

    <header class="title-bar">
      <div class="row header-row">
        <div class="col-md-7 align-self-center">
          <div class="title">
            <h1>
              {{campaign_name}}
            </h1>
            <span
              class="badge"
              :class="{
                'primary': campaign_status.status === 'pending',
                'success': campaign_status.status === 'active',
                'info': campaign_status.status === 'ended',
                'danger': campaign_status.status === 'paused'
              }"
              v-if="!is_loading && !error">
              {{ campaign_status.label }}
            </span>
          </div>
        </div>
        <div class="col-md-3 align-self-center order-last order-lg-0" :class="{'offset-md-2': campaign_details.type === 'managed' || !show_actions}">
          <date-range-picker
            v-model:value="filters.dates"
            :disabled="is_loading"
            :placeholder="$t('components.datepicker.filter_by_period')">
          </date-range-picker>
        </div>
        <div class="col-md-2 align-self-center text-right" v-if="campaign_details.type === 'self' && show_actions">
          <dropdown>
            <template #actions>
              <ul class="list">
                <li @click="updateCampaignStatus('start')" :class="{'disabled': campaign_status.status !== 'paused' && campaign_status.status !== 'ended'}">
                  <i class="fas fa-play green"></i>
                  {{ $t('campaign.edit.header.actions.start') }}
                </li>
                <li @click="updateCampaignStatus('pause')" :class="{'disabled': campaign_status.status !== 'active'}">
                  <i class="fas fa-pause"></i>
                  {{ $t('campaign.edit.header.actions.pause') }}
                </li>
                <li @click="updateCampaignStatus('end')" :class="{'disabled': campaign_status.status !== 'active' && campaign_status.status !== 'paused'}">
                  <i class="fas fa-stop red"></i>
                  {{ $t('campaign.edit.header.actions.end') }}
                </li>
                <li class="separator"></li>
                <li>
                  <a :href="'/#/geomarketing/campaigns/edit/' + campaign_details._id">
                    <i class="fas fa-pencil-alt"></i>
                    {{ $t('campaign.stats.action_edit') }}
                  </a>
                </li>
              </ul>
            </template>
            <template #title>
              {{ $t('campaign.edit.header.actions.title') }}
              <i class="fas fa-caret-down"></i>
            </template>
          </dropdown>
        </div>
      </div>
    </header>

    <loading :loading="is_loading" :error="error" :error_message="error_message"></loading>

    <div class="stats-container" v-if="!is_loading && !error && campaign_status.status !== 'pending'">

      <!-- Breadcrumbs -->
      <div class="row breadcrumbs">
        <div class="col-6 d-none d-lg-inline">
          <a href="/#/geomarketing/campaigns">‹ {{ $t('campaign.stats.back_to_campaigns') }}</a>
        </div>
        <div class="col-lg-6 text-right">
          Type:
          <span v-if="campaign_details.type === 'self'">{{ $t('campaign.stats.self_serve_campaigns') }}</span>
          <span v-else-if="campaign_details.type === 'managed'">{{ $t('campaign.stats.managed_campaigns') }}</span>

          <span class="d-none d-lg-inline">&nbsp;•&nbsp;</span>
          <span class="d-lg-none d-inline"><br></span>
          ID: {{ campaign_details._id }}
        </div>
      </div>

      <!-- Disclaimer -->
      <!--
      <aside class="alert warning" v-if="!is_loading && !error && campaign_stats && (campaign_stats.cpm.label === 9 || campaign_stats.cpm.label === 16) && org_level !== 3">
        <i class="fa fa-times-circle"></i>
        {{ $t('campaign.list.new_pricing_disclaimer') }}
        <a href="https://propulso.io/fr/blogue/nouvelle_tarification_pour_le_geomarketing" target="_blank">{{ $t('campaign.list.new_pricing_cta') }}</a>
      </aside>
      -->

      <!-- Stats -->
      <div class="row">
        <div class="col-12">
          <div class="panel graph">
            <div id="chart">
              <apexchart
                ref="chart"
                height="300"
                :options="options"
                :series="series">
              </apexchart>
            </div>
          </div>
        </div>
      </div>
      <div class="row horizontal-row">
        <div class="col-md-3 col-6 animation-horizontal-row">
          <div class="panel indicator impressions" :class="{'active': legend_state.Impressions}" @click="toggleSerie('Impressions')">
            <h3>{{ $filters.splitNumber(campaign_stats.impressions.label) }}</h3>
            <p>{{ $t('campaign.list.summary_pannels.impressions') }}</p>
          </div>
        </div>
        <div class="col-md-3 col-6 animation-horizontal-row">
          <div class="panel indicator actions" :class="{'active': legend_state.Actions}" @click="toggleSerie('Actions')">
            <h3>{{ $filters.splitNumber(campaign_stats.actions.label) }}</h3>
            <p>{{ $t('campaign.list.summary_pannels.actions') }}</p>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="panel indicator clicks" :class="{'active': legend_state.Clicks}" @click="toggleSerie('Clicks')">
            <h3>{{ campaign_stats.clicks.label }}</h3>
            <p>{{ $t('campaign.list.summary_pannels.clicks') }}</p>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="panel indicator total" :class="{'active': legend_state.Total}" @click="toggleSerie('Total')">
            <h3>{{ $filters.formatCurrency(campaign_stats.total_spend.label, '$', 2, { symbolOnLeft: false })}}</h3>
            <p>{{ $t('campaign.list.summary_pannels.total_spend') }}</p>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="panel indicator ctr" :class="{'active': legend_state.CTR}" @click="toggleSerie('CTR')">
            <h3>{{ campaign_stats.ctr.label }}%</h3>
            <p>{{ $t('campaign.list.summary_pannels.ctr') }}</p>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="panel indicator cpa" :class="{'active': legend_state.CPA}" @click="toggleSerie('CPA')">
            <h3>{{ $filters.formatCurrency(campaign_stats.cpa.label, '$', 2, { symbolOnLeft: false })}}</h3>
            <p>{{ $t('campaign.list.summary_pannels.cpa') }}</p>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="panel indicator cpc" :class="{'active': legend_state.CPC}" @click="toggleSerie('CPC')">
            <h3>{{ $filters.formatCurrency(campaign_stats.cpc.label, '$', 2, { symbolOnLeft: false })}}</h3>
            <p>{{ $t('campaign.list.summary_pannels.cpc') }}</p>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="panel indicator cpm" :class="{'active': legend_state.CPM}" @click="toggleSerie('CPM')">
            <h3>{{ $filters.formatCurrency(campaign_stats.cpm.label, '$', 2, { symbolOnLeft: false })}}</h3>
            <p>{{ $t('campaign.list.summary_pannels.cpm') }}</p>
          </div>
        </div>
      </div>

      <div class="row recommendations" v-if="campaign_details.type !== 'managed'">
        <div class="col-md-6">
          <div class="panel">
            <div class="title">
              <b>{{ $t('campaign.stats.recommendations.budget_monitoring') }}</b>
            </div>

            <div class="row">
              <div class="col-8">
                <div class="progress-bar-container">
                  <div class="progress">
                    <div
                      class="progress-bar no-animation"
                      :class="setPacingColor(pacing)"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="{width: pacing + '%'}">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <p><span class="pacing">{{ pacing }}%</span> {{ $t('campaign.stats.recommendations.of_budget') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="panel">
            <div class="title">
              <b>{{ `${$t('campaign.stats.recommendations.budget_recommendations')} ` }}</b>
              <i class="fas fa-question-circle tooltip-icon" v-tooltip.top="$t('campaign.stats.recommendations.recommendations_tooltip')"></i>
            </div>
            <p class="recommendation">{{ recommendations }}</p>
          </div>
        </div>
      </div>

      <div class="detailed-stats-container">
        <div class="row">
          <stats-by-ad :filters="filters"
                       :campaign_id="route.params.campaign_id"
                       :org_id="route.params.org_id">
          </stats-by-ad>
          <stats-by-domain :filters="filters"
                           :campaign_id="route.params.campaign_id"
                           :org_id="route.params.org_id">
          </stats-by-domain>
        </div>
        <div class="row">
          <stats-by-fences :filters="filters"
                           :campaign_id="route.params.campaign_id"
                           :org_id="route.params.org_id">
          </stats-by-fences>
          <stats-by-pc :filters="filters"
                       :campaign_id="route.params.campaign_id"
                       :org_id="route.params.org_id">
          </stats-by-pc>
        </div>
      </div>

      <insights></insights>
      <heatmap></heatmap>

      <aside class="alert danger" v-if="fields_errors.length">
        <p>
          <i class="fas fa-exclamation-triangle"></i>
          <b>{{ $t('campaign.edit.fields_errors.title') }}</b>
        </p>
        <ul>
          <li v-for="error in fields_errors">{{ error }}</li>
        </ul>
      </aside>

      <p class="text-center disclaimer">
        <i>
          {{ $t('campaign.list.disclaimer') }} $ {{ currency }}
        </i>
      </p>
    </div>

    <!-- Pending -->
    <campaign-pending v-if="campaign_status.status === 'pending'"></campaign-pending>

  </div>
</template>

<script setup>
  import dateRangePicker from '../components/dateRangePicker.vue';
  import querystring from 'querystring';
  import campaignPending from '../components/campaignPending.vue';
  import statsByDomain from '../components/campaignStatsByDomain.vue';
  import statsByPc from '../components/campaignStatsByPostalCode.vue';
  import statsByFences from '../components/campaignStatsByFence.vue';
  import statsByAd from '../components/campaignStatsByAd.vue';
  import insights from '../components/insights.vue';
  import heatmap from '../components/heatmap.vue';
  import {chartOptions, chartSeries} from '../../js/init';
  import {useChartOptions} from '../components/reports/chart-options';
  import {getDateRange, setPacingColor, parseDate, getCampaignStatus} from '../../js/helpers';
  import {useWhiteLabel} from '../composables/whitelabel';
  import {useCampaign} from '../composables/campaign';
  import {ref, computed, reactive, watch, inject, nextTick} from 'vue';
  import {useI18n} from 'vue-i18n';
  import {toast} from 'vue3-toastify';
  import {useRoute} from 'vue-router';

  const {t} = useI18n();
  const route = useRoute();
  const {show_actions} = useWhiteLabel();
  const {CAMPAIGN_STATS_TOOLTIP} = useChartOptions(t);

  const sty = inject('sentry');
  const axios = inject('axios');

  const campaign_name = ref(t('campaign.stats.title'));
  const currency = ref(localStorage.currency);
  const error = ref(false);
  const error_message = ref('');
  const is_loading = ref(false);
  const pacing = ref(0);
  const is_pacing_computable = ref(false);
  const series = ref(chartSeries);
  const fields_errors = ref([]);
  const chart = ref(null);

  const campaign = reactive({});
  const {validateCampaign} = useCampaign(campaign);

  const campaign_status = reactive({});
  const campaign_details = reactive({});

  const campaign_stats = reactive({
    impressions: {},
    clicks: {},
    ctr: {},
    cpm: {},
    cpc: {},
    cpa: {},
    total: {},
    actions: {},
    total_spend: {}
  });

  const legend_state = reactive({
    Impressions: true,
    Clicks: true,
    CTR: true,
    CPM: true,
    CPC: true,
    CPA: true,
    Total: true,
    Actions: true
  });

  const filters = reactive({
    dates: (localStorage.filter_start_date && localStorage.filter_end_date) ? {
      start: new Date(localStorage.filter_start_date),
      end: new Date(localStorage.filter_end_date)
    } : {
      start: getDateRange('all_time')[0],
      end: getDateRange('all_time')[1]
    }
  });

  const recommendations = computed(() => {
    if (pacing.value <= 85) {
      return t('campaign.stats.recommendations.add_fences');
    } else if (pacing.value > 85 && pacing.value <= 95) {
      return t('campaign.stats.recommendations.adequat_budget');
    } else if (pacing.value > 95) {
      return t('campaign.stats.recommendations.add_budget');
    }
  });

  const options = computed(() => {
    return {
      ...chartOptions,
      tooltip: {
        ...CAMPAIGN_STATS_TOOLTIP.tooltip
      },
      markers: {
        ...chartOptions.markers,
        hover: {
          size: 0
        }
      }
    };
  });

  // Enable when disclaimer is uncommented
  // const org_level = computed(() => {
  //   return JSON.parse(localStorage.getItem('user_data')).organization.org_level;
  // });

  const getCampaignsStats = () => {
    is_loading.value = true;
    error.value = false;

    const start = parseDate(filters.dates.start);
    const end = parseDate(filters.dates.end);
    const query = {
      start_date: start,
      end_date: end,
      client_id: route.params.org_id
    };

    const url = `${process.env.API_URL}/campaigns/${route.params.campaign_id}/stats?${querystring.stringify(query)}`;

    return axios.get(url)
      .then((res) => {
        campaign_details.type = res.data.type;
        campaign_details._id = res.data._id;
        Object.assign(campaign_stats, res.data.stats);
        campaign_name.value = res.data.name;
        pacing.value = res.data.pacing;
        is_pacing_computable.value = res.data.is_pacing_computable;
        campaign_status.status = res.data.status.toLowerCase();
        campaign_status.label = boundGetStatus(res.data.status);

        document.title += ` - ${campaign_name.value}`;
        nextTick(() => {
          bindStatsToSeries();
          hideSeries();
        });
        is_loading.value = false;
      })
      .catch((err) => {
        is_loading.value = false;
        error.value = true;

        if (err.status === 404) {
          error_message.value = t('campaign.list.table.error_no_data');
        } else {
          error_message.value = t('campaign.list.table.error_load_data');
          toast.error(error_message.value);

          sty.setContext('data', {
            campaign_details: campaign_details,
            filters: filters
          });
          sty.captureException(err);
        }
      });
  };

  const getCampaign = () => {
    is_loading.value = true;
    error.value = false;

    axios.get(`${process.env.API_URL}/campaigns/${route.params.campaign_id}`)
      .then((res) => {
        Object.assign(campaign, res.data);
        is_loading.value = false;
      })
      .catch((err) => {
        error.value = true;
        is_loading.value = false;
        toast.error(t('campaign.edit.fetch_error'));
        sty.setContext('data', campaign);
        sty.captureException(err);
      });
  };

  const waitForChart = () => {
    return new Promise((resolve) => {
      if (chart.value) {
        resolve(chart.value);
      } else {
        const unwatch = watch(chart, (newVal) => {
          if (newVal) {
            unwatch();
            resolve(newVal);
          }
        });
      }
    });
  };

  const bindStatsToSeries = async() => {
    await nextTick();
    await waitForChart();
    chart.value.updateOptions({labels: campaign_stats.stat_dates});

    for (const serie of series.value) {
      if (serie.fieldKey) {
        serie.data = campaign_stats[serie.fieldKey].values;
      }
    }
  };

  const hideSeries = () => {
    nextTick(() => {
      hideSingleSerie('CTR');
      hideSingleSerie('CPA');
      hideSingleSerie('CPC');
      hideSingleSerie('CPM');
    });
  };

  const hideSingleSerie = async(serie_name) => {
    await nextTick();
    await waitForChart();
    chart.value.hideSeries(serie_name);
    legend_state[serie_name] = false;
  };

  const toggleSerie = (serie_name) => {
    chart.value.toggleSeries(serie_name);
    legend_state[serie_name] = !legend_state[serie_name];
  };

  const updateCampaignStatus = (action) => {
    validateCampaign();

    if (fields_errors.value.length) {
      toast.error(t('campaign.edit.status_update.error'));
    } else {
      is_loading.value = true;
      error.value = false;

      const url = `${process.env.API_URL}/campaigns/${campaign_details._id}/${action}`;

      return axios.put(url)
        .then((res) => {
          return getCampaignsStats();
        })
        .then((res) => {
          is_loading.value = false;
          toast.success(t('campaign.stats.msg_status_updated'));
        })
        .catch((error) => {
          error.value = true;
          is_loading.value = false;
          toast.error(t('campaign.stats.error_update_campaign'));
          sty.setContext('data', {
            campaign_stats: campaign_stats,
            campaign_details: campaign_details,
            filters: filters,
            campaign_status: campaign_status
          });
          sty.captureException(error);
        });
    }
  };

  const boundGetStatus = (args) => {
    return getCampaignStatus(args, t);
  };


  watch(filters, () => {
    getCampaignsStats().then(() => {
      localStorage.filter_start_date = filters.dates.start;
      localStorage.filter_end_date = filters.dates.end;
    });
  }, {
    deep: true
  });

  getCampaignsStats();
  getCampaign();
</script>
