<template>
  <div>
    <div class="popup">
      <div class="container">
        <div class="panel">
          <!-- Title -->
          <div class="title">
            <b>{{ $t('credit_cards.popup.modify_title') }}</b>
            <aside class="actions">
              <a class="info" @click="close">
                <i class="fas fa-times"></i>
              </a>
            </aside>
          </div>

          <loading :loading="is_loading" :is_panel="false"></loading>

          <div v-if="!is_loading">
            <!-- Form -->
            <div class="row justify-content-center">
              <div class="col-md-6">
                <organizations-select
                  v-model:value="credit_card.organization_id"
                  :placeholder="$t('credit_cards.popup.select_organization')"
                  :disabled="true">
                </organizations-select>
                <input type="text" :placeholder="$t('credit_cards.popup.card_holder_name')" v-model="credit_card.credit_card.holder_name">
                <input type="text" v-model="credit_card.credit_card.label" disabled>
                <div class="row">
                  <div class="col-md-4">
                    <select v-model="credit_card.credit_card.expiration.month">
                      <option :value="null" :label="$t('credit_cards.popup.card_expiration_month')" selected disabled></option>
                      <option :value="1">{{ $t('credit_cards.popup.expiration_months.january') }}</option>
                      <option :value="2">{{ $t('credit_cards.popup.expiration_months.february') }}</option>
                      <option :value="3">{{ $t('credit_cards.popup.expiration_months.march') }}</option>
                      <option :value="4">{{ $t('credit_cards.popup.expiration_months.april') }}</option>
                      <option :value="5">{{ $t('credit_cards.popup.expiration_months.may') }}</option>
                      <option :value="6">{{ $t('credit_cards.popup.expiration_months.june') }}</option>
                      <option :value="7">{{ $t('credit_cards.popup.expiration_months.july') }}</option>
                      <option :value="8">{{ $t('credit_cards.popup.expiration_months.august') }}</option>
                      <option :value="9">{{ $t('credit_cards.popup.expiration_months.september') }}</option>
                      <option :value="10">{{ $t('credit_cards.popup.expiration_months.october') }}</option>
                      <option :value="11">{{ $t('credit_cards.popup.expiration_months.november') }}</option>
                      <option :value="12">{{ $t('credit_cards.popup.expiration_months.december') }}</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <select id="exp-years" v-model="credit_card.credit_card.expiration.year">
                      <option :value="null" :label="$t('credit_cards.popup.card_expiration_year')" selected disabled></option>
                      <option v-for="year in expiration_years" :value="year">{{ year }}</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <input type="text" placeholder="CVV" v-model="credit_card.credit_card.cvv">
                  </div>
                  <div class="col-md-8">
                    <input type="text" :placeholder="$t('credit_cards.popup.card_address_street')" maxlength="30" v-model="credit_card.address.street">
                  </div>
                  <div class="col-md-4">
                    <input type="text" :placeholder="$t('credit_cards.popup.card_postal_code')" maxlength="10" v-model="credit_card.address.postal_code">
                  </div>
                </div>
              </div>
            </div>
            <!-- Errors -->
            <aside class="alert danger" v-if="errors.length">
              <p>
                <i class="fas fa-exclamation-triangle"></i>
                <b>{{ $t('credit_cards.popup.errors.title') }}</b>
              </p>
              <ul>
                <li v-for="error in errors">{{ error }}</li>
              </ul>
            </aside>
          </div>
          <!-- Actions footer -->
          <div v-if="!is_loading" class="footer">
            <aside class="actions">
              <a class="btn" @click="close">
                {{ $t('credit_cards.popup.footer_btn_cancel') }}
              </a>
              <a class="btn success" @click="updateCreditCard">
                {{ $t('credit_cards.popup.footer_btn_add') }}
              </a>
            </aside>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
  import {reactive, ref, inject, computed} from 'vue';
  import organizationsSelect from '../organizationsSelect.vue';
  import {toast} from 'vue3-toastify';
  import {useI18n} from 'vue-i18n';

  const {t} = useI18n();

  const event_hub = inject('event-hub');
  const sty = inject('sentry');
  const axios = inject('axios');

  const props = defineProps({
    organisation : {
      type: Object,
      required: true
    }
  });

  const emit = defineEmits(['update-popup']);

  const is_loading = ref(false);
  const errors = ref(false);

  const credit_card = reactive({
    organization_id: props.organisation.organization._id,
    type: 'credit-card',
    credit_card: {
      provider: props.organisation.credit_card.provider,
      label: props.organisation.credit_card.label,
      number: null,
      expiration: {
        month: props.organisation.credit_card.expiration.month,
        year: props.organisation.credit_card.expiration.year
      },
      cvv: null,
      holder_name: props.organisation.credit_card.holder_name
    },
    address: {
      street: props.organisation.address.street,
      postal_code: props.organisation.address.postal_code
    }
  });

  const expiration_years = computed(() => {
    const current_year = new Date().getFullYear();

    return Array.from({length: 26}, (_, i) => current_year + i);
  });

  const close = () => {
    emit('update-popup', false);
  };

  const validateForm = () => {
    let errors = [];
    const form = credit_card;

    if (!form.credit_card.holder_name || form.credit_card.holder_name.length < 5) {
      errors.push(t('credit_cards.popup.errors.credit_card_holder'));
    }

    if (!form.credit_card.expiration.month || !form.credit_card.expiration.year) {
      errors.push(t('credit_cards.popup.errors.credit_card_expiration_month'));
    }

    if (!form.credit_card.cvv || form.credit_card.cvv.length < 2 || form.credit_card.cvv.length > 3) {
      errors.push(t('credit_cards.popup.errors.credit_card_cvv'));
    }

    if (
      !form.address.street ||
      form.address.street.length < 5 ||
      form.address.street.length > 30 ||
      !form.address.postal_code ||
      form.address.postal_code.length < 5 ||
      form.address.postal_code.length > 10
    ) {
      errors.push(t('credit_cards.popup.errors.credit_card_address'));
    }

    return errors;
  };

  const updateCreditCard = () => {
    errors.value = validateForm();

    if (!errors.value.length) {
      is_loading.value = true;

      const data = {
        organization_id: credit_card.organization_id,
        credit_card: {
          expiration: credit_card.credit_card.expiration,
          cvv: credit_card.credit_card.cvv,
          holder_name: credit_card.credit_card.holder_name
        },
        address: credit_card.address
      };

      axios.put(`${process.env.API_URL}/payments/profile`, data)
        .then(() => {
          is_loading.value = false;
          close();
          event_hub.emit('refresh-credit-cards');
          toast.success(t('credit_cards.popup.modify_success_msg_card'));
        })
        .catch((err) => {
          errors.value = true;
          is_loading.value = false;
          const toast_error_message = err.status === 400 ? t('credit_cards.popup.errors.invalid_informations') : t('credit_cards.popup.errors.creating_error');

          sty.setContext('data', data);
          sty.captureException(err);
          toast.error(toast_error_message);
        });
    }
  };

  const updateOrganization = (org) => {
    credit_card.organization_id = org._id;
  };

  event_hub.on('organization_update', updateOrganization);
</script>
