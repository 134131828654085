<template>
  <div>
    <div class="title-separator">
      <h3>{{ $t('geoindicators.creation.basic_informations_section_title')}}</h3>
      <hr>
    </div>

    <basic-informations
      v-model:value="local_data"
      :license="license">
    </basic-informations>

    <time-parting
      v-model:value="local_data"
      :allow="local_data.limits.allow_day_parting"
      :license="license">
    </time-parting>

    <fences-builder
      v-model:value="origin"
      :title="$t('geoindicators.creation.origin_fences_title')"
      :fence_type="'report'"
      :show_tooltip="false"
      :export_polygons_data="{ id: local_data._id, name: local_data.name }"
      :product="'economic'"
      :fences_limit="max_origin"
      :coverage_area="coverage_area"
      :economic_fence_type="'origin'"
      :license="license">
    </fences-builder>

    <div class="panel">
      <div class="title">
        <b>{{ $t('geoindicators.creation.category_title') }}</b>
        <aside class="actions">
          <a class="info" @click="addCategory">
            <i class="fas fa-plus"></i>
            {{ $t('geoindicators.creation.add_category') }}
          </a>
        </aside>
      </div>
      <!-- Categories -->
      <div class="categories" v-for="(category, i) in categories">
        <div class="row">
          <div class="col-7 align-self-center">
            <label>{{ $t('geoindicators.creation.category_name') }}</label>
            <input type="text" :value="category.name" :placeholder="$t('geoindicators.creation.category')" @input="updateCategory($event, category, i, category.value)">
          </div>
          <div class="col-4 align-self-center">
            <label>{{ $t('geoindicators.creation.category_avg_purchase') }}</label>
            <input type="number" :value="category.value" min="1" @input="updateAvgPurchase($event, category, i, category.value)">
          </div>
          <div class="col-1 align-self-center actions" v-if="categories.length > 1">
            <a class="info" @click="removeCategory(i)">
              <i class="fas fa-trash-alt"></i>
            </a>
          </div>
        </div>
        <div class="divider" v-if="i < categories.length - 1"></div>
      </div>

      <aside class="alert warning" v-if="map_disabled">
        <i class="fa fa-exclamation-circle"></i> {{ $t('campaign.edit.fences_panel.click_to_draw_disabled') }}
      </aside>
    </div>

    <!-- Report fences -->
    <fences-builder
      v-model:value="destination"
      :title="$t('geoindicators.creation.destination_fences_title')"
      :fence_type="'report'"
      :show_tooltip="false"
      :coverage_area="coverage_area"
      :export_polygons_data="{ id: local_data.id, name: local_data.name }"
      :product="'economic'"
      :categories="categories"
      :map_disabled="map_disabled"
      :fences_limit="max_destination"
      :economic_fence_type="'destination'"
      :license="license">
    </fences-builder>

    <div class="title-separator">
      <h3>{{ $t('geoindicators.creation.parameters_section_title')}}</h3>
      <hr>
    </div>

    <div class="panel">
      <div class="title">
        <b>{{ $t('geoindicators.creation.time_period_title') }}</b>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <label>{{$t('geoindicators.creation.before')}}</label>
          <div class="row align-items-center">
            <div class="col-4">
              <input
                v-tooltip.bottom="!is_before_valid ? {
                  content: t('geoindicators.creation.errors.before_delay_out_of_range',
                  {
                    min: product.min_time,
                    max: product.max_time
                  }),
                  classes: 'error'
                  }: ''"
                :class="{'invalid-field': !is_before_valid}"
                type="number"
                v-model="delay.before">
            </div>
            <div class="col-6">
              <p>{{ $t('geoindicators.creation.hours') }}</p>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <label>{{ $t('geoindicators.creation.after') }}</label>
          <div class="row align-items-center">
            <div class="col-4">
              <input
                v-tooltip.bottom="!is_after_valid ? {
                  content: t('geoindicators.creation.errors.after_delay_out_of_range',
                  {
                    min: product.min_time,
                    max: product.max_time
                  }),
                  classes: 'error'
                  }: ''"
                :class="{'invalid-field': !is_after_valid}"
                type="number"
                v-model="delay.after">
            </div>
            <div class="col-6">
              <p>{{ $t('geoindicators.creation.hours') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import basicInformations from '../basicInformations.vue';
  import fencesBuilder from '../../../fencesBuilder.vue';
  import timeParting from '../filters/timeParting.vue';
  import validato from '@propulso/validato';
  import {useI18n} from 'vue-i18n';
  import {ref, reactive, inject, computed, watch} from 'vue';

  const {t} = useI18n();

  const event_hub = inject('event-hub');

  const props = defineProps({
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    destination_fences: {
      type: Array,
      required: true,
      default: () => []
    },
    origin_fences: {
      type: Array,
      required: true,
      default: () => []
    },
    coverage_area: {
      type: Array,
      required: false,
      default: () => []
    },
    license: {
      type: Object,
      required: true,
      default: () => ({})
    }
  });

  const local_data = ref(JSON.parse(JSON.stringify(props.data)));
  const destination = ref(props.destination_fences);
  const origin = ref(props.origin_fences);
  const product = ref(validato.products[props.data.type]);

  const categories = reactive(JSON.parse(JSON.stringify(props.data.categories)));

  const delay = reactive({
    before: props.data.before_delay,
    after: props.data.after_delay
  });

  const map_disabled = computed(() => {
    return !categories.length || categories.length === 1 && categories[0].name === '';
  });

  const is_before_valid = computed(() => {
    return validato.isFieldValid('before_delay', props.license, delay.before);
  });

  const is_after_valid = computed(() => {
    return validato.isFieldValid('after_delay', props.license, delay.after);
  });

  const max_origin = computed(() => {
    return props.license.limits.report_max_polygon - destination.value.length;
  });

  const max_destination = computed(() => {
    return props.license.limits.report_max_polygon - origin.value.length;
  });

  const addCategory = () => {
    categories.push({
      name: '',
      value: 5
    });
  };

  const updateCategory = (event, category, i, avg_purchase) => {
    const old = categories[i].name;

    const new_val = {
      name: event.target.value,
      value: avg_purchase || 1
    };

    event_hub.emit('update_fence_category', {old, newVal: new_val, i});
  };

  const updateAvgPurchase = (event, category, i, avg_purchase) => {
    const old = categories[i].name;

    const new_val = {
      name: categories[i].name,
      value: parseInt(event.target.value) || 1
    };

    event_hub.emit('update_fence_category', {old, newVal: new_val, i});
  };

  const removeCategory = (category_index) => {
    if (categories.length > 1) {
      categories.splice(category_index, 1);
      event_hub.emit('delete_fence_category');
    }
  };

  watch(delay, (update) => {
    event_hub.emit('delay_update', update);
  }, {
    deep: true
  });
</script>
