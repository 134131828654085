<template>
  <div class="row">
    <div class="col-12">
      <div class="panel">
        <div class="title">
          <div class="row">
            <div class="col-9">
              <b>
                {{ $t('geoindicators.report.widgets.visits_duration_daytime_distribution.title') }}
                <tooltip
                  :text="$t('geoindicators.report.widgets.visits_duration_daytime_distribution.tooltip')"
                  :show_tooltip="show_tooltip">
                </tooltip>
              </b>
            </div>
            <div class="col-3 text-right align-self-center">
              <dropdown :is_button="false">
                <template #actions>
                  <ul class="list">
                    <li @click="updatePeriod('by_day')">
                      <label class="radio">
                        {{ $t('geoindicators.report.widgets.visits_count.per_day') }}
                        <input v-model="selected_period" value="by_day" type="radio">
                        <span></span>
                      </label>
                    </li>
                    <li @click="updatePeriod('by_month')">
                      <label class="radio">
                        {{ $t('geoindicators.report.widgets.visits_count.per_month') }}
                        <input v-model="selected_period" value="by_month" type="radio">
                        <span></span>
                      </label>
                    </li>
                    <li @click="updatePeriod('by_year')">
                      <label class="radio">
                        {{ $t('geoindicators.report.widgets.visits_count.per_year') }}
                        <input v-model="selected_period" value="by_year" type="radio">
                        <span></span>
                      </label>
                    </li>
                  </ul>
                </template>
                <template #title>
                  <a class="info">
                    {{ $t('geoindicators.report.widgets.visits_count.per_period') }}
                    <i class="fas fa-caret-down"></i>
                  </a>
                </template>
              </dropdown>
            </div>
          </div>
        </div>

        <apexchart
        height="350"
        type="bar"
        :options="options"
        :series="series">
        </apexchart>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {useChartOptions} from '../chart-options';
  import tooltip from '../../tooltip.vue';

  import {useGisWidget} from '../../../composables/gis_widget';
  import {sortEntries} from '../../../../js/helpers';
  import {ref, computed} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {t, tm} = useI18n();
  const {DEFAULT_OPTIONS, STACKED} = useChartOptions(t);

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    show_tooltip: {
      type: Boolean,
      required: false
    }
  });

  const {updatePeriod} = useGisWidget(props.data);

  const selected_period = ref('by_month');

  const ordered_values = computed(() => {
    return sortEntries(props.data[selected_period.value]);
  });

  const series = computed(() => {
    return [
      {
        name: t('geoindicators.report.widgets.visits_duration_daytime_distribution.evening'),
        data: ordered_values.value.map(([date, duration]) => duration.evening)
      },
      {
        name: t('geoindicators.report.widgets.visits_duration_daytime_distribution.afternoon'),
        data: ordered_values.value.map(([date, duration]) => duration.afternoon)
      },
      {
        name: t('geoindicators.report.widgets.visits_duration_daytime_distribution.morning'),
        data: ordered_values.value.map(([date, duration]) => duration.morning)
      }];
  });

  const options = computed(() => {
    let locale_categories = [];
    let categories = '';

    switch (selected_period.value) {
      case 'by_day':
        locale_categories = tm('geoindicators.report.widgets.visits_distribution.days_categories');
        categories = ordered_values.value.map(([date, nb_visits]) => locale_categories[date]);
        break;
      case 'by_month':
        locale_categories = tm('geoindicators.report.widgets.visits_distribution.months_categories');
        categories = ordered_values.value.map(([date, nb_visits]) => locale_categories[date]);
        break;
      case 'by_year':
        categories = ordered_values.value.map(([date, nb_visits]) => date);
        break;
    }

    return {
      ...DEFAULT_OPTIONS,
      ...STACKED,
      xaxis: {
        categories: categories,
        floating: false
      }
    };
  });

</script>
