<template>
  <div class="panel no-padding grid visits-origin" id="grid-wrapper" v-if="row_data.length">
    <div class="title" v-if="show_title">
      <b>{{ $t('geoindicators.report.tourism.details.table_title') }}</b>
    </div>

    <div class="tabs">
      <a class="tab"
        :class="{'active': selected === key }"
        v-for="[key, value] of Object.entries(categories)"
        @click="updateSelected(key)">
        {{ value }}
      </a>
    </div>

    <loading :loading="is_loading" :is_panel="false"></loading>

    <ag-grid-vue
      v-if="!is_loading"
      ref="detailed-grid"
      class="ag-theme-balham ag-grid"
      :rowData="row_data"
      :gridOptions="grid_options"
      @gridReady="onGridReady">
    </ag-grid-vue>
  </div>
</template>

<script setup>
  import {AgGridVue} from 'ag-grid-vue3';
  import {ref, reactive, getCurrentInstance} from 'vue';
  import {useI18n} from 'vue-i18n';
  const {t} = useI18n();

  const {appContext} = getCurrentInstance();
  const global_filters = appContext.config.globalProperties.$filters;

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    show_title: {
      type: Boolean,
      required: false,
      default: false
    }
  });

  const is_loading = ref(false);
  const selected = ref('countries');
  const grid_api = ref(null);
  const row_data = ref([]);
  const row_headers = ref([]);

  const blanks_filter = ref([
    {
      displayKey: 'blanks',
      displayName: t('geoindicators.report.tourism.details.all'),
      test: function(filterValue, cellValue) {
        return cellValue === "" || cellValue !== "";
      },
      hideFilterInput: true
    },
    {
      displayKey: 'no_blanks',
      displayName: t('geoindicators.report.tourism.details.no_blanks'),
      test: function(filterValue, cellValue) {
        return cellValue !== "";
      },
      hideFilterInput: true
    }
  ]);

  const categories = reactive({
    countries: t('geoindicators.report.tourism.details.country'),
    provinces: t('geoindicators.report.tourism.details.province'),
    regions: t('geoindicators.report.tourism.details.region'),
    cities: t('geoindicators.report.tourism.details.city'),
    postal_codes: t('geoindicators.report.tourism.details.postal_code')
  });

  const grid_options = reactive({
    columnDefs: [],
    defaultColDef: {
      resizable: true,
      sortable: true
    },
    domLayout: 'autoHeight',
    rowHeight: 35,
    headerHeight: 50,
    multiSortKey: 'ctrl',
    localeText: {
      contains: t('geoindicators.report.tourism.details.contains'),
      clearFilter: t('geoindicators.report.tourism.details.clearFilter'),
      filterOoo: t('geoindicators.report.tourism.details.filterOoo'),
      noRowsToShow: t('geoindicators.report.tourism.details.noRowsToShow')
    }
  });

  const contains_filter = reactive({
    displayKey: 'contains',
    test: function([filterValue], cellValue) {
      return cellValue == null || cellValue > filterValue;
    },
    hideFilterInput: true
  });

  const updateSelected = (selected_value) => {
    is_loading.value = true;
    selected.value = selected_value;
    grid_options.columnDefs = row_headers.value[selected_value];
    row_data.value = props.data[selected_value];
    setTimeout(() => { is_loading.value = false }, 3000);
  };

  const onGridReady = (params) => {
    grid_api.value = params.api;
    params.api.sizeColumnsToFit();
  };

  const getApproximateRange = (value, percent) => {
    if (percent < 75 || value < 5000) {
      return `${global_filters.splitNumber(value)} <span class="percent">(< ${percent}%)</span>`;
    }

    return `${global_filters.splitNumber(value)} <span class="percent">(${percent}%)</span>`;
  };

  const parsePercentage = (percentage) => {
    if (percentage.includes('<')) {
      return 0.99;
    } else if (percentage.includes(' - ')) {
      return parseFloat(percentage.split(' - ')[0]);
    } else if (percentage.includes('~0')) {
      return 0;
    }

    return parseFloat(percentage.replace('%', ''));
  };

  const getHeadersDefs = () => {
    const headers = [];

    for (const index in props.data) {
      headers[index] = [];

      const sorted_keys = Object.keys(props.data[index][0]).sort((a, b) => a.localeCompare(b));
      const percent_fields = ['visits_percent', 'visitors_percent', 'visitors', 'tourists', 'excursionists'];

      for (let i = 0; i < sorted_keys.length; i++) {
        const key = sorted_keys[i];
        const filter_options = [...blanks_filter.value];

        if (typeof props.data[index][0][key] === 'string') {
          filter_options.push(contains_filter);
        }

        let comparator = (first_value, second_value) => {
          const first = (first_value || '').toString().toLowerCase();
          const second = (second_value || '').toString().toLowerCase();

          return first.localeCompare(second);
        };

        if (percent_fields.includes(key)) {
          comparator = (first_value, second_value) => {
            const first_percentage = typeof first_value === 'object' && first_value !== null
              ? first_value.percentage
              : first_value;

            const second_percentage = typeof second_value === 'object' && second_value !== null
              ? second_value.percentage
              : second_value;

            return parsePercentage(first_percentage) - parsePercentage(second_percentage);
          };
        }

        headers[index].push({
          headerName: t('geoindicators.report.tourism.details.' + key),
          field: `${key}`,
          sortable: true,
          value_getter: (params) => params.data[key].percentage,
          comparator: comparator,
          cellRenderer: (params) => {
            if (params.value?.value != null) {
              return typeof params.value === 'object'
                ? getApproximateRange(params.value.value, params.value.percentage)
                : params.value;
            }
            return typeof params.value === 'object'
              ? params.value.percentage
              : params.value;
          },
          ...(key === 'visitors' || key === 'visits_percent') && {sort: 'desc'}
        });
      }

      const header_order = ['visitors', 'excursionists', 'tourists'];

      headers[index].sort((a, b) => header_order.indexOf(a.field) - header_order.indexOf(b.field));
    }

    return headers;
  };

  row_data.value = props.data[selected.value];
  row_headers.value = getHeadersDefs();
  grid_options.columnDefs = row_headers.value[selected.value];
</script>
