import {inject, ref} from 'vue';

export function usePayment(data) {
  const sty = inject('sentry');
  const axios = inject('axios');

  const is_payment_valid = ref(false);

  const validatePayment = (organization_id) => {
    return axios.get(`${process.env.API_URL}/organizations/${organization_id}/validate`)
      .then((res) => {
        is_payment_valid.value = true;
      })
      .catch((error) => {
        if (error.status === 402) {
          is_payment_valid.value = false;
        }
        sty.setContext('id', organization_id);
        sty.captureException(error);
      });
  };

  return {
    is_payment_valid,
    validatePayment
  };
}
