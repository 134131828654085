<template>
  <div class="panel">
    <div class="title">
      <b>
        {{ $t('geoindicators.report.widgets.tourists_repartition.title') }}
        <tooltip
          :text="$t('geoindicators.report.widgets.tourists_repartition.tooltip')"
          :show_tooltip="show_tooltip">
        </tooltip>
      </b>
    </div>

    <apexchart
    height="283"
    type="donut"
    :options="options"
    :series="series">
    </apexchart>
  </div>
</template>

<script setup>
  import {useChartOptions} from '../chart-options';
  import tooltip from '../../tooltip.vue';

  import {computed} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {t} = useI18n();
  const {DEFAULT_OPTIONS, DONUT, VISITORS_TOOLTIP_DONUT} = useChartOptions(t);

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    show_tooltip: {
      type: Boolean,
      required: false
    }
  });

  const series = computed(() => {
    return [props.data.tourists, props.data.locals];
  });

  const options = computed(() => {
    return {
      ...DEFAULT_OPTIONS,
      ...DONUT,
      ...VISITORS_TOOLTIP_DONUT,
      colors:  ['rgba(254, 143, 118, 1)', 'rgba(255, 160, 0, 0.6)'],
      labels: [t('geoindicators.report.widgets.tourists_repartition.tourists'), t('geoindicators.report.widgets.tourists_repartition.locals')]
    };
  });
</script>
