<template>
  <v-select
    v-model="selected"
    :options="clients"
    :isDisabled="disabled || is_loading ? true : null"
    label="name"
    value="_id"
    type="select"
    :placeholder="placeholder || $t('components.organizations_select.placeholder_default')"
    :clearable="false"
    @input="setSelected"
    @reduce="(option) => option.name">
  </v-select>
</template>

<script setup>
  import {ref, watch, inject, onBeforeMount} from 'vue';
  import {useI18n} from 'vue-i18n';
  import {toast} from 'vue3-toastify';

  const {t} = useI18n();

  const axios = inject('axios');
  const sty = inject('sentry');
  const event_hub = inject('event-hub');

  const props = defineProps({
    value: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    condition: {
      type: String,
      required: false
    }
  });

  defineEmits(['input', 'organization_update']);

  const selected = ref('');
  const is_loading = ref(false);
  const clients = ref([]);

  const setSelected = (newSelected) => {
    selected.value = newSelected;
  };

  const fetchData = () => {
    is_loading.value = true;
    const url = `${process.env.API_URL}/organizations`;
    return axios.get(url)
      .then((res) => {
        const filtered_res = filterData(res.data);

        clients.value = [
          {
            _id: JSON.parse(localStorage.user_data).organization._id,
            name: t('components.organizations_select.main_account'),
            is_billable: JSON.parse(localStorage.user_data).organization.is_billable,
            org_level: res.data.org_level
          },
          ...filtered_res
        ];

        const org = clients.value.find((org) => org._id === props.value);

        if (org) {
          selected.value = org;
        }

        is_loading.value = false;
      })
      .catch((err) => {
        toast.error(t('components.organizations_select.msg_error'));
        sty.setContext('data', {
          selected: props.value
        });
        sty.captureException(err);
      });
  };

  const filterData = (clients) => {
    if (props.condition) {
      return clients.filter((client) => client.is_billable === true);
    } else {
      return clients;
    }
  };

  watch(selected, (newVal, oldVal) => {
    if (newVal !== oldVal) {
      event_hub.emit('organization_update', newVal);
    }
  });

  onBeforeMount(() => {
    fetchData();
  });
</script>
