<template>
  <div>
    <div class="panel">
      <div class="title">
        <b>{{ t('geoindicators.report.widgets.market_path.title') }}</b>
      </div>
      <div class="tree-view text-center" v-if="charts_loaded">
        <g-chart
          type="OrgChart"
          :data="chart_data"
          :options="chart_options"
          :settings="{ packages: ['orgchart'] }"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import {computed, ref, onMounted} from 'vue';
  import {useI18n} from 'vue-i18n';
  import {loadGoogleCharts} from 'vue-google-charts';

  export default {
    props: {
      data: {
        type: Object,
        required: true,
        default: () => ({})
      }
    },

    setup(props) {
      const {t} = useI18n();
      const charts_loaded = ref(false);

      function pathToChart(data, root_label) {
        const rows = [[root_label, '', '']];
        const encountered = new Map();

        function getUniqueLabel(node) {
          const count = encountered.get(node.id) || 0;

          encountered.set(node.id, count + 1);
          return count === 0 ? node.label : `${node.label} (${count + 1})`;
        }

        function addNode(node, parent) {
          const node_label = getUniqueLabel(node);
          const tooltip = node.count ? `${node.count}%` : '';
          const formatted_node = {
            v: node_label,
            f: generateFormattedNodeContent(node)
          };

          rows.push([formatted_node, parent, tooltip]);
          if (node.children && node.children.length) {
            for (const child of node.children) {
              addNode(child, node_label);
            }
          }
        }

        for (const node of data.pathing) {
          addNode(node, root_label);
        }

        return rows;
      }

      function generateFormattedNodeContent(data, is_root = false) {
        if (is_root) {
          return `<span>${data.label}</span>`;
        } else {
          return `
          <div class="node-content" style="border-top: 7px solid ${data.color || '#000'};">
            <p class="label">${data.label}</p>
            <small class="top-number">(${data.count || 0}%)</small>
          </div>`;
        }
      }

      const chart_data = computed(() => {
        const root_data = {
          label: t('geoindicators.report.widgets.market_path.start_node')
        };
        const root = generateFormattedNodeContent(root_data, true);

        return pathToChart(props.data, root);
      });

      const chart_options = {
        allowHtml: true,
        allowCollapse: true,
        nodeClass: 'org-chart',
        selectedNodeClass: 'selected-org-chart'
      };

      onMounted(() => {
        loadGoogleCharts('current', {packages: ['orgchart']}).then(() => {
          charts_loaded.value = true;
        });
      });

      return {
        t,
        charts_loaded,
        chart_data,
        chart_options
      };
    }
  };
</script>
