<template>
  <nav class="menu" :class="is_nav_bar_visible ? 'active': 'disabled'" id="navbar" v-if="current_route !== 'proposition' && !is_public_report">

    <div @click="toggleNav" class="burger-menu">
      <div class="burger-line top"></div>
      <div class="burger-line bottom"></div>
      <p>Menu</p>
    </div>

    <div class="brand">
      <whitelabel></whitelabel>
    </div>

    <div class="menu-items">
      <ul>
        <li>
          <a href="#/geomarketing/campaigns">
            <i class="fas fa-bullhorn"></i>
            {{ $t('components.menunav.geomarketing') }}
          </a>
          <ul v-if="current_category === 'geomarketing' || is_mobile_device">
            <li>
              <a href="#/geomarketing/campaigns" :class="{'active': current_route === 'campaigns'}">{{ $t('components.menunav.geomarketing_campaigns') }}</a>
              <a href="#/geomarketing/samples" :class="{'active': current_route === 'samples'}">
              {{ $t('components.menunav.geomarketing_samples') }}
              </a>
            </li>
          </ul>
        </li>

        <li v-if="show_geo_indicators">
          <a href="#/geoindicators/reports" data-testid="geoindicators">
            <i class="fas fa-street-view"></i>
            {{ $t('components.menunav.geoindicators') }}
          </a>
          <ul v-if="current_category === 'geoindicators' || is_mobile_device">
            <li>
              <a href="#/geoindicators/reports" :class="{'active': current_route === 'reports'}">
                {{ $t('components.menunav.geoindicators_reports') }}
              </a>
            </li>
          </ul>
        </li>

        <li v-if="show_geo_academy">
          <a href="#/geoacademy/knowledge">
            <i class="fas fa-graduation-cap"></i>
            {{ $t('components.menunav.geoacademy') }}
          </a>
          <ul v-if="current_category === 'geoacademy' || is_mobile_device">
            <li>
              <a href="#/geoacademy/knowledge" :class="{'active': current_route === 'knowledge'}">
                {{ $t('components.menunav.geoacademy_knowledge') }}
              </a>
            </li>
          </ul>
        </li>

        <li>
          <a href="#/account/invoices" data-testid="account">
            <i class="fas fa-user"></i>
            {{ $t('components.menunav.account') }}
          </a>
          <ul v-if="current_category === 'account' || is_mobile_device">
            <li>
              <a href="#/account/invoices" :class="{'active': current_route === 'invoices'}">
                {{ $t('components.menunav.account_invoices') }}
              </a>
            </li>
            <li v-if="org_level === 2">
              <a href="#/account/sub-accounts" :class="{'active': current_route === 'sub-accounts'}">
                {{ $t('components.menunav.account_subaccounts') }}
              </a>
            </li>
            <li v-if="org_level === 2">
              <a href="#/account/sub-users" :class="{'active': current_route === 'sub-users'}">
                {{ $t('components.menunav.account_subusers') }}
              </a>
            </li>
            <li>
              <a href="#/account/credit-cards" :class="{'active': current_route === 'credit-cards'}">
                {{ $t('components.menunav.account_credit_cards') }}
              </a>
            </li>
            <li>
              <a @click="logout" data-testid="logout">
                {{ $t('components.menunav.account_logout') }}
              </a>
            </li>
          </ul>
        </li>

      </ul>
    </div>

    <!-- TODO
    <div class="languages text-center">
      <a :class="{'selected' : selectedLang === 'fr'}" @click="updateLang('fr')">FR</a>
      |
      <a :class="{'selected' : selectedLang === 'en'}" @click="updateLang('en')">EN</a>
    </div>
    -->

    <div class="version">
      v{{ version }}
    </div>

  </nav>
</template>

<script setup>
  import {useRoute, useRouter} from 'vue-router';
  import whitelabel from '../components/whitelabel.vue';
  import {useWhiteLabel} from '../composables/whitelabel';
  import {ref, computed, watch} from 'vue';

  const router = useRouter();
  const route = useRoute();
  const {show_geo_academy, show_geo_indicators} = useWhiteLabel();

  const is_nav_bar_visible = ref(false);
  const current_route = ref('');
  const current_category = ref('');
  const org_level = ref(null);
  const version = ref(process.env.VERSION);

  // Uncomment this block to enable language switcher
  // const selected_lang = computed(() => {
  //   return i18n.locale;
  // });

  const is_public_report = computed(() => {
    return ['public', 'preview'].includes(router.currentRoute.value.path.split('/')[1]);
  });

  const is_mobile_device = computed(() => {
    if (window.innerWidth < 993) {
      return true;
    }
  });

  const toggleNav = () => {
    is_nav_bar_visible.value = !is_nav_bar_visible.value;
  };

  const onRouteChange = () => {
    if (is_nav_bar_visible.value) {
      is_nav_bar_visible.value = !is_nav_bar_visible.value;
    }
    current_category.value = router.currentRoute.value.path.split('/')[1];
    //@TODO: temporary fix
    current_route.value = router.currentRoute.value.path === '/welcome' ? 'welcome' : router.currentRoute.value.path.split('/')[2];
    org_level.value = localStorage.user_data ? JSON.parse(localStorage.getItem('user_data')).organization.org_level : null;
  };

  const logout = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('user_data');
    localStorage.removeItem('campaign_list_filter_selected_client');
    router.push({path:'/'});
  };

  // Uncomment this block to enable language switcher
  // const updateLang = (lang) => {
  //   selected_lang.value = lang;
  //   localStorage.setItem('lang', lang);
  //   i18n.locale = lang;
  // };

  watch(() => route.path, (to, from)  => {
    onRouteChange();
  });

</script>
