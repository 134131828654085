<template>
  <div class="geoindicators-reports" :class="is_public || is_preview ? 'public' : ''">

    <!-- Header -->
    <header class="title-bar">
      <div :class="is_public || is_preview ? 'container' : ''">
        <div class="row justify-content-between">
          <div class="title align-self-center col-md-6">
            <h1 class="report-name" v-if="!is_loading">{{ report.name }}</h1>
          </div>

          <div class="col-md-6 text-right align-self-center" v-if="!is_loading && !error_message">
            <dropdown
              :id="1"
              :active_dropdown_id="active_dropdown_id"
              @toggle="toggleDropdown"
              v-if="(report.type === 'visits' || report.type === 'trails') && report.status === 'ended'">
              <template #actions>
                <ul class="list fences-list"
                v-tooltip="$t('geoindicators.report.header.filters.normalized_data_tooltip')">
                  <li>
                    <label class="switch">
                      <input type="checkbox" v-model="is_data_normalized" @change="switchNormalization"><span></span>
                    </label>
                    {{ $t('geoindicators.report.header.filters.normalized_data') }}
                  </li>
                </ul>
              </template>
              <template #title>
                {{ $t('geoindicators.report.header.filters_title') }}
                <i class="fas fa-caret-down"></i>
              </template>
            </dropdown>
            <dropdown
              :id="2"
              :active_dropdown_id="active_dropdown_id"
              @toggle="toggleDropdown"
              v-if="(report.type === 'visits' || report.type === 'trails') && report.status === 'ended'"
            >
              <template #actions>
                <ul class="list fences-list" :class="{'no-checkbox': report.type === 'market'}">
                  <div class="fence-selector top">
                    <li @click="toggleSelection" class="fence" v-if="display_fences_actions">
                      <label class="select-unselect-all">
                        <i class="far fa-minus-square" v-if="is_partial_selection"></i>
                        <i class="far fa-check-square" v-else-if="are_all_selected"></i>
                        <i class="far fa-square" v-else></i>
                        {{ $t('geoindicators.report.header.dropdown.select_all') }}
                        <span></span>
                      </label>
                    </li>
                    <li class="separator"></li>
                  </div>
                  <li v-for="fence in report.fences" class="fence">
                    <label class="checkbox">
                      <i class="color" :style="{ 'background': fence.color[3] }"></i>
                      {{ fence.label }}
                      <input v-model="fence.selected" type="checkbox">
                      <span></span>
                    </label>
                  </li>
                  <div class="fence-selector bottom">
                    <li class="separator" v-if="display_fences_actions"></li>
                    <a class="btn confirm-selection" @click="confirmSelection">
                      <i class="fas fa-sync-alt"></i>
                      {{ $t('geoindicators.report.header.dropdown.apply') }}
                    </a>
                  </div>
                </ul>
              </template>
              <template #title>
                {{ $t('geoindicators.report.header.dropdown.target_fences') }}
                <i class="fas fa-caret-down"></i>
              </template>
            </dropdown>

            <dropdown
              class="primary"
              :id="3"
              :active_dropdown_id="active_dropdown_id"
              @toggle="toggleDropdown"
              v-if="!is_public && !is_preview"
            >
              <template #actions>
                <ul class="list actions-list">
                  <template v-if="report.status === 'ended'">
                    <li
                      v-if="report.type !== 'economic'"
                      @click="exportReport"
                      :class="{'disabled' : !allow_json_report }"
                      v-tooltip="!allow_json_report ? {content: $t('campaign.edit.fences_panel.export_polygons_tooltip')} : ''">
                      <i class="fas fa-database"></i>
                      {{ $t('campaign.edit.fences_panel.export_report') }}
                    </li>
                    <li
                      v-if="allow_xlsx_report"
                      @click="exportToXlsx">
                      <i class="fas fa-database"></i>
                      {{ $t('campaign.edit.fences_panel.export_report_xlsx') }}
                    </li>
                  </template>
                  <li @click="exportFences">
                    <i class="fas fa-draw-polygon"></i>
                    {{ $t('campaign.edit.fences_panel.export_polygons') }}
                  </li>
                  <li class="separator"></li>
                  <li @click="copyReport()" v-if="!is_custom_report">
                    <i class="far fa-clone"></i>
                    {{ $t('geoindicators.report.header.copy') }}
                  </li>
                  <li @click="getSharedLink(false, false, t)">
                    <i class="fas fa-share-alt"></i>
                    {{ $t('geoindicators.report.header.share') }}
                  </li>
                  <li @click="getSharedLink(false, true, t)" v-if="license.limits && license.limits.preview_allowed" >
                    <i class="fas fa-share"></i>
                    {{ $t('geoindicators.report.header.share_preview') }}
                  </li>
                </ul>
              </template>
              <template #title>
                {{ $t('geoindicators.report.header.actions') }}
                <i class="d-none d-lg-inline fas fa-caret-down"></i>
              </template>
            </dropdown>
          </div>
        </div>
      </div>
    </header>

    <div :class="is_public || is_preview ? 'public-container container' : ''">

      <loading :loading="is_loading" :error="error" :error_message="error_message"/>

      <div class="row breadcrumbs" v-if="!is_loading && !error">
        <div class="col-md-3 d-none d-lg-inline">
          <a href="/#/geoindicators/reports" class="info" v-if="!is_public && !is_preview">{{ $t('geoindicators.report.header.return_to_reports') }}</a>
        </div>
        <div class="col-lg-9 text-right">
          <template v-if="report.time_parting && report.time_parting.length > 1">
            <span
              class="badge info"
              v-tooltip.bottom="{ content: getTimePart().join('<br>'), html: true }"
            >
            {{ $t('geoindicators.report.header.time_parting_filter') }} {{ getTimePart()[0] }} ({{ $t('geoindicators.report.header.see_more') }})
            </span>
          </template>
          <template v-else-if="report.time_parting && report.time_parting.length === 1">
            <span class="badge info">
              {{ $t('geoindicators.report.header.time_parting_filter') }} {{ getTimePart()[0] }}
            </span>
          </template>

          <template v-if="report.type === 'tourism'">
            <span class="badge info">{{ $t('geoindicators.report.header.duration_min') }} {{ report.min_duration }} {{ $t('geoindicators.report.tourism.hours').toLowerCase() }}</span>
            <span class="badge info">{{ $t('geoindicators.report.header.duration_max') }} {{ report.max_duration }} {{ $t('geoindicators.report.tourism.nights').toLowerCase() }}</span>
            <span class="badge info">{{ $t('geoindicators.report.header.frequency_min') }} {{ report.min_frequency }} {{ $t('geoindicators.report.tourism.visits').toLowerCase() }}</span>
            <span class="badge info">{{ $t('geoindicators.report.header.frequency_max') }} {{ report.max_frequency }} {{ $t('geoindicators.report.tourism.visits').toLowerCase() }}</span>
          </template>
          <template v-if="report.type === 'economic'">
            <span class="badge info">{{ $t('geoindicators.report.header.time') }} {{ report.before_delay }} {{ $t('geoindicators.report.header.time_before') }} {{ report.after_delay }} {{ $t('geoindicators.report.header.time_after') }}</span>
          </template>
          <template v-if="report.type === 'before_after'">
            <span
               class="badge info">
              {{ $t('geoindicators.report.header.time_before_hours') }} {{ report.seconds_before / 3600 }} {{ $t('geoindicators.report.header.hours') }}
            </span>
            <span
             class="badge info">
             {{ $t('geoindicators.report.header.time_after_hours') }} {{ report.seconds_after / 3600 }} {{ $t('geoindicators.report.header.hours') }}
            </span>
          </template>
          <template v-if="report.type === 'cross_visits'">
            <span
               class="badge info">
              {{ $t('geoindicators.report.header.min_time_between') }} {{ crossVisitsBadge(report.min_seconds_between) }} {{ $t('geoindicators.report.header.days') }}
            </span>
            <span
             class="badge info">
             {{ $t('geoindicators.report.header.max_time_between') }} {{ crossVisitsBadge(report.max_seconds_between) }} {{ $t('geoindicators.report.header.days') }}
            </span>
          </template>
          <span class="badge info">{{ $t('geoindicators.report.header.period') }} {{ report.start_date }} {{ $t('geoindicators.report.header.period_to') }} {{ report.end_date }}</span>
          <span class="badge info">{{ $t('geoindicators.report.header.type') }} {{ $t(`geoindicators.report.type.${report.type}`) }}</span>
          <span class="badge info">{{ $t('geoindicators.report.header.id') }} {{ route.params.report_id }}</span>
        </div>
      </div>

      <!-- Report not ready -->
      <div v-if="!is_loading && !error && report.status === 'pending'" data-testid="report-pending">
        <div class="row justify-content-center">
          <div class="panel text-center col-sm-4 message">
            <i class="icon fas fa-shipping-fast"></i>
            <h3>{{ $t('geoindicators.report.report_not_ready.report_on_its_way') }}</h3>
            <p>{{ $t('geoindicators.report.report_not_ready.come_back') }}</p>
            <a v-if="!is_public && !is_preview" href="/#/geoindicators/reports">{{ $t('geoindicators.report.report_not_ready.return_to_reports') }}</a>
          </div>
        </div>
      </div>

      <!-- Report unavailable -->
      <div v-if="!is_loading && !error && report.status === 'unavailable'">
        <div class="row justify-content-center">
          <div class="panel text-center col-sm-4 message">
            <i class="icon fas fa-ban"></i>
            <h3>{{ $t('geoindicators.report.report_unavailable.title') }}</h3>
            <p>{{ $t('geoindicators.report.report_unavailable.text') }}</p>
            <a @click="openChat">{{ $t('geoindicators.report.report_unavailable.cta') }}</a>
          </div>
        </div>
      </div>

      <!-- Report too large -->
      <div v-if="!is_loading && !error && report.status === 'too_large'">
        <div class="row justify-content-center">
          <div class="panel text-center col-sm-4 message">
            <i class="icon fas fa-exclamation-triangle"></i>
            <h3>{{ $t('geoindicators.report.report_too_large.title') }}</h3>
            <p>{{ $t('geoindicators.report.report_too_large.text') }}</p>
            <a @click="openChat">{{ $t('geoindicators.report.report_too_large.cta') }}</a>
          </div>
        </div>
      </div>

      <!-- Report content -->
      <div v-if="!is_loading && !error && report.status === 'ended'">

        <!-- Alert if data might change over time (e.g. end date is too recent) -->
        <aside class="alert warning space" v-if="data_might_change"><i class="fa fa-exclamation-circle"></i> {{ $t('geoindicators.report.warnings.normalization') }}</aside>

        <!-- DDI -->
        <sample-summary
          v-if="display_sample"
          :sample_metrics="report_series.sample_metrics">
        </sample-summary>

        <!-- Personalized comments -->
        <div class="title-separator" v-if="report.comments">
          <h1><b>{{$t('geoindicators.report.widgets.comments_title') }}</b></h1>
          <p>{{$t('geoindicators.report.widgets.comments_subtitle') }}</p>
          <hr>
        </div>
        <div v-if="report.comments">
          <div v-html="report.comments"></div>
        </div>

        <component
          :is="report_loader"
          :data="report_series"
          :limits="report.limits"
          :is_preview="is_preview"
          :report="report">
        </component>

        <template v-if="is_public || is_preview">
          <div v-if="is_wl" class="logo"><whitelabel></whitelabel></div>
          <a v-else href="https://propulso.io/"><img src="assets/propulso-logo-black.svg" class="logo" alt="Logo Propulso"></a>
        </template>

      </div>
    </div>
  </div>
</template>

<script setup>
  import {ref, computed, inject, defineAsyncComponent, onBeforeMount} from 'vue';
  import {exportGeoJson} from '../../js/fences';
  import {convertToXlsxBuffer} from '../../js/xlsxHelper.js';
  import {parseDate, handleTimezoneOffset, filterReportDataToExport, canDataChange} from '../../js/helpers';
  import {useRoute, useRouter} from 'vue-router';
  import {useReport} from '../composables/report.js';
  import {useI18n} from 'vue-i18n';
  import dropdown from '../components/dropdown.vue';
  import whitelabel from '../components/whitelabel.vue';
  import sampleSummary from '../components/reports/widgets/sampleSummary.vue';

  const {t} = useI18n();
  const route = useRoute();
  const router = useRouter();

  const sty = inject('sentry');
  const axios = inject('axios');
  const event_hub = inject('event-hub');

  const {
    report,
    is_loading,
    error,
    license,
    copyReport,
    getSharedLink,
    openChat,
    getLicense
  } = useReport(event_hub);

  const is_data_normalized = ref(true);
  const is_public = ref(false);
  const is_preview = ref(false);
  const active_dropdown_id = ref(null);
  const normalized_data = ref({});
  const error_message = ref(null);

  const is_custom_report = computed(() => {
    return report.type && (report.type === 'custom' || report.type.startsWith('beta_'));
  });

  const allow_xlsx_report = computed(() => {
    return report && report.limits.export_report_xlsx;
  });

  const allow_json_report = computed(() => {
    return report && report.limits.export_report_json;
  });

  const report_loader = computed(() => {
    const path = is_custom_report.value ? 'custom' : report.type;

    return defineAsyncComponent(() => import(/* webpackMode: "eager" */`../components/reports/types/${path}.vue`));
  });

  const display_fences_actions = computed(() => {
    return report.type !== 'market' && report.type !== 'economic';
  });

  const domain = computed(() => {
    return location.hostname.split('.').slice(-2);
  });

  const is_wl = computed(() => {
    return domain[0] !== 'propulso';
  });

  const report_series = computed(() => {
    let computed_series = JSON.parse(JSON.stringify(report.series));

    if (report.type === 'visits' || report.type === 'trails') {
      const data_type = is_data_normalized.value ? 'normalized' : 'raw';
      const selected_data_type = report.series[data_type];

      delete computed_series.raw;
      delete computed_series.normalized;

      normalized_data.value = {
        ...computed_series,
        ...report.series['normalized']
      };

      computed_series = {
        ...computed_series,
        ...selected_data_type,

      };

      return computed_series;
    }

    return is_custom_report.value ? report : computed_series;
  });

  const display_sample = computed(() => {
    const sample_metrics = report_series.value.sample_metrics ? report_series.value.sample_metrics : {};

    return !!sample_metrics.sample_size;
  });

  const selected_fences = computed(() => {
    let selected_fences = [];

    if (report.fences && report.fences.length) {
      if (report.type === 'visits' || report.type === 'trails') {
        selected_fences = report.fences.filter((fence) => fence.selected).map((fence) => fence._id);
      } else {
        selected_fences = report.fences.map((fence) => fence._id);
      }
    }

    return selected_fences;
  });

  const are_all_selected = computed(() => {
    return report.fences.every((fence) => fence.selected);
  });

  const is_partial_selection = computed(() => {
    const selected_count = report.fences.filter((fence) => fence.selected).length;

    return selected_count > 0 && selected_count < report.fences.length;
  });
  const data_might_change = computed(() => {
    return canDataChange(report.end_date, report.type);
  });

  const toggleSelection = () => {
    event.preventDefault();
    event.stopPropagation();

    const all_selected = are_all_selected.value;

    report.fences.forEach((fence) => {
      fence.selected = !all_selected;
    });
  };

  const crossVisitsBadge = (value) => {
    const day_in_seconds = 86400;

    return value % day_in_seconds === 0
      ? value / day_in_seconds
      : (value / day_in_seconds).toFixed(2);
  };

  const confirmSelection = () => {
    if (!is_custom_report.value) {
      getReportMetrics(selected_fences.value);
    }
    active_dropdown_id.value = null;
  };

  const getReportData = async() => {
    is_loading.value = true;

    return axios.get(`${process.env.API_URL}/geoindicators/reports/${route.params.report_id}`)
      .then((res) => {
        const formatted_dates = handleTimezoneOffset(res.data.start_date, res.data.end_date, false);

        Object.assign(report, {
          ...res.data,
          start_date: parseDate(formatted_dates.start, 'labels'),
          end_date: parseDate(formatted_dates.end, 'labels'),
          fences: res.data.fences ? res.data.fences.map((fence) => {
            return {
              ...fence,
              selected: true
            };
          }) : [],
          series: {},
          custom_url: res.data.custom_url ? res.data.custom_url : '',
          alert_note: res.data.alert_note ? res.data.alert_note : ''
        });

      })
      .catch((err) => {
        sty.captureException(err);
        error.value = true;

        if (err.status === 404) {
          error_message.value = t('geoindicators.report.toasted.not_found');
        }
      })
      .finally(() => {
        is_loading.value = false;
      });
  };

  const getReportMetrics = async(selected_metrics_fences) => {
    is_loading.value = true;

    const filters = {selected_fences: selected_metrics_fences};
    let base_url = `${process.env.API_URL}`;

    if (report.type === 'tourism' || report.type === 'trails') {
      base_url = `${process.env.BU_API_URL}`;
    }

    return axios.post(`${base_url}/geoindicators/reports/${route.params.report_id}/metrics`, {filters})
      .then((res) => {
        report.series = res.data.series;
      })
      .catch((err) => {
        sty.captureException(err);
        error.value = true;
      }).finally(() => {
        is_loading.value = false;
      });
  };

  const switchNormalization = () => {
    is_loading.value = true;
    setTimeout(() => is_loading.value = false, 1000);
  };

  const exportFences = () => {
    const file_name = `${report._id}_${report.name}`;
    const selected_fences = report.fences.filter((fence) => fence.selected);

    if (selected_fences.length > 0) {
      return exportGeoJson(selected_fences, file_name, 'report');
    }
  };

  const exportReport = () => {
    if (report.limits.export_report_json) {
      let computed_report;

      if (report.type === 'visits' || report.type === 'trails') {
        computed_report = {
          ...report.series,
          ...report.series.normalized
        };

        delete computed_report.raw;
        delete computed_report.normalized;

        if (computed_report.direction) {
          delete computed_report.direction.show_directions;
        }

        if (computed_report.speed) {
          delete computed_report.speed.show_speed_series;
        }

        if (computed_report.visits_source) {
          delete computed_report.visits_source.show_visits_source;
        }

        if (computed_report.visits_count) {
          delete computed_report.visits_count.by_day;
        }
      } else if (report.type === 'tourism') {
        computed_report = report.series;

        delete computed_report.heatmap;

        delete computed_report.visits.visitors.colors;
        delete computed_report.visits.tourists.colors;
        delete computed_report.visits.excursionists.colors;
      } else {
        computed_report = report.series;
      }

      const file_data = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(computed_report))}`;
      const a = document.createElement('a');

      a.href = file_data;
      a.download = `${report._id}_${report.name}_data.json`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };

  const exportToXlsx = async() => {
    if (allow_xlsx_report) {
      const computed_report = filterReportDataToExport(report);
      const buffer = await convertToXlsxBuffer(report.name, computed_report);
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${report._id}_${report.name}_data.xlsx`;

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  const getTimePart = () => {
    // Keep this order because days of the week have a specific value
    const week = [
      t('geoindicators.creation.abr_days_of_week.sunday'),
      t('geoindicators.creation.abr_days_of_week.monday'),
      t('geoindicators.creation.abr_days_of_week.tuesday'),
      t('geoindicators.creation.abr_days_of_week.wednesday'),
      t('geoindicators.creation.abr_days_of_week.thursday'),
      t('geoindicators.creation.abr_days_of_week.friday'),
      t('geoindicators.creation.abr_days_of_week.saturday')
    ];
    let string_to_return = [];

    for (const [index, part] of report.time_parting.entries()) {
      if (!string_to_return[index]) {
        string_to_return[index] = '';
      }

      for (const day of part.days) {
        string_to_return[index] += week[day];
        string_to_return[index] += ". ";
      }

      string_to_return[index] += " - ";
      string_to_return[index] += part.start_time;
      string_to_return[index] += ` ${t('geoindicators.report.header.period_until')} `;
      string_to_return[index] += part.end_time;
    }

    return string_to_return;
  };

  const toggleDropdown = (id) => {
    active_dropdown_id.value = active_dropdown_id.value === id ? null : id;
  };

  onBeforeMount(async() => {
    await getReportData().then(async() => {
      is_public.value = router.currentRoute.value.path.split('/')[1] === 'public';
      is_preview.value = router.currentRoute.value.path.split('/')[1] === 'preview';

      if (!is_custom_report.value && report.status === 'ended') {
        await getReportMetrics(selected_fences.value);
      }

      if (!is_public.value && !is_preview.value) {
        await getLicense();
      }
    });

    document.title += report.name ? ` - ${report.name}` : '';
  });
</script>
