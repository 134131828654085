<template>
  <div class="row">
    <div class="col-12">

      <div class="panel">
        <div class="title">
          <b>{{ $t('geoindicators.report.widgets.visits_pathing.title') }}</b>
          <aside class="actions">
            <dropdown :is_button="false">
              <template #actions>
                <ul class="list">
                  <li>
                    <label class="checkbox">
                      <i class="color" :style="{ 'background': before_options.gradient[4] }"></i> {{ $t('geoindicators.report.widgets.visits_pathing.30_before') }}
                      <input v-model="show_before_layer" type="checkbox">
                      <span></span>
                    </label>
                  </li>
                  <li>
                    <label class="checkbox">
                      <i class="color" :style="{ 'background': after_options.gradient[4] }"></i> {{ $t('geoindicators.report.widgets.visits_pathing.30_after') }}
                      <input v-model="show_after_layer" type="checkbox">
                      <span></span>
                    </label>
                  </li>
                </ul>
              </template>
              <template #title>
                <a class="info">
                  <i class="fas fa-layer-group"></i>
                  {{ $t('geoindicators.report.widgets.layers_toggle') }}
                  <i class="fas fa-caret-down"></i>
                </a>
              </template>
            </dropdown>
          </aside>
        </div>

        <div class="custom-heatmap-container">
          <div class="heatmap-container">
            <GoogleMap
              :api-promise="apiPromise"
              ref="map_ref_heatmap"
              style="height: 650px;"
              :zoom="map.zoom"
              :center="map.center"
              :options="map.options"
              @zoom_changed="mapZoomChanged">
              <!-- Heatmap -->
              <HeatmapLayer
                v-if="show_before_layer && google !== null"
                :options="{
                  ...before_options,
                  data: before
                }"/>
              <HeatmapLayer
                v-if="show_after_layer && google !== null"
                :options="{
                  ...after_options,
                  data: after
                }"/>
            </GoogleMap>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
  import {mapOptions} from '../../../../js/gmap-imports.js';
  import {ref, reactive, computed, watch} from 'vue';
  import {useGmap} from '../../../../js/gmap-imports.js';
  import {GoogleMap, HeatmapLayer} from 'vue3-google-map';

  const props = defineProps({
    data: {
      type: Array,
      required: true
    }
  });

  const show_before_layer = ref(true);
  const show_after_layer = ref(true);

  const map = reactive({
    options: {
      ...mapOptions
    },
    center: {
      lat: 45.539448,
      lng: -73.63254
    },
    zoom: 6
  });

  const before_options = {
    maxIntensity: 1,
    opacity: 1,
    gradient: ['rgba(255, 160, 0, 0)', 'rgba(255, 160, 0, 0.4)', 'rgba(255, 160, 0, 0.6)', 'rgba(255, 160, 0, 0.8)', 'rgba(255, 160, 0, 1)'],
    radius: 5
  };

  const after_options = {
    maxIntensity: 1,
    opacity: 1,
    gradient: ['rgba(216, 44, 13, 0)', 'rgba(216, 44, 13, 0.4)', 'rgba(216, 44, 13, 0.6)', 'rgba(216, 44, 13, 0.8)', 'rgba(216, 44, 13, 1)'],
    radius: 5
  };

  const {
    apiPromise,
    google,
    map_ref: map_ref_heatmap,
    mapZoomChanged,
    initializeGoogleMapsAPI
  } = useGmap(map);

  const before = computed(() => {
    var reduced = props.data.reduce((acc, fence) => {
      return acc.concat(fence.before.map((point) => ({
        location: {lat: point.lat, lng: point.lng},
        weight: 1
      })));
    }, []);
    return reduced;
  });

  const after = computed(() => {
    return props.data.reduce((acc, fence) => {
      return acc.concat(fence.after.map((point) => ({
        location:{lat: point.lat, lng: point.lng},
        weight: 1
      })));
    }, []);
  });

  watch(() => props.data, () => {
    if (props.data.length) {
      map.center = props.data[0].polygon[0][0];
      map.zoom = 10;
    }
  }, {
    immediate: true,
    deep: true
  });

  initializeGoogleMapsAPI();
</script>
