<template>
  <div class="row">
    <div class="col-12">
      <div class="panel">
        <div class="title">
          <div class="row">
            <div class="col-6">
              <b>
                {{ $t('geoindicators.report.widgets.visits_duration.title') }}
                <tooltip
                  :text="$t('geoindicators.report.widgets.visits_duration.tooltip')"
                  :show_tooltip="show_tooltip">
                </tooltip>
              </b>
            </div>
            <div class="col-6 text-right align-self-center">
              <dropdown :is_button="false">
                <template #actions>
                  <ul class="list">
                    <li @click="updatePeriod('by_month')">
                      <label class="radio">
                        {{ $t('geoindicators.report.widgets.visits_count.per_month') }}
                        <input v-model="selected_period" value="by_month" type="radio">
                        <span></span>
                      </label>
                    </li>
                    <li @click="updatePeriod('by_quarter')">
                      <label class="radio">
                        {{ $t('geoindicators.report.widgets.visits_count.per_quarter') }}
                        <input v-model="selected_period" value="by_quarter" type="radio">
                        <span></span>
                      </label>
                    </li>
                    <li @click="updatePeriod('by_year')">
                      <label class="radio">
                        {{ $t('geoindicators.report.widgets.visits_count.per_year') }}
                        <input v-model="selected_period" value="by_year" type="radio">
                        <span></span>
                      </label>
                    </li>
                  </ul>
                </template>
                <template #title>
                  <a class="info">
                    {{ $t('geoindicators.report.widgets.visits_count.per_period') }}
                    <i class="fas fa-caret-down"></i>
                  </a>
                </template>
              </dropdown>
            </div>
          </div>
        </div>

        <apexchart
        height="350"
        type="bar"
        :options="options"
        :series="series">
        </apexchart>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {useChartOptions} from '../chart-options';
  import tooltip from '../../tooltip.vue';

  import {parseDate, sortEntries} from '../../../../js/helpers';
  import {useGisWidget} from '../../../composables/gis_widget';
  import {ref, computed} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {t, locale} = useI18n();
  const {DEFAULT_OPTIONS, MINUTES_TOOLTIP, GRADIENT} = useChartOptions(t);

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    show_tooltip: {
      type: Boolean,
      required: false
    }
  });

  const {updatePeriod} = useGisWidget(props.data);

  const selected_period = ref('by_month');

  const ordered_values = computed(() => {
    return sortEntries(props.data[selected_period.value]);
  });

  const series = computed(() => {
    return [{
      name: '',
      data: ordered_values.value.map(([date, duration]) => duration)
    }];
  });

  const options = computed(() => {
    return {
      ...DEFAULT_OPTIONS,
      ...MINUTES_TOOLTIP,
      ...GRADIENT,
      xaxis: {
        floating: false,
        categories: ordered_values.value.map(([date, duration]) => {
          return selected_period.value === 'by_month' ? parseDate(new Date(date), 'month_year', locale.value) : date;
        }),
        tickPlacement: 'on',
        tickAmount: 10,
        labels: {
          rotate: 0
        }
      }
    };
  });
</script>
