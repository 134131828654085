<template>
    <div class="welcome">
      <div class="container">

        <header class="title-bar">
          <h1>{{ $t('welcome.title') }}</h1>
        </header>

        <div class="row">
          <div class="col-md-6">
            <div class="panel feature">
              <img src="/assets/geomarketing.svg" alt="GéoMarketing">
              <h2>{{ $t('welcome.subtitle_start') }}</h2>
              <a href="/#/geomarketing/campaigns" class="btn primary">{{ $t('welcome.btn_start') }}</a>
            </div>
          </div>
          <div class="col-md-6">
            <div class="panel feature">
              <img src="/assets/geoacademie.svg" alt="GéoMarketing">
              <h2>{{ $t('welcome.subtitle_learn') }}</h2>
              <a href="/#/geoacademy/knowledge" class="btn secondary">{{ $t('welcome.btn_learn') }}</a>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="panel support">
              <img src="/assets/support.svg" alt="Besoin d'aide?">
              <h2>
                {{ $t('welcome.subtitle_question') }}
                <i class="fas fa-share"></i>
              </h2>
            </div>
          </div>
        </div>

      </div>
    </div>
</template>

<script setup>
  import {inject} from 'vue';
  import {useRouter} from 'vue-router';

  const router = useRouter();

  const i18n = inject('i18n');

  const initLocalStorage = () => {
    const query = router.currentRoute.value.query;

    if (!query.token) {
      return;
    }

    const user = JSON.parse(atob(query.token.split('.')[1])).user;

    // Set cookies
    localStorage.setItem('jwt', query.token);
    localStorage.setItem('email', user.email);
    localStorage.setItem('user_data', JSON.stringify(user));
    localStorage.setItem('lang', user.language);

    if (user.language) {
      i18n.locale = user.language;
    }

    localStorage.setItem('currency', user.organization.markup.currency);

    if (query.url) {
      router.push(query.url);
    }
    // Auth chat
    _chatlio.identify(user._id, {
      name: user.first_name + ' ' + user.last_name,
      email: user.email,
      organization: user.organization.name,
      organization_id: user.organization._id
    });
  };

  initLocalStorage();
</script>
