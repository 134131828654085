<template>
  <div class="credit-cards">
    <header class="title-bar">
      <div class="row">
        <div class="col-8 align-self-center">
          <h1 class="title">{{ $t('credit_cards.header_title') }}</h1>
        </div>

        <div class="col-4 align-self-center text-right">
          <a @click="createPopup" class="btn primary">
            <i class="fas fa-plus"></i>
            <span class="d-none d-lg-inline pl-lg-1" data-testid="add-credit-card">{{ `${$t('credit_cards.add_credit_card_btn')}` }}</span>
          </a>
        </div>
      </div>
    </header>

    <add-credit-card-popup v-if="create_popup" @update-popup="createPopup"></add-credit-card-popup>
    <update-credit-card-popup v-if="update_popup" @update-popup="updatePopup" :organisation="selected_org"></update-credit-card-popup>

    <loading :loading="is_loading" :error="error"/>

    <div v-if="!is_loading && !error">
      <div class="panel mobile-table" v-if="payment_profiles.length">
        <table>
          <thead>
            <tr>
              <th>{{ $t('credit_cards.credit_cards_panel.table_headers.organisation') }}</th>
              <th>{{ $t('credit_cards.credit_cards_panel.table_headers.credit_card') }}</th>
              <th>{{ $t('credit_cards.credit_cards_panel.table_headers.expiration') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
            v-for="(method, index) in payment_profiles"
            :class="{'payment-exception': hasPaymentException(method.type)}"
            @click="updatePopup(method)">
              <td>{{ method.organization.name }}</td>
              <td v-if="hasPaymentException(method.type)">
                <span class="badge info">
                  {{ getPaymentMethodLabel(method.type) }}
                </span>
              </td>
              <td v-else-if="method.credit_card">
                <div class="method">
                  <i class="fab provider" :class="{'fa-cc-visa': method.credit_card.provider === 'visa', 'fa-cc-mastercard': method.credit_card.provider === 'mastercard'}"></i>
                  {{ method.credit_card.label }}
                </div>
              </td>
              <td v-else>
                -
              </td>
              <td v-if="method.credit_card">{{ method.credit_card.expiration.month }}-{{ method.credit_card.expiration.year }}</td>
              <td v-else>
                -
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="panel" v-else>
        <p>{{ $t('credit_cards.credit_cards_panel.no_payment_method') }}</p>
      </div>

    </div>

  </div>
</template>

<script setup>
  import AddCreditCardPopup from '../components/credit_cards/addCreditCardPopup.vue';
  import UpdateCreditCardPopup from '../components/credit_cards/updateCreditCardPopup.vue';
  import {ref, inject} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {t} = useI18n();

  const event_hub = inject('event-hub');
  const sty = inject('sentry');
  const axios = inject('axios');

  const payment_profiles = ref([]);
  const create_popup = ref(false);
  const update_popup = ref(false);
  const selected_org = ref({});
  const is_loading = ref(false);
  const error = ref(false);

  const getCreditCards = () => {
    is_loading.value = true;

    axios.get(`${process.env.API_URL}/payments/profile`)
      .then((res) => {
        payment_profiles.value = res.data;
        is_loading.value = false;
      })
      .catch((err) => {
        error.value = true;
        sty.captureException(error);
      });
  };

  const getPaymentMethodLabel = (type) => {
    return type === 'checks'
      ? t('credit_cards.credit_cards_panel.table.checks')
      : t('credit_cards.credit_cards_panel.table.bank_transfer');
  };

  const createPopup = (popup) => {
    create_popup.value = popup;
  };

  const updatePopup = (org) => {
    if (hasPaymentException(org.type)) return;

    selected_org.value = org;
    update_popup.value = !update_popup.value;
  };

  const hasPaymentException = (type) => {
    return type === 'bank-transfer' || type === 'checks';
  };

  event_hub.on('refresh-credit-cards', getCreditCards);
  getCreditCards();

</script>
