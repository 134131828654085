<template>
  <div class="sub-accounts">
    <header class="title-bar">
      <div class="row justify-content-between">
        <div class="col-8 align-self-center">
          <h1 class="title">{{ $t('account.sub_accounts.header_title') }}</h1>
        </div>

        <div class="col-4 align-self-center text-right">
          <a @click="openSubAccountPopup()" class="btn primary">
            <i class="fas fa-plus"></i>
            <span class="d-none d-lg-inline pl-lg-1" data-testid="add-sub-account">{{ $t('account.sub_accounts.add_sub_account_btn') }}</span>
          </a>
        </div>

      </div>
    </header>

    <loading :loading="is_loading"></loading>

    <div v-if="!is_loading">
      <div class="panel mobile-table">
        <table>
          <thead>
            <tr>
              <th>{{ $t('account.sub_accounts.table_headers.name') }}</th>
              <th>{{ $t('account.sub_accounts.table_headers.website') }}</th>
              <th>{{ $t('account.sub_accounts.table_headers.cpm') }}</th>
              <th>{{ $t('account.sub_accounts.table_headers.account') }}</th>
              <th>{{ $t('account.sub_accounts.table_headers.credit_card') }}</th>
              <th>{{ $t('account.sub_accounts.table_headers.users') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="organization in organization_list" @click="openSubAccountPopup(organization)">
              <td>{{ organization.name }}</td>
              <td>{{ organization.website }}</td>
              <td>{{ organization.markup.self.banner.value }}$ / {{ organization.markup.self.video.value }}$</td>
              <td v-if="organization.is_billable">{{ organization.name }}</td>
              <td v-else>{{ organization_name }}</td>
              <td class="invoices">
                <div class="method" v-if="organization.payment_profile && organization.payment_profile.credit_card && organization.payment_profile.credit_card.label">
                  <i class="fab provider" :class="{'fa-cc-visa': organization.payment_profile.credit_card.provider === 'visa', 'fa-cc-mastercard': organization.payment_profile.credit_card.provider === 'mastercard'}"></i>
                  {{ organization.payment_profile.credit_card.label }}
                  <small>{{ $t('account.sub_accounts.expiration') }} {{ organization.payment_profile.credit_card.expiration.month }}-{{ organization.payment_profile.credit_card.expiration.year }}</small>
                </div>
                <div v-else-if="organization.payment_profile && !organization.payment_profile.credit_card">
                  <span class="badge info">
                    {{ organization.payment_profile.type === 'checks' ? $t(`credit_cards.credit_cards_panel.table.checks`) : $t(`credit_cards.credit_cards_panel.table.bank_transfer`) }}
                  </span>
                </div>
                <div v-else>
                    <span class="badge danger">
                      <a href="#/account/credit-cards">
                      {{ $t('account.sub_accounts.missing_credit_card') }}
                    </a>
                    </span>
                </div>
              </td>
              <td>{{ organization.nb_users }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <SubAccountsPopup :company="selected_organization" v-if="show_popup" @update-popup="togglePopup"/>
  </div>
</template>

<script setup>
  import SubAccountsPopup from '../components/subaccounts/SubAccountsPopup.vue';
  import {ref, inject} from 'vue';

  const event_hub = inject('event-hub');
  const sty = inject('sentry');
  const axios = inject('axios');

  const organization_name = ref(JSON.parse(localStorage.user_data).organization.name);
  const organization_list = ref([]);
  const show_popup = ref(false);
  const is_loading = ref(false);

  const selected_organization = ref({});

  const togglePopup = (value) => {
    if (!value) {
      selected_organization.value = {};
    }

    show_popup.value = value;
  };

  const openSubAccountPopup = (organization) => {
    selected_organization.value = organization ?? {};
    show_popup.value = true;
  };

  const getOrganizations = () => {
    is_loading.value = true;

    axios.get(`${process.env.API_URL}/organizations?get_count=1`)
      .then((res) => {
        organization_list.value = res.data;
        is_loading.value = false;
      })
      .catch((error) => {
        is_loading.value = false;
        sty.captureException(error);
      });
  };

  show_popup.value = false;
  getOrganizations();
  event_hub.on('refresh-organizations', getOrganizations);
  event_hub.on('update-popup', togglePopup);
</script>
