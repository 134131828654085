<template>
  <div class="panel">
    <div class="title row">
      <div class="col-6">
        <b>{{ $t('geoindicators.report.tourism.total_visits.title') }}</b>
      </div>

      <div class="col-6 text-right align-self-center">
        <dropdown :is_button="false">
          <template #actions>
            <ul class="list">
              <li @click="updatePeriod('by_month')">
                <label class="radio">
                  {{ $t('geoindicators.report.tourism.total_visits.per_month') }}
                  <input v-model="selected_period" value="by_month" type="radio">
                  <span></span>
                </label>
              </li>
              <li @click="updatePeriod('by_quarter')">
                <label class="radio">
                  {{ $t('geoindicators.report.tourism.total_visits.per_quarter') }}
                  <input v-model="selected_period" value="by_quarter" type="radio">
                  <span></span>
                </label>
              </li>
              <li @click="updatePeriod('by_year')">
                <label class="radio">
                  {{ $t('geoindicators.report.tourism.total_visits.per_year') }}
                  <input v-model="selected_period" value="by_year"type="radio">
                  <span></span>
                </label>
              </li>
            </ul>
          </template>
          <template #title>
            <a class="info">
              {{ $t('geoindicators.report.tourism.total_visits.per_period') }}
              <i class="fas fa-caret-down"></i>
            </a>
          </template>
        </dropdown>
      </div>
    </div>
    <div class="title row">
      <div class="col-md-4">
        <div class="chart-title">
          <h2>{{ $filters.splitNumber( data.visitors[selected_period].avg) }}</h2>
          <p v-if="selected_period === 'by_month'">{{ $t('geoindicators.report.tourism.total_visits.avg_visitors_by_month') }}</p>
          <p v-if="selected_period === 'by_quarter'">{{ $t('geoindicators.report.tourism.total_visits.avg_visitors_by_quarter') }}</p>
          <p v-if="selected_period === 'by_year'">{{ $t('geoindicators.report.tourism.total_visits.avg_visitors_by_year') }}</p>
          <span class="indicator" :style="{background: data.visitors.colors[3]}"></span>
        </div>
      </div>
      <div class="col-md-4">
        <div class="chart-title">
          <h2>{{ $filters.splitNumber( data.excursionists[selected_period].avg) }}</h2>
          <p v-if="selected_period === 'by_month'">{{ $t('geoindicators.report.tourism.total_visits.avg_excursionists_by_month') }}</p>
          <p v-if="selected_period === 'by_quarter'">{{ $t('geoindicators.report.tourism.total_visits.avg_excursionists_by_quarter') }}</p>
          <p v-if="selected_period === 'by_year'">{{ $t('geoindicators.report.tourism.total_visits.avg_excursionists_by_year') }}</p>
          <span class="indicator" :style="{background: data.excursionists.colors[3]}"></span>
        </div>
      </div>
      <div class="col-md-4">
        <div class="chart-title">
          <h2>{{ $filters.splitNumber( data.tourists[selected_period].avg) }}</h2>
          <p v-if="selected_period === 'by_month'">{{ $t('geoindicators.report.tourism.total_visits.avg_tourists_by_month') }}</p>
          <p v-if="selected_period === 'by_quarter'">{{ $t('geoindicators.report.tourism.total_visits.avg_tourists_by_quarter') }}</p>
          <p v-if="selected_period === 'by_year'">{{ $t('geoindicators.report.tourism.total_visits.avg_tourists_by_year') }}</p>
          <span class="indicator" :style="{background: data.tourists.colors[3]}"></span>
        </div>
      </div>
    </div>

    <apexchart
    height="350"
    type="line"
    :options="options"
    :series="series"
    :avg="data.avg">
    </apexchart>
  </div>
</template>

<script setup>
  import {useChartOptions} from '../chart-options';
  import {useI18n} from 'vue-i18n';
  import {useGisWidget} from '../../../composables/gis_widget';
  import {parseDate} from '../../../../js/helpers';
  import {ref, computed, reactive} from 'vue';

  const {t, locale} = useI18n();
  const {DEFAULT_OPTIONS, MULTIPLE_VISITORS_AND_AVERAGE_TOOLTIP, LINE} = useChartOptions(t);

  const props = defineProps({
    data: {
      type: Object,
      required: true
    }
  });

  const {updatePeriod} = useGisWidget(props.data);

  const selected_period = ref('by_month');
  const labels = ref([t('geoindicators.report.tourism.visitors'), t('geoindicators.report.tourism.excursionists'), t('geoindicators.report.tourism.tourists')]);

  const annotations = reactive({
    yaxis: [
      {
        y: 0,
        borderColor: '#969696',
        strokeDashArray: 2,
        label: {
          borderColor: '#ffa000',
          style: {
            color: '#fff',
            background: '#ffa000'
          },
          text: ' '
        }
      }
    ]
  });

  const series = computed(() => {
    return [
      {
        name: t('geoindicators.report.tourism.visitors'),
        data: computeSerie(props.data.visitors).map(([date, nb_visits]) => nb_visits)
      },
      {
        name: t('geoindicators.report.tourism.excursionists'),
        data: computeSerie(props.data.excursionists).map(([date, nb_visits]) => nb_visits)
      },
      {
        name: t('geoindicators.report.tourism.tourists'),
        data: computeSerie(props.data.tourists).map(([date, nb_visits]) => nb_visits)
      }
    ];
  });

  const options = computed(() => {
    let categories = computeSerie(props.data.visitors).map(([date, visits]) => {
      switch (selected_period.value) {
        case 'by_month':
          return parseDate(new Date(date), 'month_year', locale.value);
        case 'by_quarter':
          return date.replace('-', ' ');
        default:
          return date;
      }
    });

    return {
      ...DEFAULT_OPTIONS,
      ...MULTIPLE_VISITORS_AND_AVERAGE_TOOLTIP,
      ...LINE,
      colors: [
        props.data.visitors.colors[2],
        props.data.excursionists.colors[2],
        props.data.tourists.colors[2]
      ],
      labels: labels.value,
      annotations: {
        ...annotations,
        yaxis: [
          {
            ...annotations.yaxis[0],
            borderColor: props.data.visitors.colors[2],
            y: props.data.visitors[selected_period.value].avg
          },
          {
            ...annotations.yaxis[0],
            borderColor: props.data.excursionists.colors[2],
            y: props.data.excursionists[selected_period.value].avg
          },
          {
            ...annotations.yaxis[0],
            borderColor: props.data.tourists.colors[2],
            y: props.data.tourists[selected_period.value].avg
          }
        ]
      },
      xaxis: {
        floating: false,
        categories: categories,
        tickAmount: 12,
        tooltip: {
          enabled: false
        }
      }
    };
  });

  const computeSerie = (data) => {
    let serie = Object.entries(data[selected_period.value].series).sort(([first_period, first_count], [second_period, second_count]) => {
      if (selected_period.value === 'by_quarter') {
        return first_period.localeCompare(second_period);
      } else {
        return new Date(first_period) - new Date(second_period);
      }
    }, []);

    return serie;
  };
</script>
