<template>
  <div class="internal">

    <!-- Header -->
    <header class="title-bar">
      <div class="container">
        <div class="row header-row">
          <div class="col-12 align-self-center">
            <div class="title">
              <h1>Outils internes</h1>
              <span class="badge danger">Ne pas partager</span>
            </div>
          </div>
        </div>
      </div>
    </header>

    <!-- Content -->
    <div class="container">

      <!-- GI Licence Calculator -->
      <div class="row space">
        <div class="col-12">
          <div class="panel">
            <div class="title">
              <b>GéoIndicateurs - Licences</b>
            </div>
            <!-- Industries -->
            <label for="gi-industry">Industrie</label>
            <select id="gi-industry" v-model="gi.industry">
              <optgroup label="Entreprises">
                <option value="retail">Commerces de détail</option>
                <option value="reseller">Consultants, agences et revendeurs</option>
              </optgroup>
              <optgroup label="Institutionnel">
                <option value="city">Villes et municipalités</option>
                <option value="city">MRC</option>
              </optgroup>
              <optgroup label="Tourisme">
                <option value="tourism-attractions">Attractions unitaires (ex: montagne, parc, sentier)</option>
                <option value="other">Festivals ou événements</option>
                <option value="other">Associations ou regroupements</option>
              </optgroup>
              <option value="other">Autres</option>
            </select>
            <!-- Territory limits -->
            <div v-if="gi.industry === 'retail' || gi.industry === 'city' || gi.industry === 'tourism-attractions'">
              <label for="gi-territory">Limites territoriales</label>
              <select id="gi-territory" v-model="gi.territory_limits">
                <option value="state">Province ou État</option>
                <option value="country">Pays</option>
                <option value="world">Monde</option>
              </select>
            </div>
            <!-- If retail -->
            <div v-if="gi.industry === 'retail'">
              <!-- Stores size -->
              <label for="gi-industry">Taille des succursales</label>
              <select id="gi-industry" v-model="gi.stores_size">
                <option value="small">Petites</option>
                <option value="medium">Moyennes</option>
                <option value="large">Grandes</option>
              </select>
              <!-- Stores qt -->
              <label for="gi-stores-qt">Nombre de succursales</label>
              <input type="number" id="gi-stores-qt" min="1" v-model="gi.stores_qt">
            </div>
            <!-- If city -->
            <div v-if="gi.industry === 'city'">
              <!-- Population -->
              <label for="gi-population">Population</label>
              <input type="number" id="gi-population" min="1000" v-model="gi.population">
            </div>
            <!-- If tourism -->
            <div v-if="gi.industry === 'tourism-attractions'">
              <!-- Visitors -->
              <label for="gi-population">Visiteurs annuels</label>
              <input type="number" id="gi-visitors" min="1000" v-model="gi.visitors">
            </div>
            <!-- Price for retail and city -->
            <div class="footer" v-if="gi.industry === 'retail' || gi.industry === 'city' || gi.industry === 'tourism-attractions'">
              <h3>Prix annuel régulier: {{ `${$filters.ifZero($filters.splitNumber(gi_price))}$ ${currency}` }}</h3>
              <div v-if="gi.industry === 'retail' && gi.stores_qt >= 3">
                <div class="divider"></div>
                <h3>Prix spéciaux:</h3>
                <ul>
                  <li>Année 1: &nbsp;&nbsp; {{ `${$filters.ifZero($filters.splitNumber(gi_price * 0.5))}$ ${currency}` }}</li>
                  <li>Année 2: &nbsp; {{ `${$filters.ifZero($filters.splitNumber(gi_price * 0.7))}$ ${currency}` }}</li>
                  <li>Année 3: &nbsp; {{ `${$filters.ifZero($filters.splitNumber(gi_price * 0.9))}$ ${currency}` }}</li>
                </ul>
              </div>
            </div>
            <!-- Price for reseller -->
            <div class="footer" v-if="gi.industry === 'reseller'">
              <div class="row">
                <div class="col-md-4">
                  <h3>Forfait A</h3>
                  <ul>
                    <li>Prix mensuel: 500$</li>
                    <li>Escompte du rapports: 50%</li>
                  </ul>
                </div>
                <div class="col-md-4">
                  <h3>Forfait B</h3>
                  <ul>
                    <li>Prix mensuel: 3 500$</li>
                    <li>Escompte du rapports: 70%</li>
                  </ul>
                  <small>Plus avantageux à partir d'environ 12 rapports par mois.</small>
                </div>
                <div class="col-md-4">
                  <h3>Forfait C</h3>
                  <ul>
                    <li>Prix mensuel: 9 500$</li>
                    <li>Escompte du rapports: 90%</li>
                  </ul>
                  <small>Plus avantageux à partir d'environ 25 rapports par mois.</small>
                </div>
              </div>
            </div>
            <!-- Price for reseller -->
            <div class="footer" v-if="gi.industry === 'other'">
              <h3>À discuter avec Nicolas</h3>
            </div>
          </div>
        </div>
      </div>

      <hr>

      <!-- GI Per Report Calculator -->
      <div class="row space">
        <div class="col-12">
          <div class="panel">
            <div class="title">
              <b>GéoIndicateurs - À la carte</b>
            </div>
            <!-- Report type -->
            <label for="report-type">Type de rapport</label>
            <select id="report-type" v-model="report.type">
              <!--<option value="visits">Visites</option>
              <option value="market-shares">Parts de marché</option>-->
              <option value="economical-impacts">Impacts économiques</option>
            </select>
            <!-- Areas -->
            <label for="report-areas">Nombre de zones</label>
            <input type="number" id="report-areas" min="1" v-model="report.areas">
            <!-- Duration -->
            <label for="report-duration">Durée</label>
            <select id="report-duration" v-model="report.duration">
              <option value="6">0.5 an</option>
              <option value="12">1 an</option>
              <option value="18">1.5 ans</option>
              <option value="24">2 ans</option>
              <option value="30">2.5 ans</option>
              <option value="36">3 ans</option>
              <option value="42">3.5 ans</option>
              <option value="48">4 ans</option>
              <option value="54">4.5 ans</option>
              <option value="60">5 ans</option>
              <option value="66">5.5 ans</option>
              <option value="72">6 ans</option>
              <option value="78">6.5 ans</option>
              <option value="84">7 ans</option>
              <option value="90">7.5 ans</option>
              <option value="96">8 ans</option>
            </select>
            <!-- Price for retail and city -->
            <div class="footer">
              <h3>Prix: {{ `${$filters.ifZero($filters.splitNumber(report_price))}$ ${currency}` }}</h3>
            </div>
          </div>
        </div>
      </div>

      <hr>

      <!-- GA Calculator -->
      <div class="row space">
        <div class="col-12">
          <div class="panel">
            <div class="title">
              <b>Calculatrice GéoAudiences</b>
            </div>
            <!-- Conditions -->
            <p>Points à considérer:</p>
            <ul>
              <li>Le frais minimal est de 500$ par audience, peu importe la taille de l'audience.</li>
              <li>Le client accepte le CPM et peut mettre un budget maximal, la taille finale de l'audience (et donc le prix final) est connue après génération.</li>
              <li>La taille de l'audience finale doit être > 1 000 devices pour des raisons de vie privée.</li>
              <li>Le "matching rate" moyen sur Facebook est de 50-75%, mais nous facturons tous les devices exportés.</li>
            </ul>
            <div class="divider"></div>
            <!-- How to -->
            <p>Procédure pour générer une audience:</p>
            <ol>
              <li>Faire préapprouver par Nicolas, car nous nous réservons le droit de refuser pour des raisons éthiques.</li>
              <li>
                Préparer le contrat dans Hubspot et le faire signer par le client.<br>
                <small>Note: si le client choisit un ou des ajouts, les CPM s'aditionnent à celui de base.</small>
              </li>
              <li>
                Le client doit préparer ses zones cibles dans la plateforme (en créant une campagne brouillon) et nous fournir le fichier GeoJSON.<br>
                <small>Note: le client peut utiliser l'outil d'importation au besoin s'il a beaucoup de lieux.</small>
              </li>
              <li>Déplacer le deal dans la colonne "To Deploy" de Hubspot avec le contrat signé et les détails de l'audience (zones, durée, filtres, etc.).</li>
              <li>Notre équipe technique va générer l'audience et le fichier crypté à importer dans Facebook.</li>
              <li>Envoyer le fichier au client avec les instructions d'importation dans Facebook.</li>
            </ol>
            <div class="divider"></div>
            <!-- Pricing -->
            <table>
              <thead>
                <tr>
                  <th>Ciblage</th>
                  <th>CPM</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span class="badge info">Base</span>
                    Les devices s'étant rendu dans des lieux.<br>
                    <small></small>
                  </td>
                  <td>20$</td>
                </tr>
                <tr>
                  <td>
                    <span class="badge">Ajout</span>
                    Durée des visites dans les lieux<br>
                    <small>Les devices qui sont allés dans le lieu A et qui sont restés plus que 1h.</small>
                  </td>
                  <td>+10$</td>
                </tr>
                <tr>
                  <td>
                    <span class="badge">Ajout</span>
                    Fréquence des visites dans les lieux<br>
                    <small>Les devices qui sont allés dans le lieu A plus de 3 fois.</small>
                  </td>
                  <td>+10$</td>
                </tr>
                <tr>
                  <td>
                    <span class="badge">Ajout</span>
                    Intersection entre des lieux<br>
                    <small>Les devices qui sont allés dans le lieu A et le lieu B.</small>
                  </td>
                  <td>+15$</td>
                </tr>
                <tr>
                  <td>
                    <span class="badge">Ajout</span>
                    Soustraction entre des lieux<br>
                    <small>Les devices qui sont allés dans le lieu A, mais pas le lieu B.</small>
                  </td>
                  <td>+15$</td>
                </tr>
                <tr>
                  <td>
                    <span class="badge">Ajout</span>
                    Lieux de résidence<br>
                    <small>Les devices qui sont allés dans le lieu A et qui habitent dans une ville ou un code postal (3 premiers chiffres).</small>
                  </td>
                  <td>+20$</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <hr>

      <!-- GM Segments Calculator -->
      <div class="row space">
        <div class="col-12">
          <div class="panel">
            <div class="title">
              <b>GéoMarketing - Segments démographiques</b>
            </div>
            <!-- Conditions -->
            <p>Conditions minimales, <u>par campagne</u>, pour ajouter des segments démographiques:</p>
            <ul>
              <li>Budget: > 5 000$ par période de 30 jours</li>
              <li>Lieux ciblés: > 50 lieux achalandés</li>
            </ul>
            <div class="divider"></div>
            <!-- How to -->
            <p>Procédure pour ajouter des segments à une campagne:</p>
            <ol>
              <li>Faire préapprouver par Nicolas, car nous nous réservons le droit de refuser pour des raisons éthiques.</li>
              <li>
                Préparer le contrat dans Hubspot et le faire signer par le client.<br>
                <small>Note: si le client choisit plusieurs segments, les CPM s'aditionnent (ex: âge + genre = +6$ CPM).</small>
              </li>
              <li>
                Le client doit préparer sa campagne en brouillon et nous notifier lorsqu'elle est prêt, <u>mais ne doit pas la publier</u>.<br>
                <small>Note: il n'est pas possible d'ajouter des segments à une campagne active ou en approbation, seulement aux campagnes brouillons jamais publiées. Il n'est pas possible non plus de retirer un segment après la publication.</small>
              </li>
              <li>Déplacer le deal dans la colonne "To Deploy" de Hubspot avec le contrat signé et l'URL (ID) de la campagne brouillon du client.</li>
              <li>Notre équipe technique va adapter le CPM, ajouter les segments puis publier la campagne.</li>
              <li>Une fois la campagne active, le client pourra la modifier comme à l'habitude, les segments resteront attachés à la campagne.</li>
            </ol>
            <div class="divider"></div>
            <!-- CPM pricing -->
            <table>
              <thead>
                <tr>
                  <th>Segment démographiques</th>
                  <th>Ajout au CPM</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Âge<br>
                    <small>Choix: 18-24 ans | 25-34 ans | 35-44 ans | 45-54 ans | 55-64 ans | 65-74 ans | 75-84 ans</small>
                  </td>
                  <td>+3$</td>
                </tr>
                <tr>
                  <td>
                    Genre<br>
                    <small>Choix: Hommes | Femmes</small>
                  </td>
                  <td>+3$</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
  import {ref, reactive, computed} from 'vue';

  const currency = ref(JSON.parse(localStorage.user_data).organization.markup.currency);

  const gi = reactive({
    industry: 'retail',
    territory_limits: 'state',
    stores_size: 'small',
    stores_qt: 1,
    population: 20000,
    visitors: 100000
  });

  const report = reactive({
    type: 'economical-impacts',
    areas: 136,
    duration: 60
  });

  const gi_price = computed(() => {
    let price = 0;

    switch (gi.industry) {
      case 'retail':
        price = getTerritoryRatio() * getStoresSizeRatio() * getEnterpriseVolumeDiscount() * gi.stores_qt;
        break;
      case 'city':
        price = getTerritoryRatio() * getCityVolumeDiscount();
        break;
      case 'tourism-attractions':
        price = getTerritoryRatio() * getTourismVolumeDiscount();
        break;
    }

    return Math.round(price / 250) * 250;
  });

  const report_price = computed(() => {
    switch (report.type) {
      case 'visits':
        return 1;
      case 'market-shares':
        return 1.25;
      case 'economical-impacts':
        return (7000 + (report.areas * 15)) * (report.duration * 0.005 + 1);
    }
  });

  const getTerritoryRatio = () => {
    switch (gi.territory_limits) {
      case 'state':
        return 1;
      case 'country':
        return 1.25;
      case 'world':
        return 1.5;
    }
  };

  const getStoresSizeRatio = () => {
    switch (gi.stores_size) {
      case 'small':
        return 6000;
      case 'medium':
        return 10000;
      case 'large':
        return 13000;
    }
  };

  const getEnterpriseVolumeDiscount = () => {
    return Math.max(
      parseFloat((1.24454 - 0.150745 * Math.log(Math.abs(16.6785 * gi.stores_qt - 21.7475))).toFixed(2)),
      0.15
    );
  };

  const getCityVolumeDiscount = () => {
    return 360.111 * Math.pow(gi.population, 0.440761) - 4817.42;
  };

  const getTourismVolumeDiscount = () => {
    return Math.max(292421 * Math.pow(gi.visitors, 0.00748459) - 302832, 8000);
  };
</script>
