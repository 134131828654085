export function useChartOptions(t, tm) {
  const DEFAULT_OPTIONS = {
    colors: ['rgba(255, 160, 0, 0.8)'],
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.55,
        }
      },
    },
    chart: {
      fontFamily: 'Poppins, sans-serif',
      foreColor: '#969696',
      width: '100%',
      toolbar: {
        show: false,
        tools: {
          zoom: false
        }
      },
      zoom: {
        enabled: false,
        type: 'x'
      }
    },
    grid: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    default_series: [],
    noData: {
      text: t('geoindicators.report.widgets.no_data_select_fence')
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: (value) => (value ? value.toLocaleString() : '0')
      }
    }
  };

  const STACKED = {
    colors: ['rgba(255, 75, 0, 0.6)', 'rgba(255, 160, 0, 0.6)', 'rgba(255, 160, 0, 0.4)'],
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      inverseOrder: false
    },
    chart: {
      fontFamily: 'Poppins, sans-serif',
      foreColor: '#969696',
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false
      }
    },
    tooltip: {
      shared: false,
      intersect: true,
      custom: ({series, seriesIndex, dataPointIndex, w}) => (
        `<div class="apex_custom_tooltip text-center">
          <span>
            ${w.globals.labels[dataPointIndex]}. ${w.globals.tooltip.legendLabels[seriesIndex].textContent}
          </span>
          <h3>
            ${series[seriesIndex][dataPointIndex]} ${t('geoindicators.report.widgets.minutes_tooltip')}
          </h3>
        </div>`
      )
    }
  };

  const SIMPLE = {
    colors: ['#ffa000'],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.5,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0,
        stops: [0, 100]
      }
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        bottom: 0
      }
    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      labels: {
        show: false,
        minWidth: 0
      },
      axisTicks: {
        show: false
      },
    },
    labels: [],
    xaxis: {
      categories: [],
      labels: {
        show: false,
        minWidth: 0
      },
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      },
      tooltip: {
        enabled: false
      }
    },
    chart: {
      fontFamily: 'Poppins, sans-serif',
      sparkline: {
        enabled: true
      },
      toolbar: {
        show: false
      },
      id: 'total',
      group: 'social'
    }
  };

  const GRADIENT = {
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.2,
        inverseColors: false,
        opacityFrom: 0.2,
        opacityTo: 1,
        stops: [0, 100],
        colorStops: [
          {
            offset: 0,
            color: '#ffa000',
            opacity: 0.6
          },
          {
            offset: 200,
            color: '#ff4b00',
            opacity: 0.6
          }
        ]
      }
    }
  };

  const HORIZONTAL_GRADIENT = {
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'horizontal',
        shadeIntensity: 0.2,
        inverseColors: false,
        opacityFrom: 0.2,
        opacityTo: 1,
        stops: [0, 10, 100],
        colorStops: [
          {
            offset: 0,
            color: '#ff4b00',
            opacity: 0.6
          },
          {
            offset: 200,
            color: '#ffa000',
            opacity: 0.6
          }
        ]
      }
    }
  };

  const AREA = {
    colors: ['#ffa000'],
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.5,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0,
        stops: [0, 100]
      }
    }
  };

  const LINE = {
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    }
  };

  const DONUT = {
    labels: [],
    chart: {
      type: 'donut'
    },
    stroke: {
      width: 0
    },
    dataLabels: {
      enabled: true,
      style: {
        fontFamily: 'Poppins, sans-serif'
      },
      dropShadow: {
        enabled: false
      }
    },
    legend: {
      position: 'bottom',
      fontFamily: 'Poppins, sans-serif',
      labels: {
        colors: '#969696'
      }
    }
  };

  const RADAR = {
    stroke: {
      show: true,
      width: 2,
      colors: ['#ffa000'],
      dashArray: 0
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 0.3,
      colors: ['#ffa000']
    },
    legend: {
      position: 'bottom',
      fontFamily: 'Poppins, sans-serif',
      fontSize: '22px',
      labels: {
        colors: '#969696'
      }
    },
    yaxis: {
      show: false
    },
    markers: {
      size: 5,
      colors: ['#ffa000']
    }
  };

  const FORECAST = {
    colors: ['#969696'],
    chart: {
      sparkline: {
        enabled: true
      },
      id: 'forecast',
      group: 'forecast'
    }
  };

  const HEATMAP = {
    chart: {
      type: 'heatmap',
      fontFamily: 'Poppins, sans-serif'
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#222']
      },
      formatter: function(val) {
        if (val === 0) {
          return `${val}%`;
        } else if (val !== null && val !== 100) {
          return `${val.toFixed(2)}%`;
        }

        return '';
      }
    },
    xaxis: {
      position: 'top',
      labels: {
        style: {
          fontFamily: 'Poppins, sans-serif',
          fontSize: '11px'
        },
        rotate: -70
      }
    },
    yaxis: {
      labels: {
        style: {
          fontFamily: 'Poppins, sans-serif'
        }
      }
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      },
      active: {
        filter: {
          type: 'none'
        }
      }
    },
    legend: {
      show: false
    }
  };

  const PERCENT_TOOLTIP = {
    tooltip: {
      custom: ({series, seriesIndex, dataPointIndex, w}) => (
        `<div class="apex_custom_tooltip text-center">
        <span>${w.globals.categoryLabels[dataPointIndex]}</span>
        <h3>${series[seriesIndex][dataPointIndex]} %</h3>
        </div>`
      )
    }
  };

  const DEMOGRAPHIC_TOOLTIP = {
    tooltip: {
      custom: ({series, seriesIndex, dataPointIndex, w}) => (
        `<div class="apex_custom_tooltip text-center">
          <span>${w.globals.labels[dataPointIndex]}</span>
          <h3>${series[seriesIndex][dataPointIndex]} %</h3>
        </div>`
      )
    }
  };

  const FREQUENCY_TOOLTIP = {
    tooltip: {
      custom: ({series, seriesIndex, dataPointIndex, w}) => (
        `<div class="apex_custom_tooltip text-center">
          <span>${w.globals.seriesNames[seriesIndex]}</span>
          <span>${w.globals.labels[dataPointIndex]}</span>
          <h3>${series[seriesIndex][dataPointIndex]} %</h3>
        </div>`
      )
    }
  };

  const MINUTES_TOOLTIP = {
    tooltip: {
      custom: ({series, seriesIndex, dataPointIndex, w}) => (
        `<div class="apex_custom_tooltip text-center">
          <span>${w.globals.categoryLabels[dataPointIndex]}</span>
          <h3>
            ${series[seriesIndex][dataPointIndex]} ${t('geoindicators.report.widgets.minutes_tooltip')}
          </h3>
        </div>`
      )
    }
  };

  const SUMMARY_SPEED_TOOLTIP = {
    tooltip: {
      custom: ({series, seriesIndex, dataPointIndex, w}) => (
        `<div class="apex_custom_tooltip text-center">
          <span>${w.globals.categoryLabels[dataPointIndex]}</span>
          <h3>
            ${series[seriesIndex][dataPointIndex]} km/h
          </h3>
        </div>`
      )
    }
  };

  const SPEED_TOOLTIP = {
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        const speed_definition = [
          tm('geoindicators.report.widgets.visits_entry_exits.speed_definition')[0],
          tm('geoindicators.report.widgets.visits_entry_exits.speed_definition')[1],
          tm('geoindicators.report.widgets.visits_entry_exits.speed_definition')[2],
          tm('geoindicators.report.widgets.visits_entry_exits.speed_definition')[3],
          tm('geoindicators.report.widgets.visits_entry_exits.speed_definition')[4],
          tm('geoindicators.report.widgets.visits_entry_exits.speed_definition')[4],
          tm('geoindicators.report.widgets.visits_entry_exits.speed_definition')[4]
        ];

        return `<div class="apex_custom_tooltip text-center">
          <span>${w.globals.labels[dataPointIndex]}</span>
          <span>(${speed_definition[dataPointIndex]})</span>
          <h3>${series[seriesIndex][dataPointIndex]} %</h3>
        </div>`;
      }
    }
  };

  const VISITS_TOOLTIP = {
    tooltip: {
      custom: ({series, seriesIndex, dataPointIndex, w}) => (
        `<div class="apex_custom_tooltip text-center">
          <span>${w.globals.categoryLabels[dataPointIndex]}</span>
          <h3>
            ${series[seriesIndex][dataPointIndex].toLocaleString()} ${t('geoindicators.report.widgets.visits_tooltip')}
          </h3>
        </div>`
      )
    }
  };

  const VISITS_TOOLTIP_DONUT = {
    tooltip: {
      custom: ({series, seriesIndex, dataPointIndex, w}) => (
        `<div class="apex_custom_tooltip text-center" style="background: ${w.config.colors[seriesIndex]}">
          <span>${w.globals.labels[seriesIndex]}</span>
          <h3>
            ${series[seriesIndex]} ${t('geoindicators.report.widgets.visits_tooltip')}
          </h3>
        </div>`
      )
    }
  };

  const VISITORS_TOOLTIP = {
    tooltip: {
      custom: ({series, seriesIndex, dataPointIndex, w}) => (
        `<div class="apex_custom_tooltip text-center">
          <span>${w.globals.categoryLabels[dataPointIndex]}</span>
          <h3>
            ${series[seriesIndex][dataPointIndex].toLocaleString()} ${t('geoindicators.report.widgets.visitors_tooltip')}
          </h3>
        </div>`
      )
    }
  };

  const VISITORS_TOOLTIP_DONUT = {
    tooltip: {
      custom: ({series, seriesIndex, dataPointIndex, w}) => (
        `<div class="apex_custom_tooltip text-center" style="background: ${w.config.colors[seriesIndex]}">
          <span>${w.globals.labels[seriesIndex]}</span>
          <h3>
          ${Number(w.globals.seriesPercent[seriesIndex]).toFixed(1)} %
          </h3>
        </div>`
      )
    }
  };

  const MULTIPLE_PERCENT_TOOLTIP = {
    tooltip: {
      custom: ({series, seriesIndex, dataPointIndex, w}) => `
        <div class="apex_custom_tooltip text-center">
          <span>${w.globals.categoryLabels[dataPointIndex]}</span>
          ${series
    .map((serie, index) => (serie.length
      ? `<h3><i class="color" style="background: ${w.config.colors[index]}"></i>${w.globals.seriesNames[index]}: ${series[index][dataPointIndex]}%</h3>`
      : '')
    )
    .join('')}
        </div>`
    }
  };

  const MULTIPLE_VISITORS_AND_AVERAGE_TOOLTIP = {
    tooltip: {
      custom: ({series, seriesIndex, dataPointIndex, w}) => (
        `<div class="apex_custom_tooltip text-center">
          <span>${w.globals.categoryLabels[dataPointIndex]}</span>
          ${series.map((serie, index) => {
          if (serie.length) {
            const value = series[index][dataPointIndex];
            const sum = value ? value.toLocaleString() : '0';

            return `<h3><i class="color" style="background: ${w.config.colors[index]}"></i>${sum} ${w.globals.seriesNames[index]}</h3>`;
          }

          return '';
        }).join('')}
        </div>`
      )
    }
  };

  const CAMPAIGN_STATS_TOOLTIP = {
    tooltip: {
      shared: true,
      intersect: false,
      custom: ({series, seriesIndex, dataPointIndex, w}) => {
        const category_labels = w.globals.categoryLabels || w.globals.labels || [];
        const label = category_labels[dataPointIndex] || '';
        const date = new Date(label);
        const formatted_date = !isNaN(date.getTime())
          ? date.toISOString().split('T')[0]
          : label;

        return (
          `<div class="apex_custom_tooltip text-center" style="padding:10px;">
            <span>${formatted_date}</span> 
            ${w.globals.seriesNames
            .map((name, index) => {
              const value = series[index][dataPointIndex];

              if (value !== undefined) {
                let formatted_value = value ? value.toLocaleString() : '0';

                if (name === 'Total') {
                  formatted_value = (formatted_value + '$').replace(',', '.');
                }

                return `<h3 style="display:block;">
              <i class="color" style="background: ${w.config.colors[index]};"></i>
              ${formatted_value} ${name}
            </h3>`;
              }

              return '';
            })
            .join('')}
          </div>`);
      }
    }
  };

  const RADAR_TOOLTIP = {
    tooltip: {
      custom: ({series, seriesIndex, dataPointIndex, w}) => (
        `<div class="apex_custom_tooltip text-center">
        <span>${w.globals.labels[dataPointIndex]}</span>
        <h3>${series[seriesIndex][dataPointIndex]} %</h3>
        </div>`
      )
    }
  };

  const LOADING_OPTIONS = {
    colors: ['#969696'],
    chart: {
      sparkline: {
        enabled: true
      }
    }
  };

  const NO_DATA_MESSAGE = {
    noData: {
      text: t('geoindicators.report.widgets.no_data_processing')
    }
  };

  return {
    DEFAULT_OPTIONS,
    STACKED,
    SIMPLE,
    GRADIENT,
    HORIZONTAL_GRADIENT,
    AREA,
    LINE,
    DONUT,
    RADAR,
    FORECAST,
    HEATMAP,
    PERCENT_TOOLTIP,
    DEMOGRAPHIC_TOOLTIP,
    FREQUENCY_TOOLTIP,
    MINUTES_TOOLTIP,
    SUMMARY_SPEED_TOOLTIP,
    SPEED_TOOLTIP,
    VISITS_TOOLTIP,
    VISITS_TOOLTIP_DONUT,
    VISITORS_TOOLTIP,
    VISITORS_TOOLTIP_DONUT,
    MULTIPLE_PERCENT_TOOLTIP,
    MULTIPLE_VISITORS_AND_AVERAGE_TOOLTIP,
    CAMPAIGN_STATS_TOOLTIP,
    RADAR_TOOLTIP,
    LOADING_OPTIONS,
    NO_DATA_MESSAGE
  };
}
