<template>
  <div class="panel">
    <div class="title">
      <b>{{ title }}</b>
    </div>

    <apexchart
    height="350"
    type="bar"
    :options="options"
    :series="series">
    </apexchart>
  </div>
</template>

<script setup>
  import {useChartOptions} from '../chart-options';
  import {useApexChart} from '../../../composables/apexchart';

  import {computed} from 'vue';
  import {useI18n} from 'vue-i18n';

  const {t, tm} = useI18n();
  const {DEFAULT_OPTIONS, HORIZONTAL_GRADIENT, FREQUENCY_TOOLTIP} = useChartOptions(t);
  const {get_max_yaxis_value} = useApexChart();

  const props = defineProps({
    title: {
      type: String,
      required: true,
      default: ''
    },
    data: {
      type: Object,
      required: true,
      default: () => ({})
    }
  });

  const series = computed(() => {
    return [{
      name: '',
      data: Object.values(props.data)
    }];
  });

  const options = computed(() => {
    return {
      ...DEFAULT_OPTIONS,
      ...HORIZONTAL_GRADIENT,
      ...FREQUENCY_TOOLTIP,
      xaxis: {
        categories: tm('geoindicators.report.tourism.frequency.categories'),
        max: get_max_yaxis_value(props.data),
        tickAmount: 5
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      }
    };
  });
</script>
