const ExcelJS = require('exceljs');

const TEMPLATE_PATH = 'assets/template_metriques.xlsx';

const fetchExcelDocument = async function(url) {
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(`Failed to fetch template: ${response.statusText}`);
  }

  return await response.arrayBuffer();
};


const convertToXlsxBuffer = async function(name, computed_report) {
  const template_array_buffer = await fetchExcelDocument(TEMPLATE_PATH);
  const workbook = new ExcelJS.Workbook();

  await workbook.xlsx.load(template_array_buffer);

  const worksheet = workbook.getWorksheet('Métriques');
  worksheet.getCell('B1').value = name;

  const start_row = 4; // according to the template
  const title_row = worksheet.getRow(start_row);
  let current_metric_index = 2;
  const data_row = worksheet.getRow(start_row + 1);
  data_row.getCell(1).value = 'All fences';

  for (const metric in computed_report) {
    const data = computed_report[metric];
    const title = metric;

    title_row.getCell(current_metric_index).value = title;
    data_row.getCell(current_metric_index).value = data;
    current_metric_index++;
  }

  return workbook.xlsx.writeBuffer();
};

export {
  convertToXlsxBuffer
};
