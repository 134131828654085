export const mapOptions = {
  scaleControl: true,
  fullscreenControl : false,
  panControl : false,
  gestureHandling: window.innerWidth < 576 ? "cooperative" : "greedy",
  rotateControl : false,
  clickableIcons : false,
  controlSize : 35,
  tilt : 0,
  minZoom: 2,
  maxZoom: 18,
  draggableCursor : 'crosshair',
  mapTypeControlOptions: {
    style : 1,
    position : 1,
    mapTypeIds : ['roadmap', 'satellite']
  },
  zoomControlOptions: {
    position : 6
  },
  restriction: {
    latLngBounds: {
      north: 83.8,
      south: -57,
      west: -180,
      east: 180,
    },
    strictBounds: false
  }
};
