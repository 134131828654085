<template>
  <a @click="getInvoicePdf" :class="{'cell-loading': downloading}">
    <i class="far fa-file-pdf"></i>
    <span v-if="!downloading">{{ $t('account.invoices.download_pdf') }}</span>
    <span v-if="downloading">{{ $t('account.invoices.generating') }}</span>
  </a>
</template>

<script setup>
  import {ref, inject} from 'vue';

  const sty = inject('sentry');
  const axios = inject('axios');
  const downloading = ref(false);

  const props = defineProps({
    id: {
      type: String,
      required: true
    }
  });

  const getInvoicePdf = () => {
    if (!downloading.value) {
      downloading.value = true;
      axios.get(`${process.env.API_URL}/accounting/invoices/${props.params.id}/pdf`, {responseType: 'arraybuffer'})
        .then((res) => {
          downloading.value = false;

          const pdf_url = URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));

          window.open(pdf_url);
        })
        .catch((error) => {
          downloading.value = false;
          sty.captureException(error);
        });
    }
  };
</script>
