<template>
  <div class="cross-visits">
    <div class="panel">
      <div class="title">
        <b>{{ $t('geoindicators.report.cross_visits.heatmap.title') }}</b>
        <aside class="actions">
          <a @click="exportChart" class="info">
            <i class="fas fa-file-excel"></i>
            <span class="d-none d-sm-inline">{{ $t('campaign.stats.export_csv') }}</span>
          </a>
        </aside>
      </div>

      <apexchart
        id="cross-visits-chart"
        height="750"
        type="heatmap"
        :options="heatmap_options"
        :series="heatmap_series">
      </apexchart>
    </div>
  </div>
</template>

<script setup>
  import {ref, computed, onMounted, onBeforeUnmount, nextTick} from 'vue';
  import ApexCharts from 'apexcharts';
  import {useChartOptions} from '../chart-options';
  import {useI18n} from 'vue-i18n';

  const {t} = useI18n();
  const {DEFAULT_OPTIONS, HEATMAP} = useChartOptions(t);

  const props = defineProps({
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    show_tooltip: {
      type: Boolean,
      required: false
    },
    report: {
      type: String,
      required: true
    }
  });

  const heatmap_options = ref({});
  const cell_width = ref(0);
  const resize_timeout = ref(null);

  const heatmap_series = computed(() => {
    return Object.entries(props.data).reverse().map(([category, entries]) => ({
      name: category,
      data: entries.map((entry) => ({
        x: entry.location,
        y: entry.percentage,
        avg_days_between: entry.avg_days_between,
      }))
    }));
  });

  const xaxis_categories = computed(() => {
    return [...Object.keys(props.data), 'Total'];
  });

  const getPercentages = () => {
    let min = Infinity;
    let max = -Infinity;

    for (const row_key in props.data) {
      const row = props.data[row_key];

      for (let i = 0; i < row.length; i++) {
        const entry = row[i];

        if (entry.percentage && entry.percentage !== 100) {
          min = Math.min(min, entry.percentage);
          max = Math.max(max, entry.percentage);
        }
      }
    }

    return {min, max};
  };

  const exportChart = () => {
    const categories = [...Object.keys(props.data), 'Total'];
    const series = [...heatmap_series.value].reverse();
    const headers = [' ', ...categories.map((category) => `"${category}"`)];
    let percentage_content = `${headers.join(',')}\n`;
    let avg_days_content = `${headers.join(',')}\n`;

    for (let i = 0; i < series.length; i++) {
      const row = series[i];
      const percentage_row_data = [`"${row.name}"`];
      const avg_days_row_data = [`"${row.name}"`];

      for (let j = 0; j < row.data.length; j++) {
        const cell = row.data[j];
        const percentage = cell.y !== 100 && cell.y !== 0 ? cell.y.toFixed(2) : cell.y || 0;
        const avg_days = cell.avg_days_between !== undefined && cell.avg_days_between !== null ? cell.avg_days_between : '';

        percentage_row_data.push(percentage);
        avg_days_row_data.push(avg_days);
      }

      percentage_content += `${percentage_row_data.join(',')}\n`;
      avg_days_content += `${avg_days_row_data.join(',')}\n`;
    }

    const content = `Cross Visits Percentages\n${percentage_content}\n\nAverage Days Between Cross Visits\n${avg_days_content}`;
    const blob = new Blob([content], {type: 'text/csv;charset=utf-8;'});
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = `${props.report._id}_${props.report.name}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const hoverCell = (chartContext, seriesIndex, dataPointIndex, applyStyle) => {
    const total_series = chartContext.opts.series.length;

    if (seriesIndex + dataPointIndex === total_series - 1) return;

    const getCellGroupText = (i, j) => {
      const cell = document.querySelector(`.apexcharts-heatmap-rect[i="${i}"][j="${j}"]`);

      if (!cell) return {cell: null, text: null, group: null};

      const parent_group = cell.closest('g');
      const text_elements = parent_group.querySelectorAll('.apexcharts-datalabel');
      const text = text_elements[j];

      return {cell, text};
    };

    const {cell: hovered_cell, text: hovered_text} = getCellGroupText(seriesIndex, dataPointIndex);
    const {cell: twin_cell, text: twin_text} = getCellGroupText(
      total_series - 1 - dataPointIndex,
      total_series - 1 - seriesIndex
    );

    if (hovered_cell) hovered_cell.style.filter = `brightness(${applyStyle ? 0 : 1})`;
    if (twin_cell) twin_cell.style.filter = `brightness(${applyStyle ? 0 : 1})`;

    if (hovered_text) hovered_text.style.fill = applyStyle ? 'white' : '#222222';
    if (twin_text) twin_text.style.fill = applyStyle ? 'white' : '#222222';
  };

  const updateCellWidth = () => {
    const cell = document.querySelector(`.apexcharts-heatmap-rect[i="0"][j="0"]`);

    if (cell) {
      cell_width.value = cell.width.baseVal.value || 0;
      addAnnotation();
    }
  };

  const addAnnotation = () => {
    const annotation = {
      xaxis: [
        {
          x: cell_width.value * (xaxis_categories.value.length - 1),
          borderColor: cell_width.value ? '#000000' : 'transparent',
          strokeDashArray: 0,
          borderWidth: 3
        }
      ]
    };

    ApexCharts.exec('cross-visits-chart', 'updateOptions', {annotations: annotation}, false);
  };

  const handleResize = () => {
    clearTimeout(resize_timeout.value);

    resize_timeout.value = setTimeout(() => {
      updateCellWidth();
    }, 1000);
  };

  const initializeChart = () => {
    window.addEventListener('resize', handleResize);

    nextTick(() => {
      updateCellWidth();
    });
  };

  onMounted(() => {
    initializeChart();

    const {min, max} = getPercentages();

    const shortened_categories = xaxis_categories.value.map((category) =>
      (category.length > 12 ? category.slice(0, 12) + '...' : category)
    );

    heatmap_options.value = {
      ...DEFAULT_OPTIONS,
      ...HEATMAP,
      xaxis: {
        ...HEATMAP.xaxis,
        categories: shortened_categories,
      },
      plotOptions: {
        heatmap: {
          shadeIntensity: 0.7,
          useFillColorAsStroke: true,
          radius: 0,
          colorScale: {
            ranges: [
              {from: 0, to: 0, color: '#FFF6E4'},
              {from: 0.01, to: min / 2, color: '#FFD78A'},
              {from: min / 2, to: min, color: '#FFC556'},
              {from: min, to: max / 2, color: '#FFB626'},
              {from: max / 2, to: max, color: '#FFAA00'},
              {from: 100, to: 100, color: '#FFFFFF'}
            ],
          }
        }
      },
      chart: {
        ...HEATMAP.chart,
        id: 'cross-visits-chart',
        events: {
          dataPointMouseEnter: (event, chartContext, {seriesIndex, dataPointIndex}) => {
            hoverCell(chartContext, seriesIndex, dataPointIndex, true);
          },
          dataPointMouseLeave: (event, chartContext, {seriesIndex, dataPointIndex}) => {
            hoverCell(chartContext, seriesIndex, dataPointIndex, false);
          }
        },
        toolbar: {
          show: false
        }
      },
      tooltip: {
        custom: ({series, seriesIndex, dataPointIndex, w}) => {
          const x_label = xaxis_categories.value[dataPointIndex];
          const y_label = w.globals.seriesNames[seriesIndex];
          const value = series[seriesIndex][dataPointIndex];
          const avg_days = w.config.series[seriesIndex]?.data[dataPointIndex]?.avg_days_between || 0;

          return value !== null && value !== 100
            ? `
            <div class="apex_custom_tooltip text-center">
              <span>${x_label === xaxis_categories.value[xaxis_categories.value.length - 1] ? t('geoindicators.report.cross_visits.heatmap.tooltip.total_percentage', {value, y_label}) : t('geoindicators.report.cross_visits.heatmap.tooltip.percentage', {value, y_label, x_label})}</span>
              <p>${t('geoindicators.report.cross_visits.heatmap.tooltip.avg_days_between', avg_days)}</p>
            </div>`
            : '';
        }
      }
    };

    handleResize();
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize);
  });
</script>
