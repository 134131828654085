<template>
  <div class="campaigns">

    <header class="title-bar">
      <div class="row">
        <div class="col-12 col-lg-3 align-self-center">
          <h1 class="title">{{ $t('campaign.list.header_title') }}</h1>
        </div>
        <div class="col-12 col-lg-9 text-right">
          <date-range-picker
            v-model:value="filters.dates"
            :disabled="is_loading"
            :placeholder="$t('components.datepicker.filter_by_period')">
          </date-range-picker>

          <organizations-select class="selection-dropdown" v-model:value="filters.selected_client"></organizations-select>

          <a
            @click="createCampaign"
            class="btn primary"
            v-if="show_campaign_creation_btn"
            data-testid="create-campaign"
          >
            <i class="fas fa-plus"></i>
            <span class="d-none d-xl-inline pl-xl-1">{{ `${$t('campaign.list.header_btn')}` }}</span>
          </a>
        </div>
      </div>
    </header>

    <loading :loading="is_loading" :error="error"/>

    <!-- Disclaimer -->
    <!--
    <aside class="alert warning" v-if="!is_loading && !error && campaigns.length && org_level !== 3">
      <i class="fa fa-times-circle"></i>
      {{ $t('campaign.list.new_pricing_disclaimer') }}
      <a href="https://propulso.io/fr/blogue/nouvelle_tarification_pour_le_geomarketing" target="_blank">{{ $t('campaign.list.new_pricing_cta') }}</a>
    </aside>
    -->

    <div class="row horizontal-row" v-if="!is_loading && !error && campaigns.length">
      <div class="col-sm-3 col-6 text-center animation-horizontal-row">
        <div class="panel">
          <h3>{{ $filters.splitNumber(summary.impressions) }}</h3>
          <p>{{ $t('campaign.list.summary_pannels.impressions') }}</p>
        </div>
      </div>
      <div class="col-sm-3 col-6 text-center animation-horizontal-row">
        <div class="panel">
          <h3>{{ $filters.splitNumber(summary.actions) }}</h3>
          <p>{{ $t('campaign.list.summary_pannels.actions') }}</p>
        </div>
      </div>
      <div class="col-sm-3 col-6 text-center">
        <div class="panel">
          <h3>{{ $filters.splitNumber(summary.clicks) }}</h3>
          <p>{{ $t('campaign.list.summary_pannels.clicks') }}</p>
        </div>
      </div>
      <div class="col-sm-3 col-6 text-center">
        <div class="panel">
          <h3>{{ $filters.splitNumber(summary.total_spend) }}$</h3>
          <p>{{ $t('campaign.list.summary_pannels.total_spend') }}</p>
        </div>
      </div>
      <div class="col-sm-3 col-6 text-center">
        <div class="panel">
          <h3>
          <span v-if="summary.ctr" >{{ summary.ctr }}</span>
          %</h3>
          <p>{{ $t('campaign.list.summary_pannels.ctr') }}</p>
        </div>
      </div>
      <div class="col-sm-3 col-6 text-center">
        <div class="panel">
          <h3>{{ $filters.splitNumber(summary.cpa) }}$</h3>
          <p>{{ $t('campaign.list.summary_pannels.cpa') }}</p>
        </div>
      </div>
      <div class="col-sm-3 col-6 text-center">
        <div class="panel">
          <h3>{{ $filters.splitNumber(summary.cpc) }}$</h3>
          <p>{{ $t('campaign.list.summary_pannels.cpc') }}</p>
        </div>
      </div>
      <div class="col-sm-3 col-6 text-center">
        <div class="panel">
          <h3>{{ $filters.splitNumber(summary.cpm) }}$</h3>
          <p>{{ $t('campaign.list.summary_pannels.cpm') }}</p>
        </div>
      </div>
    </div>

    <div class="campaigns-container" v-if="!is_loading && !error">
      <div class="panel no-padding" v-if="campaigns.length">
        <div id="grid-wrapper" ref="gridWrapper">

          <div class="grid-selection-actions" v-if="selected_rows > 0">
            {{ $t('campaign.list.selected_count', selected_rows) }}
            <a @click="exportCsv">
              <i class="fas fa-file-excel"></i>
              {{ $t('campaign.list.table.export_csv') }}
            </a>
          </div>

          <ag-grid-vue
            ref="grid"
            class="ag-theme-balham ag-grid ag-list"
            :rowData="campaigns"
            :gridOptions="grid_options"
            @gridReady="onGridReady">
          </ag-grid-vue>

        </div>
      </div>

      <!-- No campaigns -->
      <div class="row justify-content-center" v-else>
        <div class="panel text-center col-10 col-sm-4 no-results">
          <i class="icon fas fa-search-minus"></i>
          <p>{{ $t('campaign.list.table.no_campaign') }}</p>
          <a v-if="show_campaign_creation_btn" @click="createCampaign" class="btn">
            <i class="fas fa-plus"></i>
            {{ $t('campaign.list.table.no_campaign_create_btn') }}
          </a>
        </div>
      </div>

      <p class="text-center disclaimer" v-if="campaigns.length">
        <i>
          {{ $t('campaign.list.disclaimer') }} $ {{ currency }}
        </i>
      </p>
    </div>
  </div>

</template>

<script setup>
  import querystring from 'querystring';
  import {AgGridVue} from 'ag-grid-vue3';
  import rowStatus from '../components/ag-grid/rowStatus.vue';
  import dateRangePicker from '../components/dateRangePicker.vue';
  import pacingProgressBar from '../components/ag-grid/pacingProgressBar.vue';
  import organizationsSelect from '../components/organizationsSelect.vue';
  import {parseDate, getDateRange} from '../../js/helpers';
  import {useWhiteLabel} from '../composables/whitelabel';
  import {ref, reactive, getCurrentInstance, watch, inject} from 'vue';
  import {useI18n} from 'vue-i18n';
  import {toast} from 'vue3-toastify';
  import {useRouter} from 'vue-router';

  const {appContext} = getCurrentInstance();
  const global_filters = appContext.config.globalProperties.$filters;

  const {t} = useI18n();
  const router = useRouter();
  const {show_campaign_creation_btn} = useWhiteLabel();

  const event_hub = inject('event-hub');
  const axios = inject('axios');
  const sty = inject('sentry');

  const campaigns = ref([]);

  const clients = ref([
    {
      id: '',
      name: t('campaign.list.table.header_all_clients')
    }
  ]);

  const currency = ref(localStorage.currency);
  const error = ref(false);
  const grid_api = ref(null);
  const is_loading = ref(false);
  const org_name = ref('');
  const selected_rows = ref(0);

  const summary = reactive({});

  const filters = reactive({
    dates: (localStorage.filter_start_date && localStorage.filter_end_date) ? {
      start: new Date(localStorage.filter_start_date),
      end: new Date(localStorage.filter_end_date)
    } : {
      start: getDateRange('all_time')[0],
      end: getDateRange('all_time')[1]
    },
    selected_client: JSON.parse(localStorage.user_data).organization._id
  });

  const getSelectedRows = () => {
    if (grid_api.value) {
      const selected_nodes = grid_api.value.getSelectedNodes();
      selected_rows.value = selected_nodes.length;
    }
  };

  const grid_options = reactive({
    columnDefs: [
      {
        headerName: t('campaign.list.table.header_client_id'),
        field: 'client_id',
        minWidth: 60,
        filter: 'agTextColumnFilter',
        hide: true
      }, {
        cellRenderer: rowStatus,
        headerName: t('campaign.list.table.header_status'),
        field: 'status',
        width: 70,
        comparator: (firstRow, secondRow) => {
          const hierarchy = {
            active: 0,
            pending: 1,
            draft: 2,
            paused: 3,
            ended: 4
          };
          return (hierarchy[firstRow] === hierarchy[secondRow]) ? 0 : hierarchy[firstRow] < hierarchy[secondRow] ? 1 : -1;
        },
        sort: 'desc',
        sortIndex: 0
      }, {
        headerName: t('campaign.list.table.header_client_name'),
        field: 'client_name',
        minWidth: 130,
        sort: 'asc',
        sortIndex: 1
      }, {
        cellRenderer: pacingProgressBar,
        headerName: t('campaign.list.table.header_pacing'),
        field: 'pacing',
        minWidth: 130,
        comparator: (firstPacingValue, secondPacingValue, params) => {
          return (params.data.status === 'draft' || params.data.type === 'managed') ? 1 : (parseInt(firstPacingValue) < parseInt(secondPacingValue)) ? 1 : -1;
        }
      }, {
        headerName: t('campaign.list.table.header_campaign_name'),
        field: 'name',
        minWidth: 120,
        width: 250,
        sort: 'asc',
        sortIndex: 2
      }, {
        headerName: t('campaign.list.table.header_impressions'),
        field: 'stats.impressions',
        minWidth: 80,
        width: 80
      }, {
        headerName: t('campaign.list.table.header_clicks'),
        field: 'stats.clicks',
        width: 80
      }, {
        headerName: t('campaign.list.table.header_ctr'),
        field: 'stats.ctr',
        width: 100,
        cellRenderer: (params) => {
          let url = (params.data.status === 'draft' || params.data.status === 'pending') ?
            `/#/geomarketing/campaigns/edit/${params.data.id}` :
            `/#/geomarketing/campaigns/stats/${params.data.client_id}/${params.data.id}`;
          return `<a href="${url}">${params.value}%</a>`;
        }
      }, {
        headerName: t('campaign.list.table.header_total_spend'),
        field: 'stats.total_spend',
        width: 100,
        cellRenderer: (params) => {
          let url = (params.data.status === 'draft' || params.data.status === 'pending') ?
            `/#/geomarketing/campaigns/edit/${params.data.id}` :
            `/#/geomarketing/campaigns/stats/${params.data.client_id}/${params.data.id}`;
          return `<a href="${url}">${global_filters.splitNumber(params.value)}$</a>`;
        }
      }, {
        headerName: t('campaign.list.table.header_actions'),
        field: 'stats.actions',
        width: 80
      }, {
        headerName: t('campaign.list.table.header_cpa'),
        field: 'stats.cpa',
        width: 100,
        cellRenderer: (params) => {
          let url = (params.data.status === 'draft' || params.data.status === 'pending') ?
            `/#/geomarketing/campaigns/edit/${params.data.id}` :
            `/#/geomarketing/campaigns/stats/${params.data.client_id}/${params.data.id}`;
          return `<a href="${url}">${global_filters.splitNumber(params.value)}$</a>`;
        }
      }, {
        headerName: t('campaign.list.table.header_cpc'),
        field: 'stats.cpc',
        width: 80,
        cellRenderer: (params) => {
          let url = (params.data.status === 'draft' || params.data.status === 'pending') ?
            `/#/geomarketing/campaigns/edit/${params.data.id}` :
            `/#/geomarketing/campaigns/stats/${params.data.client_id}/${params.data.id}`;
          return `<a href="${url}">${params.value}$</a>`;
        }
      }, {
        headerName: t('campaign.list.table.header_cpm'),
        field: 'stats.cpm',
        width: 80,
        cellRenderer: (params) => {
          let url = (params.data.status === 'draft' || params.data.status === 'pending') ?
            `/#/geomarketing/campaigns/edit/${params.data.id}` :
            `/#/geomarketing/campaigns/stats/${params.data.client_id}/${params.data.id}`;
          return `<a href="${url}">${params.value}$</a>`;
        }
      }],
    defaultColDef : {
      cellRenderer: (params) => {
        let url = (params.data.status === 'draft' || params.data.status === 'pending') ?
          `/#/geomarketing/campaigns/edit/${params.data.id}` :
          `/#/geomarketing/campaigns/stats/${params.data.client_id}/${params.data.id}`;
        return `<a href="${url}">${global_filters.splitNumber(params.value)}</a>`;
      },
      minWidth: 100,
      resizable: true,
      sortable: true,
      hide: false
    },
    domLayout: 'autoHeight',
    rowSelection: {
      checkboxes: true,
      headerCheckbox: true,
      mode: 'multiRow',
      enableClickSelection: true
    },
    rowHeight: 35,
    headerHeight: 50,
    suppressHorizontalScroll: true,
    onSelectionChanged: getSelectedRows,
    localeText: {
      clearFilter: t('campaign.list.table.locale_text_filters_clearFilter'),
      contains: t('campaign.list.table.locale_text_filters_contains'),
      notContains: t('campaign.list.table.locale_text_filters_notContains'),
      toolPanelButton: t('campaign.list.table.locale_text_filters_toolPanelButton'),
      filterOoo: t('campaign.list.table.locale_text_filters_filterOoo')
    }
  });

  // Enable when Disclaimers are back
  // const org_level = computed(() => {
  //   return JSON.parse(localStorage.getItem('user_data')).organization.org_level;
  // });

  const fetchData = async() => {
    is_loading.value = true;

    const start_date = parseDate(filters.dates.start, 'labels');
    const end_date = parseDate(filters.dates.end, 'labels');

    const query = {
      start_date: start_date,
      end_date: end_date,
      organization_id: filters.selected_client
    };

    const url = `${process.env.API_URL}/campaigns/?${querystring.stringify(query)}`;
    return axios.get(url)
      .then((res) => {
        clients.value = [{
          id: '',
          name: t('campaign.list.table.header_all_clients')
        }].concat(res.data.clients);

        org_name.value = res.data.org_name;
        campaigns.value = res.data.campaigns;
        Object.assign(summary, res.data.summary);
        is_loading.value = false;
      })
      .catch((err) => {
        error.value = true;
        is_loading.value = false;
        toast.error(t('campaign.list.table.error_get_data'));
        sty.setContext('data', {
          filters: {...filters},
          clients: {...clients.value}
        });
        sty.captureException(err);
      });
  };

  const exportCsv = () => {
    const params = {
      fileName: `Propulso_${parseDate(filters.dates.start)}_${parseDate(filters.dates.end)}`,
      onlySelected: selected_rows.value > 0
    };
    if (selected_rows.value > 0) {
      grid_api.value.exportDataAsCsv(params);
    }
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
    grid_api.value = params.api;
  };

  const createCampaign = async() => {
    is_loading.value = true;
    error.value = false;

    try {
      const res = await axios.post(`${process.env.API_URL}/campaigns`);
      router.push({path: `/geomarketing/campaigns/edit/${res.data._id}`, query: {new: true}});
      is_loading.value = false;
    } catch (err) {
      error.value = true;
      is_loading.value = false;
      toast.error(t('campaign.list.table.error_create_campaign'));
      sty.setContext('data', {
        filters: {...filters},
        clients: [...clients.value],
        org_name: org_name.value
      });
      sty.captureException(err);
    }
  };

  const updateOrganization = (org) => {
    filters.selected_client = org._id;
    fetchData();
  };

  event_hub.on('organization_update', updateOrganization);

  watch(
    () => filters.dates,
    async(newFilters) => {
      await fetchData();
      localStorage.filter_start_date = newFilters.start;
      localStorage.filter_end_date = newFilters.end;
      selected_rows.value = 0;
    }, {
      deep: true
    });

  fetchData();
</script>
