const exportGeoJson = function(fences, file_name, type) {
  const features = fences.map((fence) => {
    let coordinates = [];
    let geometry_type = '';
    const fence_data = JSON.parse(JSON.stringify(fence));
    const is_polygon_array = Array.isArray(fence_data.polygon);
    const is_coordinates_array = Array.isArray(fence_data.coordinates);

    if (type === 'report') {
      if (is_polygon_array) {
        coordinates = fence_data.polygon.map((coord) => {
          const results = coord.map((c) => {
            const {lat, lng} = getCoordinates(c);

            return lat !== null && lng !== null ? [lng, lat] : null;
          }).filter((point) => point !== null);

          if (coord.length > 0) {
            const {lat, lng} = getCoordinates(coord[0]);

            if (lat !== null && lng !== null) {
              results.push([lng, lat]);
            }
          }

          return [results];
        });
      } else if (is_coordinates_array) {
        coordinates = fence_data.coordinates.map((coord) => {
          const results = coord.map((c) => {
            const {lat, lng} = getCoordinates(c);

            return lat !== null && lng !== null ? [lng, lat] : null;
          }).filter((point) => point !== null);

          if (coord.length > 0) {
            const {lat, lng} = getCoordinates(coord[0]);

            if (lat !== null && lng !== null) {
              results.push([lng, lat]);
            }
          }

          return [results];
        });
      }

      geometry_type = 'MultiPolygon';
    } else if (type === 'campaign') {
      const results = fence_data.coordinates.map((c) => {
        const {lat, lng} = getCoordinates(c);

        return lat !== null && lng !== null ? [lng, lat] : null;
      }).filter((point) => point !== null);

      if (fence_data.coordinates.length > 0) {
        const {lat, lng} = getCoordinates(fence_data.coordinates[0]);

        if (lat !== null && lng !== null) {
          results.push([lng, lat]);
        }
      }

      coordinates = [results];
      geometry_type = 'Polygon';
    }

    return {
      type: 'Feature',
      geometry: {
        type: geometry_type,
        coordinates
      },
      properties: {
        name: fence_data.label
      }
    };
  }).filter((feature) => feature !== null);

  const geojson = JSON.stringify({
    type: 'FeatureCollection',
    features
  });
  const file_data = `data:text/json;charset=utf-8,${encodeURIComponent(geojson)}`;
  const a = document.createElement('a');

  a.href = file_data;
  a.download = `${file_name}_polygons.json`;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const getCoordinates = function(coord) {
  return typeof coord.lat === 'function' && typeof coord.lng === 'function' ? {lat: coord.lat(), lng: coord.lng()} : coord;
};

export {
  exportGeoJson
};
