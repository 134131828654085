<template>
  <div>
    <div class="adsbygoogle ad-warning" id="ad-detector"></div>

    <div class="popup detector" v-if="show">
      <div class="container-sm">
        <div class="panel">
          <div class="title">
            <b><i class="fas fa-exclamation-circle"></i> {{ $t('components.ad_detector.title') }}</b>
          </div>

          <p>{{ $t('components.ad_detector.text') }}</p>

          <div class="footer">
            <aside class="actions">
              <a @click="togglePopup(false)" class="btn">{{ $t('components.ad_detector.btn') }}</a>
            </aside>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {onMounted, ref, nextTick} from 'vue';

  const show = ref(false);

  const togglePopup = (value) => {
    show.value = value;
  };

  onMounted(() => {
    if (process.env.NODE_ENV === 'production') {
      nextTick(() => {
        const ad_element = document.getElementById('ad-detector');
        const ad_element_style = getComputedStyle(ad_element, null);

        if (ad_element_style.display === 'none') {
          togglePopup(true);
        }
      });
    }
  });
</script>
