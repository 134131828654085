<template>
  <div>
    <div v-if="show_summary">
      <div class="title-separator">
        <h1>{{ $t('geoindicators.report.visits.summary.title') }}</h1>
        <p>{{ $t('geoindicators.report.visits.summary.text') }}</p>
        <hr>
      </div>

      <div class="row">
        <div class="col-md-3" v-if="data.summary.visits">
          <report-summary :data="data.summary.visits" :type="'visits'" :title="$t('geoindicators.report.widgets.report_summary.trails_total_visits')"></report-summary>
        </div>
        <div class="col-md-3" v-if="data.summary.visitors">
          <report-summary :data="data.summary.visitors" :type="'visitors'" :title="$t('geoindicators.report.widgets.report_summary.trails_unique_visitors')"></report-summary>
        </div>
        <div class="col-md-3">
          <report-summary :data="data.summary.duration" :type="'duration'" :title="$t('geoindicators.report.widgets.report_summary.visits_duration.text')"></report-summary>
        </div>
        <div class="col-md-3">
          <report-summary :data="data.summary.speeds" :type="'speed'" :title="$t('geoindicators.report.widgets.report_summary.visits_speed')"></report-summary>
        </div>
      </div>
    </div>

    <div class="title-separator">
      <h1>{{ $t('geoindicators.report.visits.visits.title') }}</h1>
      <p>{{ $t('geoindicators.report.visits.visits.text') }}</p>
      <hr>
    </div>

    <template v-if="display_volumes">
      <div v-if="data.summary.visits" class="row">
        <div class="col-md-6">
          <div class="panel">
            <div class="metric-title highlight">
              <div class="icon-wrapper">
                <i class="fas fa-users"></i>
              </div>
              <h2 v-if="data.summary.visits.sum">≈ {{ $filters.splitNumber(data.summary.visits.sum) }}</h2>
              <h2 v-else>-</h2>
              <p>{{ $t('geoindicators.report.widgets.report_summary.total_visits') }}</p>
            </div>
          </div>
        </div>
      </div>

      <visits-count v-if="data.visits_count" :data="data.visits_count" :show_summary="true"></visits-count>
    </template>

    <template v-if="is_preview">
        <preview-form :classes="'trails'"></preview-form>
    </template>

    <visits-repartition :class="{'preview': is_preview && display_volumes}" :data="data.visits_repartition"></visits-repartition>

    <div :class="{'preview': is_preview}">
      <template v-if="display_volumes">
        <template v-if="data.summary.visitors">
          <div class="title-separator">
            <h1>{{ $t('geoindicators.report.visits.visitors.title') }}</h1>
            <p>{{ $t('geoindicators.report.visits.visitors.text') }}</p>
            <hr>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="panel">
                <div class="metric-title highlight">
                  <div class="icon-wrapper">
                    <i class="fas fa-user"></i>
                  </div>
                  <h2 v-if="data.summary.visitors.sum">≈ {{ $filters.splitNumber(data.summary.visitors.sum) }}</h2>
                  <h2 v-else>-</h2>
                  <p>{{ $t('geoindicators.report.widgets.report_summary.unique_visitors') }}</p>
                </div>
              </div>

            </div>
          </div>
        </template>

        <visits-unique v-if="data.visitors_count" :data="data.visitors_count" :show_summary="true" :show_tooltip="true"></visits-unique>
      </template>

      <div class="title-separator">
        <h1>{{ $t('geoindicators.report.visits.origins.title') }}</h1>
        <p>{{ $t('geoindicators.report.visits.origins.text') }}</p>
        <hr>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="panel">
            <div class="metric-title">
              <h2 v-if="data.geography_summary.avg_distance_count">{{ $filters.splitNumber(data.geography_summary.avg_distance_count) }} <span>{{ $t('geoindicators.report.widgets.geography_summary.suffix') }}</span></h2>
              <h2 v-else>-</h2>
              <p>{{ $t('geoindicators.report.widgets.geography_summary.avg_title') }}</p>
            </div>
          </div>

          <tourists-repartition-donut :data="data.geography_summary.tourists_repartition"></tourists-repartition-donut>
        </div>

        <div class="col-md-6">
          <geography-summary :data="data.geography_summary"></geography-summary>
        </div>
      </div>

      <template v-if="data.origins.countries.length">
        <tourism-origins
          :data="data.origins"
          :show_title="true">
        </tourism-origins>
      </template>

      <visits-source :data="data.visits_source"></visits-source>

      <div class="title-separator">
        <h1>{{ $t('geoindicators.report.visits.movement.title') }}</h1>
        <p>{{ $t('geoindicators.report.visits.movement.text') }}</p>
        <hr>
      </div>

      <div class="row">
        <div class="col-md-5">
          <speed-summary :data="data.speed"></speed-summary>
        </div>

        <div class="col-md-7">
          <visits-direction :data="data.direction"></visits-direction>
        </div>
      </div>

      <visits-entry-exits :data="data.entry_exit_points"></visits-entry-exits>

      <div class="title-separator">
        <h1>{{ $t('geoindicators.report.visits.duration.title') }}</h1>
        <p>{{ $t('geoindicators.report.visits.duration.text') }}</p>
        <hr>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="panel">
            <div class="metric-title">
              <h2 v-if="data.summary.duration.avg">{{ $filters.splitNumber(data.summary.duration.avg) }} <span>{{ $t('geoindicators.report.widgets.report_summary.visits_duration.suffix') }}</span></h2>
              <h2 v-else>-</h2>
              <p>{{ $t('geoindicators.report.widgets.report_summary.visits_duration.text') }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <duration-distribution :data="data.duration"></duration-distribution>
        </div>

        <div class="col-md-6">
          <visits-duration-daytime-distribution :data="data.duration_daytime_distribution"></visits-duration-daytime-distribution>
        </div>
      </div>

      <visits-duration :data="data.duration"></visits-duration>

      <div class="title-separator">
        <h1>{{ $t('geoindicators.report.visits.frequency.title') }}</h1>
        <p>{{ $t('geoindicators.report.visits.frequency.text') }}</p>
        <hr>
      </div>

      <div class="row">
        <div class="col-md-6">
          <customer-visits-distribution :data="data.customer_visits_distribution"></customer-visits-distribution>
        </div>

        <div class="col-md-6">
          <customer-visits-duration-distribution :data="data.customer_visits_duration_distribution"></customer-visits-duration-distribution>
        </div>
      </div>

      <template v-if="data.demographics">
        <div class="title-separator">
          <h1>{{ $t('geoindicators.report.visits.demography.title') }}</h1>
          <p>{{ $t('geoindicators.report.visits.demography.text') }}</p>
          <hr>
        </div>

        <demographic-infos :data="data.demographics"></demographic-infos>
      </template>

      <div class="partners">
        <p>{{ $t('geoindicators.report.footer.partner_developement_text') }}</p>

        <img src="assets/tctrail-logo-fr.svg" class="logo" alt="Logo Trans Canada Trail">
      </div>
    </div>

  </div>
</template>

<script setup>
  import reportSummary from '../widgets/reportSummary.vue';
  import visitsCount from '../widgets/visitsCount.vue';
  import visitsUnique from '../widgets/visitsUnique.vue';
  import visitsDuration from '../widgets/visitsDuration.vue';
  import visitsRepartition from '../widgets/visitsRepartition.vue';
  import visitsDurationDaytimeDistribution from '../widgets/visitsDurationDaytimeDistribution.vue';
  import durationDistribution from '../widgets/durationDistribution.vue';
  import customerVisitsDistribution from '../widgets/customerVisitsDistribution.vue';
  import customerVisitsDurationDistribution from '../widgets/customerVisitsDurationDistribution.vue';
  import geographySummary from '../widgets/geographySummary.vue';
  import touristsRepartitionDonut from '../widgets/touristsRepartitionDonut.vue';
  import speedSummary from '../widgets/speedSummary.vue';
  import visitsEntryExits from '../widgets/visitsEntryExits.vue';
  import visitsSource from '../widgets/visitsSource.vue';
  import tourismOrigins from '../widgets/tourismOrigins.vue';
  import visitsDirection from '../widgets/visitsDirection.vue';
  import demographicInfos from '../widgets/demographicInfos.vue';
  import previewForm from '../widgets/previewForm.vue';
  import {ref, computed} from 'vue';

  const props = defineProps({
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    is_preview: {
      type: Boolean,
      required: false,
      default: false
    }
  });

  const show_summary = ref(process.env.SHOW_SUMMARY === 'true');

  const display_volumes = computed(() => {
    //@TODO: find a more elegant way to handle this and standardize it for visits reports
    return !(!props.data.summary.visits && !props.data.summary.visitors && !props.data.visits_count && !props.data.visitors_count);
  });
</script>
