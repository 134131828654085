<template>
  <div>
    <span class="ag-filter-title">{{ params.colDef.headerName }}</span>
    <select v-model="filter" @change="onFilterChanged">
      <option value="">{{ $t('geoindicators.reports.table.filter_report_placeholder') }}</option>
      <option v-for="option in options" :key="option" :value="option">
        {{ $t(getReportTypeTranslation(option)) }}
      </option>
    </select>
  </div>
</template>

<script>
  import {ref} from 'vue';

  export default {
    props: {
      params: {
        type: Object,
        required: true
      }
    },

    setup(props) {
      const filter = ref("");
      const options = ref([]);

      const getReportTypeTranslation = (type) => {
        const translations = {
          visits: 'geoindicators.report.type.visits',
          market: 'geoindicators.report.type.market',
          economic: 'geoindicators.report.type.economic',
          tourism: 'geoindicators.report.type.tourism',
          custom: 'geoindicators.report.type.custom',
          trails: 'geoindicators.report.type.trails',
          cross_visits: 'geoindicators.report.type.cross_visits',
          beta_habits_vs_population: 'geoindicators.report.type.beta_habits_vs_population',
          before_after: 'geoindicators.report.type.before_after',
          beta_visitors_profiles: 'geoindicators.report.type.beta_visitors_profiles',
          basic: 'geoindicators.report.type.basic'
        };

        return translations[type] || translations.basic;
      };

      const afterGuiAttached = () => {
        options.value = getUniqueValues();
      };

      const getUniqueValues = () => {
        const unique_values = new Set();

        props.params.api.forEachNode((node) => {
          if (node.data?.type) {
            unique_values.add(node.data.type);
          }
        });

        return Array.from(unique_values);
      };

      const onFilterChanged = () => {
        props.params.api.onFilterChanged();
      };

      const isFilterActive = () => {
        return filter.value !== "";
      };

      const doesFilterPass = (node) => {
        return !filter.value || node.data?.type === filter.value;
      };

      return {
        filter,
        options,
        getReportTypeTranslation,
        afterGuiAttached,
        onFilterChanged,
        isFilterActive,
        doesFilterPass
      };
    }
  };
</script>
