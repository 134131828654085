<template>
  <div class="panel" v-if="show_heatmap">
    <div class="title">
      <b>{{ $t('components.heatmap.title') }}</b>
    </div>
    <div class="heatmap">
      <div class="heatmap-wrapper">
        <GoogleMap
          :api-promise="apiPromise"
          ref="map_ref"
          :zoom="zoom"
          :center="center"
          :options="options"
          map-type-id="roadmap"
          @zoom_changed="onZoom"
          style="width: 100%; height: 500px">
          <HeatmapLayer
            :options="{
              ...radius_options,
              data: marker.positions
            }"
          />
        </GoogleMap>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {ref, reactive, computed, inject} from 'vue';
  import {GoogleMap, HeatmapLayer} from 'vue3-google-map';
  import {useGmap} from '../composables/gmap';

  const event_hub = inject('event-hub');

  const zoom = ref(8);
  const positions = ref([]);
  const show_heatmap = ref(false);
  const radius = ref(50);

  const center = reactive({
    lat: 46.194833,
    lng: -72.844110
  });

  const options = reactive({
    minZoom: 2,
    maxZoom: 16
  });

  const radius_options = reactive({
    maxIntensity: 100,
    opacity: 0.85,
    radius: radius.value
  });

  const {
    apiPromise,
    map_ref,
    initializeGoogleMapsAPI
  } = useGmap();

  const marker = computed(() => {
    return {
      positions: positions.value.map((pos) => {
        const weight = pos[2] * 100;

        return {
          location: {lat: pos[0], lng: pos[1]},
          weight: weight < 5 ? 5 : weight
        };
      })
    };
  });

  const bindData = (positionsToBind) => {
    if (positionsToBind.length) {
      positions.value = positionsToBind;
      show_heatmap.value = true;
    }
  };

  const onZoom = (zoom) => {
    radius.value = zoom < 10 ? zoom * 5 : zoom * 10;
  };

  event_hub.on('fetched_pc', bindData);

  initializeGoogleMapsAPI();
</script>
