import {useI18n} from "vue-i18n";

export function useGisWidget(data) {
  const {t} = useI18n();
  const updatePeriod = (value) => {
    data.selected_period = value;
  };

  const computeCategories = (params, type) => {
    const categories = Object.keys(params);
    const categories_labels = [];
    let suffix = '';

    switch (type) {
      case 'duration':
        suffix = t('geoindicators.report.widgets.minutes_tooltip');
        break;
      case 'speed':
        suffix = 'km/h';
        break;
      case 'distance':
        suffix = 'km';
        break;
    }

    for (let i = 0; i < categories.length; i++) {
      const category_value = categories[i].substring(1);

      if (i === 0) {
        categories_labels[i] = `${t('geoindicators.report.widgets.geography_summary.less_than')} ${category_value} ${suffix}`;
      } else if (i === categories.length - 1) {
        categories_labels[i] = `${category_value} ${suffix} ${t('geoindicators.report.widgets.geography_summary.or_more')}`;
      } else {
        categories_labels[i] = `${categories[i]} ${suffix}`;
      }
    }

    return categories_labels;
  };
  return {
    updatePeriod,
    computeCategories
  };
}
