<template>
  <div class="summary-container">
    <div v-if="step === 'beta-summary'" class="panel">
      <div class="title">
        <b>{{ $t('geoindicators.creation.summary.information.title') }}</b>
      </div>
      <div class="content">
        <div>
          <p class="category">{{ $t('geoindicators.creation.price.report_type') }}</p>
          <p class="text">{{ $t('geoindicators.report.type')[route.query.type] }}</p>
        </div>
        <div v-if="organization_name || organization.organization_name">
          <div>
            <p class="category">{{ $t('geoindicators.creation.summary.information.account') }}</p>
            <p class="text">{{ organization_name ?  organization_name : organization.organization_name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="panel">
      <div class="title">
        <b>{{ $t('geoindicators.creation.summary.information.title') }}</b>
      </div>
      <div class="content">
        <div>
          <p class="category">{{ $t('geoindicators.creation.summary.information.report_name') }}</p>
          <p class="text">{{ report.name }}</p>
        </div>
        <div>
          <p class="category">{{ $t('geoindicators.creation.price.report_type') }}</p>
          <p class="text">{{ $t(`geoindicators.report.type.${report.type}`) }}</p>
        </div>
        <div>
          <p class="category">{{ $t('geoindicators.creation.summary.information.dates.title') }}</p>
          <p class="text">{{ start_end_dates }}</p>
        </div>
        <div v-if="organization_name || organization.organization_name">
          <div>
            <p class="category">{{ $t('geoindicators.creation.summary.information.account') }}</p>
            <p class="text">{{ organization_name ?  organization_name : organization.organization_name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step !== 'beta-summary'">
      <div class="panel" v-if="is_included_in_license && is_license_valid && is_payment_valid">
        <div class="title">
          <b>{{ $t('geoindicators.creation.summary.license.title') }}</b>
        </div>
        <div class="content">
          <div>
            <p class="category">{{ $t('geoindicators.creation.summary.license.active') }}</p>
            <p class="text">{{ $tm('geoindicators.creation.license.period_type')[license.license_type] }}</p>
          </div>
          <div>
            <p class="category">{{ $t('geoindicators.creation.license.zone_type.title') }}</p>
            <p class="text">{{ $tm('geoindicators.creation.license.zone_type')[license.license_zones[0].type] }}</p>
          </div>
          <div>
            <p class="category">{{ $t('geoindicators.creation.license.title_expiration') }}</p>
            <p class="text">{{ license.end_date }}</p>
          </div>
          <div v-if="license.license_zones[0].type !== 'world'">
            <p class="category">{{ $t('geoindicators.creation.license.title_inclusions') }}</p>
            <p class="text">{{ license_zones_inclusions }}</p>
          </div>
          <div v-if="is_fence_remaining">
            <p class="category">{{ $t('geoindicators.creation.summary.license.reports_remaining') }}</p>
            <p class="text">{{ $t('geoindicators.creation.license.reports_remaining', reports_remaining_after_creation) }}</p>
          </div>
          <div v-if="license.license_type !== 'reseller'">
            <p class="category">{{ $t('geoindicators.creation.license.title_use') }}</p>
            <p class="text">{{ $t('geoindicators.creation.license.fences_used', {n: report.target_fences.length, max: license.limits.report_max_polygon}) }}</p>
          </div>
          <div>
            <p class="category">{{ $t('geoindicators.creation.summary.license.price') }}</p>
            <p v-if="price_text" class="text price">
              <span v-html="price_text.html" style="display:inline-block;"></span>
            </p>
          </div>
          <aside v-if="!price_text" class="alert danger">
            <i class="fas fa-exclamation-circle"></i>
            {{ $t('geoindicators.creation.summary.license.fences_limit') }}
          </aside>
        </div>
      </div>
      <div class="panel" v-else-if="organization.payment_profile && is_payment_valid">
        <div class="title">
          <b>{{ $t('geoindicators.creation.summary.license.payment') }}</b>
        </div>
        <div class="content">
          <div>
            <p class="category">{{ $t('geoindicators.creation.summary.license.payment_method') }}</p>
            <p class="text method" v-if="organization.payment_profile.credit_card && organization.payment_profile.credit_card.label">
              <i class="fab provider" :class="{'fa-cc-visa': organization.payment_profile.credit_card.provider === 'visa', 'fa-cc-mastercard': organization.payment_profile.credit_card.provider === 'mastercard'}"></i>
              {{ organization.payment_profile.credit_card.label }}
              <small>{{ `${$t('geoindicators.creation.summary.license.expiration')} ${organization.payment_profile.credit_card.expiration.month} - ${organization.payment_profile.credit_card.expiration.year}` }}</small>
            </p>
            <p class="text" v-else-if="organization.payment_profile.type === 'checks' || organization.payment_profile.type === 'bank-transfer'">
              {{ organization.payment_profile.type === 'checks' ? $t(`credit_cards.credit_cards_panel.table.checks`) : $t(`credit_cards.credit_cards_panel.table.bank_transfer`) }}
            </p>
          </div>
          <div>
            <p class="category" v-if="license.license_type === 'reseller'">{{ $t('geoindicators.creation.price.reseller_title') }}</p>
            <p class="category" v-else>{{ $t('geoindicators.creation.summary.license.price') }}</p>
            <p v-if="price_text" class="text price">
              <span v-html="price_text.html" style="display:inline-block;"></span>
            </p>
          </div>
        </div>
      </div>
      <aside v-else-if="!is_loading" class="alert danger">
        <div class="row">
          <div class="col-md-8">
            <p>
              <i class="fas fa-exclamation-triangle"></i>
              <b>{{ $t('geoindicators.creation.errors.payment_not_valid_title') }}</b>
            </p>
            <p>{{ $t('campaign.edit.fields_errors.errors.payment_not_valid_text') }}</p>
          </div>
          <div class="col-md-4 text-right">
            <a href="#/account/credit-cards" class="btn danger">{{ $t('campaign.edit.fields_errors.errors.payment_not_valid_btn') }}</a>
          </div>
        </div>
      </aside>
    </div>
  </div>
</template>

<script setup>
  import {useReport} from '../composables/report.js';
  import {parseDate} from '../../js/helpers.js';
  import {inject, toRef, ref, computed, onMounted, reactive} from 'vue';
  import {useI18n} from 'vue-i18n';
  import {useRoute} from 'vue-router';

  const route = useRoute();
  const {t, tm} = useI18n();
  const event_hub = inject('event-hub');

  const {
    state
  } = useReport(event_hub);

  const sty = inject('sentry');
  const axios = inject('axios');

  const props = defineProps({
    license: {
      type: Object,
      required: true,
      default: {}
    },
    report: {
      type: Object,
      required: false,
      default: {}
    },
    price_text: {
      type: Object,
      required: false,
      default: () => {
        return {
          note: '',
          html: '-'
        };
      }
    },
    organization_name: {
      type: String,
      required: true,
      default: ''
    },
    is_included_in_license: {
      type: Boolean,
      required: true,
      default: false
    },
    is_license_valid: {
      type: Boolean,
      required: true,
      default: false
    },
    is_fence_remaining: {
      type: Boolean,
      required: true,
      default: false
    },
    step: {
      type: String,
      required: false,
      default: 'summary'
    }
  });

  defineEmits(['summary_states']);

  const is_loading = toRef(state, 'loading');
  const error = toRef(state, 'error');

  const reports_remaining_after_creation = ref(0);
  const organization_id = ref(props.report.organization ? props.report.organization : props.license.organization);
  const is_payment_valid = ref(false);

  const organization = reactive({});

  const start_end_dates = computed(() => {
    if (props.report && props.report.dates && props.report.dates.start && props.report.dates.end) {
      return `${t('geoindicators.creation.summary.information.dates.from')} ${parseDate(props.report.dates.start, 'labels')} ${t('geoindicators.creation.summary.information.dates.to')} ${parseDate(props.report.dates.end, 'labels')}`;
    }
  });

  const license_zones_inclusions = computed(() => {
    return props.license.license_zones.map((zone) => tm('zones_codes')[zone.code_name]).join(', ');
  });

  const getBillableOrganization = () => {
    return axios.get(`${process.env.API_URL}/payments/profile/${organization_id.value}`)
      .then((res) => {
        Object.assign(organization, res.data);
        is_payment_valid.value = true;
      })
      .catch((err) => {
        if (err.status === 402) {
          is_payment_valid.value = false;
        } else {
          error.value = true;
        }

        sty.setContext('data', organization);
        sty.captureException(error);
      });
  };

  onMounted(() => {
    is_loading.value = true;

    getBillableOrganization().then(() => {
      const summary_states = {
        loading: false,
        error: error.value,
        payment_valid: is_payment_valid.value
      };

      event_hub.emit('summary_states', summary_states);
      is_loading.value = false;
    });

    reports_remaining_after_creation.value = props.license.limits.monthly_max_reports - props.license.reports_count - 1;
  });
</script>
