<template>
  <div class="campaigns-edit">
    <header class="title-bar">
      <div class="container">
        <div class="row">
          <div class="col-8 align-self-center">
            <h1>
              {{ $t('campaign.edit.header.title') }}
              <span class="badge danger" v-if="unsaved_changes">{{ $t('campaign.edit.header.unsaved_changes') }}</span>
            </h1>
          </div>
          <div class="col-4 text-right align-self-center">
            <dropdown>
              <template #actions>
                <ul class="list">
                  <li @click="updateCampaignStatus('start')" :class="{'disabled': campaign.status !== 'paused' && campaign.status !== 'ended'}">
                    <i class="fas fa-play green"></i>
                    {{ $t('campaign.edit.header.actions.start') }}
                  </li>
                  <li @click="updateCampaignStatus('pause')" :class="{'disabled': campaign.status !== 'active'}">
                    <i class="fas fa-pause"></i>
                    {{ $t('campaign.edit.header.actions.pause') }}
                  </li>
                  <li @click="updateCampaignStatus('end')" :class="{'disabled': campaign.status !== 'active' && campaign.status !== 'paused'}">
                    <i class="fas fa-stop red"></i>
                    {{ $t('campaign.edit.header.actions.end') }}
                  </li>
                  <li class="separator"></li>
                  <li @click="copyCampaign">
                    <i class="far fa-clone"></i>
                    {{ $t('campaign.edit.header.actions.duplicate') }}
                  </li>
                  <li @click="deleteCampaign" :class="{'disabled': campaign.status !== 'draft'}">
                    <i class="fas fa-trash-alt red"></i>
                    {{ $t('campaign.edit.header.actions.deleting') }}
                  </li>
                </ul>
              </template>
              <template #title>
                {{ $t('campaign.edit.header.actions.title') }}
                <i class="d-none d-lg-inline fas fa-caret-down"></i>
              </template>
            </dropdown>
          </div>
        </div>
      </div>
    </header>

    <loading :loading="is_loading" :error="error"/>

    <div class="container" v-if="!is_loading && !error">

      <!-- Status -->
      <div class="status">
        <ul class="steps-bar">
          <li :class="{'current': campaign.status === 'draft', 'done': campaign.status !== 'draft'}">
            <span class="item-bubble"></span>
            <p class="label">{{ $t('campaign.edit.status_bar.draft') }}</p>
          </li>
          <li :class="{'current': campaign.status === 'pending', 'done': campaign.status === 'active' | campaign.status === 'ended'}">
            <span class="item-bubble"></span>
            <p class="label">{{ $t('campaign.edit.status_bar.pending') }}</p>
          </li>
          <li :class="{'current': campaign.status === 'active', 'done': campaign.status === 'ended'}">
            <span class="item-bubble"></span>
            <p class="label">{{ $t('campaign.edit.status_bar.active') }}</p>
          </li>
          <li :class="{'current': campaign.status === 'ended'}">
            <span class="item-bubble"></span>
            <p class="label">{{ $t('campaign.edit.status_bar.ended') }}</p>
          </li>
        </ul>
      </div>

      <!-- Breadcrumbs -->
      <div class="row breadcrumbs">
        <div class="col-6 d-none d-lg-inline">
          <a :href="`/#/geomarketing/campaigns/stats/${campaign.organization._id}/${campaign._id}`"
            v-if="campaign.status !== 'draft' && campaign.status !== 'pending'">
            {{ $t('campaign.edit.breadcrumbs.back_to_stats') }}
          </a>
          <a href="/#/geomarketing/campaigns"
            v-if="campaign.status === 'draft' || campaign.status === 'pending'">
            {{ $t('campaign.edit.breadcrumbs.back_to_campaigns') }}
          </a>
        </div>
        <div class=" mt-3 mt-lg-0 col-lg-6 text-right">
          ID: {{ campaign._id }}
        </div>
      </div>

      <!-- Editor -->
      <div class="editor" v-if="campaign.status !== 'pending'">
        <div class="row">
          <!-- Left col   -->
          <div class="col-xl-8 order-first">
            <!-- Details -->
            <div class="panel">
              <div class="title">
                <b>{{ $t('campaign.edit.details_panel.title') }}</b>
              </div>
              <label for="campaign-name">{{ $t('campaign.edit.details_panel.campaign_name') }}</label>
              <input id="campaign-name" v-model="campaign.name">
              <label for="campaign-org">
                {{ $t('campaign.edit.details_panel.account') }}
                <i class="fas fa-question-circle tooltip-icon" v-tooltip="$t('campaign.edit.details_panel.account_tooltip')"></i>
              </label>
              <organizations-select v-model:value="campaign.organization._id" :disabled="campaign.status !== 'draft'"></organizations-select>
            </div>
            <!-- Budget and flights -->
            <flights-configurator
              :budget_type="campaign.budget_type"
              :budget_metric="campaign.budget_metric"
              :campaign_status="campaign.status"
              :current_cpm="current_cpm"
              v-model="campaign.budget_flights">
            </flights-configurator>
            <!-- Strategy -->
            <fences-builder
              v-model:value="campaign.target_fences"
              :export_polygons_data="{ id: campaign._id, name: campaign.name }"
              :fence_type="'campaign'"
              :title="$t('campaign.edit.strategy.title')"
              :product="'campaign_target'">
            </fences-builder>
            <!-- Conversion -->
            <fences-builder
              v-model:value="campaign.conversion_fences"
              :export_polygons_data="{ id: campaign._id, name: campaign.name }"
              :fence_type="'campaign'"
              :is_conversion="true"
              :title="$t('campaign.edit.conversion.title')"
              :product="'campaign_conversion'">
            </fences-builder>
            <!-- Creatives -->
            <creatives-handler
              :files_upload="campaign.creatives"
              :media_type="campaign.media_type"
              :is_draft="campaign.status === 'draft'"
              @updateMediaType="updateMediaType($event)">
            </creatives-handler>
          </div>

          <!-- Right col -->
          <div class="col-xl-4 order-last">
            <!-- Settings -->
            <div class="panel">
              <div class="title">
                <b>{{ $t('campaign.edit.parameters.title') }}</b>
                <aside class="actions">
                  <a class="info" @click="toggleAdvancedSettings">
                    <span v-if="!advanced_settings">
                      <i class="fas fa-plus"></i>
                      <span class="d-none d-lg-inline">{{ $t('campaign.edit.parameters.advanced_settings_title') }}</span>
                    </span>
                    <span v-if="advanced_settings">
                      <i class="fas fa-minus"></i>
                      <span class="d-none d-lg-inline">{{ $t('campaign.edit.parameters.advanced_settings_reduce_title') }}</span>
                    </span>
                  </a>
                </aside>
              </div>
              <!-- Goals -->
              <p>
                {{ $t('campaign.edit.parameters.goals.title') }}
                <i class="fas fa-question-circle tooltip-icon" v-tooltip.bottom="$t('campaign.edit.parameters.goals.title_tooltip')"></i>
              </p>
              <div class="goals">
                <label class="radio">
                  <i class="fas fa-store"></i>
                  {{ $t('campaign.edit.parameters.goals.foot_traffic') }}
                  <input type="radio" name="campaign_goal" value="foot_traffic" v-model="campaign.goal">
                  <span></span>
                </label>
                <label class="radio">
                  <i class="fas fa-mouse-pointer"></i>
                  {{ $t('campaign.edit.parameters.goals.clicks') }}
                  <input type="radio" name="campaign_goal" value="clicks" v-model="campaign.goal">
                  <span></span>
                </label>
                <label class="radio">
                  <i class="fas fa-users"></i>
                  {{ $t('campaign.edit.parameters.goals.notoriety') }}
                  <input type="radio" name="campaign_goal" value="notoriety" v-model="campaign.goal">
                  <span></span>
                </label>
                <label class="radio">
                  <i class="fas fa-bullhorn"></i>
                  {{ $t('campaign.edit.parameters.goals.announcement') }}
                  <input type="radio" name="campaign_goal" value="announcement" v-model="campaign.goal">
                  <span></span>
                </label>
              </div>

              <div class="divider"></div>

              <!-- Flight type -->
              <p>
                {{ $t('campaign.edit.parameters.frequency.title') }}
              </p>
              <label class="radio" :class="{'disabled': campaign.status !== 'draft'}">
                <i class="far fa-calendar-alt"></i>
                {{ $t('campaign.edit.parameters.frequency.monthly') }}
                <input type="radio" name="campaign_budget_type" value="monthly" v-model="campaign.budget_type" :disabled="campaign.status !== 'draft'">
                <span></span>
              </label>
              <label class="radio" :class="{'disabled': campaign.status !== 'draft'}">
                <i class="fas fa-stream"></i>
                {{ $t('campaign.edit.parameters.frequency.period') }}
                <input type="radio" name="campaign_budget_type" value="period" v-model="campaign.budget_type" :disabled="campaign.status !== 'draft'">
                <span></span>
              </label>
              <!-- Advanced mode -->
              <div v-if="advanced_settings">
                <div class="divider info" :data-label="$t('campaign.edit.parameters.advanced_settings_title')"></div>
                <!-- Budget type -->
                <p>
                  {{ $t('campaign.edit.parameters.budget_metric.title') }}
                  <i class="fas fa-question-circle tooltip-icon" v-tooltip.bottom="$t('campaign.edit.parameters.budget_metric.title_tooltip')"></i>
                </p>
                <label class="radio" :class="{'disabled': campaign.status !== 'draft'}">
                  <i class="fas fa-dollar-sign"></i>
                  {{ $t('campaign.edit.parameters.budget_metric.money') }} ({{ campaign.organization.markup?.currency || 'CAD' }})
                  <input type="radio" name="campaign_budget_metric" value="money" v-model="campaign.budget_metric" :disabled="campaign.status !== 'draft'">
                  <span></span>
                </label>
                <label class="radio" :class="{'disabled': campaign.status !== 'draft'}">
                  <i class="far fa-eye"></i>
                  {{ $t('campaign.edit.parameters.budget_metric.impression') }}
                  <input type="radio" name="campaign_budget_metric" value="impression" v-model="campaign.budget_metric" :disabled="campaign.status !== 'draft'">
                  <span></span>
                </label>

                <div class="divider"></div>

                 <!-- Duree du Ciblage -->
                <p>
                  {{ $t('campaign.edit.parameters.recency.title') }}
                  <i class="fas fa-question-circle tooltip-icon" v-tooltip.bottom="$t('campaign.edit.parameters.recency.title_tooltip')"></i>
                </p>
                <label :class="{'disabled': campaign.status !== 'draft'}">
                  <select v-model="campaign.recency" >
                    <option :key="ciblage.value" :value="ciblage.value" v-for="ciblage in $tm('campaign.edit.parameters.recency.recencies')">{{ ciblage.label }}</option>
                  </select>
                </label>

                <div class="divider"></div>

                <!-- Duree des conversions physiques -->
                <p>
                  {{ $t('campaign.edit.parameters.conversion_duration.title') }}
                  <i class="fas fa-question-circle tooltip-icon" v-tooltip.bottom="$t('campaign.edit.parameters.conversion_duration.title_tooltip')"></i>
                </p>
                <label :class="{'disabled': campaign.status !== 'draft'}">
                  <select v-model="campaign.conversion_duration">
                    <option :key="conversion.value" :value="conversion.value" v-for="conversion in $tm('campaign.edit.parameters.conversion_duration.durations')"> {{ conversion.label }}</option>
                  </select>
                </label>

                <!-- Autre options -->
                <div class="divider"></div>
                <label for="utm-source">{{ $t('campaign.edit.parameters.utm_source_title') }}</label>
                <input id="utm-source" v-model="campaign.utm.source" @change="setUtmValue('source', $event)">
                <label for="utm-medium">{{ $t('campaign.edit.parameters.utm_medium_title') }}</label>
                <input id="utm-medium" v-model="campaign.utm.medium" @change="setUtmValue('medium', $event)">
                <label for="utm-name">{{ $t('campaign.edit.parameters.utm_name_title') }}</label>
                <input id="utm-name" v-model="campaign.utm.name" @change="setUtmValue('name', $event)">
              </div>
            </div>

            <!-- Gauge -->
            <div class="panel">
              <div class="title">
                <b>{{ $t('campaign.edit.recommendations.title') }}
                <i class="fas fa-question-circle tooltip-icon" v-tooltip="$t('campaign.edit.recommendations.tooltip')"></i>
              </b>
              </div>

              <apexchart
                type="radialBar"
                height="300"
                :options="chart_options"
                :series="[gauge.score]">
              </apexchart>

              <ul class="recommandations">
                <li v-for="recommendation of gauge.recommendations">
                  <i class="far fa-check-circle"></i>
                  {{ recommendation }}
                </li>

                <li v-if="gauge.recommendations_creatives.size && campaign.media_type === 'banner'">
                  {{ $t('campaign.edit.recommendations.missing_sizes') }}:
                  <span v-for="(r, i) in gauge.recommendations_creatives">
                    {{ r }}<span v-if="i !== gauge.recommendations_creatives.length - 1">, </span>
                  </span>
                </li>
              </ul>

            </div>

            <!-- Cost -->
            <div class="panel">
              <div class="title">
                <b>{{ $t('campaign.edit.parameters.costs_panel.title') }}</b>
              </div>
              <p>{{ $t('campaign.edit.parameters.costs_panel.text') }}</p>
              <h3>{{ current_cpm }}$ CPM</h3>
            </div>

          </div>
        </div>

        <aside class="alert danger" v-if="fields_errors.length">
          <p>
            <i class="fas fa-exclamation-triangle"></i>
            <b>{{ $t('campaign.edit.fields_errors.title') }}</b>
          </p>
          <ul>
            <li v-for="error in fields_errors">{{ error }}</li>
          </ul>
        </aside>

        <aside class="alert danger" v-if="!is_payment_valid">
          <div class="row">
            <div class="col-md-8">
              <p>
                <i class="fas fa-exclamation-triangle"></i>
                <b>{{ $t('campaign.edit.fields_errors.errors.payment_not_valid_title') }}</b>
              </p>
              <p>{{ $t('campaign.edit.fields_errors.errors.payment_not_valid_text') }}</p>
            </div>
            <div class="col-md-4 text-right">
              <a href="#/account/credit-cards" class="btn danger">{{ $t('campaign.edit.fields_errors.errors.payment_not_valid_btn') }}</a>
            </div>
          </div>
        </aside>

        <!-- Actions -->
        <hr>
        <div class="row actions-bar">
          <div class="col-md-6">
            <a class="btn danger" v-if="campaign.status === 'draft'" @click="deleteCampaign">
              <i class="far fa-trash-alt"></i>
              <span class="pl-1">{{ $t('campaign.edit.footer.deleting') }}</span>
            </a>
          </div>
          <div class="col-md-6 right-actions">
            <a class="btn publish" v-if="campaign.status === 'draft'" @click="checkCampaign" :class="{ 'disabled': unsaved_changes || !is_payment_valid }" data-testid="publish-campaign">
              <i class="fas fa-play"></i>
              <span class="pl-2 pl-lg-1">{{ $t('campaign.edit.footer.publish_campaign') }}</span>
            </a>
            <a class="btn success" @click="saveCampaign" :class="{ 'disabled': !unsaved_changes }" data-testid="save-campaign">
              <i class="fas fa-save"></i>
              <span class="pl-2" v-if="campaign.status === 'draft'">{{ `${$t('campaign.edit.footer.save_draft')}` }}</span>
              <span class="pl-2" v-else>{{ `${$t('campaign.edit.footer.save')}` }}</span>
            </a>
          </div>
        </div>
      </div>

      <!-- Confirmation popup -->
      <div class="popup" v-if="show_popup">
        <div class="container">
          <div class="panel">
            <div class="title">
              <div class="close" @click="show_popup = false">
                <i class="fas fa-times"></i>
              </div>
              <h2>{{ $t('campaign.edit.activate_campaign_popup.title') }}</h2>
            </div>

            <br>
            <div v-html="$t('campaign.edit.activate_campaign_popup.text')"></div>
            <br>

            <div class="footer">
              <aside class="actions">
                <a class="btn" @click="show_popup = false">
                  {{ $t('campaign.edit.activate_campaign_popup.cancel_btn') }}
                </a>
                <a class="btn success" @click.once="activateCampaign()" data-testid="confirm-publish-campaign">
                  {{ $t('campaign.edit.activate_campaign_popup.publish_btn') }}
                </a>
              </aside>
            </div>
          </div>
        </div>
      </div>

      <!-- Pending -->
      <campaign-pending v-if="campaign.status === 'pending'"></campaign-pending>
    </div>
  </div>
</template>

<script setup>
  import organizationsSelect from '../components/organizationsSelect.vue';
  import fencesBuilder from '../components/fencesBuilder.vue';
  import creativesHandler from '../components/creativesHandler.vue';
  import flightsConfigurator from '../components/flightsConfigurator.vue';
  import campaignPending from '../components/campaignPending.vue';
  import {gaugeOptions} from '../../js/init';
  import {usePayment} from '../composables/payment.js';
  import {useCampaign} from '../composables/campaign.js';
  import {inject, watch, ref, reactive, computed} from 'vue';
  import {useI18n} from 'vue-i18n';
  import {onBeforeRouteLeave, useRoute, useRouter} from 'vue-router';
  import {toast} from 'vue3-toastify';

  const {t} = useI18n();
  const route = useRoute();
  const router = useRouter();

  const {
    is_payment_valid,
    validatePayment
  } = usePayment();

  const event_hub = inject('event-hub');
  const sty = inject('sentry');
  const axios = inject('axios');

  const is_loading = ref(false);
  const error = ref(false);
  const advanced_settings = ref(false);
  const unsaved_changes = ref(false);
  const show_popup = ref(false);

  const campaign = reactive({});

  const chart_options = reactive({
    ...gaugeOptions,
    labels: [t('campaign.edit.recommendations.gauge_label')]
  });

  const current_cpm = computed(() => {
    return JSON.parse(localStorage.user_data).organization.markup[campaign.type][campaign.media_type].value
      ? JSON.parse(localStorage.user_data).organization.markup[campaign.type][campaign.media_type].value
      : 9;
  });

  const {fields_errors, validateCampaign} = useCampaign(campaign, current_cpm);

  const computed_campaign = computed(() => {
    return Object.assign({}, JSON.parse(JSON.stringify(campaign)));
  });

  const total_budget = computed(() => {
    let budget = campaign.budget_flights.reduce((total_budget, flight) => total_budget + (flight.value ? flight.value : 0), 0);
    return campaign.budget_metric === 'impression' ? budget / 1000 * current_cpm.value : budget;
  });

  const total_duration = computed(() => {
    return campaign.budget_flights.reduce((total_duration, flight) => {
      if (flight.dates.start && flight.dates.end) {
        let time_between_dates = (new Date(flight.dates.end) - new Date(flight.dates.start)) / (1000 * 3600 * 24);
        return total_duration + time_between_dates;
      }

      return total_duration;
    }, 0);
  });

  const valid_budget_flights = computed(() => {
    for (const budget_flight in campaign.budget_flights) {
      if (budget_flight.dates.end === "" || budget_flight.dates.start === "") {
        return false;
      }
    }
    return true;
  });

  const gauge = computed(() => {
    let score = 100;
    const recommendations = new Set();
    const recommendations_creatives = new Set(['300x250', '320x50', '728x90', '300x600']);

    if (!total_budget.value) {
      recommendations.add(t('campaign.edit.recommendations.increase_budget'));
    }

    if (!valid_budget_flights) {
      recommendations.add(t('campaign.edit.fields_errors.errors.campaign_valid_dates'));
      score -= 5;
    }

    if (!(total_budget.value && (total_budget.value / total_duration.value >= 16))) {
      recommendations.add(t('campaign.edit.recommendations.increase_budget'));
      score -= 5;
    }

    if (!validFences(campaign.target_fences)) {
      score -= 40;
    } else {
      if (campaign.target_fences.length < 10) {
        recommendations.add(t('campaign.edit.recommendations.increase_target_fences'));
        score -= 20 - campaign.target_fences.length * 2;
      } else if (campaign.target_fences.length > 1500) {
        recommendations.add(t('campaign.edit.recommendations.decrease_target_fences'));
        score -= 20;
      }

      if (!(campaign.target_fences.length && total_budget.value && (total_budget.value / campaign.target_fences.length) >= 50)) {
        recommendations.add(t('campaign.edit.recommendations.increase_budget'));
        score -= 20;
      } else if (!(campaign.target_fences.length && total_budget.value && (total_budget.value / campaign.target_fences.length) < 1000)) {
        recommendations.add(t('campaign.edit.recommendations.increase_target_fences'));
        score -= 20;
      }
    }

    if (campaign.goal === 'foot_traffic') {
      if (!validFences(campaign.conversion_fences)) {
        recommendations.add(t('campaign.edit.recommendations.increase_conversion_fences'));
        score -= 20;
      }
    } else if (campaign.goal === 'clicks') {
      recommendations.add(t('campaign.edit.recommendations.goal_clicks'));
      score -= 20;
    }

    if (campaign.media_type === 'banner') {
      let creative_score = 0;
      let not_required_creatives = 0;
      score -= 15;

      if (campaign.creatives.length < 5) {
        recommendations.add(t('campaign.edit.recommendations.increase_creatives'));
        score -= 15;
      }

      for (const creative of campaign.creatives) {
        if (creative.dimensions.width === 300 && creative.dimensions.height === 250 && recommendations_creatives.has('300x250')) {
          creative_score += 3;
          recommendations_creatives.delete('300x250');
        } else if (creative.dimensions.width === 320 && creative.dimensions.height === 50 && recommendations_creatives.has('320x50')) {
          creative_score += 3;
          recommendations_creatives.delete('320x50');
        } else if (creative.dimensions.width === 728 && creative.dimensions.height === 90 && recommendations_creatives.has('728x90')) {
          creative_score += 3;
          recommendations_creatives.delete('728x90');
        } else if (creative.dimensions.width === 300 && creative.dimensions.height === 600 && recommendations_creatives.has('300x600')) {
          creative_score += 3;
          recommendations_creatives.delete('300x600');
        } else if ((creative.dimensions.width === 160 && creative.dimensions.height === 600)
          || (creative.dimensions.width === 970 && creative.dimensions.height === 250)
          || (creative.dimensions.width === 320 && creative.dimensions.height === 480)
          || (creative.dimensions.width === 320 && creative.dimensions.height === 100)
          || (creative.dimensions.width === 336 && creative.dimensions.height === 280)) {
          not_required_creatives = 3;
        }
      }

      score += creative_score + not_required_creatives;
    } else if (campaign.media_type === 'video') {
      if (campaign.creatives && campaign.creatives.length < 3) {
        recommendations.add(t('campaign.edit.recommendations.increase_creatives_video'));
        score -= 30 - campaign.creatives.length * 10;
      }
    }

    return {
      score: score,
      recommendations: recommendations,
      recommendations_creatives: recommendations_creatives
    };
  });

  const validFences = (fences) => {
    for (const fence of fences) {
      if (fence.status !== 'valid') {
        return false;
      }
    }

    return !!fences.length;
  };

  const copyCampaign = () => {
    is_loading.value = true;
    error.value = false;

    return axios.put(`${process.env.API_URL}/campaigns/${campaign._id}/copy`)
      .then((res) => {
        router.push({path: `/geomarketing/campaigns/edit/${res.data._id}`, query: {duplicated: true, new: true}});
        is_loading.value = false;
      })
      .catch((err) => {
        error.value = true;
        is_loading.value = false;
        sty.setContext('data', campaign._id);
        sty.captureException(err);
        toast.error(t('campaign.edit.duplicate.error'));
      });
  };

  const toggleAdvancedSettings = () => {
    advanced_settings.value = !advanced_settings.value;
  };

  const setUtmValue = (field, e) => {
    campaign.utm[field] = e.target.value;
  };

  const saveCampaign = () => {
    is_loading.value = true;
    error.value = false;

    if (campaign.status !== 'draft') {
      validateCampaign();
    } else {
      fields_errors.value = [];
    }

    validatePayment(campaign.organization._id);

    if (fields_errors.value.length) {
      is_loading.value = false;
    } else {
      const data = {
        organization: campaign.organization._id,
        name: campaign.name,
        goal: campaign.goal,
        budget_type: campaign.budget_type,
        budget_flights: campaign.budget_flights,
        budget_metric: campaign.budget_metric,
        conversion_fences: campaign.conversion_fences,
        target_fences: campaign.target_fences,
        media_type: campaign.media_type,
        creatives: campaign.creatives,
        utm: campaign.utm,
        recency: campaign.recency,
        conversion_duration: campaign.conversion_duration,
        status: campaign.status
      };

      return axios.put(`${process.env.API_URL}/campaigns/${route.params._id}`, data)
        .then((res) => {
          unsaved_changes.value = 'skip';
          Object.assign(campaign, res.data);
          is_loading.value = false;
          toast.success(t('campaign.edit.save.success'));
        })
        .catch((err) => {
          unsaved_changes.value = true;
          is_loading.value = false;
          toast.error(t('campaign.edit.save.error'));
          sty.setContext('data', campaign);
          sty.captureException(err);
        }).finally(() => {
          if (route.query.duplicated === 'true') {
            router.replace({'query.duplicated': null});
          }
          if (route.query.new === 'true') {
            router.replace({'query.new': null});
          }
        });
    }
  };

  const getCampaign = () => {
    is_loading.value = true;
    error.value = false;

    axios.get(`${process.env.API_URL}/campaigns/${route.params._id}`)
      .then((res) => {
        Object.assign(campaign, res.data);
        validatePayment(campaign.organization._id);
        is_loading.value = false;
      })
      .catch((err) => {
        error.value = true;
        is_loading.value = false;
        toast.error(t('campaign.edit.fetch_error'));
        sty.setContext('data', campaign);
        sty.captureException(err);
      }).finally(() => {
        if (route.query.duplicated === 'true') {
          toast.success(t('campaign.edit.duplicate.success'));
        }
      });
  };

  const activateCampaign = () => {
    is_loading.value = true;
    error.value = false;
    show_popup.value = false;

    validateCampaign();

    if (!fields_errors.value.length) {
      axios.put(`${process.env.API_URL}/campaigns/${route.params._id}/activate`, {})
        .then((res) => {
          unsaved_changes.value = 'skip';
          Object.assign(campaign, res.data);
          is_loading.value = false;
          toast.success(t('campaign.edit.status_update.active'));
        })
        .catch((err) => {
          is_loading.value = false;
          error.value = true;
          toast.error(t('campaign.edit.status_update.error'));
          sty.setContext('data', campaign);
          sty.captureException(err);
        });
    } else {
      is_loading.value = false;
    }
  };

  const checkCampaign = () => {
    validateCampaign();

    if (!fields_errors.value.length) {
      validatePayment(campaign.organization._id);
      show_popup.value = true;
    }
  };

  const updateCampaignStatus = (action) => {
    validateCampaign();

    if (fields_errors.value.length) {
      toast.error(t('campaign.edit.status_update.error'));
    } else {
      is_loading.value = true;
      error.value = false;

      const url = `${process.env.API_URL}/campaigns/${campaign._id}/${action}`;

      return axios.put(url)
        .then(() => getCampaign())
        .then(() => {
          is_loading.value = false;
          toast.success(t('campaign.edit.status_update.update'));
        })
        .catch((err) => {
          error.value = true;
          is_loading.value = false;
          toast.error(t('campaign.edit.status_update.error_update'));
          sty.setContext('data', campaign);
          sty.captureException(err);
        });
    }
  };

  const deleteCampaign = () => {
    is_loading.value = true;
    error.value = false;

    const url = `${process.env.API_URL}/campaigns/${campaign._id}`;

    return axios.delete(url)
      .then((res) => {
        is_loading.value = false;
        router.push({path: '/geomarketing/campaigns'});
      })
      .catch((err) => {
        error.value = true;
        is_loading.value = false;
        toast.error(t('campaign.edit.status_update.error_update'));
        sty.setContext('data', campaign);
        sty.captureException(err);
      });
  };

  const updateMediaType = (type) => {
    campaign.media_type = type;
  };

  watch(computed_campaign, (newVal, oldVal) => {
    if (JSON.stringify(oldVal) !== '{}') {
      if (unsaved_changes.value === 'skip') {
        unsaved_changes.value = false;
      } else {
        const has_changes = JSON.stringify(newVal) !== JSON.stringify(oldVal);

        unsaved_changes.value = has_changes;
      }
    }
  });

  watch(() => route.params._id, (new_id, old_id) => {
    if (new_id !== old_id) {
      getCampaign();
    }
  });

  const beforeBrowserUnload = (event) => {
    if (unsaved_changes.value && process.env.ENV !== 'DEV') {
      event.preventDefault();
      event.returnValue = t('campaign.edit.unsaved_changes.app_navigation');
      return t('campaign.edit.unsaved_changes.app_navigation');
    }
  };

  onBeforeRouteLeave((to, from, next) => {
    if (unsaved_changes.value === true && process.env.ENV !== 'DEV') {
      if (window.confirm(t('campaign.edit.unsaved_changes.app_navigation'))) {
        window.removeEventListener('beforeunload', beforeBrowserUnload);
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  });

  getCampaign();

  event_hub.on('organization_update', (organization) => {
    if (campaign.organization._id !== organization._id) {
      campaign.organization = organization;
    }
  });
  event_hub.on('creatives_update', (creatives) => campaign.creatives = creatives);
  event_hub.on('target_fences_update', (fences) => campaign.target_fences = fences);
  event_hub.on('conversion_fences_update', (fences) => campaign.conversion_fences = fences);

  window.addEventListener('beforeunload', beforeBrowserUnload);

</script>
