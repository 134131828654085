import {ref} from 'vue';
import {useI18n} from "vue-i18n";
import {BLOCKED_DOMAINS} from '../../js/blocked-domains';

export function useCampaign(data, current_cpm) {
  const {t} = useI18n();
  const fields_errors = ref([]);

  const validateCampaign = () => {
    fields_errors.value = [];

    // Campaign info
    if (!data.name) {
      fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_name'));
    }

    if (!data.organization) {
      fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_organization'));
    }

    if (!data.goal) {
      fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_goal'));
    }

    if (!data.budget_type) {
      fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_budget_type'));
    }

    if (!data.budget_metric) {
      fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_budget_metric'));
    }

    // Campaign utm
    if (!data.utm.medium) {
      fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_utm_medium'));
    }

    if (!data.utm.name) {
      fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_utm_name'));
    }

    if (!data.utm.source) {
      fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_utm_source'));
    }

    // Budget flights
    for (let i = 0; i < data.budget_flights.length; i++) {
      const flight = data.budget_flights[i];

      if (!(flight.dates.start && flight.dates.end)) {
        fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_valid_dates'));
      }

      if (data.status === 'draft') {
        const today = new Date(new Date().setHours(0, 0, 0, 0));
        const start_date = new Date(flight.dates.start);

        if (new Date(start_date.getUTCFullYear(), start_date.getUTCMonth(), start_date.getUTCDate()) < today) {
          fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_past_dates'));
        }
      }

      if (data.budget_metric === 'money') {
        if (!flight.value || flight.value < 75) {
          fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_period_budget_money'));
        }
      } else if (data.budget_metric === 'impression') {
        if (!flight.value || flight.value < (75 / current_cpm || 10 * 1000)) {
          fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_period_budget_impressions'));
        }
      }

      const overlaps = data.budget_flights.filter((f, j) => {
        if (i === j) {
          return false;
        } else if ((flight.dates.start > f.dates.start && f.dates.start > flight.dates.end) ||
          (flight.dates.start < f.dates.end && f.dates.end < flight.dates.end) ||
          (flight.dates.start < f.dates.start && f.dates.end < flight.dates.end)) {
          return true;
        }
        return false;
      });

      if (overlaps.length) {
        fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_dates_overlap'));
        break;
      }
    }

    // Target fences
    if (!data.target_fences.length) {
      fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_target_fences_length'));
    } else if (data.target_fences.length > 5000) {
      fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_target_fences_maximum'));
    } else {
      for (let fence of data.target_fences) {
        if (!fence.label) {
          fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_target_fence_name'));
        }
        if (fence.status !== 'valid') {
          fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_target_fences_validation'));
        }
      }
    }

    // Conversion fences
    if (data.conversion_fences.length > 5000) {
      fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_conversion_fences_maximum'));
    } else if (data.conversion_fences.length) {
      for (let fence of data.conversion_fences) {
        if (!fence.label) {
          fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_conversion_fence_name'));
        }
        if (fence.status !== 'valid') {
          fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_conversion_fences_validation'));
        }
      }
    }

    // Creatives
    if (!data.creatives.length) {
      fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_creatives_length'));
    } else if (data.creatives.length > 50) {
      fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_creatives_length_max'));
    } else {
      for (let creative of data.creatives) {
        if (!creative.name) {
          fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_creative_name'));
        }

        if (!creative.dimensions.width && !creative.dimensions.height) {
          fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_creative_dimensions'));
        }

        if (!creative.target_url.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,9}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi)) {
          fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_creative_url'));
        }

        const blocked_domains_regex = BLOCKED_DOMAINS.map((domain) => new RegExp(`(https?:\\/\\/)?(www\\.)?(${domain.replace('.', '\\.')})(\\/|$)`, 'i'));

        if (blocked_domains_regex.some((regex) => creative.target_url.match(regex))) {
          fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_creative_blocked'));
        }

        if (creative.target_url.includes("'") || creative.target_url.includes('%27')) {
          fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_creative_contains'));
        }

        if (creative.target_url.includes('utm_')) {
          fields_errors.value.push(t('campaign.edit.fields_errors.errors.campaign_creative_utm'));
        }
      }
    }

    fields_errors.value = Array.from(new Set(fields_errors.value));
  };

  return {
    fields_errors,
    validateCampaign
  };
}
