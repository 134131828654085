<template>
  <div class="insights">
    <div class="row">

      <div class="col-lg-6">
        <div class="panel">
          <div class="title">
            <b>{{ $t('components.insights.gender_chart_title') }}</b>
          </div>

          <loading :loading="is_loading" :error="error" :is_panel="false"></loading>

          <div id="gender-chart" class="chart-wrapper" v-if="!is_loading && !error && stats.gender.values.length">
            <apexchart
              ref="age-chart"
              height="300"
              type="donut"
             :options="gender_chart_options"
             :series="stats.gender.values">
            </apexchart>
          </div>
          <div v-if="!is_loading && !error && !stats.gender.values.length">
            <p class="text-center">{{ $t('components.insights.msg_no_data') }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="panel">
          <div class="title">
            <b>{{ $t('components.insights.age_chart_title') }}</b>
          </div>

          <loading :loading="is_loading" :error="error" :is_panel="false"></loading>

          <div id="age-chart" class="chart-wrapper" v-if="!is_loading && !error && stats.age.values.length">
            <apexchart
              ref="age-chart"
              height="300"
             type="donut"
             :options="age_chart_options"
             :series="stats.age.values">
            </apexchart>
          </div>
          <div v-if="!is_loading && !error && !stats.age.values.length">
            <p class="text-center">{{ $t('components.insights.msg_no_data') }}</p>
          </div>
        </div>
      </div>

    </div>

    <div class="row">

      <div class="col-lg-6">
        <div class="panel">
          <div class="title">
            <b>{{ $t('components.insights.income_chart_title') }}</b>
          </div>

          <loading :loading="is_loading" :error="error" :is_panel="false"></loading>

          <div id="income-chart" class="chart-wrapper" v-if="!is_loading && !error && stats.income.values.length">
            <apexchart
              ref="income-chart"
              height="300"
              type="donut"
              :options="income_chart_options"
              :series="stats.income.values">
            </apexchart>
          </div>
          <div v-if="!is_loading && !error && !stats.income.values.length">
            <p class="text-center">{{ $t('components.insights.msg_no_data') }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="panel">
          <div class="title">
            <b>{{ $t('components.insights.education_chart_title') }}</b>
          </div>

          <loading :loading="is_loading" :error="error" :is_panel="false"></loading>

          <div id="education-chart" class="chart-wrapper" v-if="!is_loading && !error && stats.education.values.length">
            <apexchart
              ref="income-chart"
              height="300"
              type="donut"
              :options="education_chart_options"
              :series="stats.education.values">
            </apexchart>
          </div>
          <div v-if="!is_loading && !error && !stats.education.values.length">
            <p class="text-center">{{ $t('components.insights.msg_no_data') }}</p>
          </div>
        </div>
      </div>

    </div>

    <div class="row">

      <div class="col-lg-6">
        <div class="panel">
          <div class="title">
            <b>{{ $t('components.insights.matrial_status_chart_title') }}</b>
          </div>

          <loading :loading="is_loading" :error="error" :is_panel="false"></loading>

          <div id="marital-status-chart" class="chart-wrapper" v-if="!is_loading && !error && stats.marital_status.values.length">
            <apexchart
              ref="income-chart"
              height="300"
              type="donut"
              :options="marital_status_chart_options"
              :series="stats.marital_status.values">
            </apexchart>
          </div>
          <div v-if="!is_loading && !error && !stats.marital_status.values.length">
            <p class="text-center">{{ $t('components.insights.msg_no_data') }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="panel">
          <div class="title">
            <b>{{ $t('components.insights.ethnicity_chart_title') }}</b>
          </div>

          <loading :loading="is_loading" :error="error" :is_panel="false"></loading>

          <div id="ethnicity-chart" class="chart-wrapper" v-if="!is_loading && !error && stats.ethnicity.values.length">
            <apexchart
              ref="age-chart"
              height="300"
              type="donut"
              :options="ethnicity_chart_options"
              :series="stats.ethnicity.values">
            </apexchart>
          </div>
          <div v-if="!is_loading && !error && !stats.ethnicity.values.length">
            <p class="text-center">{{ $t('components.insights.msg_no_data') }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script setup>
  import {ref, reactive, inject} from 'vue';
  import querystring from 'querystring';
  import {useRoute} from 'vue-router';

  const route = useRoute();

  const axios = inject('axios');
  const sty = inject('sentry');

  const default_options = {
    labels: [],
    colors: ['#ffa000', '#008060', '#3498db', '#d82c0d', '#ffc453', '#9b59b6', '#8a8a8a'],
    stroke: {
      width: 0
    },
    dataLabels: {
      dropShadow: {
        enabled: false
      }
    }
  };

  const is_loading = ref(false);
  const error = ref(false);

  const gender_chart_options = reactive({
    ...default_options
  });

  const age_chart_options = reactive({
    ...default_options
  });

  const income_chart_options = reactive({
    ...default_options
  });

  const education_chart_options = reactive({
    ...default_options
  });

  const marital_status_chart_options = reactive({
    ...default_options
  });

  const ethnicity_chart_options = reactive({
    ...default_options
  });

  const stats = reactive({});

  const getStats = () => {
    is_loading.value = true;
    error.value = false;

    const query = {
      client_id: route.params.org_id
    };
    const url =
      `${process.env.API_URL}/campaigns/${route.params.campaign_id}/reports/insights?${querystring.stringify(query)}`;

    return axios.get(url)
      .then((res) => {
        Object.assign(stats, res.data);
        income_chart_options.labels = res.data.income.labels;
        age_chart_options.labels = res.data.age.labels;
        marital_status_chart_options.labels = res.data.marital_status.labels;
        gender_chart_options.labels = res.data.gender.labels;
        education_chart_options.labels = res.data.education.labels;
        ethnicity_chart_options.labels = res.data.ethnicity.labels;

        is_loading.value = false;
      })
      .catch((err) => {
        error.value = true;
        is_loading.value = false;
        sty.captureException(err);
      });
  };

  getStats();
</script>
