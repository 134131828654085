<template>
  <div>
    <aside v-if="data.alert_note" class="alert warning"><i class="fa fa-exclamation-circle"></i> {{ data.alert_note }}</aside>

    <iframe
      class="custom-report"
      :class="{'panel': is_document}"
      :src=data.custom_url
      scrolling="yes"
      frameBorder="0">
    </iframe>

  </div>
</template>

<script setup>
  import {computed} from 'vue';

  const props = defineProps({
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    is_preview: {
      type: Boolean,
      required: false,
      default: false
    }
  });

  const is_document = computed(() => {
    return props.data.custom_url.includes('file') || props.data.custom_url.includes('spreadsheet');
  });
</script>
