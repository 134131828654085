<template>
  <div>
    <ul>
      <li>{{ goals[params.data.goal] }}</li>
      <li v-if="params.data.target_fences.length === 1">{{ params.data.target_fences.length }} {{ $t('samples.table.target_fence') }}</li>
      <li v-if="params.data.target_fences.length > 1">{{ params.data.target_fences.length }} {{ $t('samples.table.target_fences') }}</li>
      <li>{{ $t('samples.table.recency') }}: {{ recencies[params.data.recency] }}</li>
    </ul>
  </div>
</template>

<script setup>
  import {useI18n} from 'vue-i18n';

  const {t} = useI18n();

  const recencies =  {
    '1_month': '1 ' + t('samples.table.month'),
    '2_weeks': '2 ' + t('samples.table.weeks'),
    '1_weeks': '1 ' + t('samples.table.week'),
    '1_day': '1 ' + t('samples.table.day'),
    '30_minutes': '30 ' + t('samples.table.minutes'),
    '15_minutes': '15 ' + t('samples.table.minutes'),
    '10_minutes': '10 ' + t('samples.table.minutes'),
    '5_minutes': '5 ' + t('samples.table.minutes'),
    'instant': t('samples.table.instant')
  };
  const goals =  {
    'foot_traffic': t('samples.table.goal_foot_traffic'),
    'clicks': t('samples.table.goal_clicks'),
    'announcement': t('samples.table.goal_announcement'),
    'notoriety': t('samples.table.goal_notoriety')
  };
</script>
