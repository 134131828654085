<template>
  <div>
    <span class="ag-filter-title">{{ params.colDef.headerName }}</span>
    <select v-model="filter" @change="onFilterChanged">
      <option value="">{{ $t('geoindicators.reports.table.filter_client_placeholder') }}</option>
      <option v-for="option in options" :key="option" :value="option">
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script>
  import {ref} from 'vue';

  export default {
    props: {
      params: {
        type: Object,
        required: true
      }
    },

    setup(props) {
      const filter = ref("");
      const options = ref([]);

      const afterGuiAttached = () => {
        options.value = getUniqueValues();
      };

      const getUniqueValues = () => {
        const unique_values = new Set();

        props.params.api.forEachNode((node) => {
          if (node.data?.organization?.name) {
            unique_values.add(node.data.organization.name);
          }
        });

        return Array.from(unique_values);
      };

      const onFilterChanged = () => {
        props.params.api.onFilterChanged();
      };

      const isFilterActive = () => {
        return filter.value !== "";
      };

      const doesFilterPass = (node) => {
        return !filter.value || node.data?.organization?.name === filter.value;
      };

      return {
        filter,
        options,
        afterGuiAttached,
        onFilterChanged,
        isFilterActive,
        doesFilterPass
      };
    }
  };
</script>
